import React, { useState }   from "react";
import { Nav, NavItem, Row } from "reactstrap";
import classnames            from 'classnames';
import { MyLink }            from "../index";
import { appPaths }          from "../../routes";
import CheckIt from "../utils/CheckIt";
import { userActionsKeys } from "../../util/userActions";

const FriendTab = ({from}) => {
  const [activeTab, setActiveTab] = useState(from);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Row>
      <Nav tabs className="w-100 mb-3">
      <CheckIt action={userActionsKeys.friendListLegal}>
        <NavItem>
          <MyLink to={"/" + appPaths.FriendList} className={classnames({active: activeTab === 'Friends of CSA'}, "nav-link")} onClick={() => { toggle('Friends of CSA'); }}>
            Friends of CSA
          </MyLink>
        </NavItem>
        </CheckIt>
        <CheckIt action={userActionsKeys.categoryListLegal}>
        <NavItem>
          <MyLink to={"/" + appPaths.CategoryList} className={classnames({active: activeTab === 'Category'}, "nav-link")} onClick={() => { toggle('Category'); }}>
            Category
          </MyLink>
        </NavItem>
        </CheckIt>
      </Nav>
    </Row>
  )
}

export default FriendTab