import React                                                                       from 'react';
import { Button, Container, CustomInput, Table }                                   from 'reactstrap';
import { ColumnSort, MyPagination, MyTooltip, Search, UserActionsModal, UserItem } from "../index";
import { FontAwesomeIcon }                                                         from "@fortawesome/react-fontawesome";
import { faFileExcel, faUndo }                                                     from "@fortawesome/free-solid-svg-icons";
import { useUserList }                                                             from "../../hooks";
import { appPaths }                                                                from "../../routes";
import { useHistory }                                                              from "react-router";
import { userService }                                                             from "../../services";
import { getTodayStringValue, setAlertNotification }                               from "../../actions/util";
import { saveAs }                                                                  from "file-saver";

let search = "";

const UserList = () => {

  const {users, pagination: paginationUser, getUsers: getUsersAction, updateUsersFromActiveDirectory, addActionUser, setUser, user} = useUserList({includeAction: true})
  const history = useHistory();

  return (
    <Container fluid className="user-list">
      <div className="d-flex justify-content-between">
        <h3>User List
          {" "}
          <Button style={{padding: "3px 8px 0"}} outline id="update-users-from-active-directory" color="secondary" onClick={() => {
            updateUsersFromActiveDirectory();
          }}><FontAwesomeIcon icon={faUndo}/></Button>

          <MyTooltip placement="top" target={"update-users-from-active-directory"}>
            Update Users From Active Directory
          </MyTooltip>
        </h3>
        <div className="flex-grow-1">
          <div className="float-right ml-3 mb-1">
            <Button onClick={() => {history.push(appPaths.NewAdvocate)}}>New Advocate</Button>
          </div>
          <Search defaultValue={paginationUser.filterName} onSearch={(value) => {
            search = value;
            getUsersAction(paginationUser.limit, 0, value, paginationUser.columnSort, paginationUser.descending)
          }}/>
          <div className="float-right mr-3">
            <CustomInput className="mt-3 text-center up-it" type="checkbox" name="isSendLegal" id="isSendLegal" checked={paginationUser.showDisabled} onChange={(e) => {
              getUsersAction(paginationUser.limit, 0, paginationUser.filterName, paginationUser.columnSort, paginationUser.descending, e.target.checked)
            }} label="Show Disabled"/>
          </div>
        </div>
      </div>

      <div className="cursor-pointer text-primary mb-1" onClick={() => {
        userService()
          .exportUsers(paginationUser.filterName, paginationUser.columnSort, paginationUser.descending, paginationUser.showDisabled)
          .then(res => {
            if (res.data) {
              saveAs(res.data, "Users-" + getTodayStringValue() + ".xlsx");
            }
          })
          .catch(err => {
            setAlertNotification(err?.response?.status === 403 ? "You don't have access to this action" : ("Error getting Users Report"), "danger");
          })
      }}>
        <FontAwesomeIcon icon={faFileExcel} color="green"/> Download
      </div>

      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th>#</th>
          <th>Name <ColumnSort columnName="Name" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsersAction(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          <th>Username <ColumnSort columnName="UserName" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsersAction(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          <th>Email <ColumnSort columnName="Email" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsersAction(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          {/*<th>Permissions</th>*/}
          <th className="text-center" style={{width: "117px"}}>Is Advocate <ColumnSort columnName="IsAdvocate" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsersAction(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          <th className="text-center" style={{width: "138px"}}>Change Status {paginationUser.showDisabled &&
          <ColumnSort columnName="Disabled" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsersAction(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/>}
          </th>
          <th className="text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        {users && users.length ?
          users.map((item, index) => (
            <UserItem key={index + "" + item.id + "" + paginationUser.skip} user={item} index={index} skip={paginationUser.skip} setUser={setUser} getUsersAction={getUsersAction}/>
          )) :
          <tr>
            <th colSpan="7" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getUsersAction()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {users.length > 0 && paginationUser.count > 10 &&
      <MyPagination
        count={paginationUser.count} limit={paginationUser.limit} skip={paginationUser.skip}
        action={(skip, limit) => {
          getUsersAction(limit, skip, search, paginationUser.columnSort, paginationUser.descending)
        }}/>}

      <UserActionsModal addActionUserAction={addActionUser} toggleModalAction={() => {setUser({})}} user={user.id ? users.filter(u => u.id === user.id)[0] : user}/>
    </Container>
  );
};

export default UserList;
