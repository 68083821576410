import * as types       from "../types";

export const member = {
  CRUDMember: (state = types.CREATE_MEMBER, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_MEMBER:
        return types.CREATE_MEMBER;
      case types.UPDATE_MEMBER:
        return types.UPDATE_MEMBER;
      case types.DELETE_MEMBER:
        return types.DELETE_MEMBER;
      default:
        return state
    }
  },
  getMember: (state = {}, action) => {
    switch (action.type) {
      case types.GET_MEMBER_SUCCESS:
        return action.data;
      case types.GET_MEMBER_FAILURE:
        return {};
      default:
        return state
    }
  },
  getMembers: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBERS_SUCCESS:
        return action.data;
      case types.GET_MEMBERS_FAILURE:
        return [];
      case types.RESET_MEMBERS:
        return [];
      default:
        return state
    }
  },
  member: (state = {}, action) => {
    switch (action.type) {
      case types.GET_MEMBER_SUCCESS:
        return action.data;
      case types.ADD_MEMBER:
        return action.data;
      case types.GET_MEMBER_FAILURE:
        return {};
      default:
        return state
    }
  },
  members: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBERS_SUCCESS:
        return action.data;
      case types.GET_MEMBERS_FAILURE:
        return [];
      case types.RESET_MEMBERS:
        return [];
      default:
        return state
    }
  },
  membersFromSite: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBERS_FROM_SITE_SUCCESS:
        return action.data;
      case types.GET_MEMBERS_FROM_SITE_FAILURE:
        return [];
      case types.RESET_MEMBERS_FROM_SITE_FAILURE:
        return [];
      default:
        return state
    }
  },
  paginationMember: (state = {count: 0, limit: 10, skip: 0, filterName: "", columnSort: "Name", descending: false}, action) => {
    switch (action.type) {
      case types.ADD_MEMBER_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_MEMBER_PAGINATION:
        return {};
      default:
        return state
    }
  },
  paginationMemberFromSite: (state = {count: 0, limit: 10, skip: 0, filterName: "", columnSort: "", descending: false}, action) => {
    switch (action.type) {
      case types.ADD_MEMBER_FROM_SITE_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_MEMBER_FROM_SITE_PAGINATION:
        return {};
      default:
        return state
    }
  },
  memberActiveTab: (state = "", action) => {
    switch (action.type) {
      case types.SET_MEMBER_ACTIVE_TAB:
        return state === action.data ? "" : action.data;
      case types.RESET_MEMBER_ACTIVE_TAB:
        return "";
      default:
        return state
    }
  },
  goToMemberView: (state = false, action) => {
    switch (action.type) {
      case types.GO_TO_MEMBER_VIEW:
        return !!action.data;
      case types.RESET_GO_TO_MEMBER_VIEW:
        return false;
      default:
        return state
    }
  }
};