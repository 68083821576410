import React, { useState } from 'react';
import DatePicker          from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker = (props) => {

  const [date, setDate] = useState(undefined);

  let tmp = {
    selected: date,
    ...props, onChange: (value, e) => {
      setDate(value);
      const inputValue = e.target.value;
      if (!inputValue || (inputValue && inputValue.replace(/\//g, "").length > 5)) {

        props.onChange && props.onChange(value === null ? undefined : value, e);
      }
    },
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: "select"
  };

  return (
    <DatePicker
      {...tmp}/>
  );
};

export default MyDatePicker;
