import * as types from "../types";

export const myDispatch = (type, data = {}) => {
  let object = {type};
  for (let i in data) {
    if (data.hasOwnProperty(i)) {
      object[i] = data[i];
    }
  }
  return object;
};

let dispatchAction;

export const registerDispatch = fn => dispatchAction = fn;
export const resetDispatch = () => dispatchAction = null;
export const getDispatch = () => dispatchAction;
export const dispatch = (type, data) => dispatchAction(myDispatch(type, data));

export const setAlertNotification = (message, color = "info") => {dispatch(types.ADD_ALERT_NOTIFICATION, {message, color})};

export const fetchingFn = (option) => {dispatch(option ? types.FETCHING : types.FETCHING_END)};

export const getDate = (date, ignoreZone) => {
  if (typeof date === "object" || (typeof date === "string" && date.length === 19 && date !== "0001-01-01T00:00:00" && date !== "1753-01-01T00:00:00")) {
    return dateFromISOToLocal(date, ignoreZone);
  }
  if (!date || (date && date.toString().trim().length !== 8) || date.toString().trim() === "00000000" || date.toString().trim() === "0000/00/00" || date === "0001-01-01T00:00:00" || date === "1753-01-01T00:00:00") {
    return undefined;
  }
  date = date.toString().replace('/', '');
  return new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2))
};

export const getDateValue = (value) => {
  return !value ? value : "" + new Date(value).getFullYear() + getZero(new Date(value).getMonth() + 1) + "" + getZero(new Date(value).getDate());
};

export const getZero = (date) => {
  date = date.toString();
  return date.toString().length === 1 ? 0 + "" + date : date;
};

export const dateFormatMonthDayYear = (date, ignoreZone) => {
  if (!(typeof date === "object" || (typeof date === "string" && !/^0001-01-01T.+/.test(date) && !/1753-01-01T.+/.test(date) && !/1900-01-01T.+/.test(date)))) {
    return "";
  }
  date = dateFromISOToLocal(date, ignoreZone);
  return `${getZero(date.getMonth() + 1)}/${getZero(date.getDate())}/${date.getFullYear()}`;
};

export const dateFormatMonthDayYearHoursMinute = (date, ignoreZone) => {
  if (!(typeof date === "object" || (typeof date === "string" && !/^0001-01-01T.+/.test(date) && !/1753-01-01T.+/.test(date) && !/1900-01-01T.+/.test(date)))) {
    return "";
  }
  date = dateFromISOToLocal(date, ignoreZone);
  return `${getZero(date.getMonth() + 1)}/${getZero(date.getDate())}/${date.getFullYear()} ${getZero(date.getHours())}:${getZero(date.getMinutes())}`;
};

export const checkEmptyValue = (value) => value && value !== "undefined" && value.toString().trim() ? value : "-";

export const moneyFormat = (value) => (value ? "$" + value : "-");

export const phoneFormat = (value) => {
  if (value) {
    value = value.toString().replace("null", "").replace("undefined", "");
    const tmp = value.replace(/\s/gi, '')
      .replace(/\s/gi, '')
      .replace(/\(/gi, '')
      .replace(/\)/gi, '')
      .replace(/-/gi, '');
    if (tmp.length === 10) {
      return "(" + tmp.substr(0, 3) + ") " + tmp.substr(3, 3) + "-" + tmp.substr(6, 4);
    } else if (tmp.length === 7) {
      return tmp.substr(0, 3) + "-" + tmp.substr(3, 4);
    }
  }
  return value;
};

export const getTodayStringValue = () => {
  const today = new Date();
  return "" + today.getFullYear() + getZero(today.getMonth() + 1) + "" + getZero(today.getDate()) + "" + getZero(today.getHours()) + "" + getZero(today.getMinutes()) + "" + getZero(today.getSeconds());
};

export const getUniqueKey = () => Math.floor(Math.random() * 10000) + '' + Math.floor(Math.random() * 10000);

export const checkDateFromISOToLocal = (date) => {
  return !(!date || /^0001-01-01T.+/.test(date) || /1753-01-01T.+/.test(date) || /1900-01-01T.+/.test(date));

}
export const dateFromISOToLocal = (date, ignoreZone) => {
  if (!date || /^0001-01-01T.+/.test(date) || /1753-01-01T.+/.test(date) || /1900-01-01T.+/.test(date)) {
    return undefined
  }
  if (typeof ignoreZone === "undefined") {
    let tmp = new Date(date);
    // check for last sync date and if the date id hour, minute, second and, millisecond equal to zero
    if ((tmp.getFullYear() <= 2021
      || (tmp.getFullYear() <= 2021 && tmp.getMonth() + 1 < 4)
      || (tmp.getFullYear() <= 2021 && tmp.getMonth() + 1 < 4 && tmp.getDate() < 30))
      && tmp.getHours() === 0 && tmp.getMinutes() === 0 && tmp.getSeconds() === 0 && tmp.getMilliseconds() === 0) {
      ignoreZone = true
    }
  }
  if (/T/.test(date) && !/Z$/.test(date) && !ignoreZone) {
    date = date + "Z"
  }
  return new Date(date)
};


export const naturalCompare = (a, b) => {
  let ax = [], bx = [];

  a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    ax.push([$1 || Infinity, $2 || ""])
  });
  b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    bx.push([$1 || Infinity, $2 || ""])
  });

  while (ax.length && bx.length) {
    let an = ax.shift();
    let bn = bx.shift();
    let nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }

  return ax.length - bx.length;
};

export const compareAtr = (a, b) => a < b ? -1 : a > b ? 1 : 0;

export const logs = (...logs) => {
  if (process.env.NODE_ENV === "development") {
    console.log(...logs)
  }
};

export const cleanName = (name) => typeof name === "string" ? name.trim() : name;

export const copyTextToClipboard = (text) => {
  let textArea = document.createElement("textarea");

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    // let msg = successful ? 'successful' : 'unsuccessful';
     // console.log('Copying text command was ' + msg);
  } catch (err) {
    // console.log('Oops, unable to copy');
  } finally {
    document.body.removeChild(textArea);
  }

};

export const copySelection = () => {

  function getSelectedText() {
    let html = "";
    if (typeof window.getSelection != "undefined") {
      let sel = window.getSelection();
      if (sel.rangeCount) {
        let container = document.createElement("div");
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML;
      }
    } else if (typeof document.selection != "undefined") {
      if (document.selection.type === "Text") {
        html = document.selection.createRange().htmlText;
      }
    }
    return html
    // if only want text
    /*if (window.getSelection) {
     console.log(window.getSelection())
     return window.getSelection().toString();
     } else if (document.selection) {
     return document.selection.createRange().text;
     }
     return '';*/
  }

  window.addEventListener("mouseup", (e) => {
    let html = getSelectedText(), text = "", flag = true
    if (html) {
      const htmlTmp = html.match(/<.*?>.*?<\/.*?>/g)
      if (htmlTmp) {
        for (let t of htmlTmp) {
          t = t.replace(/<\/?[^>]+(>|$)/g, "")
          if (flag) {
            text += t + " "
            flag = false
          } else {
            text += t + "\n"
            flag = true
          }
        }
      } else {
        text = html.replace(/<\/?[^>]+(>|$)/g, "")
      }
      copyTextToClipboard(text)
    }
  })

}
