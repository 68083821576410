import { memberService, titleService } from "../services/index";

import * as types   from "../types";
import { dispatch } from "./util";
import messages     from "../util/messages";
import { appPaths } from "../routes";

export const getMemberAction = (id, updateMember, cb) => {
  dispatch(types.FETCHING);
  memberService().getMember({id})
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_MEMBER, res);
      if (cb && res && res.data) {
        cb(res.data)
      }
      if (updateMember) {
        dispatch(types.UPDATE_MEMBER);
      }
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_MEMBER, {data: {}});
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: "Member Not Found", color: 'danger'});
    });
};

export const getMemberBySocsecnoAction = (socsecno) => {
  dispatch(types.FETCHING);
  memberService().getMemberBySocsecno({socsecno})
    .then(res => {
      dispatch(types.FETCHING_END);
      const data = res.data ? res.data : {};
      dispatch(types.ADD_MEMBER, {data});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
    });
};

export const getMembersAction = (limit, skip, filterName, columnSort = "Name", descending = false) => {
  dispatch(types.FETCHING);
  if (!columnSort) {
    columnSort = "Name"
  }
  if (typeof descending === "undefined") {
    descending = false
  }
  memberService().getMembers(limit, skip, filterName, columnSort, descending)
    .then(res => {
      dispatch(types.GET_MEMBERS_SUCCESS, res);
      dispatch(types.ADD_MEMBER_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filterName,
          columnSort,
          descending
        }
      });
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const getAllTitlesAction = () => {
  titleService().getTitlesAll()
    .then(res => {

    })
    .catch((err) => {

    });
};

export const getMembersFilterByFileNoAndSocSecNoAction = (limit, skip, fileNo, socSecNo, columnSort, descending) => {
  dispatch(types.FETCHING);
  memberService().getMembersFilterByFileNoAndSocSecNo(limit, skip, fileNo, socSecNo, columnSort, descending)
    .then(res => {
      dispatch(types.GET_MEMBERS_SUCCESS, res);
      dispatch(types.ADD_MEMBER_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filterName: "",
          columnSort,
          descending
        }
      });
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const getMembersFromSiteAction = (siteId, limit, skip, filterName, columnSort, descending, isWorkSite) => {
  dispatch(types.FETCHING);
  memberService().getMembersFromSite(siteId, limit, skip, filterName, columnSort, descending, isWorkSite)
    .then(res => {
      dispatch(types.GET_MEMBERS_FROM_SITE_SUCCESS, res);
      dispatch(types.ADD_MEMBER_FROM_SITE_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filterName,
          columnSort,
          descending
        }
      });
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const addMemberAction = (member, history) => {
  dispatch(types.FETCHING);
  memberService().addMember(member)
    .then(res => {
      dispatch(types.ADD_MEMBER_SUCCESS);
      history.push({pathname: appPaths.MemberList});
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_MEMBER_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_MEMBER_FAILURE], color: 'danger'});
    });
};

export const updateMemberAction = (member, history, goToMemberView) => {
  dispatch(types.FETCHING);
  memberService().updateMember(member)
    .then(res => {
      dispatch(types.UPDATE_MEMBER_SUCCESS);
      history.push({pathname: appPaths.MemberList});
      dispatch(types.FETCHING_END);
      if (goToMemberView) {
        dispatch(types.TOGGLE_BIG_MODAL);
        dispatch(types.ADD_MEMBER, {data: member});
        getMemberAction(member.id)
      }
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_MEMBER_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_MEMBER_FAILURE], color: 'danger'});
    });
};

export const updateMemberFromSiteItemAction = (member) => {
  dispatch(types.FETCHING);
  memberService().updateMember(member)
    .then(res => {
      dispatch(types.UPDATE_MEMBER_SUCCESS);
      dispatch(types.ADD_MEMBER, {data: member});

      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_MEMBER_SUCCESS], color: 'info'});

    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_MEMBER_FAILURE], color: 'danger'});
    });
};

export const deleteMemberAction = (id, siteId) => {
  dispatch(types.FETCHING);
  memberService().deleteMember({id})
    .then(res => {
      if (siteId) {
        getMembersFromSiteAction(siteId);
      } else {
        getMembersAction();
      }
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_MEMBER_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_MEMBER_FAILURE], color: 'danger'});
    });
};
