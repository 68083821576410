import React, { useEffect }                                      from 'react';
import { Button, CustomInput, Table }                            from 'reactstrap';
import { CheckIt, ColumnSort, MyPagination, RatingItem, Search } from "../index";
import { useRatingList, useStatus, useTitle }                    from "../../hooks";
import { useHistory }                                            from "react-router";
import { appPaths }                                              from "../../routes";
import { userActionsKeys }                                       from "../../util/userActions";

let search = "";

const RatingList = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const history = useHistory();
  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();
  const {ratings, pagination, getRatings} = useRatingList();

  let items = ratings && ratings.map((rating, i) =>
    <RatingItem key={"member-item" + i} rating={ratings[i]} index={i} getJobDescription={getJobDescription} getCategory={getCategory}/>
  )

  const sortFn = (object) => {
    getRatings(pagination.limit, pagination.skip, search, object.columnSort, object.descending)
  };

  return (
    <div className="member-list">
      <div className="d-flex justify-content-between">
        <h3>Rating List</h3>
        <div className="flex-grow-1">
          <CheckIt action={userActionsKeys.addNewRatingLegal}>
            <div className="float-right ml-3 mb-1">
              <Button onClick={() => {history.push(appPaths.SiteAndMemberNewRating)}}>New Rating</Button>
            </div>
          </CheckIt>
          <Search defaultValue={pagination.filterName} onSearch={(value) => {
            search = value;
            getRatings(pagination.limit, 0, value, pagination.columnSort, pagination.descending)
          }}/>
          <div className="float-right mr-3">
            <CustomInput className="mt-3 text-center up-it" type="checkbox" name="isSendLegal" id="isSendLegal" checked={pagination.open === 0} onChange={(e) => {
              getRatings(pagination.limit, 0, search, pagination.columnSort, pagination.descending, e.target.checked ? 0 : 1)
            }} label="Show Closed"/>
          </div>
        </div>
      </div>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>Create Date <ColumnSort columnName="CreateDate" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "166px"}}>Hearing Date <ColumnSort columnName="HearingDate" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Rating Type <ColumnSort columnName="Type" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Member <ColumnSort columnName="Member" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Site <ColumnSort columnName="Site" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>School Year <ColumnSort columnName="SchoolYear" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th className="text-center" style={{minWidth: "94px"}}>Status {pagination.open === 0 && <ColumnSort columnName="IsOpen" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/>}</th>

        </tr>
        </thead>
        <tbody>
        {ratings.length ?
          items :
          <tr>
            <th colSpan="6" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getRatings()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {ratings.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getRatings(limit, skip, search, pagination.columnSort, pagination.descending)
      }}/>}

    </div>
  );
};

export default RatingList;
