import axios            from 'axios';
import { polyfill }     from 'es6-promise';
import { API_URL }      from "../config/app";
import { dispatch }     from "../actions/util";
import * as types       from "../types";
import messages         from "./messages";
import { logoutAction } from "../actions/general";

polyfill();

class RestApiClient {
  constructor(config) {
    this.client = axios.create(config);
  }

  request(options, noErrorMessage = false) {
    if (sessionStorage.getItem('tokenAuthLFS')) {
      if (!options['headers']) {
        options['headers'] = {};
      }
      if (!options['headers']['authorization']) {
        options['headers']['authorization'] = {};
      }

      options['headers']['authorization'] = `Bearer ${sessionStorage.getItem('tokenAuthLFS')}`;
    }
    let tmp = this.client.request(options);
    tmp.catch((err) => {
      if (err && err.response) {
        if (err?.response?.status === 401) {
          logoutAction();
          sessionStorage.setItem("reloadLFS", true);
        } else if (err?.response?.status === 403 && err.response?.config?.method !== "get") {
          dispatch(types.ADD_ALERT_NOTIFICATION, {message: err.response?.data?.message || "You don't have access to this action", color: 'danger'})
        }
      }
      if (err && err.message === "Network Error" && !noErrorMessage) {
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.CONNECTION_ERROR], color: 'danger'})
      }
    });
    return tmp;
  }
}

const createRestApiClient = () => ({
  withConfig: config => new RestApiClient(config)
});

export default createRestApiClient;

export const client = createRestApiClient().withConfig({baseURL: API_URL});
