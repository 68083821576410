import React, { useEffect, useRef, useState }                            from "react";
import { Badge, Button, Container, CustomInput, Row, Table }             from "reactstrap";
import { CheckIt, ColumnSort, FriendTab, MyLink, MyPagination, Search, } from "../index";
import { useCategories, useFriendList }                                  from "../../hooks";
import { useHistory }                                                    from "react-router";
import { appPaths }                                                      from "../../routes";
import { userActionsKeys }                                               from "../../util/userActions";
import { saveAs }                                                        from "file-saver";
import { FontAwesomeIcon }                                               from "@fortawesome/react-fontawesome";
import { faCheck, faFileExcel }                                          from "@fortawesome/free-solid-svg-icons";
import { friendService }                                                 from "../../services";
import { getTodayStringValue }                                           from "../../actions/util";
import { Select }                                                        from "react-functional-select";

let search = "";

const FriendList = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const selectCategoryRef = useRef();
  const selectAddressRef = useRef();

  const history = useHistory();
  const {friends, pagination, getFriends} = useFriendList();
  const {categories} = useCategories();
  const [categorySelected, setCategorySelected] = useState();
  const [addressTypeSelected, setAddressTypeSelected] = useState();

  useEffect(() => {
    getFriends(undefined, 0, undefined, undefined, undefined, undefined, undefined, undefined, categorySelected, addressTypeSelected);
    // eslint-disable-next-line
  }, [addressTypeSelected, categorySelected]);

  const items =
    friends &&
    friends.map((friend, i) =>
      <tr key={friend.id + "" + i}>
        <th scope="row">
          <MyLink to={"/" + appPaths.FriendDetail + "?friend=" + friend.id} className="no-a">
            {friend.firstname || friend.lastname ? friend.firstname + " " + friend.lastname + " " : <span>Detail</span>}
          </MyLink>
          {friend.isActive ? "" :
            <Badge color="danger" style={{fontSize: "12px", fontWeight: "normal"}}>Inactive</Badge>}
        </th>
        <th>{friend.email}</th>
        <th>{friend.organization}</th>
        <th>
          <div>{friend.address}</div>
          <div>{friend.city} {friend.state} {friend.zip}</div>
        </th>
        <th>
          {friend.isMember && <FontAwesomeIcon icon={faCheck} color="green"/>}
        </th>
      </tr>
    );

  const sortFn = (object) => {
    getFriends(undefined, undefined, search, object.columnSort, object.descending);
  };

  return (
    <Container fluid>
      <FriendTab from="Friends of CSA"/>
      <div className="d-flex justify-content-between">
        <h3>Friends of CSA</h3>
        <div className="flex-grow-1">
          <CheckIt action={userActionsKeys.addNewFriendLegal}>
            <div className="float-right ml-3 mb-1">
              <Button onClick={() => {
                history.push(appPaths.NewFriend);
              }}>
                New Friend
              </Button>
            </div>
          </CheckIt>

          <Search defaultValue={pagination.filterName} onSearch={(value) => {
            search = value;
            getFriends(undefined, 0, value);
          }}/>
        </div>
      </div>
      <Row>
        <div className="col-12 col-sm-6 col-md-4 d-flex justify-content-around">
          <div className="select-container flex-grow-1 mr-2 my-2">
            <Select
              options={[{name: "All Categories", value: 0}, ...categories.map((category) => ({
                name: category.description,
                value: category.id,
              }))]}
              placeholder="All Categories"
              name="categories"
              inputId="categories-select-input"
              ref={selectCategoryRef}
              onOptionChange={(option) => {
                if (option) {
                  setCategorySelected(option.value);
                }
              }}
              scrollMenuIntoView
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.name}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 d-flex justify-content-around">
          <div className="select-container flex-grow-1 mr-2 my-2">
            <Select
              options={["All Addresses", "Permanent Home", "Temporary Home", "Local Business", "Business"].map((value, index) => ({
                name: value,
                value: index + 1,
              }))}
              placeholder="All Addresses"
              name="addresses"
              inputId="addresses-select-input"
              ref={selectAddressRef}
              onOptionChange={(option) => {
                if (option && option.value) {
                  setAddressTypeSelected(option.value)
                }
              }}
              scrollMenuIntoView
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.name}/>

          </div>
        </div>
      </Row>

      <div className="row col-12">
        <CustomInput className="float-left mr-3 mt-3 text-center up-it"
                     type="checkbox" name="isMembers" id="isMembers" checked={pagination.member === 1}
                     onChange={(e) => {
                       getFriends(undefined, 0, search, undefined, undefined, undefined, e.target.checked ? 1 : 0);
                     }} label="Members"/>
        <CustomInput className="float-left mr-3 mt-3 text-center up-it"
                     type="checkbox" name="nonMembers"
                     id="nonMembers" checked={pagination.nonMember === 1}
                     onChange={(e) => {
                       getFriends(undefined, 0, search, undefined, undefined, undefined, undefined, e.target.checked ? 1 : 0);
                     }} label="Non Members"/>
        <CustomInput className=" float-left mr-3 mt-3 text-center up-it"
                     type="checkbox" name="isActive" id="isActive" checked={pagination.showInactive === 1}
                     onChange={(e) => {
                       getFriends(undefined, 0, search, undefined, undefined, e.target.checked ? 1 : 0);
                     }} label="Show Inactive"/>
        <div className="float-right ml-3 mb-1">
          <Button onClick={() => {
            getFriends(undefined, 0, "", undefined, undefined, 0, 1, 1, 0, 0);
            selectCategoryRef.current.clearValue();
            selectAddressRef.current.setValue({name: "Permanent Home", value: 2});
          }}>Reset</Button>
        </div>
      </div>
      <CheckIt action={userActionsKeys.exportFriendsLegal}>
        <div className="d-flex justify-content-between" style={{maxWidth: "260px"}}>
          <div className="cursor-pointer mb-2 mt-2 " onClick={() => {
            friendService()
              .exportFriends(search, pagination.columnSort, pagination.descending, pagination.showInactive, pagination.member, pagination.nonMember, categorySelected, addressTypeSelected, false)
              .then((res) => {
                if (res.data) {
                  saveAs(res.data, "FriendsOfCSA_Compact" + getTodayStringValue() + ".xlsx");
                }
              });
          }}>
            <FontAwesomeIcon icon={faFileExcel} color="green"/> Compact Export
          </div>
          <div className="cursor-pointer mb-2 mt-2" onClick={() => {
            friendService()
              .exportFriends(search, pagination.columnSort, pagination.descending, undefined, undefined, undefined, undefined, undefined, true)
              .then((res) => {
                if (res.data) {
                  saveAs(res.data, "FriendsOfCSA_Full" + getTodayStringValue() + ".xlsx");
                }
              });
          }}>
            <FontAwesomeIcon icon={faFileExcel} color="green"/> Full Export
          </div>
        </div>
      </CheckIt>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>
            Name <ColumnSort columnName="Firstname,Lastname" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/>
          </th>
          <th style={{minWidth: "124px"}}>
            Email <ColumnSort columnName="Email" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/>
          </th>
          <th style={{minWidth: "173px"}}>Organization/School <ColumnSort columnName="Organization" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>Address <ColumnSort columnName="State,City,Address,Zip" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>Is Member <ColumnSort columnName="IsMember" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {friends.length ? (
          items
        ) : (
          <tr>
            <th colSpan="5" className="text-center">
              No content data, <span className="simulate-a" onClick={() => {
              getFriends()
            }}>refresh</span>
            </th>
          </tr>
        )}
        </tbody>
      </Table>

      {friends.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getFriends(limit, skip)
      }}/>}

    </Container>
  );
};

export default FriendList;
