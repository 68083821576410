import React                                      from 'react';
import { FontAwesomeIcon }                        from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaDown, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';

const ColumnSort = ({columnName, columnSort, descending, changeSortAction}) => {

  let icon = faSort;

  if (columnName === columnSort) {
    icon = faSortAlphaDown;
  }

  if (columnName === columnSort && descending) {
    icon = faSortAlphaUp;
  }

  return (
    <FontAwesomeIcon className="cursor-pointer" icon={icon} onClick={() => {
      let object = {columnSort, descending};
      if (columnName !== columnSort) {
        object.columnSort = columnName;
        object.descending = true;
      }
      if (columnName === columnSort && descending) {
        object.columnSort = "";
        object.descending = false;
      } else {
        object.descending = !object.descending;
      }
      changeSortAction(object);
    }}/>
  );
};

export default ColumnSort;
