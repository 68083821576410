import * as types       from "../types";

export const visit = {
  CRUDVisit: (state = types.CREATE_VISIT, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_VISIT:
        return types.CREATE_VISIT;
      case types.UPDATE_VISIT:
        return types.UPDATE_VISIT;
      case types.DELETE_VISIT:
        return types.DELETE_VISIT;
      default:
        return state
    }
  },
  getVisit: (state = {}, action) => {
    switch (action.type) {
      case types.GET_VISIT_SUCCESS:
        return action.data;
      case types.GET_VISIT_FAILURE:
        return {};
      case types.RESET_GET_VISIT:
        return {};
      default:
        return state
    }
  },
  getVisits: (state = [], action) => {
    switch (action.type) {
      case types.GET_VISITS_SUCCESS:
        return action.data;
      case types.GET_VISITS_FAILURE:
        return [];
      default:
        return state
    }
  },
  visit: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_VISIT:
        return action.data;
      case types.RESET_VISIT:
        return {};
      default:
        return state
    }
  },
  visits: (state = [], action) => {
    switch (action.type) {
      case types.GET_VISITS_SUCCESS:
        return action.data;
      case types.GET_VISITS_FAILURE:
        return [];
      default:
        return state
    }
  },
  paginationVisit: (state = {count: 0, limit: 10, skip: 0, filterName: "", columnSort: "", descending: false}, action) => {
    switch (action.type) {
      case types.ADD_VISIT_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_VISIT_PAGINATION:
        return {};
      default:
        return state
    }
  }
};