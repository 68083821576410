import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "investigation/";
export default () => ({
    getInvestigation: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getInvestigation/' + id
    })),
    getInvestigationCasesByAdvocateReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetInvestigationCasesByAdvocateReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getInvestigationCasesReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetInvestigationCasesReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getInvestigations: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getInvestigations?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    getInvestigationsByMember: (memberId, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getInvestigationsByMember/' + memberId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    addInvestigation: (params) => client.request({
      method: 'POST',
      url: base + 'addInvestigation',
      data: params
    }),
    updateInvestigation: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateInvestigation/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeStateInvestigation/' + params.id + "?isOpen=" + params.isOpen + "&closedOn=" + ((params.closedOn && params.closedOn.toISOString()) || new Date().toISOString()) + "&outcome=" + params.outcome,
    }),
    deleteInvestigation: (id) => client.request({
      method: 'DELETE',
      url: base + 'deleteInvestigation/' + id
    })
  }
);
