import { useEffect, useState }  from 'react'
import { ratingCommentService } from "../services";
import { useLocation }          from "react-router";
import { setAlertNotification } from "../actions/util";
import { useDefaultLimit }      from "./WindowUtil";

export const useRatingCommentList = (props) => {

  const {defaultLimit} = useDefaultLimit()
  const {ratingId} = props || {};
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const ratingParamId = param.get("rating");

  const [ratingComments, setRatingComments] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});

  const getRatingComments = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    if (ratingId || ratingParamId) {
      ratingCommentService()
        .getRatingComments(ratingId || ratingParamId, limit, skip, filterName, columnSort, descending)
        .then(res => {
          if (res && res.data) {
            setRatingComments(res.data);
            setPagination({
              ...pagination,
              count: parseInt(res.headers['x-total-count'], 10),
              limit: parseInt(res.headers['x-total-limit'], 10),
              skip: parseInt(res.headers['x-total-skip'], 10),
              columnSort,
              descending
            })
          }
        })
    }
  };

  useEffect(() => {
    getRatingComments()
    // eslint-disable-next-line
  }, []);

  const addRatingComment = (values, cb) => {
    ratingCommentService()
      .addRatingComment({ratingModelId: ratingId || ratingParamId, ...values})
      .then(() => {
        getRatingComments();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  return {
    ratingComments,
    paginationRatingComments: pagination,
    ratingId: ratingParamId,
    getRatingComments,
    addRatingComment
  }
};
