import React from 'react'
import { dateFormatMonthDayYear }     from "../../actions/util";
import { ColumnSort, MyPagination } from "../index";
import { Table }                    from "reactstrap";
import { useAllName }               from "../../hooks";
import { isAllow, userActionsKeys } from "../../util/userActions";

const GrievanceActivityList = ({grievanceActivities, pagination, isGrievanceOpen, onPaginationAction, setGrievanceActivity}) => {

  const {getName} = useAllName();

  const sortFn = (object) => {
    onPaginationAction(pagination.limit, pagination.skip, object.columnSort, object.descending)
  };

  return (
    <>
      <Table hover className="table-dotted table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>Date <ColumnSort columnName="ActivityDate" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Grievance Stage <ColumnSort columnName="Stage" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Comment <ColumnSort columnName="Comment" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>Advocate <ColumnSort columnName="AdvocateUserName" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "145px"}}>Outcome <ColumnSort columnName="Outcome" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {grievanceActivities.length > 0 ? grievanceActivities.map((value, index) => (
          <tr key={index + pagination.skip}>
            <th>
              <span className={isGrievanceOpen && isAllow(userActionsKeys.updateGrievanceActivityLegal) ? "simulate-a" : ""} onClick={() => {
                if (isGrievanceOpen && isAllow(userActionsKeys.updateGrievanceActivityLegal)) {
                  setGrievanceActivity(value);
                }
              }}>{dateFormatMonthDayYear(value.activityDate, value.grievanceId)}</span>
            </th>
            <th>{value.stage}</th>
            <th>
              <div>{value.comment && <span dangerouslySetInnerHTML={{__html: value.comment.replace(/\n/g, "<br/>")}}/>}</div>
              <div>
                <small style={{color: "#999"}}>{getName(value.userName)} on {dateFormatMonthDayYear(value.updateDate, value.grievanceId)}</small>
              </div>
            </th>
            <th>{getName(value.advocateUserName)}</th>
            <th>{value.outcome}</th>
          </tr>
        )) : <tr>
          <th colSpan="5" className="text-center">No content data</th>
        </tr>}
        </tbody>
      </Table>


      {grievanceActivities.length > 0 && pagination.count > 10 &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
          onPaginationAction(limit, skip)
        }}/>
      </div>}
    </>
  )
};

export default GrievanceActivityList;