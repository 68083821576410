import { useEffect, useState } from 'react'
import { titleService }        from "../services";

let tmpTitle, isTitleFetching = false, subscribedTitleFunc = [];

const useTitle = () => {

  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (tmpTitle) {
      setTitles(tmpTitle)
    } else if (isTitleFetching) {
      subscribedTitleFunc.push(setTitles)
    } else {
      isTitleFetching = true
      titleService()
        .getTitlesAll()
        .then(res => {
          tmpTitle = res.data || [];
          setTitles(tmpTitle)
          subscribedTitleFunc.forEach(func => {
            func(tmpTitle)
          })
          subscribedTitleFunc = []
          isTitleFetching = false
        })
        .catch((err) => {
          setTitles([])
        });
    }
  }, []);

  const getJobDescription = (title) => {
    if (title) {
      title = title.trim();
      for (let i in titles) {
        if (titles.hasOwnProperty(i) && titles[i].jobtitle && titles[i].jobtitle.trim() === title) {
          return titles[i].description ? titles[i].description.trim() : "";
        }
      }
    }
    return "";
  };

  return {titles, getJobDescription}
};

export default useTitle;