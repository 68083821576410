import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "memberContact/";
export default () => ({
    getMemberContact: (id) => client.request({
      method: 'GET',
      url: base + 'GetMemberContact/' + id
    }),
    getAllUsers: (memberId, memberContactId) => client.request({
      method: 'GET',
      url: base + 'getAllUsers/' + memberId + "/" + memberContactId
    }),
    getMemberContacts: (idMember, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetMemberContacts/' + idMember + '?limit=' + limit + '&skip=' + skip + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + "&timestamp=" + new Date().getTime()
    })),
    getContactVisitMemberList: (idMember, limit = 10, skip = 0, descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetContactVisitMemberList/' + idMember + '?limit=' + limit + '&skip=' + skip + '&descending=' + descending + "&timestamp=" + new Date().getTime()
    })),
    addMemberContact: (params) => client.request({
      method: 'POST',
      url: base + 'AddMemberContact',
      data: params
    }),
    updateMemberContact: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateMemberContact',
      data: params
    }),
    deleteMemberContact: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteMemberContact/' + id
    })
  }
);
