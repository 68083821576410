import { useEffect, useState } from 'react'
import { siteService }         from "../services";
import { useLocation }         from "react-router";
import { useDefaultLimit }     from "./WindowUtil";

export const useSiteList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [sites, setSites] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});

  const getSites = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    siteService()
      .getSites(limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setSites(res.data);
          const sitePaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            filterName
          }
          setPagination(sitePaginateList)
          sessionStorage.setItem("sitePaginateList", JSON.stringify(sitePaginateList))
        }
      })
  };

  useEffect(() => {
    let sitePaginateList = sessionStorage.getItem("sitePaginateList")
    if (sitePaginateList) {
      sitePaginateList = JSON.parse(sitePaginateList)
      getSites(defaultLimit || sitePaginateList.limit, sitePaginateList.skip, sitePaginateList.filterName, sitePaginateList.columnSort, sitePaginateList.descending)
    } else {
      getSites()
    }
    // eslint-disable-next-line
  }, []);

  return {sites, pagination, getSites}
};

export const useSite = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const siteParamId = param.get("site");

  const [site, setSite] = useState({});

  const getSite = (siteId) => {
    if (siteId || id || siteParamId) {
      siteService()
        .getSite({id: siteId || id || siteParamId})
        .then(res => {
          if (res && res.data) {
            setSite(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setSite({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    if (site.id !== siteParamId) {
      getSite()
    }
    // eslint-disable-next-line
  }, [siteParamId, id]);

  return {
    site,
    setSite,
    getSite,
    siteId: siteParamId
  }
};
