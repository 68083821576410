import { Link }    from "react-router-dom";
import React       from "react";
import routes      from "../../routes";
import { isAllow } from "../../util/userActions";

const MyLink = ({onlyChildren, children, to, ...rest}) => {

  if (onlyChildren) {
    return children
  }

  let toTmp = to;

  if (toTmp) {
    toTmp = toTmp.replace(/\?.+$/, "").replace(/^\//, "");
    let tmp = routes.filter(f => f.path === toTmp);
    if (tmp && tmp[0] && tmp[0].action && !isAllow(tmp[0].action)) {
      return children
    }
  }

  return (
    <Link {...rest} to={to}>{children}</Link>
  )
}

export default MyLink