import React, { useEffect, useState }                                                         from 'react';
import { Badge, Button, Container, Row }                               from 'reactstrap';
import { siteService, visitMemberService, visitService } from "../../services";
import { dateFormatMonthDayYear }                                      from "../../actions/util";
import { appPaths }                                                                           from "../../routes";
import { CheckIt, MyLink, Search, SiteSummary, VisitCommentsMembershipOnly, VisitMemberList } from "../index";
import { isAllow, userActionsKeys }    from "../../util/userActions";
import { useAllName, useDefaultLimit } from "../../hooks";

const VisitDetail = ({history, location}) => {

  const {defaultLimit} = useDefaultLimit()
  const {getNames} = useAllName();
  const [site, setSite] = useState({});
  const [visit, setVisit] = useState({});
  const [visitMembers, setVisits] = useState([]);
  const [visitMemberPagination, setVisitPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "Member", descending: false});

  let param = location.search;
  const siteId = new URLSearchParams(param).get("site");
  const visitId = new URLSearchParams(param).get("visit");

  const getVisitMembers = (visitId, limit = visitMemberPagination.limit, skip = visitMemberPagination.skip, filterName = visitMemberPagination.filterName, columnSort = visitMemberPagination.columnSort, descending = visitMemberPagination.descending) => {
    visitMemberService().getVisitMembersByVisit(visitId, limit, skip, filterName, columnSort, descending).then(res => {
      if (res && res.data) {
        setVisits(res.data);
        const visitDetailMemberPaginateList = {
        ...visitMemberPagination,
            filterName,
            columnSort,
            descending,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10)
        }
        setVisitPagination(visitDetailMemberPaginateList)
        sessionStorage.setItem("visitDetailMemberPaginateList", JSON.stringify(visitDetailMemberPaginateList))
      }
    });
  };

  const getSite = (id) => {
    siteService()
      .getSite({id: id || siteId}).then(res => {
      if (res && res.data) {
        setSite(res.data);
      }
    });
  };

  const getVisit = () => {
    visitService()
      .getVisit(visitId)
      .then(res => {
        if (res && res.data) {
          setVisit(res.data);
          if (!siteId && res.data.siteModelId) {
            getSite(res.data.siteModelId);
          }
        }
      })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (siteId) {
      getSite();
    }
    if (visitId) {
      getVisit();
      let visitDetailMemberPaginateList = sessionStorage.getItem("visitDetailMemberPaginateList")
      if (visitDetailMemberPaginateList) {
        visitDetailMemberPaginateList = JSON.parse(visitDetailMemberPaginateList)
        getVisitMembers(visitId, defaultLimit, visitDetailMemberPaginateList.skip, visitDetailMemberPaginateList.filterName, visitDetailMemberPaginateList.columnSort, visitDetailMemberPaginateList.descending);
      } else {
        getVisitMembers(visitId);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid={true}>
      <Row className="padding-15">
        <h3>Visit <span className="text-gray">Details</span></h3>
        <CheckIt action={userActionsKeys.changeVisitStateLegal}>
          <div style={{marginLeft: "auto"}}>
            <Button onClick={() => {
              visitService()
                .changeState({id: visit.id, isOpen: !visit.isOpen})
                .then(() => {
                  getVisit();
                  getVisitMembers(visitId, visitMemberPagination.limit, 0);
                });
            }}>{visit.isOpen ? "Close" : "Reopen"} Visit</Button>
          </div>
        </CheckIt>
      </Row>
      <Row>
        <div className="col-12 col-sm-6">
          <div className="col-12 detail-header border-bottom-gray">
            Details
            <CheckIt action={userActionsKeys.updateVisitLegal}>
              <span style={{position: "absolute", right: "15px"}}>
                <Button style={{marginTop: "-5px"}} onClick={() => {
                  history.push("/" + appPaths.VisitForm + "?visit=" + visit.id + "&site=" + visit.siteModelId)
                }}>Edit</Button>
              </span>
            </CheckIt>
          </div>
          <div className="col-12 detail">
            <Row>
              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site</div>
              <div className="col-12 col-sm-7">
                <MyLink to={"/" + appPaths.SiteDetail + "?site=" + site.id} className="no-a">{site.sitename}{!site.sitename && <span>Detail</span>}</MyLink>
                <br/>
                <span>{site.sitecode}</span>
              </div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Visit Reason</div>
              <div className="col-12 col-sm-7">{visit.visitReason}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Field Director User</div>
              <div className="col-12 col-sm-7">{getNames(visit.userName, true)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Date</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(visit.visitDate, visit.visitId)}</div>

            </Row>
          </div>
        </div>

        <CheckIt action={userActionsKeys.visitCommentListMembershipOnlyLegal}>
          <div className="col-12 col-sm-6">
            <div className="col-12 detail-header border-bottom-gray">
              Comments for Membership only
            </div>
            <div className="col-12 detail">
              <VisitCommentsMembershipOnly visit={visit}/>
            </div>
          </div>
        </CheckIt>

        {visit.isOpen && isAllow(userActionsKeys.visitSiteSummaryListLegal) && <div className="col-12">
          <div className="col-12 detail-header border-bottom-gray">
            Site Summary
          </div>
          <div className="col-12 detail">
            <SiteSummary/>
          </div>
        </div>}

        <CheckIt action={userActionsKeys.visitMemberListLegal}>
          <div className="col-12 row no-margin">
            <div className="col-12 detail-header border-bottom-gray" style={{border: "none"}}>
              <Search defaultValue={visitMemberPagination.filterName} onSearch={(value) => {
                getVisitMembers(visitId, undefined, 0, value);
              }}/>
              <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{visitMemberPagination.count}</Badge> Visited Members
            </div>
            <div className="col-12 detail no-padding">
              <VisitMemberList visit={visit} visitMembers={visitMembers} visitMemberPagination={visitMemberPagination} getVisitMembers={getVisitMembers}/>
            </div>
          </div>
        </CheckIt>
      </Row>
    </Container>
  );
};

export default VisitDetail;
