import React, { useState }                                                            from 'react';
import { visitMemberService }                                                         from "../../services";
import { dateFormatMonthDayYear }                                                     from "../../actions/util";
import { BigModal, MemberContactForm, MyLink, MyPagination, VisitMemberCommentsView } from "../index";
import { appPaths }                                                                   from "../../routes";
import { useAllName, useDefaultLimit, useMember }                                     from "../../hooks";
import { UsernameDateData }                                                           from "../MemberContact/MemberVisitContactList";
import { isAllow, userActionsKeys }                                                   from "../../util/userActions";

const VisitMemberListView = ({site, member, visitsMemberComments, visitsMemberCommentsCount}) => {

  const [visits, setVisits] = useState(visitsMemberComments || []);
  const {defaultLimit} = useDefaultLimit()
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true, count: visitsMemberCommentsCount});
  const [isBigModalOpen, setIsBigModalOpen] = useState(false);
  const bigModalTitle = "Edit Contact";

  const toggleModal = () => {
    setIsBigModalOpen(!isBigModalOpen);
  };

  const getVisitsMemberComments = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    visitMemberService()
      .GetAllWithMemberContactBySiteAndMember(site.id, member.id, limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setVisits(res.data);
          setPagination({
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10)
          })
        }
      })
  };

  return <div>
    {visits.map((visitComments, index) => {
      const memberContact = visitComments.memberContact, visit = visitComments.visit, visitMemberComments = visitComments.visitMemberComments;
      if (memberContact) {
        return (
          <div key={index + "" + pagination.skip + "" + memberContact.id} style={{borderBottom: index < visits.length - 1 ? "1px dotted" : ""}} className="mb-2">
            <div className="mb-1">
              <span className={isAllow(userActionsKeys.editMemberContactLegal) ? "simulate-a no-a" : ""} onClick={() => {
                if (isAllow(userActionsKeys.editMemberContactLegal)) {
                  setIsBigModalOpen(memberContact.id);
                }
              }}>{memberContact.type} on {dateFormatMonthDayYear(memberContact.date)}</span>
            </div>
            <UsernameDateData value={memberContact} noBorder/>

            <BigModal className="md-modal" title={bigModalTitle} isBigModalOpen={isBigModalOpen === memberContact.id} toggleModalAction={toggleModal}>
              <MemberContactForm
                getContactVisitMemberList={getVisitsMemberComments}
                member={member}
                memberContact={memberContact}
                setMemberContact={() => {}}
                toggleModal={toggleModal}/>
            </BigModal>
          </div>
        )
      }
      return (
        <div key={index + "" + pagination.skip + "" + visit.id} style={{borderBottom: index < visits.length - 1 ? "1px dotted" : ""}} className="mb-2">
          <div className="mb-1">
            <MyLink to={"/" + appPaths.VisitDetail + "?visit=" + visit.id + "&site=" + visit.siteModelId} className="no-a">{visit.visitReason} on {dateFormatMonthDayYear(visit.visitDate, visit.visitId)}</MyLink>
          </div>
          <VisitMemberCommentsView key={visit.id} visit={visit} visitMemberComments={visitMemberComments} visitMemberCommentsCount={visitComments.visitMemberCommentsCount} visitMember={visitComments} skip={pagination.skip}/>
        </div>
      )
    })}

    {visits.length > 0 && pagination.count > 10 &&
    <div style={{padding: "15px 0 0", overflow: "auto"}}>

      <div style={{borderBottom: "1px solid", marginBottom: "10px"}}/>

      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getVisitsMemberComments(limit, skip, pagination.filterName, pagination.columnSort, pagination.descending)
      }}/>
    </div>}
  </div>
};

export default VisitMemberListView;
