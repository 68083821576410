const SuperUser = ({children}) => {
  let isSuperUser = false;

  let rol = sessionStorage.getItem("rolesLFS");

  if (rol && typeof rol === "string" && /Admin/.test(rol)) {
    isSuperUser = true
  }

  if (isSuperUser) {
    return children
  }

  return null
};

export default SuperUser