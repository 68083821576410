import React, { useState }                                 from 'react';
import { CustomInput, Row }                        from 'reactstrap';
import { visitMemberCommentService } from "../../services";
import { dateFormatMonthDayYearHoursMinute }       from "../../actions/util";
import { useForm }                                         from "react-hook-form";
import { MyPagination, UsersList, VisitMemberEditComment } from "../index";
import { isAllow, userActionsKeys }                 from "../../util/userActions";
import { useAllName, useDefaultLimit, useUserList } from "../../hooks";

const VisitMemberComments = ({visit, visitMemberComments, visitMember, getVisitMembers}) => {

  const {defaultLimit} = useDefaultLimit()
  const {register, handleSubmit, setValue, errors} = useForm();
  const [comments, setComments] = useState(visitMemberComments || []);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});
  const [isSendLegal, setIsSendLegal] = useState(false);
  const [isSendMembership, setIsSendMembership] = useState(false);
  const [isSendToUsers, setIsSendToUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editComment, setEditComment] = useState({});
  const {getName} = useAllName();

  const {users, getUsers, pagination: paginationUser} = useUserList();

  const getVisitMemberComments = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, visitMemberId) => {
    visitMemberCommentService()
      .getVisitMemberComments(visitMemberId || visitMember.id, limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setComments(res.data);
          setPagination({
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10)
          })
        }
      })
  };

  let userName = sessionStorage.getItem('usernameLFS');

  return (
    <Row>
      {comments.length > 0 && <div className={"col-12 no-padding" + (visit.isOpen ? " mb-3" : "")}>
        {comments.map((comment, index) => {
          let sentTo = "";
          if (comment.isSendLegal) {
            sentTo += ", Legal"
          }
          if (comment.isSendMembership) {
            sentTo += ", Membership"
          }
          if (comment.isSendToUsers) {
            sentTo += ", User"
          }
          sentTo = sentTo.replace(/^, /, '').replace(/,[\w\s]+$/, (val) => val.replace(",", ", and"));
          const isCurrentUserOwner = visit.isOpen && userName?.toLowerCase() === comment.userName?.toLowerCase()

          return (
            <div className="col-12 mb-2 p-2 box-border" key={index + pagination.skip} style={{backgroundColor: "white"}}>
              {isCurrentUserOwner && <Row style={{marginBottom: "-21px"}}>
                <div className="ml-auto"><span className="simulate-a" onClick={() => {setEditComment(comment)}}>Edit</span></div>
              </Row>}
              {comment.comment &&
              <div
                style={{width: isCurrentUserOwner ? "calc(100% - 26px)" : "100%"}}
                dangerouslySetInnerHTML={{__html: comment.comment.replace(/\n/g, "<br/>") + (sentTo ? "<span class=\"text-danger ml-2\">(Sent to " + sentTo + ")</span>" : "")}}/>}
              <br/>
              <small className="text-gray">by {getName(comment.userName)} on {dateFormatMonthDayYearHoursMinute(comment.createDate)}</small>
            </div>
          )
        })}

        {comments.length > 0 && pagination.count > 10 &&
        <div style={{padding: "30px 15px 0", overflow: "auto"}}>
          <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
            getVisitMemberComments(limit, skip, pagination.filterName, pagination.columnSort, pagination.descending)
          }}/>
        </div>}

      </div>}
      {visit.isOpen && isAllow(userActionsKeys.addVisitMemberCommentLegal) && <form className="col-12 no-padding" onSubmit={handleSubmit((values) => {
        if (visitMember.id) {
          visitMemberCommentService().addVisitMemberComment({...values, isSendLegal, isSendMembership, isSendToUsers, visitMemberModelId: visitMember.id, users: selectedUsers}, visit.id).then(() => {
            getVisitMemberComments();
            setValue("comment", "");
            setIsSendLegal(false);
            setIsSendMembership(false);
            setIsSendToUsers(false);
            setSelectedUsers([]);
          })
        } else {
          visitMemberCommentService().addVisitMemberCommentWithParams({...values, isSendLegal, isSendMembership, isSendToUsers, users: selectedUsers}, visit.id, visitMember.member.id).then((res) => {
            if (res && res.data) {
              getVisitMembers(visit.id);
              getVisitMemberComments(pagination.limit, pagination.skip, pagination.filterName, pagination.columnSort, pagination.descending, res.data);
            }
            setValue("comment", "");
            setIsSendLegal(false);
            setIsSendMembership(false);
            setIsSendToUsers(false);
            setSelectedUsers([]);
          })
        }
      })}>
        <Row>
          <div className="col-11 no-padding">
            <textarea className="form-control" name="comment" defaultValue="" ref={register({required: true})}/>
            <small className="text-danger" style={{position: "absolute", bottom: "-17px"}}>{errors.comment && "This field is required"}</small>
          </div>
          <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>

          <input type="submit" className="btn btn-outline-secondary mt-3" value="Add Comment"/>

          {visit.isOpen && <CustomInput
            className="mb-2 mt-auto ml-1 ml-md-4"
            type="checkbox"
            id={"send-to-legal-" + visitMember.member.id}
            checked={isSendLegal}
            inline={true}
            label="Send to Legal"
            onChange={() => {
              setIsSendLegal(!isSendLegal)
            }}/>}

          {visit.isOpen && <CustomInput
            className="mb-2 mt-auto ml-1 ml-md-2"
            type="checkbox"
            id={"send-to-debbie-" + visitMember.member.id}
            checked={isSendMembership}
            label="Send to Membership"
            inline={true}
            onChange={() => {
              setIsSendMembership(!isSendMembership)
            }}/>}

          {visit.isOpen && <CustomInput
            className="mb-2 mt-auto ml-1 ml-md-2"
            type="checkbox"
            id={"send-to-user-" + visitMember.member.id}
            checked={isSendToUsers}
            label="Send to User"
            inline={true}
            onChange={() => {
              setIsSendToUsers(!isSendToUsers)
            }}/>}

          {isSendToUsers && <div className="col-12 no-padding mt-2 px-1 pt-1 bg-white">

            <div>
              <h5>Users Selected</h5>
              <p style={{height: "80px", overflow: "auto", border: "1px solid #797979", borderRadius: "4px", padding: "0", backgroundColor: "#f5f5f5"}}>
                {selectedUsers && selectedUsers.map((value, index) => (
                  <span key={index + pagination.skip} style={{border: "1px solid", borderRadius: "4px", padding: "1px 0 3px 4px", margin: "4px", backgroundColor: "white", display: "inline-block"}}>
                  {value.name}
                    <sup style={{fontWeight: "bold", textShadow: "0 1px 0 #fff", opacity: ".5", padding: "0 4px", cursor: "pointer"}} onClick={() => {
                      let tmp = [];
                      for (let i in selectedUsers) {
                        if (selectedUsers.hasOwnProperty(i) && selectedUsers[i].id !== value.id) {
                          tmp.push(selectedUsers[i])
                        }
                      }
                      setSelectedUsers(tmp)
                    }}>x</sup>
                </span>
                ))}
              </p>
            </div>

            <UsersList users={users} getUsers={getUsers} paginationUser={paginationUser} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}/>
          </div>}

        </Row>
      </form>}
      {visit.isOpen && editComment.id && <VisitMemberEditComment editComment={editComment} getVisitMemberComments={getVisitMemberComments} setEditComment={setEditComment}/>}
    </Row>
  );
};

export default VisitMemberComments;
