import React                                 from 'react';
import { cleanName, dateFormatMonthDayYear } from "../../actions/util";
import { appPaths }                          from "../../routes";
import { MyLink }                 from "../index";


const GrievanceItem = ({grievance, getJobDescription, getCategory}) => {

  const isUnionInitiated = !(grievance.siteModelId || grievance.memberModelId);

  return (
    <tr>
      <th scope="row">
        <MyLink to={"/" + appPaths.GrievanceDetail + "?grievance=" + grievance.id + (isUnionInitiated ? "&union-initiated=true" : "&member=" + grievance.member?.id + "&site=" + grievance.site?.id)}
                className="no-a">{dateFormatMonthDayYear(grievance.openDate, grievance.grievanceId) || <span>Detail</span>}</MyLink>
      </th>
      {isUnionInitiated ?
        <th colSpan={2}>
          <div><small className="text-gray">Union Initiated</small></div>
          <div>{grievance.unionInitiated}</div>
        </th>
        : <>
          <th>
            <div>
              <small className="text-gray">File No.: {grievance.member?.fileno}</small>
            </div>
            <div>
              <small className="text-gray">{getJobDescription(grievance.member?.title)} {grievance.member?.title && <span>({grievance.member?.title?.trim()})</span>}</small>
            </div>
            <div><MyLink to={"/" + appPaths.MemberDetail + "?member=" + grievance.member?.id} className="no-a">{cleanName(grievance.member?.lastname)}{cleanName(grievance.member?.frstname) &&
            <span>, {cleanName(grievance.member?.frstname)}</span>}{!cleanName(grievance.member?.lastname) && !cleanName(grievance.member?.frstname) && <span>Detail</span>}</MyLink></div>
            <div>
              <small className="text-gray">{getCategory(grievance.member?.status)}</small>
            </div>
          </th>
          <th>
            <div>
              <small className="text-gray">District: {grievance.site ? grievance.site.district : ""}</small>
            </div>
            <div>{grievance.site?.sitecode}</div>
            <div>
              <small className="text-gray">{grievance.site ? grievance.site.sitename : ""}</small>
            </div>
          </th>
        </>}
      <th>{grievance.infraction}</th>
      <th className="text-center">
        {grievance.outcome}
        <div>Arbitration: {grievance.arbitrationOutcome}</div>
      </th>
      <th className="text-center">
        <div className={grievance.isOpen ? "open" : "closed"}>{grievance.isOpen ? "Open" : "Closed"}</div>
      </th>

    </tr>
  );
};

export default GrievanceItem;
