import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "ratingAdvocate/";
export default () => ({
    getAdvocate: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getAdvocate/' + id
    })),
    getAll: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'getAll?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAdvocatesUsers: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetAdvocatesUsers?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAllByRating: (ratingId, filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'getAllByRating/' + ratingId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAdvocates: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getAdvocates?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getAdvocatesByRating: (ratingId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getAdvocates/' + ratingId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addAdvocate: (params) => client.request({
      method: 'POST',
      url: base + 'addAdvocate',
      data: params
    }),
    updateAdvocate: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateAdvocate/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeState/' + params.id + "?isOpen=" + params.isOpen,
    }),
    deleteAdvocate: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteAdvocate/' + params.id
    })
  }
);
