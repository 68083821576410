import React, { useEffect, useState }          from 'react'
import { Button, InputGroup, InputGroupAddon } from "reactstrap";

const Search = ({onSearch, defaultValue, children}) => {

  const [filterName, setFilterName] = useState(defaultValue || "");

  useEffect(() => {
    setFilterName(defaultValue)
  }, [defaultValue])

  const cmp = <div className="float-right mb-1" style={{maxWidth: "320px", width: "100%"}}>
    <InputGroup>
      <input
        type="search" name="filterName" className="form-control" value={filterName} style={{marginRight: "-4px"}} placeholder="Try here..."
        onKeyUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.keyCode === 27) {
            setFilterName("");
            onSearch("")
          } else if (e.keyCode === 13) {
            onSearch(filterName)
          }
        }}
        onChange={(e) => {
          if (e.target.value === '') {
            onSearch("")
          }
          setFilterName(e.target.value)
        }}/>
      <InputGroupAddon addonType="prepend"><Button onClick={() => {
        onSearch(filterName)
      }}>Search</Button></InputGroupAddon>
    </InputGroup>
  </div>

  if (children) {
    return (
      <div className="d-flex flex-row justify-content-between">
        <h3>{children}</h3>
        {cmp}
      </div>
    )
  }

  return (cmp)
};

export default Search