import React                                        from "react";
import { Button, Container, FormGroup, Label, Row } from "reactstrap";
import { useForm }                                  from "react-hook-form";
import { useHistory }                               from "react-router";
import { userService }                              from "../../services";
import { appPaths }                                 from "../../routes";
import { useAllName }                               from "../../hooks";

const NewAdvocate = () => {

  const {register, handleSubmit, watch} = useForm();
  const history = useHistory();
  const {isThere, getName, getUsers} = useAllName();

  return (
    <Container>
      <Row><h3>New Advocate</h3></Row>
      <Row>
        <div className="col-12 col-md-6 offset-md-3 detail-header border-bottom-gray">
          Details
        </div>
        <form className="col-12 col-md-6 offset-md-3 detail" onSubmit={handleSubmit((values) => {
          let tmp = {
            disabled: false,
            email: null,
            isAdvocate: true,
            isOnAD: false,
            mobilePhone: null,
            name: "",
            userAction: [],
            username: "",
            ...values
          };
          userService()
            .addAdvocate(tmp)
            .then(res => {
              getUsers()
              history.push(appPaths.UserList)
            })
        })}>
          <Row>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Username</Label>
                <div className="col-9">
                  <input className="form-control" name="username" required ref={register}/>
                  {watch("username") && isThere(watch("username")) && <small className="text-danger">That username belongs to: {getName(watch("username"))}</small>}
                </div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Name</Label>
                <div className="col-9"><input className="form-control" name="name" required ref={register}/></div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Email</Label>
                <div className="col-9"><input type="email" className="form-control" name="email" required ref={register}/></div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Mobile</Label>
                <div className="col-9"><input className="form-control" name="mobilePhone" required ref={register}/></div>
              </Row>
            </FormGroup>

            <div className="col-12" style={{marginTop: "30px", borderTop: "1px solid #797979", paddingTop: "15px"}}>
              <input type="submit" className="btn btn-outline-secondary" value="Save"/>
              {" "}
              or
              {" "}
              <Button outline color="danger" onClick={() => {
                history.goBack()
              }}>Cancel</Button>
            </div>

          </Row>
        </form>
      </Row>
    </Container>
  )
}

export default NewAdvocate