import { useEffect, useState }     from 'react'
import { categoryService }         from "../services";
import { useHistory, useLocation }              from "react-router";
import { naturalCompare, setAlertNotification } from "../actions/util";
import { appPaths }                             from "../routes";
import { useDefaultLimit }         from "./WindowUtil";

export const useCategoryList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});

  const getCategories = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    categoryService()
      .getCategories(limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setCategories(res.data);
          const categoryPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            filterName
          }
          setPagination(categoryPaginateList)
          sessionStorage.setItem("categoryPaginateList", JSON.stringify(categoryPaginateList))
        }
      })
  };

  useEffect(() => {
    let categoryPaginateList = sessionStorage.getItem("categoryPaginateList")
    if (categoryPaginateList) {
      categoryPaginateList = JSON.parse(categoryPaginateList)
      getCategories(defaultLimit || categoryPaginateList.limit, categoryPaginateList.skip, categoryPaginateList.filterName, categoryPaginateList.columnSort, categoryPaginateList.descending)
    } else {
      getCategories()
    }
    // eslint-disable-next-line
  }, []);

  return {categories, pagination, getCategories}
};

export const useCategories = () => {

  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({filterName: "", columnSort: "Description", descending: true});

  const getCategories = (filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    categoryService()
      .getAllCategories(filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setCategories(res.data.sort((a, b) => naturalCompare(a.description, b.description)));
          const categoryPaginateList = {
            ...pagination,
            columnSort,
            descending,
            filterName
          }
          setPagination(categoryPaginateList)
        }
      })
  };

  useEffect(() => {
      getCategories()
    // eslint-disable-next-line
  }, []);

  return {categories, pagination, getCategories}
};

export const useCategory = (props) => {

  const {id, onChange} = props || {}

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const categoryParamId = param.get("category");

  const [category, setCategory] = useState({});

  const getCategory = (categoryId) => {
    if (categoryId || id || categoryParamId) {
      categoryService()
        .getCategory(categoryId || id || categoryParamId)
        .then(res => {
          if (res && res.data) {
            setCategory(res.data);

            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setCategory({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getCategory()
    // eslint-disable-next-line
  }, [categoryParamId, id]);

  const deleteCategory = () => {
    categoryService()
      .deleteCategory(categoryParamId)
      .then(() => {
        history.push(appPaths.CategoryList);
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  return {
    category,
    getCategory,
    categoryId: categoryParamId,
    deleteCategory
  }
};
