import React                                 from 'react';
import { cleanName, dateFormatMonthDayYear } from "../../actions/util";
import { appPaths }                          from "../../routes";
import { MyLink }                 from "../index";


const RatingItem = ({rating, getJobDescription, getCategory}) => {

  return (
    <tr>
      <th scope="row">
        <MyLink to={"/" + appPaths.RatingDetail + "?rating=" + rating.id + "&member=" + rating.member?.id + "&site=" + rating.site?.id}
                className="no-a">{dateFormatMonthDayYear(rating.createDate, rating.ratingId) || <span>Detail</span>}</MyLink>
      </th>
      <th scope="row">
        {dateFormatMonthDayYear(rating.hearingDate, rating.ratingId)}
      </th>
      <th>{rating.type}</th>
      <th>
        <div>
          <small className="text-gray">File No.: {rating.member?.fileno}</small>
        </div>
        <div>
          <small className="text-gray">{getJobDescription(rating.member?.title)} {rating.member?.title && <span>({rating.member?.title?.trim()})</span>}</small>
        </div>
        <div><MyLink to={"/" + appPaths.MemberDetail + "?member=" + rating.member?.id} className="no-a">{cleanName(rating.member?.lastname)}{cleanName(rating.member?.frstname) &&
        <span>, {cleanName(rating.member?.frstname)}</span>}{!cleanName(rating.member?.lastname) && !cleanName(rating.member?.frstname) && <span>Detail</span>}</MyLink></div>
        <div>
          <small className="text-gray">{getCategory(rating.member?.status)}</small>
        </div>
      </th>
      <th>
        <div>
          <small className="text-gray">District: {rating.site ? rating.site.district : ""}</small>
        </div>
        <div>{rating.site?.sitecode}</div>
        <div>
          <small className="text-gray">{rating.site ? rating.site.sitename : ""}</small>
        </div>
      </th>
      <th>{rating.schoolYear}</th>
      <th>
        <div className={rating.isOpen ? "open" : "closed"}>{rating.isOpen ? "Open" : "Closed"}</div>
      </th>
    </tr>
  );
};

export default RatingItem;
