import * as types from "../types";

let messages = {};

messages[types.ADD_MEMBER_SUCCESS] = "Save new Member";
messages[types.ADD_SITE_SUCCESS] = "Save new Site";
messages[types.ADD_COMMENT_SUCCESS] = "Save new Comment";
messages[types.ADD_COPE_SUCCESS] = "Save new COPE";
messages[types.UPDATE_MEMBER_SUCCESS] = "Member update";
messages[types.UPDATE_SITE_SUCCESS] = "Site update";
messages[types.UPDATE_COMMENT_SUCCESS] = "Comment update";
messages[types.UPDATE_COPE_SUCCESS] = "COPE update";
messages[types.DELETE_MEMBER_SUCCESS] = "Member deleted";
messages[types.DELETE_SITE_SUCCESS] = "Site deleted";
messages[types.DELETE_COMMENT_SUCCESS] = "Comment deleted";
messages[types.DELETE_COPE_SUCCESS] = "COPE deleted";
messages[types.PREPARE_COPE_FILES_SUCCESS] = "Preparing COPE files";
messages[types.POPULATE_DATABASE_SUCCESS] = "Populate";
messages[types.PREPARE_DAY_CARE_SUCCESS] = "Prepare Day Care Billing";
messages[types.LOGIN_SUCCESS] = "Login";
messages[types.AUTOMATIC_LOGIN] = "You have been automatically logged in";
messages[types.PROCESSING_PAYROLL_SUCCESS] = "Processing Payroll";
messages[types.PROCESSING_BOE_SUCCESS] = "Processing BOE";
messages[types.PROCESSING_OPA_SUCCESS] = "Processing OPA";
messages[types.ASSOCIATION_SUMMARY_REPORT_SUCCESS] = "Association Collected Summary Report";
messages[types.ASSOCIATION_DETAIL_REPORT_SUCCESS] = "Association Collected Detail Report";
messages[types.NEW_TO_ASSOCIATION_REPORT_SUCCESS] = "New To Association";
messages[types.NEW_TO_PAYROLL_REPORT_SUCCESS] = "New To Payroll";
messages[types.LEFT_ASSOCIATION_REPORT_SUCCESS] = "Left Association";
messages[types.COPE_COLLECTED_BY_DISTRICT_REPORT_SUCCESS] = "Cope Collections By District - Summary";
messages[types.COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS] = "Cope District Zero Collections Summary";
messages[types.COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS] = "Cope Contributor/Non Contributors District Zero Summary";
messages[types.COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_SUCCESS] = "Cope District Zero Collections Detail";
messages[types.COPE_COLLECTED_DETAIL_REPORT_SUCCESS] = "Cope Collected Detail Report";
messages[types.NEW_MEMBER_REPORT_SUCCESS] = "New Member Report";
messages[types.DELETED_MEMBER_REPORT_SUCCESS] = "Deleted Member Report";
messages[types.CHANGE_IN_TITLE_REPORT_SUCCESS] = "Change In Title Report";
messages[types.CHANGE_IN_SCHOOL_REPORT_SUCCESS] = "Change In School Report";
messages[types.OFF_PAYROLL_REPORT_SUCCESS] = "Off Payroll Report";
messages[types.ON_PAYROLL_NO_BOE_REPORT_SUCCESS] = "On Payroll, Not DOE";
messages[types.GET_SITE_SUCCESS] = "Get Site";
messages[types.SITE_VERIFICATION] = "Site Verification";
messages[types.WORK_SITE_VERIFICATION] = "Work Site Verification";
messages[types.GET_TITLE_SUCCESS] = "Get Title";
messages[types.GET_TITLE_SUCCESS] = "Get Title";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_SUCCESS] = "Change In School and/or Title Report";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_SUCCESS] = "Change In School and/or Title Report - NO ZoneSchools";
messages[types.STATUS_CHANGE_REPORT_SUCCESS] = "Dept of Ed Status Change Report";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_SUCCESS] = "Change in School and/or Title Report - Zone Schools ONLY";
messages[types.ADD_STATUS_SUCCESS] = "Save new Status";
messages[types.UPDATE_STATUS_SUCCESS] = "Status update";
messages[types.DELETE_STATUS_SUCCESS] = "Status update";
messages[types.ADD_ASSIGNMENT_SUCCESS] = "Save new Assignment";
messages[types.UPDATE_ASSIGNMENT_SUCCESS] = "Assignment update";
messages[types.DELETE_ASSIGNMENT_SUCCESS] = "Assignment deleted";
messages[types.DISTRICT_ROSTER_SUCCESS] = "District Roster";
messages[types.REGION_ROSTER_SUCCESS] = "Region Roster";
messages[types.PER_CAPITA_SUCCESS] = "Membership Per Capita Report";
messages[types.EXTRACT_ADDRESSES_SUCCESS] = "Extract Addresses for Mailing";
messages[types.EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_SUCCESS] = "Extract Addresses for Specific Titles";
messages[types.CHARTER_SCHOOL_SUCCESS] = "Charter School Report";
messages[types.PREPARE_ASSOCIATION_FILES_SUCCESS] = "Prepare Association Files";
messages[types.PREPARE_DUES_FILES_SUCCESS] = "Prepare Dues Files";
messages[types.PREPARE_DUES_FILES_ALL_SUCCESS] = "Prepare Dues Files - All";
messages[types.UPDATE_USERS_FROM_ACTIVE_DIRECTORY_SUCCESS] = "Update Users From Active Directory";
messages[types.REPORT_SUCCESS] = "Report";
messages[types.SUCCESS] = "Operation";
messages[types.ADD_ATTENDANCE_SUCCESS] = "Save new  Attendance";
messages[types.UPDATE_ATTENDANCE_SUCCESS] = "Attendance update";
messages[types.DELETE_ATTENDANCE_SUCCESS] = "Attendance deleted";
messages[types.ADD_BENEFIT_SUCCESS] = "Save new Benefit";
messages[types.UPDATE_BENEFIT_SUCCESS] = "Benefit update";
messages[types.DELETE_BENEFIT_SUCCESS] = "Benefit deleted";
messages[types.ADD_BOE_SUCCESS] = "Save new BOE";
messages[types.UPDATE_BOE_SUCCESS] = "BOE update";
messages[types.DELETE_BOE_SUCCESS] = "BOE deleted";
messages[types.ADD_COPA_SUCCESS] = "Save new COPA";
messages[types.UPDATE_COPA_SUCCESS] = "COPA update";
messages[types.DELETE_COPA_SUCCESS] = "COPA deleted";
messages[types.ADD_DUES_COLLECTED_SUCCESS] = "Save new Dues Collected";
messages[types.UPDATE_DUES_COLLECTED_SUCCESS] = "Dues Collected update";
messages[types.DELETE_DUES_COLLECTED_SUCCESS] = "Dues Collected deleted";
messages[types.ADD_HISTORY_SUCCESS] = "Save new History";
messages[types.UPDATE_HISTORY_SUCCESS] = "History update";
messages[types.DELETE_HISTORY_SUCCESS] = "History deleted";
messages[types.ADD_INTERIM_ACTING_SUCCESS] = "Save new Interim Acting";
messages[types.UPDATE_INTERIM_ACTING_SUCCESS] = "Interim Acting update";
messages[types.DELETE_INTERIM_ACTING_SUCCESS] = "Interim Acting deleted";
messages[types.ADD_MEMBER_VISIT_SUCCESS] = "Save new Member Visit";
messages[types.UPDATE_MEMBER_VISIT_SUCCESS] = "Member Visit update";
messages[types.DELETE_MEMBER_VISIT_SUCCESS] = "Member Visit deleted";
messages[types.ADD_SITE_UPDATE_SUCCESS] = "Save new Site Visit";
messages[types.UPDATE_SITE_UPDATE_SUCCESS] = "Site Visit update";
messages[types.DELETE_SITE_UPDATE_SUCCESS] = "Site Visit deleted";
messages[types.ADD_SITE_VISIT_SUCCESS] = "Save new Site Visit";
messages[types.UPDATE_SITE_VISIT_SUCCESS] = "Site Visit update";
messages[types.DELETE_SITE_VISIT_SUCCESS] = "Site Visit deleted";
messages[types.ADD_TITLE_SUCCESS] = "Save new Title";
messages[types.UPDATE_TITLE_SUCCESS] = "Title update";
messages[types.DELETE_TITLE_SUCCESS] = "Title Visit deleted";
messages[types.ADD_CUSTOM_REPORT_SUCCESS] = "Save new Custom Report";
messages[types.UPDATE_CUSTOM_REPORT_SUCCESS] = "Custom Report update";
messages[types.DELETE_CUSTOM_REPORT_SUCCESS] = "Custom Report deleted";
messages[types.ADD_VISIT_SUCCESS] = "Save new Visit";
messages[types.UPDATE_VISIT_SUCCESS] = "Visit update";
messages[types.DELETE_VISIT_SUCCESS] = "Visit deleted";

messages[types.PREPARE_FILES_NO_FILES] = "No Files to Download";

messages[types.FAILURE] = "Operation";
messages[types.REPORT_FAILURE] = "Report";
messages[types.ADD_MEMBER_FAILURE] = "Save new Member";
messages[types.ADD_SITE_FAILURE] = "Save new Site";
messages[types.ADD_COMMENT_FAILURE] = "Save new Comment";
messages[types.ADD_COPE_FAILURE] = "Save new COPE";
messages[types.UPDATE_MEMBER_FAILURE] = "Member update";
messages[types.UPDATE_SITE_FAILURE] = "Site update";
messages[types.UPDATE_COMMENT_FAILURE] = "Comment update";
messages[types.UPDATE_COPE_FAILURE] = "COPE update";
messages[types.DELETE_MEMBER_FAILURE] = "Member deleted";
messages[types.DELETE_SITE_FAILURE] = "Site deleted";
messages[types.DELETE_COMMENT_FAILURE] = "Comment deleted";
messages[types.DELETE_COPE_FAILURE] = "COPE deleted";
messages[types.PREPARE_COPE_FILES_FAILURE] = "Preparing COPE files";
messages[types.POPULATE_DATABASE_FAILURE] = "Populate";
messages[types.PREPARE_DAY_CARE_FAILURE] = "Prepare Day Care Billing";
messages[types.LOGIN_FAILURE] = "Login";
messages[types.CONNECTION_ERROR] = "Connection error, contact with Admin";
messages[types.POST_DAY_CARE_PAYMENT_FAILURE] = "POst Day Care Payment";
messages[types.GET_MEMBER_FAILURE] = "Getting Member Information";
messages[types.PROCESSING_PAYROLL_FAILURE] = "Processing Payroll";
messages[types.PROCESSING_BOE_FAILURE] = "Processing BOE";
messages[types.PROCESSING_OPA_FAILURE] = "Processing OPA";
messages[types.ASSOCIATION_SUMMARY_REPORT_FAILURE] = "Association Collected Summary Report";
messages[types.ASSOCIATION_DETAIL_REPORT_FAILURE] = "Association Collected Detail Report";
messages[types.NEW_TO_ASSOCIATION_REPORT_FAILURE] = "New To Association";
messages[types.NEW_TO_PAYROLL_REPORT_FAILURE] = "New To Payroll";
messages[types.LEFT_ASSOCIATION_REPORT_FAILURE] = "Left Association";
messages[types.COPE_COLLECTED_BY_DISTRICT_REPORT_FAILURE] = "Cope Collections By District - Summary";
messages[types.COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE] = "Cope District Zero Collections Summary";
messages[types.COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE] = "Cope Contributor/Non Contributors District Zero Summary";
messages[types.COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_FAILURE] = "Cope District Zero Collections Detail";
messages[types.COPE_COLLECTED_DETAIL_REPORT_FAILURE] = "Cope Collected Detail Report";
messages[types.NEW_MEMBER_REPORT_FAILURE] = "New Member Report";
messages[types.DELETED_MEMBER_REPORT_FAILURE] = "Deleted Member Report";
messages[types.CHANGE_IN_TITLE_REPORT_FAILURE] = "Change In Title Report";
messages[types.CHANGE_IN_SCHOOL_REPORT_FAILURE] = "Change In School Report";
messages[types.OFF_PAYROLL_REPORT_FAILURE] = "Off Payroll Report";
messages[types.ON_PAYROLL_NO_BOE_REPORT_FAILURE] = "On Payroll, Not DOE";
messages[types.GET_SITE_FAILURE] = "Get Site";
messages[types.GET_TITLE_FAILURE] = "Get Title";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_FAILURE] = "Change In School and/or Title Report";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_FAILURE] = "Change In School and/or Title Report - NO ZoneSchools";
messages[types.STATUS_CHANGE_REPORT_FAILURE] = "Dept of Ed Status Change Report";
messages[types.CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_FAILURE] = "Change in School and/or Title Report - Zone Schools ONLY";
messages[types.ADD_STATUS_FAILURE] = "Save new Status";
messages[types.UPDATE_STATUS_FAILURE] = "Status update";
messages[types.DELETE_STATUS_FAILURE] = "Status deleted";
messages[types.ADD_ASSIGNMENT_FAILURE] = "Save new Assignment";
messages[types.UPDATE_ASSIGNMENT_FAILURE] = "Assignment update";
messages[types.DELETE_ASSIGNMENT_FAILURE] = "Assignment deleted";
messages[types.DISTRICT_ROSTER_FAILURE] = "District Roster";
messages[types.REGION_ROSTER_FAILURE] = "Region Roster";
messages[types.PER_CAPITA_FAILURE] = "Membership Per Capita Report";
messages[types.EXTRACT_ADDRESSES_FAILURE] = "Extract Addresses for Mailing";
messages[types.EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_FAILURE] = "Extract Addresses for Specific Titles";
messages[types.CHARTER_SCHOOL_FAILURE] = "Charter School Report";
messages[types.PREPARE_ASSOCIATION_FILES_FAILURE] = "Prepare Association Files";
messages[types.PREPARE_DUES_FILES_FAILURE] = "Prepare Dues Files";
messages[types.PREPARE_DUES_FILES_ALL_FAILURE] = "Prepare Dues Files - All";
messages[types.UPDATE_USERS_FROM_ACTIVE_DIRECTORY_FAILURE] = "Update Users From Active Directory";
messages[types.ADD_ATTENDANCE_FAILURE] = "Save new  Attendance";
messages[types.UPDATE_ATTENDANCE_FAILURE] = "Attendance update";
messages[types.DELETE_ATTENDANCE_FAILURE] = "Attendance deleted";
messages[types.ADD_BENEFIT_FAILURE] = "Save new Benefit";
messages[types.UPDATE_BENEFIT_FAILURE] = "Benefit update";
messages[types.DELETE_BENEFIT_FAILURE] = "Benefit deleted";
messages[types.ADD_BOE_FAILURE] = "Save new BOE";
messages[types.UPDATE_BOE_FAILURE] = "BOE update";
messages[types.DELETE_BOE_FAILURE] = "BOE deleted";
messages[types.ADD_COPA_FAILURE] = "Save new COPA";
messages[types.UPDATE_COPA_FAILURE] = "COPA update";
messages[types.DELETE_COPA_FAILURE] = "COPA deleted";
messages[types.ADD_DUES_COLLECTED_FAILURE] = "Save new Dues Collected";
messages[types.UPDATE_DUES_COLLECTED_FAILURE] = "Dues Collected update";
messages[types.DELETE_DUES_COLLECTED_FAILURE] = "Dues Collected deleted";
messages[types.ADD_HISTORY_FAILURE] = "Save new History";
messages[types.UPDATE_HISTORY_FAILURE] = "History update";
messages[types.DELETE_HISTORY_FAILURE] = "History deleted";
messages[types.ADD_INTERIM_ACTING_FAILURE] = "Save new Interim Acting";
messages[types.UPDATE_INTERIM_ACTING_FAILURE] = "Interim Acting update";
messages[types.DELETE_INTERIM_ACTING_FAILURE] = "Interim Acting deleted";
messages[types.ADD_MEMBER_VISIT_FAILURE] = "Save new Member Visit";
messages[types.UPDATE_MEMBER_VISIT_FAILURE] = "Member Visit update";
messages[types.DELETE_MEMBER_VISIT_FAILURE] = "Member Visit deleted";
messages[types.ADD_SITE_UPDATE_FAILURE] = "Save new Site Visit";
messages[types.UPDATE_SITE_UPDATE_FAILURE] = "Site Visit update";
messages[types.DELETE_SITE_UPDATE_FAILURE] = "Site Visit deleted";
messages[types.ADD_SITE_VISIT_FAILURE] = "Save new Site Visit";
messages[types.UPDATE_SITE_VISIT_FAILURE] = "Site Visit update";
messages[types.DELETE_SITE_VISIT_FAILURE] = "Site Visit deleted";
messages[types.ADD_TITLE_FAILURE] = "Save new Title";
messages[types.UPDATE_TITLE_FAILURE] = "Title update";
messages[types.DELETE_TITLE_FAILURE] = "Title Visit deleted";
messages[types.ADD_CUSTOM_REPORT_FAILURE] = "Save new Custom Report";
messages[types.UPDATE_CUSTOM_REPORT_FAILURE] = "Custom Report update";
messages[types.DELETE_CUSTOM_REPORT_FAILURE] = "Custom Report deleted";
messages[types.ADD_VISIT_FAILURE] = "Save new Visit";
messages[types.UPDATE_VISIT_FAILURE] = "Visit update";
messages[types.DELETE_VISIT_FAILURE] = "Visit deleted";

export default messages;