import React                                   from 'react';
import ReactDOM                                from 'react-dom';
import { createStore }                         from 'redux';
import { Provider }                            from 'react-redux';
import './index.css';
import App                                     from './App';
import rootReducer                             from './reducers';
import * as serviceWorker                      from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import { BrowserRouter as Router, withRouter } from "react-router-dom";


const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const SomeComponent = withRouter(props => <App {...props}/>);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}><Router><SomeComponent/></Router></Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
