import { useEffect, useState }            from 'react'
import { grievanceService } from "../services";
import { useHistory, useLocation }        from "react-router";
import { setAlertNotification }           from "../actions/util";
import { appPaths }                       from "../routes";
import { useDefaultLimit }                from "./WindowUtil";

export const useGrievanceList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [grievances, setGrievances] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "LastModification", descending: true, open: 1});

  const getGrievances = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    grievanceService()
      .getGrievances(limit, skip, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          setGrievances(res.data);
          const grievancePaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            open,
            filterName
          }
          setPagination(grievancePaginateList)
          sessionStorage.setItem("grievancePaginateList", JSON.stringify(grievancePaginateList))
        }
      })
  };

  useEffect(() => {
    let grievancePaginateList = sessionStorage.getItem("grievancePaginateList")
    if (grievancePaginateList) {
      grievancePaginateList = JSON.parse(grievancePaginateList)
      getGrievances(defaultLimit || grievancePaginateList.limit, grievancePaginateList.skip, grievancePaginateList.filterName, grievancePaginateList.columnSort, grievancePaginateList.descending, grievancePaginateList.open)
    } else {
      getGrievances()
    }
    // eslint-disable-next-line
  }, []);

  return {grievances, pagination, getGrievances}
};

export const useGrievance = ({id, onChange, closeModal}) => {

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const grievanceParamId = param.get("grievance");

  const [grievance, setGrievance] = useState({});

  const getGrievance = (grievanceId) => {
    if (grievanceId || id || grievanceParamId) {
      grievanceService()
        .getGrievance(grievanceId || id || grievanceParamId)
        .then(res => {
          if (res && res.data) {
            setGrievance(res.data);
            if (res.data.siteModelId && res.data.memberModelId) {
              history.push(appPaths.GrievanceDetail + "?grievance=" + res.data.id + "&site=" + res.data.siteModelId + "&member=" + res.data.memberModelId)
            } else {
              history.push(appPaths.GrievanceDetail + "?grievance=" + res.data.id + "&union-initiated=true")
            }
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setGrievance({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getGrievance()
    // eslint-disable-next-line
  }, [grievanceParamId, id]);

  const changeGrievanceState = (values) => {
    let params = {id: grievance.id, isOpen: !grievance.isOpen};
    if (values) {
      params = {...params, outcome: values.outcome, closedDate: values.closedDate}
    }
    grievanceService()
      .changeState(params)
      .then(() => {
        getGrievance();
        closeModal();
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };
  const deleteGrievance = () => {
    grievanceService()
      .deleteGrievance(grievanceParamId)
      .then(() => {
        history.push(appPaths.GrievanceList);
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  return {
    grievance,
    getGrievance,
    grievanceId: grievanceParamId,
    changeGrievanceState,
    isGrievanceOpen: grievance.isOpen || typeof grievance.isOpen === "undefined",
    deleteGrievance
  }
};
