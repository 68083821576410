import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "visitCommentMembershipOnly/";
export default () => ({
    getVisitCommentMembershipOnly: (id) => client.request({
      method: 'GET',
      url: base + 'getVisitCommentMembershipOnly/' + id
    }),
    getVisitCommentsMembershipOnly: (visitId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetVisitCommentsMembershipOnly/' + visitId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addVisitCommentMembershipOnly: (params) => client.request({
      method: 'POST',
      url: base + 'addVisitCommentMembershipOnly',
      data: params
    }),
    updateVisitCommentMembershipOnly: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateVisitCommentMembershipOnly/' + params.id,
      data: params
    }),
    deleteVisitCommentMembershipOnly: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteVisitCommentMembershipOnly/' + params.id
    })
  }
);
