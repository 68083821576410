import React, { useEffect, useRef, useState }                                                                    from 'react';
import { Badge, Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { friendService }                                                                                         from "../../services";
import { appPaths }                                                                                              from "../../routes";
import { useHistory }                                                                                            from "react-router";
import { useForm }                                                                                               from "react-hook-form";
import {dateFormatMonthDayYearHoursMinute, phoneFormat, setAlertNotification} from "../../actions/util";
import { useAllName, useCategories, useFriend, useFriendAddressList, useMember, useSite }                        from "../../hooks";
import { useLocation }                                                                                           from "react-router-dom";
import { Select }                                                                                                from "react-functional-select";
import { BigModal, FriendAddressForm, FriendAddressList, FriendTab, ReplaceOnWidth }                             from "../index";
import { FontAwesomeIcon }                                                                                       from "@fortawesome/react-fontawesome";
import { faCheck, faPlus }                                                                                       from "@fortawesome/free-solid-svg-icons";
import CheckIt                                                                                                   from '../utils/CheckIt';
import { isAllow, userActionsKeys }                                                                              from '../../util/userActions';

const FriendDetail = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {register, handleSubmit, reset, setValue} = useForm();
  const {getName} = useAllName();
  const {site, siteId} = useSite();
  const {member, memberId} = useMember();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (member.id && !siteId) {
      history.push(location.pathname + location.search + "&site=" + member.siteModelId)
    }
    // eslint-disable-next-line
  }, [member])

  const {categories} = useCategories();
  const [categorySelected, setCategorySelected] = useState({});
  const selectRef = useRef();
  const [phone, setPhone] = useState("");

  const {friend, friendId, changeFriendState, friendCategories, getFriendCategories, deleteFriendCategory} = useFriend({onChange: data => {reset(data); setPhone(data.phone)}});
  const {friendAddresses, getFriendAddresses, paginationFriendAddress, onSubmit, deleteFriendAddress} = useFriendAddressList();
  const [addressToEdit, setAddressToEdit] = useState({});
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const isMember = friendId ? !!friend.memberModelId : memberId && member.id;

  useEffect(() => {
    if (friend.id && friend.memberModelId && !location.search.includes("member")) {
      history.push(location.pathname + location.search + "&member=" + friend.memberModelId)
    }
    // eslint-disable-next-line
  }, [friend])

  return (
    <Container fluid={true}>
      <FriendTab from="Friends of CSA"/>
      <Row className="padding-15 mb-2">
        <h3 className="no-margin">{friend.id ? "Edit" : "New"} Friend</h3>
      </Row>
      <Row>
        <div className={"col-12 col-md-6 transition-all" + (friendId ? "" : " offset-md-3")}>
          <div className="col-12 detail-header border-bottom-gray">
            Details {isMember && <span>[Member No. {member.fileno}]</span>}
          </div>
          <div className="col-12 detail">
            <form onSubmit={handleSubmit((values) => {
              values = {...friend, ...values, phone, memberModelId: memberId};
              if (isMember) {
                values = {
                  ...values,
                  firstname: member.frstname,
                  lastname: member.lastname,
                  title: member.title,
                  email: member.email,
                  organization: site.sitename,
                }
              }
              if (friend.id) {
                friendService()
                  .updateFriend({...friend, ...values})
                  .then(res => {setAlertNotification("Saved")})
                  .catch(err => {setAlertNotification("Saved", "danger")})
              } else {
                friendService()
                  .addFriend(values)
                  .then(res => {
                    if (res && res.data) {
                      history.push(appPaths.FriendDetail + (isMember ? "?member=" + memberId + "&site=" + siteId + "&friend=" + res.data : "?friend=" + res.data))
                    }
                    setAlertNotification("Saved")
                  })
                  .catch(err => {setAlertNotification("Saved", "danger")})
              }
            })}>
              <Row>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Prefix</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  <input className="form-control" name="prefix" ref={register}/>
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Firstname</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? member.frstname : <input className="form-control" name="firstname" ref={register}/>}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Lastname</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? member.lastname : <input className="form-control" name="lastname" ref={register}/>}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Title</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? member.title : <input className="form-control" name="title" ref={register}/>}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Email</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? member.email : <input className="form-control" type="email" name="email" ref={register}/>}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Phone Number</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? phoneFormat(member.telephon) : <input className="form-control" name="phone" value={phone} onChange={(e) => {
                    let value = e.target.value
                    if (phoneFormat(value) !== phoneFormat(phone)) {
                      setPhone(phoneFormat(value))
                    }
                  }}/>}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">{isMember ? "School" : "Organization"}</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isMember ? site.sitename : <input className="form-control" name="organization" ref={register}/>}
                </div>

                {friend.id && <>

                  <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Active</div>
                  <div className="col-12 col-sm-7 mt-2 mb-2">
                    {friend.isActive && <FontAwesomeIcon className="mr-2" color="green" icon={faCheck}/>}
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color={friend.isActive ? "danger" : "secondary"}>
                        {friend.isActive ? "Inactive" : "Active"} Friend
                      </DropdownToggle>
                      <CheckIt action={userActionsKeys.changeStatusFriendLegal}>
                        <DropdownMenu style={{minWidth: "8rem"}}>
                          <DropdownItem className={friend.isActive ? "text-danger" : "text-secondary"} onClick={() => {
                            changeFriendState(!friend.isActive);
                          }}>Confirm</DropdownItem>
                        </DropdownMenu>
                      </CheckIt>
                    </UncontrolledButtonDropdown>
                  </div>


                  <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Updated On</div>
                  <div className="col-12 col-sm-7 mt-2 mb-2">{dateFormatMonthDayYearHoursMinute(friend.updatedOn)}</div>

                  <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Updated By</div>
                  <div className="col-12 col-sm-7 mt-2 mb-2">{getName(friend.updatedBy)}</div>
                </>}
                <CheckIt action={userActionsKeys.updateFriendLegal}>
                  <div className="col-12 mt-4 mb-2"><Button>Save</Button></div>
                </CheckIt>
              </Row>
            </form>

          </div>
        </div>
        <CheckIt action={userActionsKeys.friendCategoryListLegal}>
          {friendId && <div className="col-12 col-md-6">
            <div className="col-12 detail-header border-bottom-gray">
              Category
            </div>
            <div className="col-12 detail">
              <Row>
                <CheckIt action={userActionsKeys.addNewFriendCategoryLegal}>
                  <div className="col-12 d-flex justify-content-around">
                    <div className="select-container flex-grow-1 mr-2">
                      <Select
                        options={categories.filter(f => !friendCategories.some(s => s?.category?.id === f.id)).map(category => ({name: category.description, value: category}))}
                        placeholder="Select Category"
                        name="categories"
                        inputId="category-select-input"
                        ref={selectRef}
                        onOptionChange={(option) => {
                          if (option && option.value) {
                            setCategorySelected(option)
                          }
                        }}
                        scrollMenuIntoView
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.name}
                      />
                    </div>
                    <div className="text-right">

                      <Button
                        disabled={!categorySelected?.value?.id}
                        onClick={() => {
                          friendService()
                            .addFriendCategory(friendId, categorySelected?.value?.id)
                            .then(res => {
                              selectRef.current && selectRef.current.clearValue();
                              setCategorySelected({});
                              getFriendCategories();
                            })
                            .catch(err => {setAlertNotification("Error adding an Category", "danger")});
                        }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>

                    </div>
                  </div>
                </CheckIt>

                <div className="col-12 mt-3">
                  {friendCategories?.length > 0 && friendCategories.map((friendCategory, index) => (
                    <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                      {getName(friendCategory?.category?.description)}
                      <CheckIt action={userActionsKeys.deleteCategoryLegal}>
                        <div className="inline-right text-danger hover-show" onClick={() => {deleteFriendCategory(friendCategory.id)}}>Delete</div>
                      </CheckIt>
                    </div>
                  ))}
                </div>

              </Row>
            </div>
          </div>}
        </CheckIt>
        <CheckIt action={userActionsKeys.friendAddressListLegal}>
          {friendId && <div className="col-12">
            <div className="col-12 detail-header border-0">
              <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{paginationFriendAddress.count}</Badge> Address
              <CheckIt action={userActionsKeys.addNewFriendAddressLegal}>
            <span style={{position: "absolute", right: "15px"}}>
                   <Button style={{marginTop: "-5px"}} onClick={() => {
                     setAddressToEdit({});
                     setIsAddressModalOpen(true)
                   }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="New"/></Button>
                </span>
              </CheckIt>
            </div>
            <div className="col-12 detail no-padding">
              <FriendAddressList
                friendAddresses={friendAddresses}
                pagination={paginationFriendAddress}
                setFriendAddress={(values) => {
                  setAddressToEdit(values);
                  setIsAddressModalOpen(true);
                }}
                onPaginationAction={(limit, skip, columnSort, desc) => {
                  getFriendAddresses(limit, skip, "", columnSort, desc)
                }}
                deleteFriendAddress={deleteFriendAddress}/>
            </div>
          </div>}
        </CheckIt>
      </Row>
      {(isAllow(userActionsKeys.updateFriendAddressLegal) || isAllow(userActionsKeys.addNewFriendAddressLegal)) && friendId &&
      <BigModal className="modal-green" title="Friend Address" isBigModalOpen={isAddressModalOpen} toggleModalAction={() => {setIsAddressModalOpen(false)}}>
        <FriendAddressForm defaultValues={addressToEdit} onCancel={() => {setIsAddressModalOpen(false)}} onSubmit={(values) => {
          onSubmit(values, () => {
            setIsAddressModalOpen(false)
          });
        }}/>
      </BigModal>}

    </Container>
  );
};

export default FriendDetail;
