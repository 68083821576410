import React                                from 'react'
import { ColumnSort, MyPagination, Search } from "../index";
import { CustomInput, Table }               from "reactstrap";

let search = "";

const UsersList = ({users, getUsers, paginationUser, selectedUsers, setSelectedUsers}) => {

  const isSelected = (user) => selectedUsers.some(u => u.id === user.id);

  const selectFn = (user) => {
    if (isSelected(user)) {
      let tmp = selectedUsers.filter(u => u.id !== user.id);
      setSelectedUsers(tmp)
    } else {
      setSelectedUsers([...selectedUsers, user])
    }
  };

  return (
    <div>
      <Search defaultValue={paginationUser.filterName} onSearch={(value) => {
        search = value;
        getUsers(paginationUser.limit, 0, value, paginationUser.columnSort, paginationUser.descending)
      }}/>
      <h5 style={{marginBottom: "20px"}}>Users List</h5>
      <Table hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Name <ColumnSort columnName="Name" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsers(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          <th>Username <ColumnSort columnName="UserName" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
            getUsers(paginationUser.limit, paginationUser.skip, search, object.columnSort, object.descending)
          }}/></th>
          <th className="text-center">Select</th>
        </tr>
        </thead>
        <tbody>
        {users && users.map((user, index) => (
          <tr key={index + paginationUser.skip}>
            <th className="cursor-pointer" scope="row" onClick={() => {selectFn(user)}}>{index + paginationUser.skip + 1}</th>
            <th className="cursor-pointer" onClick={() => {selectFn(user)}}>{user.name}</th>
            <th className="cursor-pointer" onClick={() => {selectFn(user)}}>{user.username}</th>
            <th className="text-center">
              <CustomInput
                type="checkbox"
                className="float-none"
                name={"user" + index + paginationUser.skip + 1}
                id={"user" + index + paginationUser.skip + 1}
                checked={isSelected(user)}
                onClick={(e) => {e.stopPropagation()}}
                onChange={() => {
                  selectFn(user)
                }}
                label="Select"/>
            </th>
          </tr>
        ))}
        </tbody>
      </Table>
      <div style={{overflow: "auto"}}>
        {users.length > 0 && paginationUser.count > 10 &&
        <MyPagination count={paginationUser.count} limit={paginationUser.limit} skip={paginationUser.skip}
                      action={(skip, limit) => {
                        getUsers(limit, skip, search, paginationUser.columnSort, paginationUser.descending)
                      }}/>}
      </div>
    </div>
  )
};

export default UsersList;