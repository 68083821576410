import React                       from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { appPaths }                from "../../routes";
import { MyLink }                  from "../index";
import { cleanName }               from "../../actions/util";
import { useStatus, useTitle }     from "../../hooks";

const SelectMemberItem = ({member, selectMember, isUnionInitiatedAllow}) => {

  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();

  const history = useHistory();
  const location = useLocation();
  let param = location.search;
  const memberId = new URLSearchParams(param).get("member");

  let cursorPointer = "cursor-pointer";
  // eslint-disable-next-line
  if (memberId == member.id) {
    cursorPointer += " row-selected"
  }

  return (
    <tr className={cursorPointer} onClick={() => {
      selectMember(member);
      history.push(location.pathname + "?site=" + member.siteModelId + "&member=" + member.id + (isUnionInitiatedAllow ? "&allow-union-initiated=true" : ""))
    }}>
      <th scope="row">{member.fileno}</th>
      <th>
        <div>
          <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
        </div>
        <div><MyLink onlyChildren={!!selectMember} to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a" onClick={e => {e.stopPropagation()}}>{cleanName(member.lastname)}{cleanName(member.frstname) &&
        <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !(member.frstname) && <span>Detail</span>}</MyLink></div>
        <div>
          <small className="text-gray">{getCategory(member.status)}</small>
        </div>
      </th>
      <th>{member.site ? member.site.district : ""}</th>
    </tr>
  );
};

export default SelectMemberItem;
