import { client }         from '../util/createRestApiClient';
import { loading }        from "./index";
import { SENIORITY_TYPE } from "../types";

const base = "Footer/";
export default () => ({
    getFooter: (footerType) => loading(client.request({
      method: 'GET',
      url: base + 'GetFooter/' + footerType
    })),
    getAllFooters: () => client.request({
      method: 'GET',
      url: base + 'GetAllFooters?filter='
    }),
    addFooter: (params) => client.request({
      method: 'POST',
      url: base + 'AddFooter'+ (params.type === SENIORITY_TYPE ? "Seniority" : "Bonus"),
      data: params
    }),
    updateAccess: (params) => client.request({
      method: 'POST',
      url: base + 'UpdateAccess' + (params.type === SENIORITY_TYPE ? "Seniority" : "Bonus"),
      data: params
    }),

  }
);
