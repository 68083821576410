import * as types       from "../types";
import { getUniqueKey } from "../actions/util";
import { appPaths }     from "../routes";

export const general = {
  temporalCRUDObject: (state = {}, action) => {
    switch (action.type) {
      case types.SET_TEMPORAL_CRUD_OBJECT:
        return {...state, ...action.data};
      case types.RESET_TEMPORAL_CRUD_OBJECT:
        return {};
      default:
        return state
    }
  },
  isModalOpen: (state = "", action) => {
    switch (action.type) {
      case types.TOGGLE_MODAL:
        return state ? "" : action.data ? action.data : "";
      default:
        return state
    }
  },
  isBigModalOpen: (state = false, action) => {
    switch (action.type) {
      case types.TOGGLE_BIG_MODAL:
        return !state;
      default:
        return state
    }
  },
  isFetching: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING:
        return true;
      case types.FETCHING_END:
        return false;
      case types.TOGGLE_FETCHING:
        return !state;
      default:
        return state
    }
  },
  isFetchingCOPEFiles: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING_COPE_FILES:
        return true;
      case types.FETCHING_COPE_FILES_END:
        return false;
      default:
        return state
    }
  },
  isFetchingAssociationFiles: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING_ASSOCIATION_FILES:
        return true;
      case types.FETCHING_ASSOCIATION_FILES_END:
        return false;
      default:
        return state
    }
  },
  isFetchingDuesFiles: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING_DUES_FILES:
        return true;
      case types.FETCHING_DUES_FILES_END:
        return false;
      default:
        return state
    }
  },
  isFetchingDuesAllFiles: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING_DUES_ALL_FILES:
        return true;
      case types.FETCHING_DUES_ALL_FILES_END:
        return false;
      default:
        return state
    }
  },
  alertNotification: (state = [], action) => {
    switch (action.type) {
      case types.ADD_ALERT_NOTIFICATION:
        return [...state, {id: getUniqueKey(), message: action.message, color: action.color}];
      case types.DISMISS_ALERT_NOTIFICATION:
        return state.filter(t => t.id !== action.id);
      case types.RESET_ALERT_NOTIFICATION:
        return [];
      default:
        return state
    }
  },
  showAuthModal: (state = sessionStorage && !sessionStorage.getItem('tokenAuthLFS'), action) => {
    switch (action.type) {
      case types.SHOW_AUTH_MODAL:
        return true;
      case types.HIDE_AUTH_MODAL:
        return false;
      case types.TOKEN_IS_IN_LOCAL_STORAGE:
        return !!sessionStorage.getItem('tokenAuthLFS');
      default:
        return state
    }
  },
  sublistActiveItem: (state = "", action) => {
    switch (action.type) {
      case types.SET_SUBLIST_ACTIVE_ITEM:
        return action.data || "";
      case types.RESET_SUBLIST_ACTIVE_ITEM:
        return action.data || "";
      default:
        return state
    }
  },
  collapseMemberOptions: (state = (window && window.location ? window.location.pathname === appPaths.MemberForm : sessionStorage.getItem("lastActiveItem") === "AddNewMember"), action) => {
    switch (action.type) {
      case types.TOGGLE_COLLAPSE_MEMBER_OPTIONS:
        return !state;
      case types.SET_COLLAPSE_MEMBER_OPTIONS:
        return action.data;
      case types.RESET_COLLAPSE_MEMBER_OPTIONS:
        return true;
      default:
        return state
    }
  }
};