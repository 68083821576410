import React                                                              from 'react'
import { CheckIt, ColumnSort, MyLink, MyPagination, VisitMemberComments } from "../index";
import { CustomInput, Table }                                             from "reactstrap";
import { appPaths }                                                       from "../../routes";
import { visitMemberService }                                             from "../../services";
import { isAllow, userActionsKeys }                                       from "../../util/userActions";
import { cleanName }                                                      from "../../actions/util";
import { useDefaultLimit }                                                from "../../hooks";

const VisitMemberList = ({visit, visitMembers, visitMemberPagination, getVisitMembers}) => {

  const {defaultLimit} = useDefaultLimit()
  const sortFn = (object) => {
    getVisitMembers(visit.id, defaultLimit, visitMemberPagination.skip, visitMemberPagination.filterName, object.columnSort, object.descending)
  };

  return (
    <>
      <Table hover className="table-dotted table-responsive-vertical">
        <thead>
        <tr>
          <th style={{width: "250px"}}>Member <ColumnSort columnName="Member" columnSort={visitMemberPagination.columnSort} descending={visitMemberPagination.descending} changeSortAction={sortFn}/></th>
          <CheckIt action={userActionsKeys.visitMemberCommentListLegal}>
            <th>Comments</th>
          </CheckIt>
        </tr>
        </thead>
        <tbody>
        {visitMembers.length ? visitMembers.map((visitMember, index) => (

            <tr key={index + "" + visitMemberPagination.skip + "" + visitMember.id}>
              <th scope="row">
                {visit.isOpen && isAllow(userActionsKeys.addVisitMemberCommentLegal) && <CustomInput
                  type="checkbox"
                  id={"visitMember-" + index + visitMemberPagination.skip}
                  checked={visitMember.wasVisited}
                  disabled={(visitMember.wasVisited && visitMember.visitMemberComments && visitMember.visitMemberComments.length > 0) || !visit.isOpen}
                  style={{float: "left"}}
                  onChange={() => {
                    if (visitMember.wasVisited) {
                      visitMemberService()
                        .deleteVisitMember(visitMember.id)
                        .then(() => {
                          getVisitMembers(visit.id)
                        });
                    } else {
                      visitMemberService()
                        .addVisitMember({...visitMember, member: null, visitModelId: visit.id})
                        .then(() => {
                          getVisitMembers(visit.id)
                        });
                    }
                  }}/>}

                {visitMember.member && visitMember.member.id &&
                <MyLink to={"/" + appPaths.MemberDetail + "?member=" + visitMember.member.id} className={"no-a" + (visit.isOpen ? " ml-2" : "")}>
                  {cleanName(visitMember.member.lastname)}{cleanName(visitMember.member.frstname) && <span>, {cleanName(visitMember.member.frstname)}</span>}{!cleanName(visitMember.member.lastname) && !cleanName(visitMember.member.frstname) && <span>Detail</span>}
                </MyLink>}
              </th>
              <CheckIt action={userActionsKeys.visitMemberCommentListLegal}>
                <th>
                  <VisitMemberComments key={visitMember.id + "" + index} visit={visit} visitMemberComments={visitMember.visitMemberComments} visitMember={visitMember} getVisitMembers={getVisitMembers}/>
                </th>
              </CheckIt>
            </tr>
          )) :
          <tr>
            <th colSpan="2" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getVisitMembers(visit.id)
            }}>refresh</span></th>
          </tr>}
        </tbody>
      </Table>

      {visitMembers.length > 0 && visitMemberPagination.count > 10 &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={visitMemberPagination.count} limit={defaultLimit} skip={visitMemberPagination.skip} action={(skip, limit) => {
          getVisitMembers(visit.id, limit, skip, visitMemberPagination.filterName, visitMemberPagination.columnSort, visitMemberPagination.descending)
        }}/>
      </div>}
    </>
  )
};

export default VisitMemberList;