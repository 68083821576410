import { siteSummaryService } from "../services/index";

import * as types   from "../types";
import { dispatch } from "./util";

export const addSiteSummaryAction = (comment, cb) => {
  dispatch(types.FETCHING);
  siteSummaryService().addSiteSummary(comment)
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: "Save new Comments", color: 'info'});
      if (cb) {
        cb()
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: "Save new Comments", color: 'danger'});
    });
};
