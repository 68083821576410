import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "rating/";
export default () => ({
    getRating: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getRating/' + id
    })),
    getRatingCasesByAdvocateReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetRatingCasesByAdvocateReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getRatingCasesReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetRatingCasesReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getRatings: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getRatings?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    getRatingsByMember: (memberId, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getRatingsByMember/' + memberId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    addRating: (params) => client.request({
      method: 'POST',
      url: base + 'addRating',
      data: params
    }),
    updateRating: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateRating/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeStateRating/' + params.id + "?isOpen=" + params.isOpen + "&closedOn=" + ((params.closedOn && params.closedOn.toISOString()) || new Date().toISOString()) + "&outcome=" + params.outcome,
    }),
    deleteRating: (id) => client.request({
      method: 'DELETE',
      url: base + 'deleteRating/' + id
    })
  }
);
