import React                      from 'react';
import { Row, UncontrolledAlert } from 'reactstrap';
import happyFace                  from "../../resources/SVG/ic_28px_Notification_happy_face.svg"
import sadFace                    from "../../resources/SVG/ic_28px_Notification_sad_face.svg"

const AlertNotification = ({color, id, message, dismissAlertAction}) => {
  setTimeout(() => {
    dismissAlertAction(id)
  }, 5000);

  let img;

  if (color === "danger" || color === "warning") {
    img = sadFace;
  } else {
    img = happyFace;
  }

  return (
    <UncontrolledAlert color={color || "primary"} style={{fontWeight: "bold", minWidth: "max-content"}} onClick={() => dismissAlertAction(id)}>
      <Row style={{margin: "0 -12px 0 0", height: "45px"}}>
        <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
          <img src={img} width={28} alt="notification icon"/>
        </div>
        <div className="col-10" style={{paddingLeft: "12px", paddingRight: "0"}}>
          <h5 className="alert-heading" style={{margin: "0 0 4px", paddingTop: "5px"}}>{color === "danger" ? "Failure" : (color === "warning" ? "Warning" : "Success")}</h5>
          <p style={{margin: 0, minWidth: "270px"}}>{message}</p>
        </div>
      </Row>
    </UncontrolledAlert>
  );
};

export default AlertNotification;
