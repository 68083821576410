import { useState }                                                                                 from "react";
import { grievanceService, investigationService, memberService, ratingService, visitMemberService } from "../services";
import { getTodayStringValue, setAlertNotification }                                                from "../actions/util";
import { saveAs }                                                from "file-saver";

export const useReport = () => {

  const [dateFrom, setDateFrom] = useState(undefined)
  const [dateTo, setDateTo] = useState(undefined)

  const disabled = !(dateFrom && dateTo)

  let datesError = "";

  if (dateFrom && dateTo) {
    if(dateTo.valueOf() - dateFrom.valueOf() < 0) {
      datesError = "'To' date is lower than 'From'. Please check this."
    }
  }

  const getReport = (serviceFunc, reportName) => {
    if (datesError) {
      return
    }
    serviceFunc(dateFrom, dateTo, new Date().getTimezoneOffset() / -60)
      .then(res => {
        if (res.data) {
          saveAs(res.data, reportName
            .replace(/\s/g, "")
            .replace("by", "By")
            .replace("Member", "Members")
            .replace("with", "With") + "-" + getTodayStringValue() + ".xlsx");
        }
      })
      .catch(err => {
        setAlertNotification(err?.response?.status === 403 ? "You don't have access to this action" : ("Error getting " + reportName + " Report"), "danger");
      })
  }

  const grievanceByAdvocateReport = () => {getReport(grievanceService().getGrievanceCasesByAdvocateReport, "Grievance Cases by Advocate")}

  const grievanceByArbitratorReport = () => {getReport(grievanceService().getGrievanceCasesByArbitratorReport, "Grievance Cases by Arbitrator")}

  const grievanceReport = () => {getReport(grievanceService().getGrievanceCasesReport, "Grievance Cases by Date Report")}

  const investigationByAdvocateReport = () => {getReport(investigationService().getInvestigationCasesByAdvocateReport, "Investigation Cases by Advocate")}

  const investigationReport = () => {getReport(investigationService().getInvestigationCasesReport, "Investigation Cases by Date")}

  const ratingByAdvocateReport = () => {getReport(ratingService().getRatingCasesByAdvocateReport, "Rating Cases by Advocate")}

  const ratingReport = () => {getReport(ratingService().getRatingCasesReport, "Rating Cases by Date")}

  const memberNotVisitedReport = () => {getReport(memberService().getMembersNotVisitedReport, "Member Not Visited")}

  const memberWithoutACReport = () => {getReport(memberService().getMembersWithoutAuthorizationCardReport, "Member without AC")}

  const memberWithACReport = () => {getReport(memberService().getMembersWithAuthorizationCardReport, "Member with AC")}

  const visitByFieldDirectorReport = () => {getReport(visitMemberService().getVisitByFieldDirectorReport, "Visit by Field Director")}

  const visitBySiteReport = () => {getReport(visitMemberService().getVisitBySiteReport, "Visit by Site")}

  return {
    setDateFrom,
    setDateTo,
    datesError,
    disabled,
    grievanceByAdvocateReport,
    grievanceByArbitratorReport,
    grievanceReport,
    investigationByAdvocateReport,
    investigationReport,
    memberNotVisitedReport,
    memberWithoutACReport,
    memberWithACReport,
    visitByFieldDirectorReport,
    visitBySiteReport,
    ratingByAdvocateReport,
    ratingReport
  }
}
