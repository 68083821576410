import { useEffect, useState }         from 'react'
import { investigationMeetingService } from "../services";
import { useLocation }                 from "react-router";
import { setAlertNotification }        from "../actions/util";
import { useDefaultLimit }             from "./WindowUtil";

export const useInvestigationMeetingList = (props) => {

  const {defaultLimit} = useDefaultLimit()
  const {investigationId} = props || {};
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const investigationParamId = param.get("investigation");

  const [investigationMeetings, setInvestigationMeetings] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});

  const getInvestigationMeetings = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    if (investigationId || investigationParamId) {
      investigationMeetingService()
        .getInvestigationMeetings(investigationId || investigationParamId, limit, skip, filterName, columnSort, descending)
        .then(res => {
          if (res && res.data) {
            setInvestigationMeetings(res.data);
            setPagination({
              ...pagination,
              count: parseInt(res.headers['x-total-count'], 10),
              limit: parseInt(res.headers['x-total-limit'], 10),
              skip: parseInt(res.headers['x-total-skip'], 10),
              columnSort,
              descending
            })
          }
        })
    }
  };

  useEffect(() => {
    getInvestigationMeetings()
    // eslint-disable-next-line
  }, []);

  const addInvestigationMeeting = (values, cb) => {
    investigationMeetingService()
      .addInvestigationMeeting({investigationModelId: investigationId || investigationParamId, ...values})
      .then(() => {
        getInvestigationMeetings();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const updateInvestigationMeeting = (values, cb) => {
    investigationMeetingService()
      .updateInvestigationMeeting({investigationModelId: investigationId || investigationParamId, ...values})
      .then(() => {
        getInvestigationMeetings();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const onSubmit = (values, cb) => {
    if (values.id) {
      updateInvestigationMeeting(values, cb)
    } else {
      addInvestigationMeeting(values, cb)
    }
  };

  return {
    investigationMeetings,
    paginationInvestigationMeetings: pagination,
    investigationId: investigationParamId,
    getInvestigationMeetings,
    addInvestigationMeeting,
    onSubmit
  }
};
