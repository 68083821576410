import React, { useEffect, useState }                                                                                         from 'react';
import { Badge, Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown }              from 'reactstrap';
import { getMemberAction }                                                                                                    from "../../actions/member";
import { memberService, statusService, titleService }                                                                         from "../../services";
import { cleanName, dateFormatMonthDayYear, fetchingFn, getTodayStringValue, moneyFormat, phoneFormat, setAlertNotification } from "../../actions/util";
import { BigModal, CheckIt, MemberContactForm, MemberVisitContactList, MyLink, ReplaceOnWidth }                               from "../index";
import { faDownload, faEraser, faPlus }                                                                                       from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }                                                                                                    from "@fortawesome/react-fontawesome";
import { saveAs }                                                                                                             from "file-saver";
import { appPaths }                                                                                                           from "../../routes";
import { isAllow, userActionsKeys }                                                                                           from "../../util/userActions";
import { useAllName, useCaseList, useMemberVisitContactList }                                                                 from "../../hooks";

const MemberDetail = ({history}) => {

  const {getName} = useAllName();
  const [member, setMember] = useState({});
  const [title, setTitle] = useState({});
  const [status, setStatus] = useState({});
  const [isBigModalOpen, setIsBigModalOpen] = useState(false);
  const [bigModalTitle, setBigModalTitle] = useState("UNION DUES AUTHORIZATION CARD");
  const [authorizationCard, setAuthorizationCard] = useState({});
  const [memberContact, setMemberContact] = useState({});

  const {contactVisitMemberList, getContactVisitMemberList, pagination} = useMemberVisitContactList()
  const {cases} = useCaseList()

  const toggleModal = () => {
    setIsBigModalOpen(!isBigModalOpen);
  };

  const getAuthorizationCard = (id) => {
    memberService()
      .getAuthorizationCard(id)
      .then(res => {
        setAuthorizationCard(res.data || {})
      })
      .catch((err) => {
        setAuthorizationCard({})
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let param = history.location.search;
    if (!(member && member.id) && param) {
      const memberId = new URLSearchParams(param).get("member");
      if (memberId) {
        statusService()
          .getStatusByMemberId(memberId)
          .then(res => {
            setStatus(res.data || [])
          })
          .catch((err) => {
            setStatus([])
          });
        titleService()
          .getTitleByMemberId(memberId)
          .then(res => {
            setTitle(res.data || [])
          })
          .catch((err) => {
            setTitle([]);
          });
        getAuthorizationCard(memberId);
        getMemberAction(memberId, false, (data) => {
          setMember(data);
        });
      }
    }
    // eslint-disable-next-line
  }, [history, member]);

  const getSex = (sex) => sex === "M" ? 'Male' : sex === "F" ? "Female" : "";

  return (
    <Container fluid={true}>
      <Row className="padding-15">
        {cleanName(member.lastname) && cleanName(member.frstname) ? <h3>{cleanName(member.lastname)}, {cleanName(member.frstname)} <span className="text-gray">Details</span></h3> : <h3 className="text-gray">Details</h3>}
        {(isAllow(userActionsKeys.addNewInvestigationLegal) || isAllow(userActionsKeys.addNewGrievanceLegal) || isAllow(userActionsKeys.addNewRatingLegal)) &&
        <div style={{marginLeft: "auto"}}>
          <UncontrolledButtonDropdown className="ml-2 mb-2">
            <DropdownToggle caret color="secondary">
              New Case
            </DropdownToggle>
            <DropdownMenu style={{minWidth: "8rem"}}>
              {isAllow(userActionsKeys.addNewInvestigationLegal) && <DropdownItem onClick={() => {
                history.push(appPaths.InvestigationDetail + "?site=" + member.siteModelId + "&member=" + member.id)
              }}>Investigation</DropdownItem>}
              {isAllow(userActionsKeys.addNewGrievanceLegal) && <DropdownItem onClick={() => {
                history.push(appPaths.GrievanceDetail + "?site=" + member.siteModelId + "&member=" + member.id)
              }}>Grievance</DropdownItem>}
              {isAllow(userActionsKeys.addNewRatingLegal) && <DropdownItem onClick={() => {
                history.push(appPaths.RatingDetail + "?site=" + member.siteModelId + "&member=" + member.id)
              }}>Rating</DropdownItem>}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>}
      </Row>
      <Row>
        <div className="col-12 col-md-6">
          <div className="col-12 detail-header border-bottom-gray">
            Personal Information
          </div>
          <div className="col-12 detail">
            <Row>
              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">File No</div>
              <div className="col-12 col-sm-7">{member.fileno}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Title</div>
              <div className="col-12 col-sm-7">{title.description}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">First Name</div>
              <div className="col-12 col-sm-7">{cleanName(member.frstname)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Last Name</div>
              <div className="col-12 col-sm-7">{cleanName(member.lastname)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Status</div>
              <div className="col-12 col-sm-7">{status.category}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Sex</div>
              <div className="col-12 col-sm-7">{getSex(member.sex)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Date of Birth</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(member.dob, member.memberid)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">COPE/PAC</div>
              <div className="col-12 col-sm-7">{member.copeAmount ? moneyFormat(member.copeAmount) : "$0"}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">CEB</div>
              <div className="col-12 col-sm-7">{member.ceb}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Tenure Date</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(member.tenureDate, member.memberid)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Location</div>
              <div className="col-12 col-sm-7">{member.location}</div>

              {/*<div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Amount</div>
              <div className="col-12 col-sm-7">{member.copeAmount ? moneyFormat(member.copeAmount) : "$0"}</div>*/}

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Presidents Club</div>
              <div className="col-12 col-sm-7">{member.presidentsClub}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">CSA Payroll Date</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(member.csa, member.memberid)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Department Education Date</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(member.boEDate, member.memberid)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Appointment Date</div>
              <div className="col-12 col-sm-7">{dateFormatMonthDayYear(member.appointmentDate, member.memberid)}</div>

            </Row>
          </div>

        </div>

        <div className="col-12 col-md-6">
          <div className="col-12 detail-header border-bottom-gray">
            Contact Information
          </div>
          <div className="col-12 detail">
            <Row>
              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Address</div>
              <div className="col-12 col-sm-7" style={{overflow: "auto"}}>{member.address1} <br/> {member.city}, {member.state} {member.zip}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Phone</div>
              <div className="col-12 col-sm-7">{phoneFormat(member.telephon)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Mobile</div>
              <div className="col-12 col-sm-7">{phoneFormat(member.celphone)}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Personal Email</div>
              <div className="col-12 col-sm-7">{member.personalEmail}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">DOE Email</div>
              <div className="col-12 col-sm-7">{member.email}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site</div>
              <div className="col-12 col-sm-7">
                {member.site &&
                <MyLink to={"/" + appPaths.SiteDetail + "?site=" + member.site.id} className="no-a">{member.site.sitename}{!member.site.sitename && <span>Detail</span>}</MyLink>}
                <div><span>{member.sitecode}</span></div>
              </div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site Phone</div>
              <div className="col-12 col-sm-7">{member.site && member.site.telephon ? phoneFormat((member.site ? member.site.areacode : "") + member.site.telephon) : ""}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Principal Direct #</div>
              <div className="col-12 col-sm-7">{member.site && member.site.principalLine ? phoneFormat(member.site.principalLine) : ""}</div>

              <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">District</div>
              <div className="col-12 col-sm-7">{member.site ? member.site.district : ""}</div>

            </Row>
          </div>

          <div className="col-12 detail-header border-bottom-gray">
            Authorization Card
            {member.id && authorizationCard.id && isAllow(userActionsKeys.deleteAuthorizationCardLegal) &&
            <span style={{position: "absolute", right: "15px"}}>
              <UncontrolledButtonDropdown style={{marginTop: "-5px"}}>
                <DropdownToggle caret color="secondary">
                  <ReplaceOnWidth content={<FontAwesomeIcon icon={faEraser}/>} smContent="Delete"/>
                </DropdownToggle>
                <DropdownMenu style={{minWidth: "8rem"}}>
                  <DropdownItem className="text-danger" onClick={() => {
                    if (member.id) {
                      fetchingFn(true);
                      memberService()
                        .deleteAuthorizationCardByMemberId(member.id)
                        .then(res => {
                          setAuthorizationCard({});
                          setAlertNotification("Delete Authorization Card", 'info');
                          fetchingFn();
                        })
                        .catch((err) => {
                          setAlertNotification("Something got wrong deleting the Authorization Card", 'danger');
                          fetchingFn();
                        });
                    }
                  }}>Confirm</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </span>}
          </div>
          <div className="col-12 detail">
            {member.id && !authorizationCard.id && <div>
              <CheckIt action={userActionsKeys.uploadAuthorizationCardLegal}>
                <MyLink className="btn btn-outline-secondary mb-1" to={"/" + appPaths.UnionDuesAuthorizationCard + "?" + member.id}>Sign Authorization Card</MyLink>
                {" "}
                or
                {" "}
                <Button outline color="secondary fileContainer">
                  <input type="file" accept=".pdf" onChange={(e) => {
                    if (e.target.files[0] && member.id) {
                      fetchingFn(true);
                      var file = e.target.files[0];
                      var fd = new FormData();
                      fd.append('pdf', file);
                      memberService()
                        .saveMemberAuthorizationCard({id: member.id, pdf: fd})
                        .then(() => {
                          getAuthorizationCard(member.id);
                          setAlertNotification("Authorization Card upload", 'info');
                          fetchingFn();
                        })
                        .catch(() => {
                          setAlertNotification("Something got wrong uploading the Authorization Card", 'danger');
                          fetchingFn();
                        });
                    }
                  }}/>
                  Upload</Button>
              </CheckIt>
            </div>}

            {member.id && authorizationCard.id && <div>
              <div>
                Card Signed on {dateFormatMonthDayYear(authorizationCard.createDate)} <CheckIt action={userActionsKeys.downloadAuthorizationCardLegal}><FontAwesomeIcon className="cursor-pointer" icon={faDownload} onClick={() => {
                if (member.id) {
                  fetchingFn(true);
                  memberService()
                    .getAuthorizationCardPDF(member.id)
                    .then(res => {
                      saveAs(res.data, "MemberAuthorizationCard" + getTodayStringValue() + ".pdf");
                      fetchingFn();
                    })
                    .catch((err) => {
                      setAlertNotification("Something got wrong downloading the Authorization Card", 'danger');
                      fetchingFn();
                    });
                }
              }}/></CheckIt>
              </div>
              <div>Authorization Card collected by {getName(authorizationCard.userName)}</div>
            </div>}
          </div>

        </div>

        <CheckIt action={userActionsKeys.memberContactListLegal}>
          <div className="col-12 col-sm-6">
            <div className="col-12 detail-header border-bottom-gray">
              <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{pagination.count}</Badge> Contact History
              <CheckIt action={userActionsKeys.addNewMemberContactLegal}>
              <span style={{position: "absolute", right: "15px"}}>
                <Button style={{marginTop: "-5px"}} onClick={() => {
                  setBigModalTitle("Add Contact");
                  setIsBigModalOpen(true);
                }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="New Contact"/></Button>
              </span>
              </CheckIt>
            </div>
            <div className="col-12 detail">
              <MemberVisitContactList
                member={member}
                contactVisitMemberList={contactVisitMemberList}
                pagination={pagination}
                getContactVisitMemberList={getContactVisitMemberList}
                setBigModalTitle={setBigModalTitle}
                setIsBigModalOpen={setIsBigModalOpen}
                setMemberContact={setMemberContact}/>
            </div>
          </div>
        </CheckIt>

        <CheckIt action={userActionsKeys.memberCasesListLegal}>
          <div className="col-12 col-sm-6 no-padding">
            <div className="col-12 detail-header border-bottom-gray">
              <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{cases.length}</Badge> Cases
            </div>
            <div className="col-12 detail">
              {cases.map((value, index) => {
                let date, dateName, link, caseId;
                if (value.caseType === "Investigation") {
                  dateName = "Initial Contact Date"
                  date = value.initialContactDate
                  link = appPaths.InvestigationDetail + "?investigation=" + value.id
                  caseId = value.investigationid
                } else if (value.caseType === "Grievance") {
                  dateName = "Open Date"
                  date = value.openDate
                  link = appPaths.GrievanceDetail + "?grievance=" + value.id
                  caseId = value.grievanceid
                } else if (value.caseType === "Rating") {
                  dateName = "Create Date"
                  date = value.createDate
                  link = appPaths.RatingDetail + "?rating=" + value.id
                  caseId = value.ratingid
                }
                return (<Row key={index} className="border-bottom-gray py-2">
                  <div className="col-12 col-sm-5">
                    <div><MyLink to={"/" + link + "&site=" + member.siteModelId + "&member=" + member.id}>{value.caseType}</MyLink></div>
                    {value.userNameCreated && <div className="text-gray small">Created by {getName(value.userNameCreated)}</div>}
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="text-gray small">{dateName}</div>
                    <div>{dateFormatMonthDayYear(date, caseId)}</div>
                  </div>
                  <div className={"ml-3 " + (value.isOpen ? "open" : "closed")}>{value.isOpen ? "Open" : "Closed"}</div>
                </Row>)
              })}
            </div>
          </div>
        </CheckIt>

      </Row>
      <BigModal className="md-modal" title={bigModalTitle} isBigModalOpen={isBigModalOpen} toggleModalAction={toggleModal}>
        {(bigModalTitle === "Add Contact" || bigModalTitle === "Edit Contact") &&
        <MemberContactForm
          getContactVisitMemberList={getContactVisitMemberList}
          member={member}
          memberContact={memberContact}
          setMemberContact={setMemberContact}
          toggleModal={toggleModal}/>}
      </BigModal>
    </Container>
  );
};

export default MemberDetail;
