import React from 'react'
import {dateFormatMonthDayYear} from "../../actions/util";
import {MyLink, MyPagination, VisitMemberCommentsView} from "../index";
import {appPaths} from "../../routes";
import {isAllow, userActionsKeys} from "../../util/userActions";
import {useAllName} from "../../hooks";

export const UsernameDateData = ({value, noBorder = false}) => {
  const {getName} = useAllName();

  let splitUsernames = value.userName.split(', '), createdUsername = "", updatedUsernames = ""
  if (splitUsernames && splitUsernames[0]) {
    createdUsername = getName(splitUsernames[0])
    if (splitUsernames.length > 1) {
      for (let i = 1; i < splitUsernames.length; i++) {
        updatedUsernames += ", " + getName(splitUsernames[i])
      }
      updatedUsernames = updatedUsernames.replace(/^, /, '')
    }
  }
  return (
    <div style={{marginBottom: "10px", borderBottom: noBorder ? "" : "1px dotted", paddingBottom: "10px"}}>
      {value.comment ? <div>{value.comment.substr(0, 100)}{value.comment.length > 100 ? "... " : " "}</div> : <div/>}
      {createdUsername && <small style={{color: "#999"}}>Created by {createdUsername} on {dateFormatMonthDayYear(value.createDate)}</small>}
      {updatedUsernames &&
      <small style={{color: "#999", marginTop: "-5px", display: "block"}}>{updatedUsernames} last update {dateFormatMonthDayYear(value.lastModification)}</small>}
    </div>
  )
}

const MemberVisitContactList = ({member, contactVisitMemberList, pagination, getContactVisitMemberList, setBigModalTitle, setIsBigModalOpen, setMemberContact}) => {

  return (
    <>
      {contactVisitMemberList.map((data, index) => {
        let visitComments;
        if (data.tableName === "VisitMember") {
          visitComments = data.visitCommentList
        }
        if (visitComments) {
          const visit = visitComments.visit, visitMemberComments = visitComments.visitMemberComments;
          return (
            <div className="col-12" key={data.id + data.tableName}>
              <div style={{borderBottom: index < contactVisitMemberList.length - 1 ? "1px dotted" : ""}} className="mb-2">
                <div>
                  <MyLink to={"/" + appPaths.VisitDetail + "?visit=" + visit.id + "&site=" + visit.siteModelId}
                          className="no-a">{visit.visitReason} on {dateFormatMonthDayYear(visit.visitDate)}</MyLink>
                </div>
                <VisitMemberCommentsView key={visit.id} visit={visit} visitMemberComments={visitMemberComments} padding={true}
                                         visitMemberCommentsCount={visitComments.visitMemberCommentsCount} visitMember={visitComments}
                                         skip={pagination.skip}/>
              </div>
            </div>
          )
        }
        const value = data.memberContact
        return (
          <div key={data.id + data.tableName} className="col-12">
            <div>
              <span className={isAllow(userActionsKeys.editMemberContactLegal) ? "simulate-a" : ""} onClick={() => {
                if (isAllow(userActionsKeys.editMemberContactLegal)) {
                  setMemberContact(value);
                  setBigModalTitle("Edit Contact");
                  setIsBigModalOpen(true);
                }
              }}>{value.type} on {dateFormatMonthDayYear(value.date)}</span>
            </div>
            <UsernameDateData value={value}/>
          </div>
        )
      })}

      {contactVisitMemberList.length > 0 && pagination.count > 10 &&
      <div style={{padding: "15px 0 0", overflow: "auto"}}>

        <div style={{borderBottom: "1px solid", marginBottom: "10px"}}/>

        <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
          getContactVisitMemberList(limit, skip, pagination.descending)
        }}/>
      </div>}
    </>
  )
}

export default MemberVisitContactList;