import React                             from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const BigModal = ({children, className, title, extraBtn, isBigModalOpen, toggleModalAction}) => {

  const extraBtnContainer = <div style={{position: "absolute", right: "65px", top: "12px"}}>{extraBtn}</div>;

  return (
    <Modal isOpen={isBigModalOpen} toggle={() => {
      toggleModalAction()
    }} className={className || "big-modal"}>
      <ModalHeader toggle={() => {
        toggleModalAction()
      }}>{title} {extraBtn ? extraBtnContainer : ""}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default BigModal;
