import React, { useEffect, useRef, useState }                                                                                                                                from 'react';
import { Badge, Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown }                                                             from 'reactstrap';
import { grievanceAdvocateService, grievanceDateService, grievanceService }                                                                                                  from "../../services";
import { appPaths }                                                                                                                                                          from "../../routes";
import { useHistory, useLocation }                                                                                                                                           from "react-router";
import { useForm }                                                                                                                                                           from "react-hook-form";
import { BigModal, CheckIt, CloseGrievance, GrievanceActivityForm, GrievanceActivityList, MyDatePicker, MyLink, ReplaceOnWidth }                   from "../index";
import { checkDateFromISOToLocal, cleanName, dateFormatMonthDayYear, dateFormatMonthDayYearHoursMinute, dateFromISOToLocal, setAlertNotification } from "../../actions/util";
import { Select }                                                                                                                                  from 'react-functional-select';
import { useAllName, useGrievance, useGrievanceActivityList, useGrievanceAdvocateList, useGrievanceDateList, useMember, useSite, useStatus, useTitle, useUsersAdvocateList } from "../../hooks";
import { isAllow, userActionsKeys }                                                                                                                                          from "../../util/userActions";
import { FontAwesomeIcon }                                                                                                                                                   from "@fortawesome/react-fontawesome";
import { faPlus }                                                                                                                                                            from "@fortawesome/free-solid-svg-icons";

const GrievanceDetail = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {site, siteId} = useSite();
  const {member, memberId} = useMember();
  const {getName, names} = useAllName();
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [dateComment, setDateComment] = useState("");

  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();
  const [openDate, setOpenDate] = useState(new Date());
  const [intentToArbitrateDate, setIntentToArbitrateDate] = useState(undefined);
  const [demandToArbitrateDate, setDemandToArbitrateDate] = useState(undefined);
  const [arbitrationDate, setArbitrationDate] = useState(undefined);

  const selectRef = useRef();
  const grievanceAdvocateRef = useRef();
  const {users} = useUsersAdvocateList();
  const [userSelected, setUserSelected] = useState({});
  const [grievanceAdvocate, setGrievanceAdvocate] = useState([]);
  const {grievanceAdvocates, getGrievanceAdvocates, deleteGrievanceAdvocate} = useGrievanceAdvocateList();
  const {grievanceDates, getGrievanceDates, deleteGrievanceDate} = useGrievanceDateList();
  const [activityToEdit, setActivityToEdit] = useState({});

  const {register, handleSubmit, reset, errors, watch} = useForm();

  const {grievance, grievanceId, changeGrievanceState, isGrievanceOpen} = useGrievance({
    onChange: (grievance) => {
      reset(grievance);
      setOpenDate(dateFromISOToLocal(grievance?.openDate, grievance?.grievanceId));
      if (checkDateFromISOToLocal(grievance?.intentToArbitrateDate)) {
        setIntentToArbitrateDate(dateFromISOToLocal(grievance?.intentToArbitrateDate, grievance?.grievanceId));
      }
      if (checkDateFromISOToLocal(grievance?.demandToArbitrateDate)) {
        setDemandToArbitrateDate(dateFromISOToLocal(grievance?.demandToArbitrateDate, grievance?.grievanceId));
      }
    },
    closeModal: () => {setIsCloseModalOpen(false)}
  });

  useEffect(() => {
    getName(grievance.userName) && grievanceAdvocateRef.current && grievanceAdvocateRef.current.setValue({name: getName(grievance.userName), value: {}})
    // eslint-disable-next-line
  }, [names, grievance.userName])

  const {grievanceActivities, getGrievanceActivities, paginationGrievanceActivities, onSubmit} = useGrievanceActivityList();

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const isUnionInitiated = param.get("union-initiated") === "true";

  return (
    <Container fluid={true}>
      <Row className="padding-15 mb-2">
        <h3 className="no-margin">Grievance {isUnionInitiated ? "" : "for " + ((member.id && "'" + cleanName(member.lastname) + ", " + cleanName(member.frstname) + "'") || "")}</h3>
        {grievanceId && isAllow(userActionsKeys.changeGrievanceStateLegal) && <div style={{marginLeft: "auto"}}>
          <UncontrolledButtonDropdown className="ml-2 mb-2">
            <DropdownToggle caret color="secondary">
              {isGrievanceOpen ? "Close" : "Reopen"} Grievance
            </DropdownToggle>
            <DropdownMenu style={{minWidth: "8rem"}}>
              <DropdownItem onClick={() => {
                if (isGrievanceOpen) {
                  setIsCloseModalOpen(true);
                } else {
                  changeGrievanceState();
                }
              }}>Confirm</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>}
        <div className="width-100"/>
        {!isGrievanceOpen && <div className="m-auto">This Grievance was closed on {dateFormatMonthDayYear(grievance.closedDate, grievance.grievanceId)} {grievance.outcome && grievance.outcome !== "-" && " as " + grievance.outcome}</div>}
      </Row>
      <form onSubmit={handleSubmit((values) => {
        values = {...values, openDate, intentToArbitrateDate: intentToArbitrateDate || undefined, demandToArbitrateDate: demandToArbitrateDate || undefined, userName: grievanceAdvocate?.value?.userName || "", memberModelId: memberId, siteModelId: siteId};
        if (grievance.id) {
          grievanceService()
            .updateGrievance({...grievance, ...values})
            .then(res => {setAlertNotification("Saved")})
            .catch(err => {setAlertNotification("Saved", "danger")})
        } else {
          grievanceService()
            .addGrievance(values)
            .then(res => {
              if (res && res.data) {
                history.push(appPaths.GrievanceDetail + "?grievance=" + res.data + (isUnionInitiated ? "&union-initiated=true" : "&site=" + siteId + "&member=" + memberId))
              }
              setAlertNotification("Saved")
            })
            .catch(err => {setAlertNotification("Saved", "danger")})
        }
      })}>
        <Row>
          <div className="col-12 col-md-6">
            <div className="col-12 detail-header border-bottom-gray">
              Details
            </div>
            <div className="col-12 detail">
              <Row>
                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Open Date</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={openDate}
                      onChange={(value) => {
                        setOpenDate(value);
                      }}/>
                    : dateFormatMonthDayYear(openDate, grievance.grievanceId)}
                </div>

                {isUnionInitiated ?
                  <>
                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Union Initiated</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isGrievanceOpen ?
                        <Row className="no-margin">
                          <textarea className="col-11 form-control" name="unionInitiated" ref={register}/>
                        </Row>
                        : grievance.unionInitiated}
                    </div>
                  </>
                  : <>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">District</div>
                    <div className="col-12 col-sm-7">{site.district}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site</div>
                    <div className="col-12 col-sm-7">{site.sitecode}<br/>{site.sitename}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">File No.</div>
                    <div className="col-12 col-sm-7">{member.fileno}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Member</div>
                    <div className="col-12 col-sm-7">
                      <div style={{lineHeight: "14px"}}>
                        <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
                      </div>
                      <div style={{lineHeight: "23px"}}>
                        <MyLink to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a">{cleanName(member.lastname)}{cleanName(member.frstname) &&
                        <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !cleanName(member.frstname) &&
                        <span>Detail</span>}</MyLink>
                      </div>
                      <div style={{lineHeight: "14px"}}>
                        <small className="text-gray">{getCategory(member.status)}</small>
                      </div>
                    </div>
                  </>}

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Infraction</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <Row className="no-margin">
                      <textarea className="col-11 form-control" name="infraction" ref={register({required: true})}/>
                      <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
                      <small className="text-danger" style={{position: "absolute", bottom: "-17px"}}>{errors.infraction && "This field is required"}</small>
                    </Row>
                    : grievance.infraction}
                </div>

                {grievanceId && isAllow(userActionsKeys.addGrievanceAdvocateLegal) && <>
                  <h5 className="col-12 primary-color mt-3 mb-2 border-bottom-gray">Advocate</h5>
                  {isGrievanceOpen && <div className="col-12 d-flex justify-content-around">
                    <div className="select-container flex-grow-1 mr-2">
                      <Select
                        options={users.filter(f => !grievanceAdvocates || !grievanceAdvocates.some(s => f.userName === s.userName)).map(user => ({name: getName(user.userName), value: user}))}
                        placeholder="Select Advocate"
                        name="advocates"
                        inputId="advocate-select-input"
                        ref={selectRef}
                        onOptionChange={(option) => {
                          if (option && option.value) {
                            setUserSelected(option)
                          }
                        }}
                        scrollMenuIntoView
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.name}
                      />
                    </div>
                    <div className="text-right">
                      <Button
                        disabled={!userSelected?.value?.userName}
                        onClick={() => {
                          let params = {grievanceModelId: grievanceId, userName: userSelected.value.userName};
                          if (userSelected.value && userSelected.value.userModelId) {
                            params["userModelId"] = userSelected.value.userModelId
                          } else if (userSelected.value && userSelected.value.applicationUserId) {
                            params["applicationUserId"] = userSelected.value.applicationUserId
                          }
                          grievanceAdvocateService()
                            .addAdvocate(params)
                            .then(res => {
                              selectRef.current && selectRef.current.clearValue();
                              setUserSelected({});
                              getGrievanceAdvocates();
                            })
                            .catch(err => {setAlertNotification("Error adding an Advocate", "danger")});
                        }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>
                    </div>
                  </div>}

                  <div className="col-12 mt-3">
                    {grievanceAdvocates?.length > 0 && grievanceAdvocates.map((advocate, index) => (
                      <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                        {getName(advocate.userName)}
                        {isGrievanceOpen && isAllow(userActionsKeys.deleteGrievanceAdvocateLegal) && <div className="inline-right text-danger hover-show" onClick={() => {deleteGrievanceAdvocate(advocate.id)}}>Delete</div>}
                      </div>
                    ))}
                  </div>
                </>}

              </Row>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="col-12 detail-header border-bottom-gray">
              Arbitration
            </div>
            <div className="col-12 detail">
              <Row>
                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">AAA No.</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <input className="form-control" name="arbitrationNumber" ref={register}/>
                    : grievance.arbitrationNumber}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Arbitrator Name</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <input className="form-control" name="arbitratorName" ref={register}/>
                    : grievance.arbitratorName}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Intent to Arbitrate Date</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={intentToArbitrateDate}
                      onChange={(value) => {
                        setIntentToArbitrateDate(value);
                      }}/>
                    : dateFormatMonthDayYear(intentToArbitrateDate, grievance.grievanceId)}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Demand to Arbitrate Date</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={demandToArbitrateDate}
                      onChange={(value) => {
                        setDemandToArbitrateDate(value);
                      }}/>
                    : dateFormatMonthDayYear(demandToArbitrateDate, grievance.grievanceId)}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Advocate</div>
                <div className="col-12 col-sm-7 mt-2 mb-2 select-container">
                  {isGrievanceOpen ?
                    <Select
                      options={[{name: "", value: {}}, ...users.map(user => ({name: getName(user.userName), value: user}))]}
                      placeholder="Select Advocate"
                      name="advocates"
                      inputId="advocate-select-input"
                      ref={grievanceAdvocateRef}
                      onOptionChange={(option) => {
                        if (option && option.value) {
                          setGrievanceAdvocate(option)
                        }
                      }}
                      scrollMenuIntoView
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.name}
                    />
                    : getName(grievance.userName)}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">DOE Advocate</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <input className="form-control" name="doeAdvocate" ref={register}/>
                    : grievance.doeAdvocate}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Outcome</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <select className="form-control" name="arbitrationOutcome" ref={register}>
                      <option value="">-</option>
                      {["Withdrawn", "Abeyance", "Stipulation", "Opinion & Award", "Consolidated_wiht_Another_Case", "Duplicate_Case", "Resolved_Without_Stip"].map((value, index) => (
                        <option value={value} key={index}>{value.replace(/_/g, " ").replace("wiht", "with").replace("Stip", "Strip")}</option>
                      ))}
                    </select>
                    : grievance.arbitrationOutcome}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Status</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isGrievanceOpen ?
                    <input className="form-control" name="status" ref={register}/>
                    : grievance.status}
                </div>

                {watch("arbitrationOutcome") === "Consolidated_wiht_Another_Case" &&
                <>
                  <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Other Arbitration</div>
                  <div className="col-12 col-sm-7 mt-2 mb-2">
                    {isGrievanceOpen ?
                      <input className="form-control" name="otherArbitration" ref={register}/>
                      : grievance.otherArbitration}
                  </div>
                </>
                }

                {grievanceId && isAllow(userActionsKeys.addGrievanceArbitrationDatesLegal) && <>
                  <h5 className="col-12 primary-color mt-3 mb-2 border-bottom-gray">Arbitration Date(s)</h5>
                  {isGrievanceOpen && <>
                    <div className="col-12 d-flex justify-content-around">
                      <div className="flex-grow-1 mr-2">
                        <MyDatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={arbitrationDate}
                          onChange={(value) => {
                            setArbitrationDate(value);
                          }}/>
                      </div>
                      <div className="text-right">
                        <Button
                          disabled={!arbitrationDate}
                          onClick={() => {
                            grievanceDateService()
                              .addDate({date: arbitrationDate, grievanceModelId: grievanceId, comment: dateComment})
                              .then(res => {
                                setArbitrationDate(undefined)
                                setDateComment("")
                                getGrievanceDates();
                              })
                              .catch(err => {setAlertNotification("Error adding an Arbitration Date", "danger")});
                          }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>
                      </div>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                      <textarea className="form-control" name="comment" placeholder="Comments" value={dateComment} onChange={event => {setDateComment(event.target.value)}}/>
                    </div>
                  </>}

                  <div className="col-12 mt-3">
                    {grievanceDates?.length > 0 && grievanceDates.map((date, index) => (
                      <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                        {dateFormatMonthDayYear(date.date, grievance.grievanceId)}
                        {isGrievanceOpen && isAllow(userActionsKeys.deleteGrievanceArbitrationDatesLegal) && <div className="inline-right text-danger hover-show" onClick={() => {deleteGrievanceDate(date.id)}}>Delete</div>}
                        <div className="col-12 mb-2 p-2 box-border" key={index}>
                          {date.comment && <span dangerouslySetInnerHTML={{__html: date?.comment?.replace(/\n/g, "<br/>")}}/>}
                          <br/>
                          <small className="text-gray">{getName(date.userName)} on {dateFormatMonthDayYearHoursMinute(date.createDate)}</small>
                        </div>
                      </div>
                    ))}
                  </div>
                </>}

              </Row>
            </div>
          </div>

          {isGrievanceOpen && isAllow(grievance.id ? userActionsKeys.updateGrievanceLegal : userActionsKeys.addNewGrievanceLegal) && <div className="col-12 mb-4"><Button>Save</Button></div>}


        </Row>
      </form>
      {grievanceId && isAllow(userActionsKeys.grievanceActivityListLegal) &&
        <Row>
          <div className="col-12">
            <div className="col-12 detail-header border-0">
              <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{paginationGrievanceActivities.count}</Badge> Activities
              <CheckIt action={userActionsKeys.addGrievanceActivityLegal}>
                <span style={{position: "absolute", right: "15px"}}>
                   {isGrievanceOpen && <Button style={{marginTop: "-5px"}} onClick={() => {
                     setActivityToEdit({});
                     setIsActivityModalOpen(true)
                   }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="New"/></Button>}
                </span>
              </CheckIt>
            </div>
            <div className="col-12 detail no-padding">
              <GrievanceActivityList
                grievanceActivities={grievanceActivities}
                pagination={paginationGrievanceActivities}
                setGrievanceActivity={(values) => {
                  setActivityToEdit(values);
                  setIsActivityModalOpen(true);
                }}
                onPaginationAction={(limit, skip, columnSort, desc) => {
                  getGrievanceActivities(limit, skip, "", columnSort, desc)
                }}
                isGrievanceOpen={isGrievanceOpen}/>
            </div>
          </div>
        </Row>}
      <BigModal className="modal-green" title="Close Grievance" isBigModalOpen={isCloseModalOpen} toggleModalAction={() => {setIsCloseModalOpen(false)}}>
        <CloseGrievance changeGrievanceState={changeGrievanceState}/>
      </BigModal>
      <BigModal className="modal-green" title="Grievance Activity" isBigModalOpen={isActivityModalOpen} toggleModalAction={() => {setIsActivityModalOpen(false)}}>
        <GrievanceActivityForm grievanceActivities={grievanceActivities} defaultValues={activityToEdit} onCancel={() => {setIsActivityModalOpen(false)}} onSubmit={(values) => {
          onSubmit(values, () => {
            setIsActivityModalOpen(false)
          });
        }}/>
      </BigModal>
    </Container>
  );
};

export default GrievanceDetail;
