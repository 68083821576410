import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "member/";
export default () => ({
    getMember: (params) => client.request({
      method: 'GET',
      url: base + 'getMember/' + params.id + "?timestamp=" + new Date().getTime()
    }),
    getAuthorizationCardPDF: (id) => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'DownloadMemberAuthorizationCard/' + id
    }),
    getMembersNotVisitedReport: (from, to, timezone) => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetMembersNotVisitedReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    }),
    getMembersWithoutAuthorizationCardReport: () => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetMembersWithoutAuthorizationCardReport'
    }),
    getMembersWithAuthorizationCardReport: () => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetMembersWithAuthorizationCardReport'
    }),
    getAuthorizationCard: (id) => client.request({
      method: 'GET',
      url: base + 'GetAuthorizationCardByMemberId/' + id + "?timestamp=" + new Date().getTime()
    }),
    getMemberBySocsecno: (params) => client.request({
      method: 'GET',
      url: base + 'GetMemberBySocsecno/' + params.socsecno
    }),
    getMembers: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = false, onlyWithSite = false) => loading(client.request({
      method: 'GET',
      url: base + 'GetMembersSite?limit=' + limit + '&skip=' + skip + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&isFromLegal=' + true + '&onlyWithSite=' + onlyWithSite
    })),
    getMembersFilterByFileNoAndSocSecNo: (limit = 10, skip = 0, fileNo = "", socSecNo = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getMembersFilterByFileNoAndSocSecNo?limit=' + limit + '&skip=' + skip + '&fileNo=' + fileNo + '&socSecNo=' + socSecNo + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getMembersFromSite: (siteId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, isWorkSite = false) => loading(client.request({
      method: 'GET',
      url: base + 'getMembersFromSite/' + siteId + '?limit=' + limit + '&skip=' + skip + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&isWorkSite=' + isWorkSite + '&isFromLegal=' + true
    })),
    GetMembersFromSiteIncludeVisitAndComment: (siteId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, isWorkSite = false) => loading(client.request({
      method: 'GET',
      url: base + 'GetMembersFromSiteIncludeVisitAndComment/' + siteId + '?limit=' + limit + '&skip=' + skip + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&isWorkSite=' + isWorkSite
    })),
    addMember: (params) => client.request({
      method: 'POST',
      url: base + 'addMember',
      data: params
    }),
    saveMemberAuthorizationCard: (params) => client.request({
      method: 'POST',
      url: base + 'SaveMemberAuthorizationCard/' + params.id + "?isAppointed=" + params.isAppointed + "&isInterimActing=" + params.isInterimActing,
      data: params.pdf
    }),
    updateMember: (params) => client.request({
      method: 'PUT',
      url: base + 'updateMember',
      data: params
    }),
    deleteMember: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteMember/' + params.id
    }),
    deleteAuthorizationCardByMemberId: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteAuthorizationCardByMemberId/' + id
    })
  }
);
