import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "visitMemberComment/";
export default () => ({
    getVisitMemberComment: (id) => client.request({
      method: 'GET',
      url: base + 'getVisitMemberComment/' + id
    }),
    getVisitMemberComments: (visitMemberId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetVisitMemberComments/' + visitMemberId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getAllVisitMemberCommentUsers: (visitMemberCommentId) => client.request({
      method: 'GET',
      url: base + 'GetAllVisitMemberCommentUsers/' + visitMemberCommentId
    }),
    addVisitMemberComment: (params, visitId) => client.request({
      method: 'POST',
      url: base + 'addVisitMemberComment?visitId=' + visitId,
      data: params
    }),
    addVisitMemberCommentWithParams: (params, visitId, memberId) => client.request({
      method: 'POST',
      url: base + 'addVisitMemberComment?visitId=' + visitId + "&memberId=" + memberId,
      data: params
    }),
    updateVisitMemberComment: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateVisitMemberComment/' + params.id,
      data: params
    }),
    deleteVisitMemberComment: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteVisitMemberComment/' + params.id
    })
  }
);
