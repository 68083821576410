import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "ratingComment/";
export default () => ({
    getRatingComment: (id) => client.request({
      method: 'GET',
      url: base + 'getRatingComment/' + id
    }),
    getRatingComments: (ratingId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetRatingComments/' + ratingId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addRatingComment: (params) => client.request({
      method: 'POST',
      url: base + 'addRatingComment',
      data: params
    }),
    updateRatingComment: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateRatingComment/' + params.id,
      data: params
    }),
    deleteRatingComment: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteRatingComment/' + params.id
    })
  }
);
