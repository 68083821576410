import React                      from 'react';
import { dateFormatMonthDayYear } from "../../actions/util";
import { appPaths }               from "../../routes";
import { MyLink }                 from "../index";
import { useAllName }             from "../../hooks";

const VisitItem = ({visit}) => {

  const {getNames} = useAllName();

  return (
    <tr>
      <th scope="row"><MyLink to={"/" + appPaths.VisitDetail + "?visit=" + visit.id + "&site=" + visit.siteModelId} className="no-a">{dateFormatMonthDayYear(visit.visitDate, visit.visitId) || <span>Detail</span>}</MyLink></th>
      <th>
        <div>{visit.site && visit.site.sitename}</div>
        <div>
          <small className="text-gray">District: {visit.site && visit.site.district}</small>
        </div>
      </th>
      <th>{visit.visitReason}</th>
      <th>{getNames(visit.userName, true)}</th>
      <th>
        <div className={visit.isOpen ? "open" : "closed"}>{visit.isOpen ? "Open" : "Closed"}</div>
      </th>
    </tr>
  );
};

export default VisitItem;
