import { client } from '../util/createRestApiClient';

const base = "title/";
export default () => ({
    getTitlesAll: () => client.request({
      method: 'GET',
      url: base + 'GetTitlesAll'
    }),
    getTitle: (title) => client.request({
      method: 'GET',
      url: base + 'GetTitle',
      params: {title}
    }),
    getTitleByMemberId: (id) => client.request({
      method: 'GET',
      url: base + 'GetTitleByMemberId/' + id + "?timestamp=" + new Date().getTime()
    })
  }
);
