import React, { useEffect, useRef, useState }                                                                                                                    from 'react';
import { Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown }                                                        from 'reactstrap';
import { investigationAdvocateService, investigationService }                                                                                                    from "../../services";
import { appPaths }                                                                                                                                              from "../../routes";
import { useHistory }                                                                                                                                            from "react-router";
import { useForm }                                                                                                                                               from "react-hook-form";
import { BigModal, CheckIt, CloseInvestigation, Comments, InvestigationMeetingForm, InvestigationMeetingList, MyDatePicker, MyLink, ReplaceOnWidth }             from "../index";
import { cleanName, dateFormatMonthDayYear, dateFromISOToLocal, setAlertNotification }                                                                           from "../../actions/util";
import { Select }                                                                                                                                                from 'react-functional-select';
import { useAllName, useInvestigation, useInvestigationCommentList, useInvestigationMeetingList, useMember, useSite, useStatus, useTitle, useUsersAdvocateList } from "../../hooks";
import { isAllow, userActionsKeys }                                                                                                                              from "../../util/userActions";
import { FontAwesomeIcon }                                                                                                                                       from "@fortawesome/react-fontawesome";
import { faPlus }                                                                                                                                                from "@fortawesome/free-solid-svg-icons";

const InvestigationDetail = () => {

  const {site, siteId} = useSite();
  const {member, memberId} = useMember();
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const {getName} = useAllName();

  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();
  const [initialContactDate, setInitialContactDate] = useState(new Date());

  const selectRef = useRef();
  const {users} = useUsersAdvocateList();
  const [userSelected, setUserSelected] = useState({});
  const [advocates, setAdvocates] = useState([]);
  const [meetingToEdit, setMeetingToEdit] = useState({});

  const {register, handleSubmit, reset} = useForm();

  const {investigation, investigationId, changeInvestigationState, isInvestigationOpen, deleteInvestigation} = useInvestigation({
    onChange: (investigation) => {
      reset(investigation);
      setInitialContactDate(dateFromISOToLocal(investigation?.initialContactDate, investigation?.investigationId));
    },
    closeModal: () => {setIsCloseModalOpen(false)}
  });

  const {investigationComments, getInvestigationComments, paginationInvestigationComments, addInvestigationComment} = useInvestigationCommentList();
  const {investigationMeetings, getInvestigationMeetings, paginationInvestigationMeetings, onSubmit} = useInvestigationMeetingList();

  const history = useHistory();

  const getAdvocates = () => {
    if (investigationId) {
      investigationAdvocateService()
        .getAllByInvestigation(investigationId)
        .then(res => {
          setAdvocates(res.data || [])
        })
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdvocates();
    // eslint-disable-next-line
  }, [investigationId]);

  const deleteAdvocateDate = (id) => {
    investigationAdvocateService()
      .deleteAdvocate({id})
      .then(res => {
        getAdvocates();
      })
      .catch(err => {setAlertNotification("Error deleting an Advocate", "danger")});
  };

  return (
    <Container fluid={true}>
      <Row className="padding-15 mb-2">
        <h3 className="no-margin">Investigation for {member.id && "'" + cleanName(member.lastname) + ", " + cleanName(member.frstname) + "'"}</h3>
        {investigationId && <div style={{marginLeft: "auto"}}>
          <CheckIt action={userActionsKeys.changeInvestigationStateLegal}>
            <UncontrolledButtonDropdown className="ml-2 mb-2">
              <DropdownToggle caret color="secondary">
                {isInvestigationOpen ? "Close" : "Reopen"} Investigation
              </DropdownToggle>
              <DropdownMenu style={{minWidth: "8rem"}}>
                <DropdownItem onClick={() => {
                  if (isInvestigationOpen) {
                    setIsCloseModalOpen(true);
                  } else {
                    changeInvestigationState();
                  }
                }}>Confirm</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </CheckIt>

          {isInvestigationOpen && isAllow(userActionsKeys.deleteInvestigationLegal) && <UncontrolledButtonDropdown className="ml-2 mb-2">
            <DropdownToggle caret color="danger">
              Delete
            </DropdownToggle>
            <DropdownMenu style={{minWidth: "8rem"}}>
              <DropdownItem className="text-danger" onClick={() => {
                deleteInvestigation();
              }}>Confirm</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>}
        </div>}
        <div className="width-100"/>
        {!isInvestigationOpen && <div className="m-auto">This Investigation was closed on {dateFormatMonthDayYear(investigation.closedDate, investigation.investigationId)} {investigation.outcome && investigation.outcome !== "-" && " as " + investigation.outcome}</div>}
      </Row>
      <Row>
        <div className="col-12">
          <div className="col-12 detail-header border-bottom-gray">
            Details
          </div>
          <div className="col-12 detail">
            <form onSubmit={handleSubmit((values) => {
              values = {...values, initialContactDate, memberModelId: memberId, siteModelId: siteId};
              if (investigation.id) {
                investigationService()
                  .updateInvestigation({...investigation, ...values})
                  .then(res => {setAlertNotification("Saved")})
                  .catch(err => {setAlertNotification("Saved", "danger")})
              } else {
                investigationService()
                  .addInvestigation(values)
                  .then(res => {
                    if (res && res.data) {
                      history.push(appPaths.InvestigationDetail + "?site=" + siteId + "&member=" + memberId + "&investigation=" + res.data)
                    }
                    setAlertNotification("Saved")
                  })
                  .catch(err => {setAlertNotification("Saved", "danger")})
              }
            })}>
              <Row>
                <div className="col-12 col-md-6">
                  <Row>
                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">District</div>
                    <div className="col-12 col-sm-7">{site.district}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site</div>
                    <div className="col-12 col-sm-7">{site.sitecode}<br/>{site.sitename}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">File No.</div>
                    <div className="col-12 col-sm-7">{member.fileno}</div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Member</div>
                    <div className="col-12 col-sm-7">
                      <div style={{lineHeight: "14px"}}>
                        <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
                      </div>
                      <div style={{lineHeight: "23px"}}>
                        <MyLink to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a">{cleanName(member.lastname)}{cleanName(member.frstname) &&
                        <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !cleanName(member.frstname) &&
                        <span>Detail</span>}</MyLink>
                      </div>
                      <div style={{lineHeight: "14px"}}>
                        <small className="text-gray">{getCategory(member.status)}</small>
                      </div>
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Summary</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <textarea className="form-control" name="summary" ref={register}/>
                        : investigation.summary}
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Investigation Type</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <select className="form-control" name="type" defaultValue="" ref={register}>
                          <option value="">-</option>
                          <option value="Subject">Subject</option>
                          <option value="Witness">Witness</option>
                          <option value="Complainant">Complainant</option>
                        </select>
                        : investigation.type}
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Initial Contact Date</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <MyDatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={initialContactDate}
                          onChange={(value) => {
                            setInitialContactDate(value);
                          }}/>
                        : dateFormatMonthDayYear(initialContactDate, investigation.investigationId)}
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Case No.</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <input className="form-control" name="caseNumber" ref={register}/>
                        : investigation.caseNumber}
                    </div>

                  </Row>
                </div>

                <div className="col-12 col-md-6">
                  <Row>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Investigator Name</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <input className="form-control" name="investigatorName" ref={register}/>
                        : investigation.investigatorName}
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Investigative Office</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <input className="form-control" name="investigativeOffice" ref={register}/>
                        : investigation.investigativeOffice}
                    </div>

                    <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Subject of Investigation</div>
                    <div className="col-12 col-sm-7 mt-2 mb-2">
                      {isInvestigationOpen ?
                        <select className="form-control" name="subject" defaultValue="" ref={register}>
                          <option value="">-</option>
                          <option value="Academic Policy Violation">Academic Policy Violation</option>
                          <option value="Compliance">Compliance</option>
                          <option value="Conflict of Interest">Conflict of Interest</option>
                          <option value="Corporal Punishment">Corporal Punishment</option>
                          <option value="Discrimination">Discrimination</option>
                          <option value="Failure to Report">Failure to Report</option>
                          <option value="Failure to Supervise">Failure to Supervise</option>
                          <option value="FERPA">FERPA</option>
                          <option value="Financial Irregularities Misappropriation">Financial Irregularities Misappropriation</option>
                          <option value="Financial Irregularities Misuse">Financial Irregularities Misuse</option>
                          <option value="Harassment">Harassment</option>
                          <option value="Inappropriate Relationship">Inappropriate Relationship</option>
                          <option value="Misconduct">Misconduct</option>
                          <option value="Other">Other</option>
                          <option value="Retaliation">Retaliation</option>
                          <option value="Sexual Impropriety">Sexual Impropriety</option>
                          <option value="Testing">Testing</option>
                          <option value="Theft of Property">Theft of Property</option>
                          <option value="Theft of Service">Theft of Service</option>
                          <option value="Verbal Abuse">Verbal Abuse</option>
                          <option value="Whistleblower">Whistleblower</option>
                        </select>
                        : investigation.subject}
                    </div>

                    {investigationId && <>
                      <h5 className="col-12 primary-color mt-3 mb-2 border-bottom-gray">Advocate</h5>
                      {isInvestigationOpen && isAllow(userActionsKeys.addInvestigationAdvocateLegal) && <div className="col-12 d-flex justify-content-around">
                        <div className="select-container flex-grow-1 mr-2">
                          <Select
                            options={users.filter(f => !advocates || !advocates.some(s => f.userName === s.userName)).map(user => ({name: getName(user.userName), value: user}))}
                            placeholder="Select Advocate"
                            name="advocates"
                            inputId="advocate-select-input"
                            ref={selectRef}
                            onOptionChange={(option) => {
                              if (option && option.value) {
                                setUserSelected(option)
                              }
                            }}
                            scrollMenuIntoView
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.name}
                          />
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              let params = {investigationModelId: investigationId, userName: userSelected.value.userName};
                              if (userSelected.value && userSelected.value.userModelId) {
                                params["userModelId"] = userSelected.value.userModelId
                              } else if (userSelected.value && userSelected.value.applicationUserId) {
                                params["applicationUserId"] = userSelected.value.applicationUserId
                              }
                              investigationAdvocateService()
                                .addAdvocate(params)
                                .then(res => {
                                  selectRef.current && selectRef.current.clearValue();
                                  setUserSelected({});
                                  getAdvocates();
                                })
                                .catch(err => {setAlertNotification("Error adding an Advocate", "danger")});
                            }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>
                        </div>
                      </div>}

                      <div className="col-12 mt-3">
                        {advocates.map((advocate, index) => (
                          <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                            {getName(advocate.userName)}
                            {isInvestigationOpen && isAllow(userActionsKeys.deleteInvestigationAdvocateLegal) && <div className="inline-right text-danger hover-show" onClick={() => {deleteAdvocateDate(advocate.id)}}>Delete</div>}
                          </div>
                        ))}
                      </div>
                    </>}
                  </Row>
                </div>
                {isInvestigationOpen && isAllow(investigation.id ? userActionsKeys.updateInvestigationLegal : userActionsKeys.addNewInvestigationLegal) && <div className="col-12 mt-4 mb-2"><Button>Save</Button></div>}
              </Row>
            </form>
          </div>
        </div>
        {investigationId && <>
          <CheckIt action={userActionsKeys.investigationCommentListLegal}>
            <div className="col-12 col-sm-6">
              <div className="col-12 detail-header border-bottom-gray">
                Comments
              </div>
              <div className="col-12 detail">
                <Comments
                  comments={investigationComments}
                  pagination={paginationInvestigationComments}
                  showForm={isInvestigationOpen && isAllow(userActionsKeys.addInvestigationCommentLegal)}
                  getPaginationAction={(skip, limit) => {
                    getInvestigationComments(limit, skip)
                  }}
                  addCommentAction={addInvestigationComment}/>
              </div>
            </div>
          </CheckIt>
          <CheckIt action={userActionsKeys.investigationMeetingListLegal}>
            <div className="col-12 col-sm-6">
              <div className="col-12 detail-header border-bottom-gray">
                Meetings
                <span style={{position: "absolute", right: "15px"}}>
                {isInvestigationOpen && isAllow(userActionsKeys.addInvestigationMeetingLegal) && <Button style={{marginTop: "-5px"}} onClick={() => {
                  setMeetingToEdit({});
                  setIsMeetingModalOpen(true)
                }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>}
            </span>
              </div>
              <div className="col-12 detail">
                <InvestigationMeetingList
                  investigationMeetings={investigationMeetings}
                  pagination={paginationInvestigationMeetings}
                  setInvestigationMeeting={(values) => {
                    setMeetingToEdit(values);
                    setIsMeetingModalOpen(true);
                  }}
                  onPaginationAction={(limit, skip) => {
                    getInvestigationMeetings(limit, skip)
                  }}
                  isInvestigationOpen={isInvestigationOpen}/>
              </div>
            </div>
          </CheckIt>
        </>}
      </Row>
      <BigModal className="modal-green" title="Close Investigation" isBigModalOpen={isCloseModalOpen} toggleModalAction={() => {setIsCloseModalOpen(false)}}>
        <CloseInvestigation changeInvestigationState={changeInvestigationState}/>
      </BigModal>
      <BigModal className="modal-green" title="Investigation Meeting" isBigModalOpen={isMeetingModalOpen} toggleModalAction={() => {setIsMeetingModalOpen(false)}}>
        <InvestigationMeetingForm defaultValues={meetingToEdit} onSubmit={(values) => {
          onSubmit(values, () => {
            setIsMeetingModalOpen(false)
          });
        }} onCancel={() => {setIsMeetingModalOpen(false)}}/>
      </BigModal>
    </Container>
  );
};

export default InvestigationDetail;
