import React                                                 from 'react';
import { cleanName, dateFormatMonthDayYear, naturalCompare } from "../../actions/util";
import { appPaths }                                          from "../../routes";
import { MyLink }                 from "../index";
import { useAllName }             from "../../hooks";

const InvestigationItem = ({investigation, getJobDescription, getCategory}) => {
  const {getName} = useAllName();

  return (
    <tr>
      <th scope="row">
        <div>
          <MyLink to={"/" + appPaths.InvestigationDetail + "?investigation=" + investigation.id + "&member=" + investigation.member?.id + "&site=" + investigation.site?.id}
                  className="no-a">{dateFormatMonthDayYear(investigation.initialContactDate, investigation.investigationId) || <span>Detail</span>}</MyLink>
        </div>
        <div>
          <small className="text-gray">Case No.: {investigation.caseNumber}</small>
        </div>
      </th>
      <th>
        <div>
          <small className="text-gray">File No.: {investigation.member?.fileno}</small>
        </div>
        <div>
          <small className="text-gray">{getJobDescription(investigation.member?.title)} {investigation.member?.title && <span>({investigation.member?.title?.trim()})</span>}</small>
        </div>
        <div><MyLink to={"/" + appPaths.MemberDetail + "?member=" + investigation.member?.id} className="no-a">{cleanName(investigation.member?.lastname)}{cleanName(investigation.member?.frstname) &&
        <span>, {cleanName(investigation.member?.frstname)}</span>}{!cleanName(investigation.member?.lastname) && !cleanName(investigation.member?.frstname) && <span>Detail</span>}</MyLink></div>
        <div>
          <small className="text-gray">{getCategory(investigation.member?.status)}</small>
        </div>
      </th>
      <th>
        <div>
          <small className="text-gray">District: {investigation.site ? investigation.site.district : ""}</small>
        </div>
        <div>{investigation.site?.sitecode}</div>
        <div>
          <small className="text-gray">{investigation.site ? investigation.site.sitename : ""}</small>
        </div>
      </th>
      <th>
        <div>
          {investigation.investigatorName}
        </div>
        <div>
          <small className="text-gray">{investigation.investigativeOffice}</small>
        </div>
      </th>
      <th>
        {investigation.advocates && investigation.advocates.sort((a, b) => naturalCompare(getName(a.userName), getName(b.userName))).map((advocate, index) => (
          <div key={index}>{getName(advocate.userName)}</div>
        ))}
      </th>
      <th>
        <div className={investigation.isOpen ? "open" : "closed"}>{investigation.isOpen ? "Open" : "Closed"}</div>
      </th>

    </tr>
  );
};

export default InvestigationItem;
