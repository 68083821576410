import React, { useEffect, useRef, useState } from 'react'
import ReactQuill                             from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { CustomInput, FormGroup }             from "reactstrap";
import { Controller, useForm }                from "react-hook-form";
import { useFooter }                          from "../../hooks/Footer";
import { isAllow, userActionsKeys }           from "../../util/userActions";
import { BONUS_TYPE }                         from "../../types";

const Footer = () => {

    const textEditorDescription = useRef();
    const {register, handleSubmit, reset, control, watch} = useForm();
    const [readMode, setReadMode] = useState(false)

    const {footer, footerType, addFooter, updatedAccess} = useFooter({
      onChange: (data) => {
        reset(data)
      }
    })

    const enablePublicOption = isAllow(footerType === BONUS_TYPE ? userActionsKeys.enableAccessPublicBonusApp : userActionsKeys.enableAccessPublicSeniorityApp)

    useEffect(() => {
      if (!isAllow(footerType === BONUS_TYPE ? userActionsKeys.changeFooterBonusLegal : userActionsKeys.changeFooterSeniorityLegal)) {
        setReadMode(true);
      }
    }, [footerType])

    const modules = {
      toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        [{'color': []}, {'background': []}],
        ['clean']
      ],
    }

    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'color', 'background'
    ]

    let titleStyle = {width: "500px"}

    if (watch("title") && watch("title").length > 63) {
      titleStyle = {...titleStyle, width: "100%", maxWidth: ((watch("title").length + 1) * 8) + "px"}
    }

    const handleOnChange = () => {
      let temp = {
        title: footer.title,
        description: footer.description,
        type: footerType,
        enabledPage: !footer.enabledPage
      }
      updatedAccess(temp)
    };

    return (
      <form className="row" onSubmit={handleSubmit((values) => {
        let temp = {
          ...values,
          description: values.description.toString('html'),
          type: footerType,
          enabledPage: footer.enabledPage
        }
        addFooter(temp)
      })}>

        <FormGroup className="col-12 mb-2">
          <CustomInput
            className="mt-3 text-center up-it" type="checkbox" name="enabledPage" id="enabledPage"
            checked={typeof footer.enabledPage === "undefined" ? false : footer.enabledPage} onChange={handleOnChange}
            label="Enable access to the public website" disabled={!enablePublicOption}/>
        </FormGroup>

        <FormGroup className="col-12 mt-3 mb-2">
          {readMode && <h5 className="m-0">{watch("title")}</h5>}
          <input className={readMode ? "d-none" : "form-control"} style={titleStyle} name="title" ref={register} placeholder={'Title'}/>
        </FormGroup>

        <FormGroup className={"col-12 react-quill-editor-container" + (readMode ? " read-only" : "")}>
          <Controller
            as={<ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Description"
              ref={textEditorDescription}
              readOnly={readMode}/>}
            name="description"
            defaultValue={""}
            control={control}
          />
        </FormGroup>

        {isAllow(footerType === BONUS_TYPE ? userActionsKeys.changeFooterBonusLegal : userActionsKeys.changeFooterSeniorityLegal) &&
        <div className="float-right ml-3 mb-1">
          {readMode ?
            <span className="btn btn-outline-info" onClick={() => setReadMode(false)}>Edit Mode</span>
            : <>
              <span className="btn btn-outline-info" onClick={() => setReadMode(true)}>Preview</span>
              {" "}
              <input type="submit" className="btn btn-outline-secondary" value="Save"/>
            </>}
        </div>}

      </form>
    )
  }
;

export default Footer