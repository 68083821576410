import { useEffect, useState }                       from 'react'
import { investigationCommentService } from "../services";
import { useLocation }                               from "react-router";
import { setAlertNotification }                      from "../actions/util";
import { useDefaultLimit }                           from "./WindowUtil";

export const useInvestigationCommentList = (props) => {

  const {defaultLimit} = useDefaultLimit()
  const {investigationId} = props || {};
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const investigationParamId = param.get("investigation");

  const [investigationComments, setInvestigationComments] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});

  const getInvestigationComments = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    if (investigationId || investigationParamId) {
      investigationCommentService()
        .getInvestigationComments(investigationId || investigationParamId, limit, skip, filterName, columnSort, descending)
        .then(res => {
          if (res && res.data) {
            setInvestigationComments(res.data);
            setPagination({
              ...pagination,
              count: parseInt(res.headers['x-total-count'], 10),
              limit: parseInt(res.headers['x-total-limit'], 10),
              skip: parseInt(res.headers['x-total-skip'], 10),
              columnSort,
              descending
            })
          }
        })
    }
  };

  useEffect(() => {
    getInvestigationComments()
    // eslint-disable-next-line
  }, []);

  const addInvestigationComment = (values, cb) => {
    investigationCommentService()
      .addInvestigationComment({investigationModelId: investigationId || investigationParamId, ...values})
      .then(() => {
        getInvestigationComments();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  return {
    investigationComments,
    paginationInvestigationComments: pagination,
    investigationId: investigationParamId,
    getInvestigationComments,
    addInvestigationComment
  }
};
