import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "grievance/";
export default () => ({
    getGrievance: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getGrievance/' + id
    })),
    getGrievanceCasesByAdvocateReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetGrievanceCasesByAdvocateReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getGrievanceCasesByArbitratorReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetGrievanceCasesByArbitratorReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getGrievanceCasesReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetGrievanceCasesReport?timezone=' + timezone + (from ? '&from=' + from.toISOString() : "") + (to ? '&to=' + to.toISOString() : "")
    })),
    getGrievances: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getGrievances?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    getGrievancesByMember: (memberId, filterName = "", columnSort = "", descending = true, open = 0) => client.request({
      method: 'GET',
      url: base + 'getGrievancesByMember/' + memberId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    }),
    addGrievance: (params) => client.request({
      method: 'POST',
      url: base + 'addGrievance',
      data: params
    }),
    updateGrievance: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateGrievance/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeStateGrievance/' + params.id + "?isOpen=" + params.isOpen + "&closedOn=" + ((params.closedDate && params.closedDate.toISOString()) || new Date().toISOString()) + "&outcome=" + params.outcome,
    }),
    deleteGrievance: (id) => client.request({
      method: 'DELETE',
      url: base + 'deleteGrievance/' + id
    })
  }
);
