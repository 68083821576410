import React, { useEffect, useState }        from 'react';
import { Row }                                                       from 'reactstrap';
import { visitMemberCommentService }                   from "../../services";
import { dateFormatMonthDayYear, dateFormatMonthDayYearHoursMinute } from "../../actions/util";
import { BigModal, MyPagination }      from "../index";
import { useAllName, useDefaultLimit } from "../../hooks";

const VisitMemberCommentsView = ({visitMemberComments, visit, visitMemberCommentsCount, visitMember, skip, padding}) => {

  const {defaultLimit} = useDefaultLimit()
  const [comments, setComments] = useState(visitMemberComments || []);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true, count: visitMemberCommentsCount});
  const {getNames} = useAllName();
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getVisitMemberComments = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, visitMemberId) => {
    visitMemberCommentService()
      .getVisitMemberComments(visitMemberId || visitMember.visitModelId, limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setComments(res.data);
          setPagination({
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10)
          })
        }
      })
  };

  useEffect(() => {
    setComments(visitMemberComments);
    // eslint-disable-next-line
  }, [visitMemberComments]);

  useEffect(() => {
    setPagination({...pagination, count: visitMemberCommentsCount});
    // eslint-disable-next-line
  }, [visitMemberCommentsCount]);


  let {createdUsername, updatedUsernames} = getNames(comments[0]?.userName || "");
  const firstItem = comments[0] &&
    <div className="col-12 no-padding mb-2">
      {comments[0].comment &&
      <span
        dangerouslySetInnerHTML={{
          __html: (comments[0].comment.length > 100 ? comments[0].comment.substr(0, 100) + "..." : comments[0].comment).replace(/\n/g, "<br/>")
        }}/>}
      {comments[0].sentToLegal && <span className="text-danger ml-2">(Sent to Legal)</span>}
      <br/>
      {createdUsername && <small style={{color: "#999"}}>Created by {createdUsername} on {dateFormatMonthDayYear(comments[0].createDate)}</small>}
      {updatedUsernames &&
      <small style={{color: "#999", marginTop: "-5px", display: "block"}}>{updatedUsernames} last update {dateFormatMonthDayYear(comments[0].updateDate)}</small>}
    </div>

  const items = comments.map((comment, index) => {

    let {createdUsername, updatedUsernames} = getNames(comment.userName);
    return (
      <div className="col-12 p-2 box-border mb-2" key={index + pagination.skip + skip}>
        {comment.comment &&
        <span
          dangerouslySetInnerHTML={{
            __html: comment.comment.replace(/\n/g, "<br/>")
          }}/>}
        {comment.sentToLegal && <span className="text-danger ml-2">(Sent to Legal)</span>}
        <br/>
        {createdUsername && <small style={{color: "#999"}}>Created by {createdUsername} on {dateFormatMonthDayYear(comment.createDate)}</small>}
        {updatedUsernames &&
        <small style={{color: "#999", marginTop: "-5px", display: "block"}}>{updatedUsernames} last update {dateFormatMonthDayYear(comment.updateDate)}</small>}
      </div>
    )
  })

  const paginationItem = comments.length > 0 && isModalOpen && pagination.count > 10 &&
    <div style={{padding: "15px 15px 0", overflow: "auto"}}>
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getVisitMemberComments(limit, skip, pagination.filterName, pagination.columnSort, pagination.descending)
      }}/>
    </div>

  const showMore = pagination.count > 1 || (comments[0] && comments[0].comment.length > 100)

  return (
    <Row className={showMore ? "cursor-pointer" : ""} onClick={() => {
      if (showMore) {
        setIsModalOpen(true)
      }
    }}>
      <span className="text-gray small ml-auto" style={{marginTop: "-6px", marginRight: "15px", marginBottom: "-2px"}}>{pagination.count > 1 ? pagination.count + " comments, click to see more" : pagination.count > 0 && comments[0] && comments[0].comment.length > 100 ? "click to see more" : ""}</span>
      {comments.length > 0 && <div className={"col-12" + (padding ? "" : " no-padding")}>
        {firstItem}
      </div>}
      {isModalOpen && <BigModal isBigModalOpen={true} title={visit.visitReason + " on " + dateFormatMonthDayYear(visit.visitDate, visit.visitId) + " (" + pagination.count + " comment" + (pagination.count > 1 ? "s" : "") + ")"} toggleModalAction={() => {setIsModalOpen(false)}}>
        <Row>
          {comments.length > 0 && <div className={"col-12" + (padding ? "" : " no-padding")}>
            {items}
            {paginationItem}
          </div>}
        </Row>
      </BigModal>}
    </Row>
  );
};

export default VisitMemberCommentsView;
