import { useEffect, useState } from 'react'
import { userService }         from "../services";
import { useLocation }         from "react-router";
import { dispatch }            from "../actions/util";
import * as types              from "../types";
import messages                from "../util/messages";
import { useDefaultLimit }     from "./WindowUtil";

let usersTmp = null, isUsersFetching = false, subscribedUsersFunc = [];
export const useUserList = (props) => {

  const {defaultLimit} = useDefaultLimit()
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true, showDisabled: false});

  const getUsers = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, showDisabled = pagination.showDisabled) => {
    isUsersFetching = true;
    userService()
      .getUsers(limit, skip, props?.includeAction, filterName, columnSort, descending, showDisabled)
      .then(res => {
        if (res && res.data) {
          setUsers(res.data);
          const userPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            filterName,
            showDisabled
          }
          setPagination(userPaginateList)
          sessionStorage.setItem("userPaginateList", JSON.stringify(userPaginateList))
          usersTmp = res.data
          subscribedUsersFunc.forEach(func => {
            func(usersTmp)
          })
          subscribedUsersFunc = []
        }
        isUsersFetching = false;
      })
  };

  useEffect(() => {
    if (usersTmp) {
      setUsers(usersTmp)
    } else if (isUsersFetching) {
      subscribedUsersFunc.push(setUsers)
    } else {
      let userPaginateList = sessionStorage.getItem("userPaginateList")
      if (userPaginateList) {
        userPaginateList = JSON.parse(userPaginateList)
        getUsers(defaultLimit || userPaginateList.limit, userPaginateList.skip, userPaginateList.filterName, userPaginateList.columnSort, userPaginateList.descending, userPaginateList.showDisabled)
      } else {
        getUsers()
      }
    }
    return () => {
      usersTmp = null
    }
    // eslint-disable-next-line
  }, []);

  const updateUsersFromActiveDirectory = () => {
    userService().updateUsersFromActiveDirectory()
      .then(res => {
        getUsers(pagination.limit, 0)
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_USERS_FROM_ACTIVE_DIRECTORY_SUCCESS], color: 'info'});
      })
      .catch((err) => {
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_USERS_FROM_ACTIVE_DIRECTORY_FAILURE], color: 'danger'});
      })
  }

  const addActionUser = (action) => {
    userService().AddUserAction(user.id, action)
      .then(res => {
        getUsers()
      })
  }

  return {users, pagination, getUsers, updateUsersFromActiveDirectory, addActionUser, setUser, user}
};

let namesTmp = null, isNamesFetching = false, subscribedNamesFunc = [];
export const useAllName = () => {

  const [names, setNames] = useState([]);

  const getUsers = () => {
    isNamesFetching = true;
    userService()
      .getNames()
      .then(res => {
        if (res && res.data) {
          setNames(res.data);
          namesTmp = res.data
          subscribedNamesFunc.forEach(func => {
            func(namesTmp)
          })
          subscribedNamesFunc = []
        }
        isNamesFetching = false;
      })
  };

  useEffect(() => {
    if (namesTmp) {
      setNames(namesTmp)
    } else if (isNamesFetching) {
      subscribedNamesFunc.push(setNames)
    } else {
      getUsers();
    }
    // eslint-disable-next-line
  }, []);

  const getName = (userName) => {
    let tmp = names.filter(f => f.userName && userName && f.userName.toLowerCase() === userName.toLowerCase());
    if (tmp[0] && tmp[0].name) {
      return tmp[0].name
    } else {
      return userName
    }
  }

  const getNames = (userName, joined = false) => {
    let splitUsernames = userName?.split(', '), createdUsername = "", updatedUsernames = ""
    if (splitUsernames && splitUsernames[0]) {
      createdUsername = getName(splitUsernames[0])
      if (splitUsernames.length > 1) {
        for (let i = 1; i < splitUsernames.length; i++) {
          updatedUsernames += ", " + getName(splitUsernames[i])
        }
        updatedUsernames = updatedUsernames.replace(/^, /, '')
      }
    }
    return joined ? createdUsername + (updatedUsernames ? ", " : "") + updatedUsernames : {createdUsername, updatedUsernames};
  }

  const isThere = (userName) => {
    let tmp = names.filter(f => f.userName && userName && f.userName.toLowerCase() === userName.toLowerCase());
    return !!(tmp[0] && tmp[0].userName);
  }

  return {names, getName, getNames, isThere, getUsers}
};

export const useUser = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const userParamId = param.get("user");

  const [user, setUser] = useState({});

  const getUser = (userId) => {
    if (userId || id || userParamId) {
      userService()
        .getUser({id: userId || id || userParamId})
        .then(res => {
          if (res && res.data) {
            setUser(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setUser({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [userParamId, id]);

  return {
    user,
    setUser,
    getUser,
    userId: userParamId
  }
};
