import { dispatch } from '../actions/util'
import * as types   from "../types";

export { default as generalService }                    from './general';
export { default as memberService }                     from './member';
export { default as titleService }                      from './title';
export { default as statusService }                     from './status';
export { default as memberContactService }              from './memberContact';
export { default as userService }                       from './user';
export { default as siteService }                       from './site';
export { default as visitService }                      from './visit';
export { default as visitCommentMembershipOnlyService } from './visitCommentMembershipOnly';
export { default as siteSummaryService }                from './siteSummary';
export { default as visitMemberService }                from './visitMember';
export { default as visitMemberCommentService }         from './visitMemberComment';
export { default as investigationService }              from './investigation';
export { default as investigationAdvocateService }      from './investigationAdvocate';
export { default as investigationCommentService }       from './investigationComment';
export { default as investigationMeetingService }       from './investigationMeeting';
export { default as grievanceService }                  from './grievance';
export { default as grievanceAdvocateService }          from './grievanceAdvocate';
export { default as grievanceDateService }              from './grievanceDate';
export { default as grievanceActivityService }          from './grievanceActivity';
export { default as ratingService }                     from './rating';
export { default as ratingAdvocateService }             from './ratingAdvocate';
export { default as ratingWaiveService }                from './ratingWaiver';
export { default as ratingCommentService }              from './ratingComment';
export { default as ratingActivityService } from './ratingActivity';
export { default as categoryService }       from './category';
export { default as friendService }       from './friend';
export { default as friendAddressService }       from './friendAddress';
export { default as bonusService }       from './bonus';
export { default as seniorityService }       from './seniority';
export { default as footerServices }       from './footer';

export const defaultLimit = parseInt(localStorage.getItem("defaultLimitLegal") || "10", 10) ?? 10;

export const loading = (promise) => {
  dispatch(types.FETCHING);
  return promise.then(res => {
    dispatch(types.FETCHING_END);
    return res;
  }).catch(err => {
    dispatch(types.FETCHING_END);
    return err;
  });
};