import React, { useEffect }                 from 'react';
import { Container, Table }                 from 'reactstrap';
import BonusTab                             from './BonusSeniorityTab';
import SearchBonusSeniority                 from './SearchBonusSeniority';
import { bonusService }                     from "../../services";
import { useBonusList }                     from '../../hooks/Bonus';
import ImportFile                           from './ImportFile';
import { fetchingFn, setAlertNotification } from "../../actions/util";
import ColumnSort                           from '../utils/ColumnSort';
import MyPagination                         from '../utils/MyPagination';
import Footer                               from "./Footer";
import { isAllow, userActionsKeys }         from "../../util/userActions";

let fileNoDef = "";
let lastNameDef = "";

const BonusList = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {bonus, pagination, getBonus, deleteBonus} = useBonusList();
  const items = bonus && bonus.map((bonus, i) =>
    <tr key={bonus.id}>
      <th>{bonus.role} </th>
      <th>{bonus.firstName}</th>
      <th>{bonus.lastName}</th>
      <th>{bonus.dbn}</th>
      <th>{bonus.amount}</th>
      <th>{bonus.notes}</th>
    </tr>
  )

  const sortFn = (object) => {
    getBonus(pagination.limit, pagination.skip, object.columnSort, object.descending)
  };

  return (
    <Container fluid>
      <BonusTab from="Bonus"/>
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">Bonus</h3>
        <ImportFile
          actionImport={isAllow(userActionsKeys.importBonusLegal)}
          onChange={(file) => {
            bonusService().importBonus(file).then(() => {
              setAlertNotification("File upload", 'info');
              fetchingFn();
              getBonus()
            })
              .catch(() => {
                setAlertNotification("Something got wrong uploading the file", 'danger');
                fetchingFn();
              });
          }}
          actionDelete={isAllow(userActionsKeys.deleteBonusLegal)}
          deleteAll={() => {deleteBonus()}}/>
      </div>

      <label>Input your File # and your Last Name</label>
      <div className="flex-grow-1">
        <SearchBonusSeniority defaultValue={""} onSearch={(fileNo, lastName) => {
          fileNoDef = fileNo;
          lastNameDef = lastName;
          getBonus(pagination.limit, 0, pagination.columnSort, pagination.descending, fileNoDef, lastNameDef)
        }}/>
      </div>

      <Table hover responsive className="table-responsive-vertical mb-5">
        <thead>
        <tr>
          <th style={{minWidth: "70px"}}>Role <ColumnSort columnName="Role" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "105px"}}>Firstname <ColumnSort columnName="Firstname" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "106px"}}>LastName <ColumnSort columnName="Lastname" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "67px"}}>Dbn <ColumnSort columnName="Dbn" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "91px"}}>Amount <ColumnSort columnName="Amount" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "79px"}}>Notes <ColumnSort columnName="Notes" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {bonus.length ?
          items :
          <tr>
            <th colSpan="6" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getBonus()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>
      {bonus.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getBonus(limit, skip, pagination.columnSort, pagination.descending)
      }}/>}
      <Footer/>
    </Container>
  );
};

export default BonusList;
