import React                                                                                                                from "react";
import { Button, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row, UncontrolledButtonDropdown } from "reactstrap";
import { categoryService }                                                                                                  from "../../services";
import { appPaths }                                                                                                         from "../../routes";
import { useForm }                                  from "react-hook-form";
import { useHistory }                               from "react-router";
import { useCategory }                        from "../../hooks";
import { FriendTab }                                from "../index";
import CheckIt from "../utils/CheckIt";
import { userActionsKeys } from "../../util/userActions";

const CategoryForm = () => {
  const {register, handleSubmit, reset} = useForm();
  const history = useHistory();
  const {category} = useCategory({
    onChange: (data) => {
      reset(data)
    }})

  return (
    <Container fluid>
      <FriendTab from="Category"/>
      <Row><h3>{category.id ? "Update" : "New"} Category</h3></Row>
      <Row>
        <div className="col-12 col-md-6 offset-md-3 detail-header border-bottom-gray">
          Details
        </div>
        <form className="col-12 col-md-6 offset-md-3 detail" onSubmit={handleSubmit((values) => {
          const tmp = {...category, ...values};
          if (category.id) {
            categoryService()
              .updateCategory(tmp)
              .then(res => {
                history.push(appPaths.CategoryList)
              })
          } else {
            categoryService()
              .addCategory(tmp)
              .then(res => {
                history.push(appPaths.CategoryList)
              })
          }
        })}>
          <Row>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Category</Label>
                <div className="col-9"><input className="form-control" name="category" required ref={register}/></div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Responsible</Label>
                <div className="col-9"><input className="form-control" name="responsible" ref={register}/></div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Description</Label>
                <div className="col-9"><input className="form-control" name="description" ref={register}/></div>
              </Row>
            </FormGroup>

            <div className="col-12" style={{marginTop: "30px", borderTop: "1px solid #797979", paddingTop: "15px"}}>
            <CheckIt action={userActionsKeys.updateFriendCategoryLegal}>
              <input type="submit" className="btn btn-outline-secondary" value="Save"/>
              </CheckIt>
              {" "}
              <CheckIt action={userActionsKeys.deleteCategoryLegal}>
              {category.id && <UncontrolledButtonDropdown>
                <DropdownToggle caret color="danger">
                  Delete
                </DropdownToggle>
                <DropdownMenu style={{minWidth: "8rem"}}>
                  <DropdownItem className="text-danger" onClick={() => {
                    categoryService()
                      .deleteCategory(category.id)
                      .then(res => {
                        history.goBack()
                      })
                  }}>Confirm</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>}
              {" "}
              or
              {" "}
              </CheckIt>
              <Button outline color="danger" onClick={() => {
                history.goBack()
              }}>Cancel</Button>
            </div>

          </Row>
        </form>
      </Row>
    </Container>
  )
}

export default CategoryForm