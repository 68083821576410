import React                                 from 'react';
import { Row }                               from 'reactstrap';
import { dateFormatMonthDayYearHoursMinute } from "../../actions/util";
import { useForm }                           from "react-hook-form";
import { MyPagination }                      from "../index";
import { useAllName }                        from "../../hooks";

const Comments = ({comments, pagination, showForm, addCommentAction, getPaginationAction}) => {

  const {register, handleSubmit, setValue, errors} = useForm();
  const {getName} = useAllName();

  return (
    <Row>
      {comments.length > 0 ? <div className="col-12 mb-3">
        {comments.map((comment, index) => (
          <div className="col-12 mb-2 p-2 box-border" key={index + pagination.skip}>
            {comment.comment && <span dangerouslySetInnerHTML={{__html: comment.comment.replace(/\n/g, "<br/>")}}/>}
            <br/>
            <small className="text-gray">by {getName(comment.userName)} on {dateFormatMonthDayYearHoursMinute(comment.createDate)}</small>
          </div>
        ))}

        {comments.length > 0 && pagination.count > 10 &&
        <div style={{padding: "30px 15px 0", overflow: "auto"}}>
          <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
            getPaginationAction(skip, limit)
          }}/>
        </div>}

      </div> : <div className="col-12 mb-3 text-center">No content data</div>}
      {showForm && <form className="col-12" onSubmit={handleSubmit((values) => {
        addCommentAction({...values}, () => {
          setValue("comment", "")
        })
      })}>
        <Row>
          <div className="col-11 no-padding-right">
            <textarea className="form-control" name="comment" defaultValue="" ref={register({required: true})}/>
            <small className="text-danger" style={{position: "absolute", bottom: "-17px"}}>{errors.comment && "This field is required"}</small>
          </div>
          <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
        </Row>
        <input type="submit" className="btn btn-outline-secondary mt-3" value="Add Comment"/>
      </form>}
    </Row>
  );
};

export default Comments;
