import { isAllow } from "../../util/userActions";

const CheckIt = ({children, action}) => {

  if (isAllow(action)) {
    return children || null
  }

  return null
};

export default CheckIt