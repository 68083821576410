import { client } from '../util/createRestApiClient';
import { loading } from "./index";

const base = "userManager/";
export default () => ({
    getUser: (params) => client.request({
      method: 'GET',
      url: base + 'getUser/' + params.id
    }),
    getNames: () => client.request({
      method: 'GET',
      url: base + 'GetNames'
    }),
    getUsers: (limit = 10, skip = 0, includeAction = false, filterName = "", columnSort = "", descending = true, showDisabled = false) => loading(client.request({
      method: 'GET',
      url: base + 'getUsers?limit=' + limit + '&skip=' + skip + "&includeAction=" + includeAction + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&showDisabled=' + showDisabled
    })),
    exportUsers: (filterName = "", columnSort = "", descending = true, showDisabled = false) => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'ExportUsers?filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&showDisabled=' + showDisabled
    }),
    AddUserAction: (userId, data) => client.request({
      method: 'POST',
      url: base + 'addAction/' + userId,
      data
    }),
    addAdvocate: (params) => client.request({
      method: 'POST',
      url: base + 'AddAdvocate',
      data: params
    }),
    setAdvocate: (userId, isAdvocate) => client.request({
      method: 'POST',
      url: base + 'SetAdvocate/' + userId + "?isAdvocate=" + isAdvocate
    }),
    changeState: (userId, state) => client.request({
      method: 'POST',
      url: base + 'ChangeState/' + userId + "?state=" + state
    }),
    updateUsersFromActiveDirectory: () => loading(client.request({
      method: 'POST',
      url: base + 'UpdateUsersFormActiveDirectory'
    }))
  }
);
