import React                     from "react";
import { Container, Row }        from "reactstrap";
import { CheckIt, MyDatePicker } from "../index";
import { useReport }                from "../../hooks";
import { isAllow, userActionsKeys } from "../../util/userActions";

const Card = ({title, children, className}) => (
  <div className={"col-12 col-sm-12 col-md-6 " + (className || "")}>
    <div className="col-12 detail-header border-bottom-gray">
      {title}
    </div>
    <div className="col-12 detail">
      {children}
    </div>
  </div>
)

const CardReport = ({title, children}) => (
  <Card title={title}>
    <div className="flex-center justify-content-around flex-column flex-sm-row flex-md-column flex-lg-row">
      {children}
    </div>
  </Card>
)

const Button = ({children, disabled, onClick}) => (
  <button disabled={disabled} className="btn btn-outline-secondary ml-1 mr-1 mb-2 mb-sm-0 mb-md-2 mb-lg-0" onClick={onClick}>{children}</button>
)

const Reports = () => {

  const {
    setDateFrom, setDateTo, datesError,
    grievanceByAdvocateReport, grievanceByArbitratorReport, grievanceReport, investigationByAdvocateReport, investigationReport, memberNotVisitedReport,
    memberWithoutACReport, memberWithACReport, visitByFieldDirectorReport, visitBySiteReport, ratingReport, ratingByAdvocateReport
  } = useReport()

  const getButton = (children, onClick) => <Button disabled={datesError || !onClick} onClick={() => {onClick && onClick()}}>{children}</Button>
  const getMemberButton = (children, onClick) => <Button disabled={!onClick} onClick={() => {onClick && onClick()}}>{children}</Button>

  return (
    <Container>
      <h3><span className="text-gray">Download</span> Reports</h3>
      <Row>
        <Card title="Select a Range" className="offset-md-3 date-range">
          <Row>
            <div className="col-12 col-sm-6">
              <div className="form-check-inline mb-2 mb-sm-0">
                <label className="mr-1" style={{minWidth: "38px"}}>From: </label>
                <MyDatePicker onChange={(value) => {setDateFrom(value)}}/>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-check-inline">
                <label className="mr-1" style={{minWidth: "38px"}}>To: </label>
                <MyDatePicker onChange={(value) => {setDateTo(value)}}/>
              </div>
            </div>
            <div className="text-center small text-danger width-100">{datesError}</div>
          </Row>
        </Card>

        {(isAllow(userActionsKeys.reportMemberWithACLegal) || isAllow(userActionsKeys.reportMemberWithoutACLegal)) &&
        <CardReport title="Authorization Card Reports">
          <CheckIt action={userActionsKeys.reportMemberWithACLegal}>
            {getMemberButton("Member with AC", memberWithACReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportMemberWithoutACLegal}>
            {getMemberButton("Member without AC", memberWithoutACReport)}
          </CheckIt>
        </CardReport>}

        {(isAllow(userActionsKeys.reportVisitReportBySiteLegal) || isAllow(userActionsKeys.reportVisitReportMemberNotVisitedLegal) || isAllow(userActionsKeys.reportVisitReportByFieldDirectorLegal)) &&
        <CardReport title="Member Contact and Visit Reports">
          <CheckIt action={userActionsKeys.reportVisitReportBySiteLegal}>
            {getButton("by Site", visitBySiteReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportVisitReportMemberNotVisitedLegal}>
            {getMemberButton("Member Not Visited", memberNotVisitedReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportVisitReportByFieldDirectorLegal}>
            {getButton("by Field Director", visitByFieldDirectorReport)}
          </CheckIt>
        </CardReport>}

        {(isAllow(userActionsKeys.reportInvestigationReportCasesByDateLegal) || isAllow(userActionsKeys.reportInvestigationReportCasesByAdvocateLegal)) &&
        <CardReport title="Investigation Reports">
          <CheckIt action={userActionsKeys.reportInvestigationReportCasesByDateLegal}>
            {getButton("Cases by Date", investigationReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportInvestigationReportCasesByAdvocateLegal}>
            {getButton("Cases by Advocate", investigationByAdvocateReport)}
          </CheckIt>
        </CardReport>}

        {(isAllow(userActionsKeys.reportGrievanceReportCasesByDateLegal) || isAllow(userActionsKeys.reportGrievanceReportCasesByAdvocateLegal) || isAllow(userActionsKeys.reportGrievanceReportCasesByArbitratorLegal)) &&
        <CardReport title="Grievance Reports">
          <CheckIt action={userActionsKeys.reportGrievanceReportCasesByDateLegal}>
            {getButton("Cases by Date", grievanceReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportGrievanceReportCasesByAdvocateLegal}>
            {getButton("Cases by Advocate", grievanceByAdvocateReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportGrievanceReportCasesByArbitratorLegal}>
            {getButton("Cases by Arbitrator", grievanceByArbitratorReport)}
          </CheckIt>
        </CardReport>}

        {(isAllow(userActionsKeys.reportRatingReportCasesByDateLegal) || isAllow(userActionsKeys.reportRatingReportCasesByAdvocateLegal)) &&
        <CardReport title="Rating Reports">
          <CheckIt action={userActionsKeys.reportRatingReportCasesByDateLegal}>
            {getButton("Cases by Date", ratingReport)}
          </CheckIt>
          <CheckIt action={userActionsKeys.reportRatingReportCasesByAdvocateLegal}>
            {getButton("Cases by Advocate", ratingByAdvocateReport)}
          </CheckIt>
        </CardReport>}

      </Row>
    </Container>
  )
}

export default Reports

let tmp = {}, field = "color";
[{id: 1, color: "green"}, {id: 2, color: "blue"}, {id: 3, color: "green"}].filter(f => {
  if (!tmp[f[field]]) {
    tmp[f[field]] = true
    return true
  } else {
    return false
  }
})