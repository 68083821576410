import { generalService } from "../services/index";

import * as types   from "../types";
import { dispatch } from "./util";
import messages     from "../util/messages";

export const loginAction = (username, password) => {
  dispatch(types.FETCHING);
  sessionStorage.setItem('usernameLFS', username);
  generalService()
    .loginService({username, password})
    .then(res => {
      if (!isToken()) {
        dispatch(types.FETCHING_END);
      }
      if (res.data && res.data.token) {
        sessionStorage.setItem('tokenAuthLFS', res.data.token);
        sessionStorage.setItem('rolesLFS', res.data.roles);
        let tmp = [];
        for (let i in res.data.actions) {
          if (res.data.actions[i].allow) {
            tmp.push(res.data.actions[i].name)
          }
        }
        sessionStorage.setItem('actionsLFS', tmp);

        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.LOGIN_SUCCESS], color: 'info'});
        dispatch(types.HIDE_AUTH_MODAL);
        if (sessionStorage.getItem("reloadLFS")) {
          sessionStorage.removeItem("reloadLFS");
          window.location.reload();
        }
      }
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: err.response?.data?.message || messages[types.LOGIN_FAILURE], color: 'danger'});
    });
};

export const logoutAction = (noDispatch) => {
  sessionStorage.removeItem('usernameLFS');
  sessionStorage.removeItem('tokenAuthLFS');
  sessionStorage.removeItem('rolesLFS');
  if (!noDispatch) {
    dispatch(types.SHOW_AUTH_MODAL);
  }
};

export const refreshTokenAction = () => {
  generalService().refreshTokenService()
    .then(res => {
      if (res.data && res.data.token) {
        sessionStorage.setItem('tokenAuthLFS', res.data.token);
        sessionStorage.setItem('rolesLFS', res.data.roles);
        let tmp = [];
        for (let i in res.data.actions) {
          if (res.data.actions[i].allow) {
            tmp.push(res.data.actions[i].name)
          }
        }
        const beforeAction = sessionStorage.getItem('actionsLFS');
        sessionStorage.setItem('actionsLFS', tmp);
        if (beforeAction !== tmp.toString()) {
          window.location.reload();
        }
      }
    })
    .catch((err) => {
    });
};

export const isToken = () => !!sessionStorage.getItem('tokenAuthLFS');

let refreshTokenInterval = 0;

if (isToken()) {
  refreshTokenAction();
  clearInterval(refreshTokenInterval);
  refreshTokenInterval = setInterval(() => {
    if (isToken()) {
      refreshTokenAction();
    }
  }, 60 * 1000 * 60 * 2);
}
