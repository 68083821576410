import React, { useEffect, useState }  from 'react';
import { dateFormatMonthDayYear }      from "../../actions/util";
import { BigModal, MemberContactForm } from "../index";
import { useAllName, useMember }       from "../../hooks";
import { isAllow, userActionsKeys }    from "../../util/userActions";


const MemberContactItem = ({memberContact, getVisitMembers = null}) => {
  const [isBigModalOpen, setIsBigModalOpen] = useState(false);
  const bigModalTitle = "Edit Contact";
  const {member} = useMember({id: memberContact?.memberModelId});
  const {getNames} = useAllName();

  const toggleModal = () => {
    setIsBigModalOpen(!isBigModalOpen);
  };

  return (
    <>
      <tr>
        <th scope="row">
          <span className={isAllow(userActionsKeys.editMemberContactLegal) ? "simulate-a no-a" : ""} onClick={() => {
            if (isAllow(userActionsKeys.editMemberContactLegal)) {
              setIsBigModalOpen(memberContact.id);
            }
          }}>{dateFormatMonthDayYear(memberContact.date) || <span>Detail</span>}</span>


        </th>
        <th>
          <div>{memberContact?.member?.site?.sitename}</div>
          <div>
            <small className="text-gray">District: {memberContact?.member?.site?.district}</small>
          </div>
        </th>
        <th>{memberContact.type}</th>
        <th>{getNames(memberContact.userName, true)}</th>
        <th>
          <div className="text-center">Contact</div>
        </th>
      </tr>
      <BigModal className="md-modal" title={bigModalTitle} isBigModalOpen={isBigModalOpen === memberContact.id} toggleModalAction={toggleModal}>
        <MemberContactForm
          getContactVisitMemberList={getVisitMembers}
          member={member}
          memberContact={memberContact}
          setMemberContact={() => {}}
          toggleModal={toggleModal}/>
      </BigModal>
    </>
  );
};

export default MemberContactItem;
