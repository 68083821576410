import React, { useEffect, useRef, useState }            from 'react'
import jsPDF                                             from 'jspdf';
import html2canvas                                       from "html2canvas";
import SignatureCanvas                                   from 'react-signature-canvas'
import CSALogo_BlackWhite                                from '../../resources/images/CSALogo_BlackWhite.png'
import './UnionDuesAuthorizationCard.css'
import { cleanName, dateFormatMonthDayYear, dispatch, phoneFormat } from "../../actions/util";
import { memberService, titleService }                              from "../../services/index";
import { Button }                                        from "reactstrap";
import { getMemberAction }                               from "../../actions/member";
import * as types                                        from "../../types";
import { CheckIt }                                       from "../index";
import { userActionsKeys }                               from "../../util/userActions";

const UnionDuesAuthorizationCard = ({history}) => {

  const [isFetching, setIsFetching] = useState(false);
  const [member, setMember] = useState({});
  const [title, setTitle] = useState({});

  useEffect(() => {
    let param = history.location.search;
    if (!(member && member.id) && param) {
      param = param.replace("?", "");
      if (param) {
        titleService().getTitleByMemberId(param)
          .then(res => {
            setTitle(res.data || [])
          })
          .catch((err) => {
            setTitle([]);
          });
        getMemberAction(param, false, (data) => {
          setMember(data);
        });
      }
    }
  }, [history, member]);

  const [isIA, setIsIA] = useState("");
  const [isAppointed, setIsAppointed] = useState("");
  const [signatureImg, setSignatureImg] = useState("");
  const [preview, setPreview] = useState(false);

  const sigPad = useRef(null);

  return (
    <div>
      <div style={{width: "fit-content", margin: "auto"}}>
        <table id="my-pdf-union-dues-authorization-card" className="Document" style={{borderSpacing: "0px", margin: "20px 0 0 30px"}} cellSpacing="0">
          <tbody>
          <tr className="Document">
            <td style={{verticalAlign: "top"}}>
              <div id="wt161_wtMainContent" className="Document">
                <div id="wt161_wtMainContent_wtAuthorizationCard" className="OSInline" style={{width: "900px", padding: "0px"}}>
                  <div id="wt161_wtMainContent_wtHeading">
                    <div className="OSInline" align="center" style={{width: "200px", minHeight: "100px"}}>
                      <img alt="" src={CSALogo_BlackWhite}/>
                    </div>
                    <div className="OSInline" style={{width: "700px", minHeight: "100px"}}>
                      <span className="Bold" style={{verticalAlign: "-55px"}}>Council of School Supervisors and Administrators</span>
                    </div>
                  </div>
                  <div id="wt161_wtMainContent_wtTitle" align="center">
                    <span className="Bold">UNION DUES AUTHORIZATION CARD</span>
                  </div>
                  <div id="wt161_wtMainContent_wtForUseByCSA">
                    <div className="OSInline" align="right" style={{width: "100%", minHeight: "30px"}}>
                      <div className="OSInline" align="center">
                        <span style={{verticalAlign: "-10px"}}>For use by CSA&nbsp;</span>
                        <span className="OSInline" style={{border: "5px solid", height: "30px", width: "30px", marginLeft: "10px"}}/>
                      </div>
                    </div>
                  </div>
                  <div id="wt161_wtMainContent_wtEmpty">

                  </div>
                  <div id="wt161_wtMainContent_wtPleasePrintClearly">
                    <span className="Italic">Please PRINT clearly:</span>
                  </div>
                  <div id="wt161_wtMainContent_wtForm">
                    <div className="form-left Form" style={{width: "900px", marginTop: "0"}} id="wt161_wtMainContent_wtMemberForm">
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>File #</label>
                        <span className="Value">{member.fileno}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>

                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Last Name</label>
                        <span className="Value">{cleanName(member.lastname)}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>First Name</label>
                        <span className="Value">{cleanName(member.frstname)}</span>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Home Address</label>
                        <span className="Value">{member.address1}</span>
                        &nbsp;<span className="Value">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>City</label>
                        <span className="Value">{member.city}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "50px"}}>State</label>
                        <span className="Value">{member.state}</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "70px"}}>Zip</label>
                        <span className="Value">{member.zip}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Telephone</label>
                        <span className="Value">{phoneFormat(member.telephon)}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Cell Phone</label>
                        <span className="Value">{phoneFormat(member.celphone)}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Date of Birth</label>
                        <span className="Value">{dateFormatMonthDayYear(member.dob, member.memberid)}</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "50px"}}>Male</label>
                        <div className="Bold OSInline" align="center">
                          {member.sex === "M" && <span className="Value">X</span>}
                        </div>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "70px"}}>Female</label>
                        <div className="Bold OSInline" align="center">
                          {member.sex === "F" && <span className="Value">X</span>}
                        </div>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>School/Office</label>
                        <span className="Value">{member.site ? member.site.sitename : ""}</span>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>Address</label>
                        <span className="Value">{member.site ? member.site.address1 : ""}</span>&nbsp;
                        <span className="Value">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>City</label>
                        <span className="Value">{member.city}</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "50px"}}>State</label>
                        <span className="Value">{member.state}</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "70px"}}>Zip</label>
                        <span className="Value">{member.zip}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "130px"}}>School Phone</label>
                        <span className="Value">{member.site && member.site.telephon ? phoneFormat(member.site.telephon) : ""}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "60px"}}>E-mail</label>
                        <span className="Value">{member.email}</span>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "500px"}}>Department of Education Start Date (Total Service Including Teacher)</label>
                        <span className="Value">{dateFormatMonthDayYear(member.BoEDate, member.memberid)}</span>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "270px"}}>License Currently Serving Under</label>
                        <span className="Value">{title.description}</span>
                      </div>
                      <div className="OSInline" style={{width: "50%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "150px"}}>Assignment Date</label>
                        <span className="Value">{dateFormatMonthDayYear(member.currassign, member.memberid)}</span>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "120px"}}>Interim Acting</label>
                        <div className="Bold OSInline" align="center">
                          <div id="wt161_wtMainContent_wtcb_InterimActing" className="OSInline div-check" align="left"
                               onClick={(e) => {
                                 e.preventDefault();
                                 if (!preview) {
                                   setIsIA(!isIA)
                                 }
                               }}
                               style={{width: "20px", minHeight: "20px", border: !isIA && !preview ? "2px solid" : ""}}>
                            {isIA && <b>X</b>}
                          </div>
                        </div>
                      </div>
                      <div className="OSInline" style={{width: "25%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "100px"}}>Appointed</label>
                        <div className="Bold OSInline" align="center">
                          <div id="wt161_wtMainContent_wtcb_Appointed" className="OSInline div-check"
                               onClick={(e) => {
                                 e.preventDefault();
                                 if (!preview) {
                                   setIsAppointed(!isAppointed)
                                 }
                               }}
                               style={{width: "20px", minHeight: "20px", border: !isAppointed && !preview ? "2px solid" : ""}}>
                            {isAppointed && <b>X</b>}
                          </div>
                        </div>
                      </div>
                      <div className="OSInline" style={{width: "100%", marginTop: "10px"}}>
                        <label className=" OSInline" style={{width: "270px"}}>Position Currently Serving Under</label>
                        <span className="Value">{title.description}</span>
                      </div>
                    </div>
                    <div id="wt161_wtMainContent_wtNotes" className="TermsConditions">
                      <div style={{marginTop: "20px"}}>
                        Subject to the terms and conditions set forth in the Resolution adopted by the Board of Estimate on Jan. 12, 1956 (Calendar No.127), and in all resolutions amendatory or supplemental thereto now in existence or
                        hereafter adopted, and Article Xlll of the CBA to which terms and conditions I consent and agree. I hereby authorize the City of New York to deduct in each regular payroll from my salary or wages the sum of 1.5% of
                        total gross earnings beginning Oct. 1, 2003 semi-monthly and to pay over said sum to the EMPLOYEE ORGANIZATION CHECKOFF COMMITTEE described in such resolution or resolutions in payment of my dues in the above
                        captioned
                        employee organization, on condition that said employee organization, through said committee, pay to the City of New York all costs and expenses determined by the City of New York as incurred by the City in connection
                        with carrying out the plan authorized by said resolution or resolutions.&nbsp;
                      </div>
                      <div style={{marginTop: "10px"}}>
                        There shall be no change in the amount of dues deduction without due prior notice to the undersigned employee member.
                      </div>
                      <div style={{marginTop: "10px"}}>
                        This authorization shall terminate and cease not later than five weeks (if I am a semi-monthly, monthly or bi-weekly paid employee) or not later than three weeks (if I am a weekly paid employee) after the department
                        or
                        agency of the City of New York in which I am employed receives written notice from me revoking and canceling the same.
                      </div>
                    </div>
                    <div id="wt161_wtMainContent_wtEmpty2" style={{minHeight: "30px"}}>

                    </div>
                    <div id="wt161_wtMainContent_wtSignature" className="OSInline" style={{width: "900px", minHeight: "100px"}}>
                      <div className="OSInline" style={{width: "200px"}}>
                        <label className=" OSInline" style={{width: "150px", verticalAlign: "-85px", color: "#999"}} htmlFor="">Signature of Employee</label>
                      </div>
                      <div className="OSInline" style={{width: "500px"}}>
                        <div id="wt161_wtMainContent_wtSignatureRenderer" className="OSInline" style={{width: "500px", minHeight: "100px"}}>
                          <div style={{padding: "0", margin: "0", width: "100%", height: "0", marginTop: "-1em", marginBottom: "1em"}}/>

                          {/*<canvas className="jSignature" width="500" height="125" style={{padding: "0", margin: "0", width: "100%", height: "125px", border: "none"}}/>*/}


                          <div style={{width: "500px", height: "125px"}}>{signatureImg ? <img alt="signature" src={signatureImg} width="500" height="125"/> :
                            <SignatureCanvas penColor='black' canvasProps={{width: 500, height: 125, className: 'sigCanvas'}} ref={sigPad}/>}</div>

                          <div style={{padding: "0", margin: "0", width: "100%", height: "0", marginTop: "-1.5em", marginBottom: "1.5em", borderBottom: "1px solid"}}/>
                        </div>
                        <div className="OSInline" style={{width: "500px"}}>
                          <span id="wt161_wtMainContent_Signature_wtSignHere_block"/>
                        </div>

                      </div>
                      <div className="OSInline" align="right" style={{width: "200px", minHeight: "50px"}}>
                        <span className="Menu" style={{verticalAlign: "-85px", color: "#999"}}>Date&nbsp;</span>
                        <span style={{verticalAlign: "-85px"}}>{dateFormatMonthDayYear(new Date())}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <CheckIt action={userActionsKeys.uploadAuthorizationCardLegal}>
        <div id="wt161_wtMainContent_wtSave" style={{margin: "30px", width: "900px", marginLeft: "auto", marginRight: "auto"}}>

          {!preview && <Button outline onClick={() => {
            if (signatureImg) {
              setSignatureImg(null);
            } else if (sigPad.current) {
              sigPad.current.clear()
            }
          }}>
            Clear Signature
          </Button>}

          {" "}

          {preview && <Button outline onClick={() => {
            setPreview(false)
          }}>
            Back
          </Button>}

          {" "}

          {!preview && <Button outline onClick={() => {
            if (!signatureImg) {
              if (sigPad.current.isEmpty()) {
                dispatch(types.ADD_ALERT_NOTIFICATION, {message: "Please, provide a signature to continue", color: 'danger'});
              } else {
                setSignatureImg(sigPad.current.getSignaturePad().toDataURL('image/png'));
                setPreview(true)
              }
            } else {
              setPreview(true)
            }
          }}>
            Next
          </Button>}

          {" "}

          {preview &&
          <Button
            outline name="wt161$wtMainContent$wt101" id="wt161_wtMainContent_wt101" tabIndex="3" className="Button Is_Default"
            onClick={() => {
              setIsFetching(true);
              window.scrollTo(0, 0);
              const content = document.getElementById("my-pdf-union-dues-authorization-card");

              const pdf = new jsPDF('p', 'pt', [980, 1350]);

              window.html2canvas = html2canvas;

              pdf.html(content, {
                callback: function (doc) {
                  // doc.output("dataurlnewwindow");
                  var file = doc.output('blob');
                  var fd = new FormData();     // To carry on your data
                  fd.append('pdf', file);
                  memberService().saveMemberAuthorizationCard({id: member.id, pdf: fd, isAppointed, isInterimActing: isIA})
                    .then(() => {
                      setIsFetching();
                      history.goBack();
                    })
                    .catch(() => {
                      setIsFetching();
                    });
                  // doc.save();
                }
              });
            }}>
            Save
          </Button>}
        </div>
      </CheckIt>
      <div className="fetching">
        {isFetching &&
        <div>
          <div className="sk-fade-in sk-spinner sk-chasing-dots">
            <div/>
            <div/>
          </div>
        </div>}
      </div>
    </div>
  )
};

export default UnionDuesAuthorizationCard;