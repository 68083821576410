import React, { useEffect, useState } from 'react'
import { MyDatePicker }               from "../index";
import { Button }                     from "reactstrap";
import { useForm }                    from "react-hook-form";
import { dateFromISOToLocal }         from "../../actions/util";

const RatingActivityForm = ({defaultValues, onSubmit, onCancel}) => {

  const {register, handleSubmit, reset} = useForm();

  const [activityDate, setActivityDate] = useState(new Date());

  useEffect(() => {
    if (defaultValues) {
      reset({...defaultValues});
      setActivityDate(dateFromISOToLocal(defaultValues.activityDate, defaultValues?.rating?.ratingId))
    } else {
      reset({});
      setActivityDate(new Date())
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleSubmit(values => {
      if (activityDate) {
        onSubmit({...defaultValues, ...values, activityDate})
      }
    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Activity Type</div>
      <div className="col-6 mt-2 mb-2">
        <select className="form-control" name="type" ref={register({required: true})}>
          <option value="">-</option>
          {["Sent Appeal Letter", "Received Appeal Letter", "Received Rating Sheet", "Received_Initial_PIP", "First_Superintendent_PIP_Visit",
            "Second_Superintendent_PIP_Visit", "First_Network_PIP_Visit", "Second_Network_PIP_Visit"]
            .map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
        </select>
      </div>
      <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>

      <div className="col-5 border-bottom-gray toast-title-item">Date</div>
      <div className="col-6 mt-2 mb-2">
        <MyDatePicker
          dateFormat="MM/dd/yyyy"
          selected={activityDate}
          onChange={(value) => {
            setActivityDate(value);
          }}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Comment</div>

      <div className="col-7 mt-2 mb-2">
        <textarea className="form-control" name="comment" ref={register}/>
      </div>

      <div className="col-12 mt-4 mb-2">
        <Button disabled={!activityDate}>Save</Button>
        {" "} or {" "}
        <Button outline onClick={() => {
          onCancel()
        }}>Cancel</Button>
      </div>
    </form>
  )
};

export default RatingActivityForm