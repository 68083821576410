import React, { useState }                                                                 from 'react';
import { Button, InputGroup, InputGroupAddon, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { getUniqueKey }                                                                    from "../../actions/util";
import { useDefaultLimit }                                                                 from "../../hooks";

const MyPagination = ({count, limit, skip, action}) => {

  const [go, setGo] = useState('');
  const {defaultLimit, setDefaultLimit} = useDefaultLimit()

  let items = [], cantItems = 10, actualPage = skip / limit, pages = count / limit, dif = pages - actualPage;

  for (let i = actualPage <= 5 ? 0 : actualPage - (dif < 5 ? 10 - ((/\./.test(dif.toString()) ? parseInt(dif, 10) + 1 : parseInt(dif, 10) + 1)) : 5); i < pages; i++) {

    if (cantItems > 0) {
      if (i >= 0) {
        items.push(
          <PaginationItem key={getUniqueKey()} active={i === actualPage} onClick={(e) => {
            e.preventDefault();
            action(limit * i, limit)
          }}>
            <PaginationLink>
              {i + 1}
            </PaginationLink>
          </PaginationItem>);
      }
      cantItems--;
    }

  }

  return (
    <div key={defaultLimit} className="my-pagination overflow-auto">
      <div style={{marginTop: "8px", minWidth: "90px"}}>Page {actualPage + 1} of {(/\./.test(pages.toString()) ? parseInt(pages, 10) + 1 : parseInt(pages, 10))}</div>

      <Pagination>

        <select
          name="pageLimit"
          defaultValue={defaultLimit + ""}
          id="pageLimit"
          className="form-control mr-1"
          style={{width: "52px", height: "36px"}}
          onChange={(e) => {
            const value = e.target.value
            localStorage.setItem("defaultLimitLegal", value)
            action(0, value)
            setDefaultLimit(value)
          }}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>

        <InputGroup style={{width: "120px", marginRight: "15px", height: "36px"}}>
          <input type="text" className="form-control" value={go} onChange={e => {
            const value = e.target.value;
            if (!value || (/^\d+$/.test(value) && (/\./.test(pages.toString()) ? parseInt(pages, 10) : parseInt(pages, 10) - 1) >= value - 1)) {
              setGo(value)
            }
          }} style={{marginRight: "-4px", height: "36px"}} placeholder="Page"
                 onKeyUp={(e) => {
                   if (e.keyCode === 27) {
                     action(0, limit);
                     setGo('');
                   } else {
                     const value = e.target.value;
                     if (e.keyCode === 13) {
                       if (!value) {
                         action(0)
                       } else {
                         action(limit * (value - 1), limit)
                       }
                     }
                   }
                 }}/>
          <InputGroupAddon addonType="prepend"><Button onClick={(e) => {
            e.preventDefault();
            if (!go) {
              action(0, limit)
            } else {
              action(limit * (go - 1), limit)
            }
          }}>Go</Button></InputGroupAddon>
        </InputGroup>

        {items}

        <PaginationItem disabled={actualPage - 1 < 0}>
          <PaginationLink disabled={actualPage - 1 < 0} onClick={(e) => {
            e.preventDefault();
            action(0, limit)
          }}>Start</PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={actualPage - 1 < 0}>
          <PaginationLink disabled={actualPage - 1 < 0} onClick={(e) => {
            e.preventDefault();
            action(limit * (actualPage - 1), limit)
          }}><b>{"<"}</b></PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={actualPage + 1 >= pages}>
          <PaginationLink disabled={actualPage + 1 >= pages} onClick={(e) => {
            e.preventDefault();
            action(limit * (actualPage + 1), limit)
          }}><b>{">"}</b></PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={actualPage + 1 >= pages}>
          <PaginationLink disabled={actualPage + 1 >= pages} onClick={(e) => {
            e.preventDefault();
            action(limit * (/\./.test(pages.toString()) ? parseInt(pages, 10) : parseInt(pages, 10) - 1), limit)
          }}>End</PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default MyPagination;
