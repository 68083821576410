export const userActionsKeys = {
  memberLegal: "memberLegal",
  memberListLegal: "memberListLegal",
  memberDetailLegal: "memberDetailLegal",
  uploadAuthorizationCardLegal: "uploadAuthorizationCardLegal",
  downloadAuthorizationCardLegal: "downloadAuthorizationCardLegal",
  deleteAuthorizationCardLegal: "deleteAuthorizationCardLegal",
  memberContactListLegal: "memberContactListLegal",
  addNewMemberContactLegal: "addNewMemberContactLegal",
  editMemberContactLegal: "editMemberContactLegal",
  memberCasesListLegal: "memberCasesListLegal",

  siteLegal: "siteLegal",
  siteListLegal: "siteListLegal",
  siteDetailLegal: "siteDetailLegal",
  siteVisitListLegal: "siteVisitListLegal",
  siteMemberListLegal: "siteMemberListLegal",

  visitLegal: "visitLegal",
  visitListLegal: "visitListLegal",
  visitDetailLegal: "visitDetailLegal",
  addNewVisitLegal: "AddNewVisitLegal",
  updateVisitLegal: "updateVisitLegal",
  changeVisitStateLegal: "changeVisitStateLegal",
  visitCommentListMembershipOnlyLegal: "visitCommentListMembershipOnlyLegal",
  addVisitCommentMembershipOnlyLegal: "addVisitCommentMembershipOnlyLegal",
  visitSiteSummaryListLegal: "visitSiteSummaryListLegal",
  addVisitCommentSiteSummaryLegal: "addVisitCommentSiteSummaryLegal",
  visitMemberListLegal: "visitMemberListLegal",
  visitMemberCommentListLegal: "visitMemberCommentListLegal",
  addVisitMemberCommentLegal: "addVisitMemberCommentLegal",

  investigationLegal: "investigationLegal",
  investigationListLegal: "investigationListLegal",
  investigationDetailLegal: "investigationDetailLegal",
  addNewInvestigationLegal: "addNewInvestigationLegal",
  updateInvestigationLegal: "updateInvestigationLegal",
  deleteInvestigationLegal: "deleteInvestigationLegal",
  changeInvestigationStateLegal: "changeInvestigationStateLegal",
  addInvestigationAdvocateLegal: "addInvestigationAdvocateLegal",
  deleteInvestigationAdvocateLegal: "deleteInvestigationAdvocateLegal",
  investigationCommentListLegal: "investigationCommentListLegal",
  addInvestigationCommentLegal: "addInvestigationCommentLegal",
  investigationMeetingListLegal: "investigationMeetingListLegal",
  addInvestigationMeetingLegal: "addInvestigationMeetingLegal",
  updateInvestigationMeetingLegal: "updateInvestigationMeetingLegal",

  grievanceLegal: "grievanceLegal",
  grievanceListLegal: "grievanceListLegal",
  grievanceDetailLegal: "grievanceDetailLegal",
  addNewGrievanceLegal: "addNewGrievanceLegal",
  updateGrievanceLegal: "updateGrievanceLegal",
  changeGrievanceStateLegal: "changeGrievanceStateLegal",
  addGrievanceAdvocateLegal: "addGrievanceAdvocateLegal",
  deleteGrievanceAdvocateLegal: "deleteGrievanceAdvocateLegal",
  addGrievanceArbitrationDatesLegal: "addGrievanceArbitrationDatesLegal",
  deleteGrievanceArbitrationDatesLegal: "deleteGrievanceArbitrationDatesLegal",
  grievanceActivityListLegal: "grievanceActivityListLegal",
  addGrievanceActivityLegal: "addGrievanceActivityLegal",
  updateGrievanceActivityLegal: "updateGrievanceActivityLegal",

  ratingLegal: "ratingLegal",
  ratingListLegal: "ratingListLegal",
  ratingDetailLegal: "ratingDetailLegal",
  addNewRatingLegal: "addNewRatingLegal",
  updateRatingLegal: "updateRatingLegal",
  changeRatingStateLegal: "changeRatingStateLegal",
  addRatingAdvocateLegal: "addRatingAdvocateLegal",
  deleteRatingAdvocateLegal: "deleteRatingAdvocateLegal",
  addRatingWaiverLegal: "addRatingWaiverLegal",
  deleteRatingWaiverLegal: "deleteRatingWaiverLegal",
  ratingActivityListLegal: "ratingActivityListLegal",
  addRatingActivityLegal: "addRatingActivityLegal",
  updateRatingActivityLegal: "updateRatingActivityLegal",
  ratingCommentListLegal: "ratingCommentListLegal",
  addRatingCommentLegal: "addRatingCommentLegal",

  reportLegal: "reportLegal",
  reportViewLegal: "reportViewLegal",
  reportMemberWithACLegal: "reportMemberWithACLegal",
  reportMemberWithoutACLegal: "reportMemberWithoutACLegal",
  reportVisitReportBySiteLegal: "reportVisitReportBySiteLegal",
  reportVisitReportMemberNotVisitedLegal: "reportVisitReportMemberNotVisitedLegal",
  reportVisitReportByFieldDirectorLegal: "reportVisitReportByFieldDirectorLegal",
  reportInvestigationReportCasesByDateLegal: "reportInvestigationReportCasesByDateLegal",
  reportInvestigationReportCasesByAdvocateLegal: "reportInvestigationReportCasesByAdvocateLegal",
  reportGrievanceReportCasesByDateLegal: "reportGrievanceReportCasesByDateLegal",
  reportGrievanceReportCasesByAdvocateLegal: "reportGrievanceReportCasesByAdvocateLegal",
  reportGrievanceReportCasesByArbitratorLegal: "reportGrievanceReportCasesByArbitratorLegal",
  reportRatingReportCasesByDateLegal: "reportRatingReportCasesByDateLegal",
  reportRatingReportCasesByAdvocateLegal: "reportRatingReportCasesByAdvocateLegal",

  friendLegal: "friendLegal",
  friendListLegal: "friendListLegal",
  categoryListLegal: "categoryListLegal",
  addNewFriendLegal: "addNewFriendLegal",
  addNewFriendCategoryLegal: "addNewFriendCategoryLegal",
  addNewFriendAddressLegal: "addNewFriendAddressLegal",
  friendDetailLegal: "friendDetailLegal",
  exportFriendsLegal: "exportFriendsLegal",
  exportCategoriesLegal: "exportCategoriesLegal",
  updateFriendLegal: "updateFriendLegal",
  updateFriendAddressLegal: "updateFriendAddressLegal",
  updateFriendCategoryLegal: "updateFriendCategoryLegal",
  deleteCategoryLegal: "deleteCategoryLegal",
  changeStatusFriendLegal: "changeStatusFriendLegal",
  addCategoryLegal: "addCategoryLegal",
  categoryDetailLegal: "categoryDetailLegal",
  friendAddressListLegal: "friendAddressListLegal",
  friendCategoryListLegal: "friendCategoryListLegal",
  deleteFriendAddressLegal: "deleteFriendAddressLegal",

  bonusSeniorityLegal: "bonusSeniorityLegal",
  bonusListLegal: "bonusListLegal",
  importBonusLegal: "importBonusLegal",
  changeFooterBonusLegal: "changeFooterBonusLegal",
  seniorityListLegal: "seniorityListLegal",
  importSeniorityLegal: "importSeniorityLegal",
  deleteSeniorityLegal: "deleteSeniorityLegal",
  changeFooterSeniorityLegal: "changeFooterSeniorityLegal",
  enableAccessPublicBonusApp: "enableAccessPublicBonusApp",
  enableAccessPublicSeniorityApp: "enableAccessPublicSeniorityApp",
  deleteBonusLegal:"deleteBonusLegal"
};

let userActions = {};

userActions[userActionsKeys.memberLegal] = "Member";
userActions[userActionsKeys.memberListLegal] = "Member List"
userActions[userActionsKeys.memberDetailLegal] = "Member Detail"
userActions[userActionsKeys.uploadAuthorizationCardLegal] = "Upload Authorization Card"
userActions[userActionsKeys.downloadAuthorizationCardLegal] = "Download Authorization Card Legal"
userActions[userActionsKeys.deleteAuthorizationCardLegal] = "Delete Authorization Card"
userActions[userActionsKeys.memberContactListLegal] = "Member Contact List"
userActions[userActionsKeys.addNewMemberContactLegal] = "Add New Member Contact"
userActions[userActionsKeys.editMemberContactLegal] = "Edit Member Contact"
userActions[userActionsKeys.memberCasesListLegal] = "Member Case List"

userActions[userActionsKeys.siteLegal] = "Site";
userActions[userActionsKeys.siteListLegal] = "Site List";
userActions[userActionsKeys.siteDetailLegal] = "Site Detail";
userActions[userActionsKeys.siteVisitListLegal] = "Site Visit List";
userActions[userActionsKeys.siteMemberListLegal] = "Site Member List";

userActions[userActionsKeys.visitLegal] = "Visit";
userActions[userActionsKeys.visitListLegal] = "Visit List";
userActions[userActionsKeys.visitDetailLegal] = "Visit Detail";
userActions[userActionsKeys.addNewVisitLegal] = "Add New Visit";
userActions[userActionsKeys.updateVisitLegal] = "Update Visit";
userActions[userActionsKeys.changeVisitStateLegal] = "Change Visit State Open/Close";
userActions[userActionsKeys.visitCommentListMembershipOnlyLegal] = "Visit Comment List Membership Only";
userActions[userActionsKeys.addVisitCommentMembershipOnlyLegal] = "Add Visit Comment Membership Only";
userActions[userActionsKeys.visitSiteSummaryListLegal] = "Visit Site Summary List";
userActions[userActionsKeys.addVisitCommentSiteSummaryLegal] = "Add Visit Site Summary Comment";
userActions[userActionsKeys.visitMemberListLegal] = "Visit Member List";
userActions[userActionsKeys.visitMemberCommentListLegal] = "Visit Member Comment List";
userActions[userActionsKeys.addVisitMemberCommentLegal] = "Add Visit Member Comment";

userActions[userActionsKeys.investigationLegal] = "Investigation";
userActions[userActionsKeys.investigationListLegal] = "Investigation List";
userActions[userActionsKeys.investigationDetailLegal] = "Investigation Detail";
userActions[userActionsKeys.addNewInvestigationLegal] = "Add New Investigation";
userActions[userActionsKeys.updateInvestigationLegal] = "Update Investigation";
userActions[userActionsKeys.deleteInvestigationLegal] = "Delete Investigation";
userActions[userActionsKeys.changeInvestigationStateLegal] = "Change Investigation State Open/Close";
userActions[userActionsKeys.addInvestigationAdvocateLegal] = "Add Investigation Advocate";
userActions[userActionsKeys.deleteInvestigationAdvocateLegal] = "Delete Investigation Advocate";
userActions[userActionsKeys.investigationCommentListLegal] = "Investigation Comment List";
userActions[userActionsKeys.addInvestigationCommentLegal] = "Add Investigation Comment";
userActions[userActionsKeys.investigationMeetingListLegal] = "Investigation Meeting List";
userActions[userActionsKeys.addInvestigationMeetingLegal] = "Add Investigation Meeting";
userActions[userActionsKeys.updateInvestigationMeetingLegal] = "Update Investigation Meeting";

userActions[userActionsKeys.grievanceLegal] = "Grievance";
userActions[userActionsKeys.grievanceListLegal] = "Grievance List";
userActions[userActionsKeys.grievanceDetailLegal] = "Grievance Detail";
userActions[userActionsKeys.addNewGrievanceLegal] = "Add New Grievance";
userActions[userActionsKeys.updateGrievanceLegal] = "Update Grievance";
userActions[userActionsKeys.changeGrievanceStateLegal] = "Change Grievance State Open/Close";
userActions[userActionsKeys.addGrievanceAdvocateLegal] = "Add Grievance Advocate";
userActions[userActionsKeys.deleteGrievanceAdvocateLegal] = "Delete Grievance Advocate";
userActions[userActionsKeys.addGrievanceArbitrationDatesLegal] = "Add Grievance Arbitration Dates";
userActions[userActionsKeys.deleteGrievanceArbitrationDatesLegal] = "Delete Grievance Arbitration Dates";
userActions[userActionsKeys.grievanceActivityListLegal] = "Grievance Activity List";
userActions[userActionsKeys.addGrievanceActivityLegal] = "Add Grievance Activity";
userActions[userActionsKeys.updateGrievanceActivityLegal] = "Update Grievance Activity";

userActions[userActionsKeys.ratingLegal] = "Rating";
userActions[userActionsKeys.ratingListLegal] = "Rating List";
userActions[userActionsKeys.ratingDetailLegal] = "Rating Detail";
userActions[userActionsKeys.addNewRatingLegal] = "Add New Rating";
userActions[userActionsKeys.updateRatingLegal] = "Update Rating";
userActions[userActionsKeys.changeRatingStateLegal] = "Change Rating State Open/Close";
userActions[userActionsKeys.addRatingAdvocateLegal] = "Add Rating Advocate";
userActions[userActionsKeys.deleteRatingAdvocateLegal] = "Delete Rating Advocate";
userActions[userActionsKeys.addRatingWaiverLegal] = "Add Rating Waiver";
userActions[userActionsKeys.deleteRatingWaiverLegal] = "Delete Rating Waiver";
userActions[userActionsKeys.ratingActivityListLegal] = "Rating Activity List";
userActions[userActionsKeys.addRatingActivityLegal] = "Add Rating Activity";
userActions[userActionsKeys.updateRatingActivityLegal] = "Update Rating Activity";
userActions[userActionsKeys.ratingCommentListLegal] = "Rating Comment List";
userActions[userActionsKeys.addRatingCommentLegal] = "Add Rating Comment";

userActions[userActionsKeys.reportLegal] = "Report";
userActions[userActionsKeys.reportViewLegal] = "Report View";
userActions[userActionsKeys.reportMemberWithACLegal] = "Member with AC";
userActions[userActionsKeys.reportMemberWithoutACLegal] = "Member without AC";
userActions[userActionsKeys.reportVisitReportBySiteLegal] = "Visit Report by Site";
userActions[userActionsKeys.reportVisitReportMemberNotVisitedLegal] = "Visit Report Member Not Visited";
userActions[userActionsKeys.reportVisitReportByFieldDirectorLegal] = "Visit Report by Field Director";
userActions[userActionsKeys.reportInvestigationReportCasesByDateLegal] = "Investigation Report Cases by Date";
userActions[userActionsKeys.reportInvestigationReportCasesByAdvocateLegal] = "Investigation Report Cases by Advocate";
userActions[userActionsKeys.reportGrievanceReportCasesByDateLegal] = "Grievance Report Cases by Date";
userActions[userActionsKeys.reportGrievanceReportCasesByAdvocateLegal] = "Grievance Report Cases by Advocate";
userActions[userActionsKeys.reportGrievanceReportCasesByArbitratorLegal] = "Grievance Report Cases by Arbitrator";
userActions[userActionsKeys.reportRatingReportCasesByDateLegal] = "Rating Report Cases by Date";
userActions[userActionsKeys.reportRatingReportCasesByAdvocateLegal] = "Rating Report Cases by Advocate";

userActions[userActionsKeys.friendLegal] = "Friends of CSA";
userActions[userActionsKeys.friendListLegal] = "Friends List";
userActions[userActionsKeys.categoryListLegal] = "Categories List";
userActions[userActionsKeys.addNewFriendLegal] = "Add New Friend";
userActions[userActionsKeys.addNewFriendCategoryLegal] = "Add Category Friend";
userActions[userActionsKeys.friendDetailLegal] = "Friend Detail";
userActions[userActionsKeys.exportFriendsLegal] = "Export Friends";
userActions[userActionsKeys.exportCategoriesLegal] = "Export Categories";
userActions[userActionsKeys.updateFriendLegal] = "Update Friend";
userActions[userActionsKeys.updateFriendAddressLegal] = "Update Friend Address";
userActions[userActionsKeys.deleteCategoryLegal] = "Delete Category";
userActions[userActionsKeys.updateFriendCategoryLegal] = "Update Category";
userActions[userActionsKeys.addNewFriendAddressLegal] = "Add New Address Friend";
userActions[userActionsKeys.changeStatusFriendLegal] = "Change Status Friend";
userActions[userActionsKeys.addCategoryLegal] = "Add New Category";
userActions[userActionsKeys.categoryDetailLegal] = "Category Detail";
userActions[userActionsKeys.friendAddressListLegal] = "Friend Address List";
userActions[userActionsKeys.friendCategoryListLegal] = "Friend Category List";
userActions[userActionsKeys.deleteFriendAddressLegal] = "Delete Friend Address";

userActions[userActionsKeys.bonusSeniorityLegal] = "Bonus & Seniority";
userActions[userActionsKeys.bonusListLegal] = "Bonus List";
userActions[userActionsKeys.importBonusLegal] = "Import Bonus from file";
userActions[userActionsKeys.changeFooterBonusLegal] = "Update Bonus Footer";
userActions[userActionsKeys.seniorityListLegal] = "Seniority List";
userActions[userActionsKeys.importSeniorityLegal] = "Import Seniority from file";
userActions[userActionsKeys.deleteSeniorityLegal] = "Delete Seniority";
userActions[userActionsKeys.changeFooterSeniorityLegal] = "Update Seniority Footer";
userActions[userActionsKeys.enableAccessPublicBonusApp] = "Enable/Disable access to Bonus public website";
userActions[userActionsKeys.enableAccessPublicSeniorityApp] = "Enable/Disable access to Seniority public website";
userActions[userActionsKeys.deleteBonusLegal] = "Delete Bonus";

export default userActions;

let tree = {};

tree[userActionsKeys.memberLegal] = [userActionsKeys.memberListLegal, userActionsKeys.memberDetailLegal, userActionsKeys.uploadAuthorizationCardLegal, userActionsKeys.downloadAuthorizationCardLegal, userActionsKeys.deleteAuthorizationCardLegal, userActionsKeys.memberContactListLegal, userActionsKeys.addNewMemberContactLegal, userActionsKeys.editMemberContactLegal, userActionsKeys.memberCasesListLegal];
tree[userActionsKeys.siteLegal] = [userActionsKeys.siteListLegal, userActionsKeys.siteDetailLegal, userActionsKeys.siteVisitListLegal, userActionsKeys.siteMemberListLegal];
tree[userActionsKeys.visitLegal] = [userActionsKeys.visitListLegal, userActionsKeys.visitDetailLegal, userActionsKeys.addNewVisitLegal, userActionsKeys.updateVisitLegal, userActionsKeys.changeVisitStateLegal, userActionsKeys.visitCommentListMembershipOnlyLegal, userActionsKeys.addVisitCommentMembershipOnlyLegal, userActionsKeys.visitSiteSummaryListLegal, userActionsKeys.addVisitCommentSiteSummaryLegal, userActionsKeys.visitMemberListLegal, userActionsKeys.visitMemberCommentListLegal, userActionsKeys.addVisitMemberCommentLegal];
tree[userActionsKeys.investigationLegal] = [userActionsKeys.investigationListLegal, userActionsKeys.investigationDetailLegal, userActionsKeys.addNewInvestigationLegal, userActionsKeys.updateInvestigationLegal, userActionsKeys.deleteInvestigationLegal, userActionsKeys.changeInvestigationStateLegal, userActionsKeys.addInvestigationAdvocateLegal, userActionsKeys.deleteInvestigationAdvocateLegal, userActionsKeys.investigationCommentListLegal, userActionsKeys.addInvestigationCommentLegal, userActionsKeys.investigationMeetingListLegal, userActionsKeys.addInvestigationMeetingLegal, userActionsKeys.updateInvestigationMeetingLegal];
tree[userActionsKeys.grievanceLegal] = [userActionsKeys.grievanceListLegal, userActionsKeys.grievanceDetailLegal, userActionsKeys.addNewGrievanceLegal, userActionsKeys.updateGrievanceLegal, userActionsKeys.changeGrievanceStateLegal, userActionsKeys.addGrievanceAdvocateLegal, userActionsKeys.deleteGrievanceAdvocateLegal, userActionsKeys.addGrievanceArbitrationDatesLegal, userActionsKeys.deleteGrievanceArbitrationDatesLegal, userActionsKeys.grievanceActivityListLegal, userActionsKeys.addGrievanceActivityLegal, userActionsKeys.updateGrievanceActivityLegal];
tree[userActionsKeys.ratingLegal] = [userActionsKeys.ratingListLegal, userActionsKeys.ratingDetailLegal, userActionsKeys.addNewRatingLegal, userActionsKeys.updateRatingLegal, userActionsKeys.changeRatingStateLegal, userActionsKeys.addRatingAdvocateLegal, userActionsKeys.deleteRatingAdvocateLegal, userActionsKeys.addRatingWaiverLegal, userActionsKeys.deleteRatingWaiverLegal, userActionsKeys.ratingCommentListLegal, userActionsKeys.addRatingCommentLegal, userActionsKeys.ratingActivityListLegal, userActionsKeys.addRatingActivityLegal, userActionsKeys.updateRatingActivityLegal];
tree[userActionsKeys.reportLegal] = [userActionsKeys.reportViewLegal, userActionsKeys.reportMemberWithACLegal, userActionsKeys.reportMemberWithoutACLegal, userActionsKeys.reportVisitReportBySiteLegal, userActionsKeys.reportVisitReportMemberNotVisitedLegal, userActionsKeys.reportVisitReportByFieldDirectorLegal, userActionsKeys.reportInvestigationReportCasesByDateLegal, userActionsKeys.reportInvestigationReportCasesByAdvocateLegal, userActionsKeys.reportGrievanceReportCasesByDateLegal, userActionsKeys.reportGrievanceReportCasesByAdvocateLegal, userActionsKeys.reportGrievanceReportCasesByArbitratorLegal, userActionsKeys.reportRatingReportCasesByDateLegal, userActionsKeys.reportRatingReportCasesByAdvocateLegal];
tree[userActionsKeys.friendLegal] = [userActionsKeys.friendListLegal, userActionsKeys.categoryListLegal, userActionsKeys.exportFriendsLegal, userActionsKeys.exportCategoriesLegal, userActionsKeys.addNewFriendLegal, userActionsKeys.addCategoryLegal, userActionsKeys.addNewFriendCategoryLegal, userActionsKeys.addNewFriendAddressLegal, userActionsKeys.friendDetailLegal, userActionsKeys.categoryDetailLegal, userActionsKeys.friendCategoryListLegal, userActionsKeys.friendAddressListLegal, userActionsKeys.updateFriendLegal, userActionsKeys.updateFriendAddressLegal, userActionsKeys.updateFriendCategoryLegal, userActionsKeys.changeStatusFriendLegal, userActionsKeys.deleteCategoryLegal, userActionsKeys.deleteFriendAddressLegal]
tree[userActionsKeys.bonusSeniorityLegal] = [userActionsKeys.bonusListLegal, userActionsKeys.importBonusLegal,userActionsKeys.deleteBonusLegal, userActionsKeys.changeFooterBonusLegal, userActionsKeys.enableAccessPublicBonusApp, userActionsKeys.seniorityListLegal, userActionsKeys.importSeniorityLegal, userActionsKeys.deleteSeniorityLegal, userActionsKeys.changeFooterSeniorityLegal, userActionsKeys.enableAccessPublicSeniorityApp]
export const userActionsTree = tree;


export const isAllow = (action, roles) => {
  const localRoles = sessionStorage.getItem("rolesLFS");
  if ((!action && !roles) || localRoles === "Admin" || (roles && roles.length > 0 && roles.some(s => localRoles.includes(s)))) {
    return true;
  }
  let tmp = sessionStorage.getItem("actionsLFS");
  if (!tmp) {
    return false;
  }
  tmp = tmp.split(',');
  return Array.isArray(action) ? tmp.some(f => action.some(s => s === f)) : tmp.some(s => s === action);
};
