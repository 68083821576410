import React from 'react';
import {cleanName, phoneFormat} from "../../actions/util";
import {appPaths} from "../../routes";
import {MyLink} from "../index";

const MemberItem = ({member, getJobDescription, getCategory}) => {

  return (
    <tr>
      <th scope="row">{member.fileno}</th>
      <th>
        <div>
          <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
        </div>
        <div><MyLink to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a">{cleanName(member.lastname)}{cleanName(member.frstname) &&
        <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !cleanName(member.frstname) && <span>Detail</span>}</MyLink>
        </div>
        <div>
          <small className="text-gray">{getCategory(member.status)}</small>
        </div>
      </th>
      <th>
        <div>{member.sitecode}</div>
        <div>
          <small className="text-gray">{member.site ? member.site.sitename : ""}</small>
        </div>
      </th>
      <th>{member.site ? member.site.district : ""}</th>
      <th>
        <div className="d-flex justify-content-start flex-wrap">
          <div className="mr-3">
            <div>
              <small className="text-gray">phone: </small>
              {phoneFormat(member.telephon)}
            </div>
            <div>
              <small className="text-gray">mobile: </small>
              {phoneFormat(member.celphone)}
            </div>
          </div>
          <div>
            <div>
              <small className="text-gray">Work E-mail: </small>
              {member.email}
            </div>
            <div>
              <small className="text-gray">Personal E-mail: </small>
              {member.personalEmail}
            </div>
          </div>
        </div>
      </th>

    </tr>
  );
};

export default MemberItem;
