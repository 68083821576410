import { useEffect, useState }        from "react";
import { footerServices }             from "../services";
import { useLocation }                from "react-router";
import { setAlertNotification }       from "../actions/util";
import { BONUS_TYPE, SENIORITY_TYPE } from "../types";


export const useFooter = (props) => {

  const {onChange} = props || {}
  const [footer, setFooter] = useState("");
  const location = useLocation();
  const footerType = location.pathname === '/seniority-list' ? SENIORITY_TYPE : BONUS_TYPE

  const getFooter = () => {
    footerServices()
      .getFooter(footerType)
      .then(res => {
        if (res && res.data) {
          setFooter(res.data);
          if (onChange) {
            onChange(res.data);
          }
        }
      })
      .catch(err => {
        setFooter({});
        if (onChange) {
          onChange({});
        }
      })
  };

  const addFooter = (values) => {
    footerServices().addFooter(values)
      .then(res => {
        setFooter(values)
        setAlertNotification("Footer updated", 'info');
      })
      .catch(() => {
        setAlertNotification("Something got wrong", 'danger');
      });
  };
  const updatedAccess = (values) => {
    footerServices().updateAccess(values)
      .then(res => {
        setFooter(values);
        setAlertNotification(values.enabledPage ? "Enable Access" : "Disable Access", 'info')
      })
      .catch(() => {
        setAlertNotification("Something got wrong", 'danger');
      });
  };
  useEffect(() => {
    getFooter()
    // eslint-disable-next-line
  }, []);


  return {
    footer,
    footerType,
    getFooter,
    setFooter,
    addFooter,
    updatedAccess
  }
};