import { useEffect, useState }  from 'react'
import { grievanceDateService } from "../services";
import { useLocation }          from "react-router";
import { setAlertNotification } from "../actions/util";

export const useGrievanceDateList = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const grievanceParamId = param.get("grievance");

  const [grievanceDates, setGrievanceDates] = useState([]);

  const getGrievanceDates = (grievanceId) => {
    if (grievanceId || id || grievanceParamId) {
      grievanceDateService()
        .getAllByGrievance(grievanceId || id || grievanceParamId)
        .then(res => {
          if (res && res.data) {
            setGrievanceDates(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setGrievanceDates({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getGrievanceDates()
    // eslint-disable-next-line
  }, [grievanceParamId, id]);

  const deleteGrievanceDate = (id) => {
    grievanceDateService()
      .deleteDate({id})
      .then(res => {
        getGrievanceDates()
      })
      .catch(err => {
        setAlertNotification("Error deleting an Date", "danger")
      });
  }

  return {
    grievanceDates,
    getGrievanceDates,
    grievanceId: grievanceParamId,
    deleteGrievanceDate
  }
};
