import { client } from '../util/createRestApiClient';

export default () => ({
    loginService: (params) => client.request({
      method: 'POST',
      url: 'UserActiveDirectory/login',
      data: params
    }),
    refreshTokenService: (params) => client.request({
      method: 'POST',
      url: 'UserActiveDirectory/RefreshToken',
      data: params
    })
  }
);
