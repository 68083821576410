import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "investigationMeeting/";
export default () => ({
    getInvestigationMeeting: (id) => client.request({
      method: 'GET',
      url: base + 'getInvestigationMeeting/' + id
    }),
    getInvestigationMeetings: (investigationId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetInvestigationMeetings/' + investigationId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addInvestigationMeeting: (params) => client.request({
      method: 'POST',
      url: base + 'addInvestigationMeeting',
      data: params
    }),
    updateInvestigationMeeting: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateInvestigationMeeting/' + params.id,
      data: params
    }),
    deleteInvestigationMeeting: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteInvestigationMeeting/' + params.id
    })
  }
);
