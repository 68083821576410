import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "grievanceDate/";
export default () => ({
    getDate: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getDate/' + id
    })),
    getDatesUsers: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetDatesUsers?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAllByGrievance: (grievanceId, filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'getAllByGrievance/' + grievanceId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getDates: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getDates?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getDatesByGrievance: (grievanceId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getDates/' + grievanceId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addDate: (params) => client.request({
      method: 'POST',
      url: base + 'addDate',
      data: params
    }),
    updateDate: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateDate/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeState/' + params.id + "?isOpen=" + params.isOpen,
    }),
    deleteDate: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteDate/' + params.id
    })
  }
);
