import React                                                                             from 'react'
import { ColumnSort, MyPagination }                                                      from "../index";
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledButtonDropdown } from "reactstrap";
import { isAllow, userActionsKeys }                                                      from '../../util/userActions';

const FriendAddressList = ({friendAddresses, pagination, onPaginationAction, setFriendAddress, deleteFriendAddress}) => {

  const sortFn = (object) => {
    onPaginationAction(pagination.limit, pagination.skip, object.columnSort, object.descending)
  };

  return (
    <>
      <Table hover className="table-dotted table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>Address Type <ColumnSort columnName="Type" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Name <ColumnSort columnName="Name" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Address <ColumnSort columnName="Address" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          {isAllow(userActionsKeys.deleteFriendAddressLegal) && <th className="text-center">Actions</th>}
        </tr>
        </thead>
        <tbody>
        {friendAddresses.length > 0 ? friendAddresses.map((value, index) => (
          <tr key={index + pagination.skip}>
            <th>
              <span className={"simulate-a"} onClick={() => {
                if (isAllow(userActionsKeys.updateFriendAddressLegal)) {
                  setFriendAddress(value);
                }
              }}>{value.type}</span>
            </th>
            <th>{value.name}</th>
            <th>{value.address}</th>
            {isAllow(userActionsKeys.deleteFriendAddressLegal) && <th className="text-center">
              <UncontrolledButtonDropdown className="ml-2 mb-2">
                <DropdownToggle caret color="danger">
                  Delete
                </DropdownToggle>
                <DropdownMenu style={{minWidth: "8rem"}}>
                  <DropdownItem className="text-danger" onClick={() => {
                    deleteFriendAddress(value.id)
                  }}>Confirm</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </th>}
          </tr>
        )) : <tr>
          <th colSpan="6" className="text-center">No content data</th>
        </tr>}
        </tbody>
      </Table>


      {friendAddresses.length > 0 && pagination.count > 10 &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
          onPaginationAction(limit, skip)
        }}/>
      </div>}
    </>
  )
};

export default FriendAddressList;