import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "ratingActivity/";
export default () => ({
    getRatingActivity: (id) => client.request({
      method: 'GET',
      url: base + 'getRatingActivity/' + id
    }),
    getRatingActivities: (ratingId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetRatingActivities/' + ratingId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addRatingActivity: (params) => client.request({
      method: 'POST',
      url: base + 'addRatingActivity',
      data: params
    }),
    updateRatingActivity: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateRatingActivity/' + params.id,
      data: params
    }),
    deleteRatingActivity: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteRatingActivity/' + params.id
    })
  }
);
