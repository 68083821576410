import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "visit/";
export default () => ({
    getVisit: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getVisit/' + id
    })),
    getVisitsMemberContacts: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'GetVisitMemberContactList?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    getVisits: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getVisits?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    getVisitsBySite: (siteId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, open = 0) => loading(client.request({
      method: 'GET',
      url: base + 'getVisitsBySite/' + siteId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&open=' + open
    })),
    addVisit: (params) => client.request({
      method: 'POST',
      url: base + 'addVisit',
      data: params
    }),
    updateVisit: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateVisit/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeStateVisit/' + params.id + "?isOpen=" + params.isOpen,
    }),
    deleteVisit: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteVisit/' + params.id
    })
  }
);
