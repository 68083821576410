import React, { useEffect, useState }          from 'react';
import { Row }                                             from 'reactstrap';
import { visitCommentMembershipOnlyService } from "../../services";
import { dateFormatMonthDayYearHoursMinute }               from "../../actions/util";
import { useLocation }                         from "react-router-dom";
import { useForm }                             from "react-hook-form";
import { addVisitCommentMembershipOnlyAction } from "../../actions/visitCommentMembershipOnly";
import { MyPagination }                        from "../index";
import { isAllow, userActionsKeys }    from "../../util/userActions";
import { useAllName, useDefaultLimit } from "../../hooks";

const VisitCommentsMembershipOnly = ({visit}) => {

  const {defaultLimit} = useDefaultLimit()
  const location = useLocation();
  const {register, handleSubmit, setValue, errors} = useForm();
  const [visitComments, setVisitComments] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true});
  const {getName} = useAllName();

  let param = location.search;

  const visitId = new URLSearchParams(param).get("visit");

  const getVisit = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    visitCommentMembershipOnlyService()
      .getVisitCommentsMembershipOnly(visitId, limit, skip, filterName, columnSort, descending)
      .then(res => {
        if (res && res.data) {
          setVisitComments(res.data);
          setPagination({
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10)
          })
        }
      })
  };

  useEffect(() => {
    if (param) {
      if (visitId) {
        getVisit()
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Row>
      {visitComments.length > 0 && <div className="col-12 mb-3">
        {visitComments.map((comment, index) => (
          <div className="col-12 mb-2 p-2 box-border" key={index + pagination.skip}>
            {comment.comment && <span dangerouslySetInnerHTML={{__html: comment.comment.replace(/\n/g, "<br/>")}}/>}
            <br/>
            <small className="text-gray">by {getName(comment.userName)} on {dateFormatMonthDayYearHoursMinute(comment.createDate)}</small>
          </div>
        ))}


        {visitComments.length > 0 && pagination.count > 10 &&
        <div style={{padding: "30px 15px 0", overflow: "auto"}}>
          <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
            getVisit(limit, skip, pagination.filterName, pagination.columnSort, pagination.descending)
          }}/>
        </div>}

      </div>}

      {visit.isOpen && isAllow(userActionsKeys.addVisitCommentMembershipOnlyLegal) && <form className="col-12" onSubmit={handleSubmit((values) => {
        addVisitCommentMembershipOnlyAction({...values, visitModelId: visitId}, () => {
          getVisit();
          setValue("comment", "")
        })
      })}>
        <Row>
          <div className="col-11 no-padding-right">
            <textarea className="form-control" name="comment" defaultValue="" ref={register({required: true})}/>
            <small className="text-danger" style={{position: "absolute", bottom: "-17px"}}>{errors.comment && "This field is required"}</small>
          </div>
          <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
        </Row>
        <input type="submit" className="btn btn-outline-secondary mt-3" value="Add Comment"/>
      </form>}
    </Row>
  );
};

export default VisitCommentsMembershipOnly;
