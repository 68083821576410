import { useEffect, useState }      from 'react'
import { grievanceActivityService } from "../services";
import { useLocation }              from "react-router";
import { setAlertNotification }     from "../actions/util";

export const useGrievanceActivityList = (props) => {

  const {grievanceId} = props || {};
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const grievanceParamId = param.get("grievance");

  const [grievanceActivities, setGrievanceActivities] = useState([]);
  const [pagination, setPagination] = useState({limit: 15, skip: 0, filterName: "", columnSort: "ActivityDate", descending: true});

  const getGrievanceActivities = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    if (grievanceId || grievanceParamId) {
      grievanceActivityService()
        .getGrievanceActivities(grievanceId || grievanceParamId, limit, skip, filterName, columnSort, descending)
        .then(res => {
          if (res && res.data) {
            let index = res.data.findIndex(value => value.stage === "Mediation")
            if (index >= 0) {
              res.data[index].stage = "Mediation Filed"
            }
            setGrievanceActivities(res.data);
            setPagination({
              ...pagination,
              count: parseInt(res.headers['x-total-count'], 10),
              limit: parseInt(res.headers['x-total-limit'], 10),
              skip: parseInt(res.headers['x-total-skip'], 10),
              columnSort,
              descending
            })
          }
        })
    }
  };

  useEffect(() => {
    getGrievanceActivities()
    // eslint-disable-next-line
  }, []);

  const addGrievanceActivity = (values, cb) => {
    grievanceActivityService()
      .addGrievanceActivity({grievanceModelId: grievanceId || grievanceParamId, ...values})
      .then(() => {
        getGrievanceActivities();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const updateGrievanceActivity = (values, cb) => {
    grievanceActivityService()
      .updateGrievanceActivity({grievanceModelId: grievanceId || grievanceParamId, ...values})
      .then(() => {
        getGrievanceActivities();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const onSubmit = (values, cb) => {
    if (values.id) {
      updateGrievanceActivity(values, cb)
    } else {
      addGrievanceActivity(values, cb)
    }
  };

  return {
    grievanceActivities,
    paginationGrievanceActivities: pagination,
    grievanceId: grievanceParamId,
    getGrievanceActivities,
    addGrievanceActivity,
    onSubmit
  }
};
