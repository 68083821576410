import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "friend/";
export default () => ({
    getFriend: (id) => loading(client.request({
      method: 'GET',
      url: base + 'GetFriend/' + id
    })),
    getFriends: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, showInactive = 0
      , member = 1, nonMember = 1, categoryId = 0, addressType = 0) => loading(client.request({
      method: 'GET',
      url: base + 'GetFriends?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending='
        + descending + '&showInactive=' + showInactive + '&member=' + member + '&nonMember=' + nonMember + '&categoryId=' + categoryId + '&addressType=' + addressType
    })),
    getFriendCategories: (friendId) => client.request({
      method: 'GET',
      url: base + 'GetFriendCategoriesAll?friendId=' + friendId
    }),
    addFriend: (params) => client.request({
      method: 'POST',
      url: base + 'AddFriend',
      data: params
    }),
    addFriendCategory: (friendId, categoryId) => client.request({
      method: 'POST',
      url: base + 'AddFriendCategory?friendId=' + friendId + "&categoryId=" + categoryId
    }),
    updateFriend: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateFriend/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeStateFriend/' + params.id + "?isActive=" + params.isActive,
      data: params
    }),
    deleteFriend: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteFriend/' + id
    }),
    deleteFriendCategory: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteFriendCategory/' + id
    }),
    exportFriends: (filterName = "", columnSort = "", descending = true, showInactive = 0
      , member = 1, nonMember = 1, categoryId = 0, addressType = 0, fullReport = true) => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'ExportFriends?&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&showInactive=' + showInactive +
        '&member=' + member + '&nonMember=' + nonMember + '&categoryId=' + categoryId + '&addressType=' + addressType + '&fullReport=' + fullReport
    }),
  }
);
