import React, { useEffect }                             from 'react';
import { Container, Table }                             from 'reactstrap';
import { BonusSeniorityTab, ColumnSort, MyPagination, } from "../index";
import { useSeniorityList }                             from "../../hooks";
import { seniorityService }                             from "../../services";
import { fetchingFn, setAlertNotification }             from "../../actions/util";
import ImportFile                                       from './ImportFile';
import SearchBonusSeniority                             from './SearchBonusSeniority';
import Footer                                           from "./Footer";
import { isAllow, userActionsKeys }                     from "../../util/userActions";


let fileNoDef = "";
let lastNameDef = "";

const SeniorityList = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const {seniority, pagination, getSeniorityList, deleteSeniority} = useSeniorityList();


  const items = seniority && seniority.map((seniorityItem, i) =>
    <tr key={seniorityItem.id}>
      <th>{seniorityItem.license}</th>
      <th>{seniorityItem.seniority}</th>
      <th>{seniorityItem.firstName}</th>
      <th>{seniorityItem.middle}</th>
      <th>{seniorityItem.lastName}</th>
      <th>{seniorityItem.pay}</th>
      <th>{seniorityItem.hrsStat}</th>
      <th>{seniorityItem.asignCode}</th>
    </tr>
  )

  const sortFn = (object) => {
    getSeniorityList(pagination.limit, 0, object.columnSort, object.descending)

  };

  return (
    <Container fluid>
      <BonusSeniorityTab from="Seniority"/>
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">Seniority</h3>
        <ImportFile
          actionImport={isAllow(userActionsKeys.importSeniorityLegal)}
          onChange={(file) => {
            seniorityService().importSeniority(file).then(() => {
              setAlertNotification("File upload", 'info');
              fetchingFn();
              getSeniorityList()
            })
              .catch(() => {
                setAlertNotification("Something got wrong uploading the file", 'danger');
                fetchingFn();
              });
          }}
          actionDelete={isAllow(userActionsKeys.deleteSeniorityLegal)}
          deleteAll={() => {deleteSeniority()}}/>

      </div>
      <label>Input your File # and your Last Name</label>
      <div className="flex-grow-1">
        <SearchBonusSeniority defaultValue={""} onSearch={(fileNo, lastName) => {
          fileNoDef = fileNo;
          lastNameDef = lastName;
          getSeniorityList(pagination.limit, 0, pagination.columnSort, pagination.descending, fileNoDef, lastNameDef)
        }}/>
      </div>

      <Table hover responsive className="table-responsive-vertical mb-5">
        <thead>
        <tr>
          <th style={{minWidth: "91px"}}>License <ColumnSort columnName="License" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "97px"}}>Seniority <ColumnSort columnName="Seniority" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "105px"}}>Firstname <ColumnSort columnName="Firstname" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "85px"}}>Middle <ColumnSort columnName="Middle" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "104px"}}>Lastname <ColumnSort columnName="Lastname" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "65px"}}>Pay <ColumnSort columnName="Pay" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "100px"}}>HRS Stat <ColumnSort columnName="HRSStat" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "112px"}}>Asgn Code <ColumnSort columnName="Asgn Code" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {seniority.length ?
          items :
          <tr>
            <th colSpan="8" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getSeniorityList()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>
      {seniority.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getSeniorityList(limit, skip, pagination.columnSort, pagination.descending)
      }}/>}

      <Footer/>
    </Container>
  );
};

export default SeniorityList;
