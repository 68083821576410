import React                       from 'react';
import { phoneFormat }             from "../../actions/util";
import { useHistory, useLocation } from "react-router-dom";
import { appPaths }                from "../../routes";
import { faArrowRight }            from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }         from "@fortawesome/react-fontawesome";
import { MyLink }                  from "../index";

const SiteItem = ({site, selectSite, isUnionInitiatedAllow}) => {

  const history = useHistory();

  const location = useLocation();
  let param = location.search;
  const siteId = new URLSearchParams(param).get("site");
  let cursorPointer = selectSite ? "cursor-pointer" : "";
  // eslint-disable-next-line
  if (siteId == site.id) {
    cursorPointer += " row-selected"
  }

  return (
    <tr className={cursorPointer} onClick={() => {
      if (selectSite) {
        selectSite(site);
        history.push(location.pathname + "?site=" + site.id + (isUnionInitiatedAllow ? "&allow-union-initiated=true" : ""))
      }
    }}>
      <th scope="row">{site.sitecode}</th>
      <th>
        <div><MyLink onlyChildren={!!selectSite} to={"/" + appPaths.SiteDetail + "?site=" + site.id} className="no-a" onClick={e => {e.stopPropagation()}}>{site.sitename}{!site.sitename && <span>Detail</span>}</MyLink></div>
        <div>
          <small className="text-gray">District: {site.district}</small>
        </div>
      </th>
      <th>
        <div>{site.address1}</div>
        <div>{site.city ? site.city + (site.state || site.zip ? ", " : "") : ""}{site.state ? site.state + (site.zip ? ", " : "") : ""}{site.zip}</div>
      </th>
      {!selectSite && <th>
        <div>
          <small className="text-gray">phone: </small>
          {site.telephon ? phoneFormat(site.areacode + site.telephon) : ""}</div>
        <div>
          <small className="text-gray" style={{marginRight: "17px"}}>fax: </small>
          {site.faxNo ? phoneFormat(site.fareacode + site.faxNo) : ""}
        </div>
      </th>}
      {selectSite ?
        <th>
          <FontAwesomeIcon className="cursor-pointer" icon={faArrowRight}/>
        </th> :
        <th>
          {site.membersCount || ""} {site.membersCount > 1 ? "members" : site.membersCount === 1 ? "member" : ""}
        </th>}

    </tr>
  );
};

export default SiteItem;
