import React                      from 'react'
import { dateFormatMonthDayYear } from "../../actions/util";
import { MyPagination }           from "../index";
import Row                        from "reactstrap/es/Row";
import { useAllName }               from "../../hooks";
import { isAllow, userActionsKeys } from "../../util/userActions";

const InvestigationMeetingList = ({investigationMeetings, pagination, isInvestigationOpen, onPaginationAction, setInvestigationMeeting}) => {

  const {getName} = useAllName();

  return (
    <>
      {investigationMeetings.length > 0 ? investigationMeetings.map((value, index) => (
        <div key={index + pagination.skip} className="col-12" style={{marginBottom: "10px", borderBottom: "1px dotted", paddingBottom: "10px"}}>
          <Row>
            <div className="col-12 col-sm-3 pl-md-0 pl-lg-3">
              <span className={isInvestigationOpen && isAllow(userActionsKeys.updateInvestigationMeetingLegal) ? "simulate-a" : ""} onClick={() => {
                if (isInvestigationOpen && isAllow(userActionsKeys.updateInvestigationMeetingLegal)) {
                  setInvestigationMeeting(value);
                }
              }}>{dateFormatMonthDayYear(value.meetingDate, value.investigationId)}</span>
            </div>
            <div className="col-12 col-sm-9">
              <div>{value.comment && <span dangerouslySetInnerHTML={{__html: value.comment.replace(/\n/g, "<br/>")}}/>}</div>
              <div>
                <small style={{color: "#999"}}>{getName(value.userName)} on {dateFormatMonthDayYear(value.updateDate)}</small>
              </div>
            </div>
          </Row>
        </div>
      )) : <div className="col-12 mb-3 text-center">No content data</div>}

      {investigationMeetings.length > 0 && pagination.count > 10 &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
          onPaginationAction(limit, skip)
        }}/>
      </div>}
    </>
  )
};

export default InvestigationMeetingList;