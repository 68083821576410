import React, { useEffect }                            from 'react';
import { connect }                                     from 'react-redux';
import { Table }                                                          from 'reactstrap';
import { ColumnSort, MemberContactItem, MyPagination, Search, VisitItem } from "../index";
import { getVisitsAction }                                                from "../../actions/visit";
import { myDispatch }                                  from "../../actions/util";
import * as types                                      from "../../types";
import { useDefaultLimit }                             from "../../hooks";

let search = "";

const VisitList = ({visits, isFetching, paginationVisit, changeSortAction, getVisitsAction}) => {

  const {defaultLimit} = useDefaultLimit()
  useEffect(() => {
    window.scrollTo(0, 0);
    getVisitsMembers()
    // eslint-disable-next-line
  }, []);

  let items = [];

  const getVisitsMembers = () => { getVisitsAction(defaultLimit, paginationVisit.skip, paginationVisit.filterName, paginationVisit.columnSort || "VisitDate", true)}

  if (visits.length) {
    for (let i in visits) {
      if (visits[i].tableName === "Visit") {
        items.push(<VisitItem key={"visit-item" + visits[i].tableName + visits[i].id} visit={visits[i]?.visit}/>);
      } else {
        items.push(<MemberContactItem key={"memberContact-item" + visits[i].tableName + visits[i].id} memberContact={visits[i]?.memberContact} getVisitMembers={getVisitsMembers}/>);
      }
    }
  }

  const sortFn = (object) => {
    if (!isFetching) {
      changeSortAction({...paginationVisit, ...object});
      getVisitsAction(defaultLimit, paginationVisit.skip, search, object.columnSort, object.descending)
    }
  };

  return (
    <div className="member-list">
      <Search defaultValue={paginationVisit.filterName} onSearch={(value) => {
        search = value;
        getVisitsAction(defaultLimit, 0, value, paginationVisit.columnSort, paginationVisit.descending)
      }}>Visit List</Search>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "90px"}}>Visit Date <ColumnSort columnName="VisitDate" columnSort={paginationVisit.columnSort} descending={paginationVisit.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Site <ColumnSort columnName="Site" columnSort={paginationVisit.columnSort} descending={paginationVisit.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Visit Reason <ColumnSort columnName="VisitReason" columnSort={paginationVisit.columnSort} descending={paginationVisit.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "145px"}}>Field Director <ColumnSort columnName="UserName" columnSort={paginationVisit.columnSort} descending={paginationVisit.descending} changeSortAction={sortFn}/></th>
          <th className="text-center" style={{minWidth: "104px"}}>State <ColumnSort columnName="State" columnSort={paginationVisit.columnSort} descending={paginationVisit.descending} changeSortAction={sortFn}/></th>

        </tr>
        </thead>
        <tbody>
        {visits.length ?
          items :
          <tr>
            <th colSpan="5" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getVisitsAction()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {visits.length > 0 && paginationVisit.count > 10 &&
      <MyPagination count={paginationVisit.count} limit={paginationVisit.limit} skip={paginationVisit.skip} action={(skip, limit) => {
        getVisitsAction(limit, skip, search, paginationVisit.columnSort, paginationVisit.descending)
      }}/>}

    </div>
  );
};


const mapStateToProps = ({visits, isFetching, paginationVisit}) => ({
  visits,
  isFetching,
  paginationVisit
});

const mapDispatchProps = dispatch => {
  return {
    getVisitsAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_VISIT_PAGINATION, {data}));
    }
  }
};

export default connect(mapStateToProps, mapDispatchProps)(VisitList);
