import { useEffect, useState }  from 'react'
import { memberContactService } from "../services";
import { useLocation }          from "react-router";
import { useDefaultLimit }      from "./WindowUtil";

export const useMemberVisitContactList = (props) => {

  const {id} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const memberParamId = param.get("member");

  const {defaultLimit} = useDefaultLimit()
  const [contactVisitMemberList, setContactVisitMemberList] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, descending: true});

  const getContactVisitMemberList = (limit = pagination.limit, skip = pagination.skip, descending = pagination.descending) => {
    memberContactService()
      .getContactVisitMemberList(id || memberParamId, limit, skip, descending)
      .then(res => {
        if (res && res.data) {
          setContactVisitMemberList(res.data);
          const friendPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            descending,
          }
          setPagination(friendPaginateList)
          sessionStorage.setItem("contactVisitMemberList", JSON.stringify(friendPaginateList))
        }
      })
  };

  useEffect(() => {
    let contactVisitMemberList = sessionStorage.getItem("contactVisitMemberList")
    if (contactVisitMemberList) {
      contactVisitMemberList = JSON.parse(contactVisitMemberList)
      getContactVisitMemberList(defaultLimit || contactVisitMemberList.limit, contactVisitMemberList.skip, contactVisitMemberList.descending)
    } else {
      getContactVisitMemberList()
    }
    // eslint-disable-next-line
  }, []);

  return {contactVisitMemberList, pagination, getContactVisitMemberList}
};
