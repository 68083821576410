import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "siteSummary/";
export default () => ({
    getSiteSummary: (id) => client.request({
      method: 'GET',
      url: base + 'getSiteSummary/' + id
    }),
    getSiteSummaries: (visitId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetSiteSummaries/' + visitId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addSiteSummary: (params) => client.request({
      method: 'POST',
      url: base + 'addSiteSummary',
      data: params
    }),
    updateSiteSummary: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateSiteSummary/' + params.id,
      data: params
    }),
    deleteSiteSummary: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteSiteSummary/' + params.id
    })
  }
);
