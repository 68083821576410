import React                                                                                           from 'react';
import { Button, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import userActions, { userActionsKeys, userActionsTree }                                               from "../../util/userActions";
import { MyTooltip }                                     from "../index";
import editIcon                                          from "../../resources/SVG/icons/table/ic_24px_tablefile_edit.svg";
import { userService }                                   from "../../services";

const UserItem = ({user, index, setUser, skip, getUsersAction}) => (
  <tr style={{background: user.disabled ? "#f8f8f8" : ""}}>
    <th scope="row">{index + skip + 1}</th>
    <th>{user.name}</th>
    <th>{user.username}</th>
    <th>{user.email}</th>

    {/*<th>
      {user && user.userAction && getActions(user.userAction)}
    </th>*/}

    <th className="text-center">
      <CustomInput
        type="checkbox"
        className="float-none"
        name={"user" + index + skip + 1}
        id={"user" + index + skip + 1}
        checked={user.isAdvocate}
        onClick={(e) => {e.stopPropagation()}}
        onChange={() => {
          userService().setAdvocate(user.id, !user.isAdvocate)
            .then(res => {
              getUsersAction()
            })
        }}/>
    </th>

    <th className="text-center">
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color={!user.disabled ? "danger" : "secondary"}>
          {!user.disabled ? "Disable" : "Enable"}
        </DropdownToggle>
        <DropdownMenu style={{minWidth: "8rem"}}>
          <DropdownItem onClick={() => {
            userService().changeState(user.id, !user.disabled)
              .then(res => {
                getUsersAction()
              })
          }}>Confirm</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </th>

    <th className="action-1-btn">
      <Button size="sm" color="secondary" id={"tooltip-user-edit" + index} onClick={() => {
        setUser(user)
      }}><img src={editIcon} alt="edit-icon" width={24} height={24}/></Button>
      <MyTooltip placement="top" target={"tooltip-user-edit" + index}>
        Edit
      </MyTooltip>
    </th>
  </tr>
);

// eslint-disable-next-line
const getActions = (actions) => {
  let spans = [], count = actions.length;
  for (let i in userActionsKeys) {
    for (let j in actions) {
      if (userActionsKeys[i] === actions[j].name && actions[j].allow) {
        count--;
        spans.push(
          <span key={actions[j].name + j} style={{fontSize: "11px", fontWeight: (userActionsTree[actions[j].name] ? "bold" : "normal")}}>
            {userActions[actions[j].name] + "" + (count > 0 ? ', ' : '')}
          </span>
        )
      }
    }
  }
  return spans;
};

export default UserItem;
