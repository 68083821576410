import { combineReducers } from 'redux';
import { general }         from './general';
import { member }          from './member';
import { site }            from './site';
import { visit }           from './visit';


export const reducers = {
  ...general,
  ...member,
  ...site,
  ...visit,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
