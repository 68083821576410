import { useEffect, useState } from 'react'
import { seniorityService }    from "../services";
import { useDefaultLimit }     from "./WindowUtil";

export const useSeniorityList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [seniority, setSeniority] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, columnSort: "", descending: true,fileNo: "", lastName: ""});

  const getSeniorityList = (limit = pagination.limit, skip = pagination.skip, columnSort = pagination.columnSort, descending = pagination.descending, fileNo = pagination.fileNo, lastName = pagination.lastName) => {
    seniorityService()
      .getSeniorityList(limit, skip, columnSort, descending, fileNo, lastName)
      .then(res => {
        if (res && res.data) {
          setSeniority(res.data);
          const seniorityPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            fileNo,
            lastName,
          }
          setPagination(seniorityPaginateList)
          sessionStorage.setItem("seniorityPaginateList", JSON.stringify(seniorityPaginateList))
        }
      })
  };

  const deleteSeniority = () => {
    seniorityService()
      .deleteSeniority()
      .then(res => {
        setSeniority([])
      })
  };

  useEffect(() => {
    let seniorityPaginateList = sessionStorage.getItem("seniorityPaginateList")
    if (seniorityPaginateList) {
      seniorityPaginateList = JSON.parse(seniorityPaginateList)
      getSeniorityList(defaultLimit || seniorityPaginateList.limit, seniorityPaginateList.skip, seniorityPaginateList.columnSort, seniorityPaginateList.descending)
    } else {
      getSeniorityList()
    }
    // eslint-disable-next-line
  }, []);

  return {seniority, pagination, getSeniorityList, deleteSeniority}
};




