import React, { useState }                                                                                                                 from 'react';
import { Link, Route, Switch }                                                                                                             from "react-router-dom";
import { useIdleTimer }                                                                                                                    from 'react-idle-timer';
import routes, { appPaths }                                                                                                                from './routes';
import logo                                                                                                                                from './resources/SVG/ic_75px_General_Logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, Row, UncontrolledDropdown } from 'reactstrap';
import { connect }                                                                                                                         from "react-redux";
import { myDispatch, registerDispatch }                                                                                                    from "./actions/util";
import * as types                                                                                                                          from "./types";
import { AlertNotification, CheckIt, LoginModal, SuperUser, UnionDuesAuthorizationCard }                                                   from "./components/index";
import { logoutAction }                                                                                                                    from "./actions/general";
import { isAllow, userActionsKeys }                                                                                                        from "./util/userActions";
import { useIsOnWidth }                                                                                                                    from "./hooks";

function App({alertNotification, location, history, isFetching, showAuthModal, dismissAlertAction}) {

  const [isOpen, setIsOpen] = useState(false);
  const isOnWidth = useIsOnWidth(767);

  const toggle = () => {
    if (isOnWidth) {
      setIsOpen(!isOpen);
    } else if (isOpen) {
      setIsOpen(!isOpen);
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: () => {
      if (!localStorage.getItem("Testing")) {
        logoutAction()
      }
    },
    debounce: 500
  });

  const pathname = location.pathname && location.pathname.split("?") && location.pathname.split("?")[0] ? location.pathname.split("?")[0].replace("/", "") : location.pathname;

  if (pathname === appPaths.UnionDuesAuthorizationCard && isAllow(userActionsKeys.signAuthorizationCardLegal)) {
    return (
      <>
        <UnionDuesAuthorizationCard history={history}/>
        {showAuthModal && <LoginModal/>}

        <div className="alert-notification">
          {alertNotification.map((alert) => (
            <AlertNotification color={alert.color} key={alert.id} id={alert.id} message={alert.message} dismissAlertAction={dismissAlertAction}/>
          ))}
        </div>
      </>
    )
  }

  const isTabActive = (name) => pathname === name ? "active" : "";

  let userName = sessionStorage.getItem('usernameLFS');
  if (userName) {
    userName = userName.toLowerCase()
  }

  let navLinkStyle = {}, navItemStyle = {}
  if (isOpen) {
    navLinkStyle = {
      zIndex: 99,
      height: "auto",
      padding: "8px",
      width: "100%"
    }
    navItemStyle = {
      zIndex: 99,
      width: "100%",
      background: "#004751"
    }
  }

  return (
    <div className="App">
      <Container fluid>
        <header className="App-header">
          <Row className="App-header">

            <Navbar color="faded" dark expand="md" style={{width: "100%", paddingTop: 0, paddingBottom: 0}}>
              <Link to={"/"} className="no-a navbar-brand"><img src={logo} className="App-logo" alt="logo"/></Link>
              <NavbarToggler onClick={toggle}/>
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="list-on-top">
                  <CheckIt action={userActionsKeys.memberListLegal}>
                    <NavItem className={isTabActive(appPaths.MemberList) || isTabActive("")} style={navItemStyle}>
                      <Link to={"/" + appPaths.MemberList} className="nav-link" style={navLinkStyle} onClick={toggle}>Member</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.siteListLegal}>
                    <NavItem className={isTabActive(appPaths.SiteList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.SiteList} className="nav-link" style={navLinkStyle} onClick={toggle}>Site</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.visitListLegal}>
                    <NavItem className={isTabActive(appPaths.VisitList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.VisitList} className="nav-link" style={navLinkStyle} onClick={toggle}>Visit</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.investigationListLegal}>
                    <NavItem className={isTabActive(appPaths.InvestigationList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.InvestigationList} className="nav-link" style={navLinkStyle} onClick={toggle}>Investigation</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.grievanceListLegal}>
                    <NavItem className={isTabActive(appPaths.GrievanceList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.GrievanceList} className="nav-link" style={navLinkStyle} onClick={toggle}>Grievance</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.ratingListLegal}>
                    <NavItem className={isTabActive(appPaths.RatingList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.RatingList} className="nav-link" style={navLinkStyle} onClick={toggle}>Rating</Link>
                    </NavItem>
                  </CheckIt>

                  <CheckIt action={userActionsKeys.reportViewLegal}>
                    <NavItem className={isTabActive(appPaths.Reports)} style={navItemStyle}>
                      <Link to={"/" + appPaths.Reports} className="nav-link" style={navLinkStyle} onClick={toggle}>Reports</Link>
                    </NavItem>
                  </CheckIt>

                  {(isAllow(userActionsKeys.friendListLegal) || isAllow(userActionsKeys.categoryListLegal)) &&
                  <NavItem className={isTabActive(appPaths.FriendList) || isTabActive(appPaths.CategoryList) || isTabActive(appPaths.FriendDetail) || isTabActive(appPaths.CategoryForm) || isTabActive(appPaths.NewFriend)}
                           style={navItemStyle}>
                    <Link to={isAllow(userActionsKeys.friendListLegal) ? "/" + appPaths.FriendList : isAllow(userActionsKeys.categoryListLegal) ? "/" + appPaths.CategoryList : "#"} className="nav-link" style={navLinkStyle} onClick={toggle}>Friends of
                      CSA</Link>
                  </NavItem>
                  }

                  {(isAllow(userActionsKeys.bonusListLegal) || isAllow(userActionsKeys.seniorityListLegal)) &&
                  <NavItem className={isTabActive(appPaths.BonusList) || isTabActive(appPaths.SeniorityList)} style={navItemStyle}>
                    <Link to={"/" + appPaths.BonusList} className="nav-link" style={navLinkStyle} onClick={toggle}>Bonus & Seniority</Link>
                  </NavItem>}

                  <SuperUser>
                    <NavItem className={isTabActive(appPaths.UserList)} style={navItemStyle}>
                      <Link to={"/" + appPaths.UserList} className="nav-link" style={navLinkStyle} onClick={toggle}>Users</Link>
                    </NavItem>
                  </SuperUser>

                  {/*<NavItem className={isTabActive(paths.MemberDetail)}>
                   <Link to={"/" + paths.MemberList} className="nav-link">Member Data</Link>
                   </NavItem>*/}

                  {userName ?
                    <UncontrolledDropdown className="nav-item capitalize" style={{position: !isOpen ? "absolute" : "", right: isOpen ? "0" : "30px", height: "auto", top: 0, ...navLinkStyle, ...navItemStyle}}>
                      <DropdownToggle nav caret style={{paddingTop: isOpen ? 0 : "", height: isOpen ? "auto" : ""}}>
                        Hello {userName}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => {
                          sessionStorage.clear();
                          window.location.reload();
                        }}>
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    : ""
                  }
                </Nav>
              </Collapse>
            </Navbar>
          </Row>
        </header>

        <div className="alert-notification">
          {alertNotification.map((alert) => (
            <AlertNotification color={alert.color} key={alert.id} id={alert.id} message={alert.message} dismissAlertAction={dismissAlertAction}/>
          ))}
        </div>

        <div className="fetching">
          {isFetching &&
          <div>
            <div className="sk-fade-in sk-spinner sk-chasing-dots">
              <div/>
              <div/>
            </div>
          </div>}
        </div>

        {showAuthModal || !(userName && sessionStorage.getItem('tokenAuthLFS')) ? <LoginModal/> :
          <Row className="transition-all">

            <div className={"right-container "}>

              {userName && !sessionStorage.getItem('actionsLFS') && !(sessionStorage.getItem('rolesLFS') && sessionStorage.getItem('rolesLFS').match("Admin")) &&
              <h4 className="text-warning container text-center">Hello {userName}. Contact with your Admin. You don't have any permissions in this App</h4>}

              <Switch>{routes.map((route, index) =>
                <Route key={index} path={"/" + route.path} exact={route.exact} render={props =>
                  isAllow(route.action, route.roles) ?
                    React.createElement(route.component, {...props})
                    : (props.match.url !== "/" ? props.history.goBack() : "")
                }/>
              )}
              </Switch>
            </div>

          </Row>}

      </Container>

      <div style={{height: "30px"}}/>

    </div>
  );
}


const mapStateToProps = ({alertNotification, isFetching, showAuthModal}) => ({
  alertNotification,
  isFetching,
  showAuthModal,
});


const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    dismissAlertAction: (id) => {
      dispatch(myDispatch(types.DISMISS_ALERT_NOTIFICATION, {id}))
    },
  }
};


export default connect(mapStateToProps, mapDispatchProps)(App);
