import React, { useEffect }                                             from 'react';
import { Button, Container, Table }                                     from 'reactstrap';
import { CheckIt, ColumnSort, FriendTab, MyLink, MyPagination, Search } from "../index";
import { useCategoryList }                                              from "../../hooks";
import { useHistory }                                                   from "react-router";
import { appPaths }                                                     from "../../routes";
import { userActionsKeys }                                              from "../../util/userActions";
import { saveAs }                                                       from "file-saver";
import { FontAwesomeIcon }                                              from "@fortawesome/react-fontawesome";
import { faFileExcel}                                                   from "@fortawesome/free-solid-svg-icons";
import { categoryService }                                              from "../../services";
import { getTodayStringValue }                                          from "../../actions/util";

let search = "";

const CategoryList = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const history = useHistory();
  const {categories, pagination, getCategories} = useCategoryList();

  const items = categories && categories.map((category, i) =>
    <tr key={category.id}>
      <th scope="row">
        <MyLink to={"/" + appPaths.CategoryForm + "?category=" + category.id} className="no-a">
          {category.description || <span>Detail</span>}
        </MyLink>
      </th>
      <th>{category.responsible}</th>
      <th>{category.friendCount}</th>
    </tr>
  )

  const sortFn = (object) => {
    getCategories(pagination.limit, pagination.skip, search, object.columnSort, object.descending)
  };

  return (
    <Container fluid>
      <FriendTab from="Category"/>
      <div className="d-flex justify-content-between">
        <h3>Categories</h3>
        <div className="flex-grow-1">
          <CheckIt action={userActionsKeys.addCategoryLegal}>
            <div className="float-right ml-3 mb-1">
              <Button onClick={() => {history.push(appPaths.CategoryForm)}}>New Category</Button>
            </div>
          </CheckIt>
          <Search defaultValue={pagination.filterName} onSearch={(value) => {
            search = value;
            getCategories(pagination.limit, 0, value, pagination.columnSort, pagination.descending)
          }}/>
        </div>
      </div>
      <CheckIt action={userActionsKeys.exportCategoriesLegal}>
      <div className="cursor-pointer text-primary mb-1" onClick={() => {
        categoryService()
          .exportCategories(search, pagination.columnSort, pagination.descending)
          .then(res => {
            if (res.data) {
              saveAs(res.data, "Categories" + getTodayStringValue() + ".xlsx");
            }
          })
          
      }}>
        <FontAwesomeIcon icon={faFileExcel} color="green"/> Export to Excel
      </div>
      </CheckIt>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>Description <ColumnSort columnName="Description" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Responsible <ColumnSort columnName="Responsible" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>Friends</th>
        </tr>
        </thead>
        <tbody>
        {categories.length ?
          items :
          <tr>
            <th colSpan="3" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getCategories()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {categories.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getCategories(limit, skip, search, pagination.columnSort, pagination.descending)
      }}/>}

    </Container>
  );
};

export default CategoryList;
