import React, { useState } from 'react'
import { MyDatePicker }    from "../index";
import { Button }          from "reactstrap";
import { useForm }         from "react-hook-form";

const CloseRating = ({changeRatingState}) => {

  const {register, handleSubmit} = useForm();

  const [closedOn, setClosedOn] = useState(new Date());
  return (
    <form className="row" onSubmit={handleSubmit(values => {
      changeRatingState({...values, closedOn})
    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Closed Date</div>
      <div className="col-7 mt-2 mb-2">
        <MyDatePicker
          dateFormat="MM/dd/yyyy"
          selected={closedOn}
          onChange={(value) => {
            setClosedOn(value);
          }}/>
      </div>
      <div className="col-5 border-bottom-gray toast-title-item">Outcome</div>
      <div className="col-7 mt-2 mb-2">
        <select className="form-control" name="outcome" ref={register}>
          <option value="">-</option>
          {["Abandoned", "Denied", "No Appeal on File with OAR", "Withdrawn", "Waiver", "Retired", "RatingChanged"]
            .map(((value, index) => (
              <option key={index} value={value}>{value}</option>
            )))}
        </select>
      </div>
      <div className="col-12 mt-4 mb-2"><Button>Close Rating</Button></div>
    </form>
  )
};

export default CloseRating