import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "friendAddress/";
export default () => ({
    getFriendAddress: (id) => client.request({
      method: 'GET',
      url: base + 'getFriendAddress/' + id
    }),
    getFriendAddresses: (friendId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetFriendAddresses/' + friendId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addFriendAddress: (params) => client.request({
      method: 'POST',
      url: base + 'addFriendAddress',
      data: params
    }),
    updateFriendAddress: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateFriendAddress/' + params.id,
      data: params
    }),
    deleteFriendAddress: (id) => client.request({
      method: 'DELETE',
      url: base + 'deleteFriendAddress/' + id
    }),
    getAllFriendAddresses: () => client.request({
      method: 'GET',
      url: base + 'GetAllFriendAddress'
    }),
  }
);
