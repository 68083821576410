import React, { useEffect } from 'react';
import { Table }            from 'reactstrap';
import { ColumnSort, MyPagination, Search, SiteItem } from "../index";
import { useSiteList }                                from "../../hooks";

let search = "";

const SiteList = ({selectSite, isUnionInitiatedAllow}) => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {sites, getSites, pagination: paginationSite} = useSiteList();

  let items = [];

  if (sites.length) {
    for (let i in sites) {
      if (sites.hasOwnProperty(i)) {
        items.push(<SiteItem key={"site-item" + i} site={sites[i]} index={i} selectSite={selectSite} isUnionInitiatedAllow={isUnionInitiatedAllow}/>);
      }
    }
  }

  const sortFn = (object) => {
    getSites(paginationSite.limit, paginationSite.skip, search, object.columnSort, object.descending)
  };

  return (
    <div className="member-list">
      <Search defaultValue={paginationSite.filterName} onSearch={(value) => {
        search = value;
        getSites(paginationSite.limit, 0, value, paginationSite.columnSort, paginationSite.descending)
      }}>Site List</Search>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "104px"}}>Site Code <ColumnSort columnName="Sitecode" columnSort={paginationSite.columnSort} descending={paginationSite.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Site <ColumnSort columnName="Sitename" columnSort={paginationSite.columnSort} descending={paginationSite.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "94px"}}>Address <ColumnSort columnName="Address" columnSort={paginationSite.columnSort} descending={paginationSite.descending} changeSortAction={sortFn}/></th>
          {!selectSite && <th style={{minWidth: "145px"}}>Contacts <ColumnSort columnName="Contacts" columnSort={paginationSite.columnSort} descending={paginationSite.descending} changeSortAction={sortFn}/></th>}
          {!selectSite && <th style={{minWidth: "104px"}}>Members</th>}
          {selectSite && <th/>}

        </tr>
        </thead>
        <tbody>
        {sites.length ?
          items :
          <tr>
            <th colSpan="5" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getSites()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {sites.length > 0 && paginationSite.count > 10 &&
      <MyPagination count={paginationSite.count} limit={paginationSite.limit} skip={paginationSite.skip} action={(skip, limit) => {
        getSites(limit, skip, search, paginationSite.columnSort, paginationSite.descending)
      }}/>}

    </div>
  );
};


export default SiteList;
