import React, { useEffect, useState } from 'react'
import { MyDatePicker }               from "../index";
import { Button }                     from "reactstrap";
import { useForm }                    from "react-hook-form";
import { dateFromISOToLocal }         from "../../actions/util";

const InvestigationMeetingForm = ({defaultValues, onSubmit, onCancel}) => {

  const {register, handleSubmit, reset} = useForm();

  const [meetingDate, setMeetingDate] = useState(new Date());

  useEffect(() => {
    if (defaultValues) {
      reset({comment: defaultValues?.comment});
      setMeetingDate(dateFromISOToLocal(defaultValues.meetingDate, defaultValues?.investigation?.investigationId))
    } else {
      reset({});
      setMeetingDate(new Date())
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleSubmit(values => {
      if (meetingDate) {
        onSubmit({...defaultValues, ...values, meetingDate})
      }
    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Meeting Date</div>
      <div className="col-6 mt-2 mb-2">
        <MyDatePicker
          dateFormat="MM/dd/yyyy"
          selected={meetingDate}
          onChange={(value) => {
            setMeetingDate(value);
          }}/>
      </div>
      <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
      <div className="col-5 border-bottom-gray toast-title-item">Comment</div>
      <div className="col-7 mt-2 mb-2">
        <textarea className="form-control" name="comment" ref={register}/>
      </div>
      <div className="col-12 mt-4 mb-2">
        <Button disabled={!meetingDate}>Save</Button>
        {" "} or {" "}
        <Button outline onClick={() => {
          onCancel()
        }}>Cancel</Button>
      </div>
    </form>
  )
};

export default InvestigationMeetingForm