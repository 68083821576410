import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "ratingWaiver/";
export default () => ({
    getWaive: (id) => loading(client.request({
      method: 'GET',
      url: base + 'getWaive/' + id
    })),
    getWaivesUsers: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetWaivesUsers?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAllByRating: (ratingId, filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'getAllByRating/' + ratingId + '?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getWaives: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getWaives?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getWaivesByRating: (ratingId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getWaives/' + ratingId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addWaive: (params) => client.request({
      method: 'POST',
      url: base + 'addWaive',
      data: params
    }),
    updateWaive: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateWaive/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeState/' + params.id + "?isOpen=" + params.isOpen,
    }),
    deleteWaive: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteWaive/' + params.id
    })
  }
);
