import {
  GrievanceDetail,
  GrievanceList,
  InvestigationDetail,
  InvestigationList,
  MemberDetail,
  MemberList,
  RatingDetail,
  RatingList,
  SelectMember,
  SiteDetail,
  SiteList,
  UnionDuesAuthorizationCard,
  VisitDetail,
  VisitForm,
  VisitList,
  Reports, UserList,
  NewAdvocate, CategoryForm, CategoryList, NewFriend, FriendDetail, FriendList,
  SeniorityList, BonusList
} from './components/index';

import {userActionsKeys}  from './util/userActions'

export const appPaths = {
  MemberList: "member-list",
  MemberDetail: "member-detail",
  SiteList: "site-list",
  SiteDetail: "site-detail",
  UnionDuesAuthorizationCard: "union-dues-authorization-card",
  VisitForm: "visit-form",
  VisitList: "visit-list",
  VisitDetail: "visit-detail",
  SiteAndMemberNewInvestigation: "site-member-new-investigation",
  InvestigationDetail: "investigation-detail",
  InvestigationList: "investigation-list",
  SiteAndMemberNewGrievance: "site-member-new-grievance",
  GrievanceDetail: "grievance-detail",
  GrievanceList: "grievance-list",
  SiteAndMemberNewRating: "site-member-new-rating",
  RatingDetail: "rating-detail",
  RatingList: "rating-list",
  Reports: "reports",
  UserList: 'user-list',
  NewAdvocate: 'new-advocate',
  CategoryForm: 'category-form',
  CategoryList: 'category-list',
  FriendDetail: 'friend-detail',
  NewFriend: 'new-friend',
  FriendList: 'friend-list',
  BonusList: 'bonus-list',
  SeniorityList:'seniority-list'

};

const routes = [
  {
    path: '',
    component: MemberList,
    exact: true,
    action: userActionsKeys.memberListLegal
  }, {
    path: appPaths.MemberList,
    component: MemberList,
    exact: true,
    action: userActionsKeys.memberListLegal
  }, {
    path: appPaths.MemberDetail,
    component: MemberDetail,
    exact: true,
    action: userActionsKeys.memberDetailLegal
  }, {
    path: appPaths.SiteList,
    component: SiteList,
    exact: true,
    action: userActionsKeys.siteListLegal
  }, {
    path: appPaths.SiteDetail,
    component: SiteDetail,
    exact: true,
    action: userActionsKeys.siteDetailLegal
  }, {
    path: appPaths.UnionDuesAuthorizationCard,
    component: UnionDuesAuthorizationCard,
    exact: true,
    action: userActionsKeys.signAuthorizationCardLegal
  }, {
    path: appPaths.VisitForm,
    component: VisitForm,
    exact: true,
    action: [userActionsKeys.addNewVisitLegal, userActionsKeys.updateVisitLegal]
  }, {
    path: appPaths.VisitList,
    component: VisitList,
    exact: true,
    action: userActionsKeys.visitListLegal
  }, {
    path: appPaths.VisitDetail,
    component: VisitDetail,
    exact: true,
    action: userActionsKeys.visitDetailLegal
  }, {
    path: appPaths.SiteAndMemberNewInvestigation,
    component: SelectMember,
    exact: true,
    action: userActionsKeys.addNewInvestigationLegal
  }, {
    path: appPaths.InvestigationDetail,
    component: InvestigationDetail,
    exact: true,
    action: userActionsKeys.investigationDetailLegal
  }, {
    path: appPaths.InvestigationList,
    component: InvestigationList,
    exact: true,
    action: userActionsKeys.investigationListLegal
  }, {
    path: appPaths.SiteAndMemberNewGrievance,
    component: SelectMember,
    exact: true,
    action: userActionsKeys.addNewGrievanceLegal
  }, {
    path: appPaths.GrievanceDetail,
    component: GrievanceDetail,
    exact: true,
    action: userActionsKeys.grievanceDetailLegal
  }, {
    path: appPaths.GrievanceList,
    component: GrievanceList,
    exact: true,
    action: userActionsKeys.grievanceListLegal
  }, {
    path: appPaths.SiteAndMemberNewRating,
    component: SelectMember,
    exact: true,
    action: userActionsKeys.addNewRatingLegal
  }, {
    path: appPaths.RatingDetail,
    component: RatingDetail,
    exact: true,
    action: userActionsKeys.ratingDetailLegal
  }, {
    path: appPaths.RatingList,
    component: RatingList,
    exact: true,
    action: userActionsKeys.ratingListLegal
  }, {
    path: appPaths.Reports,
    component: Reports,
    exact: true,
    action: userActionsKeys.reportViewLegal
  }, {
    path: appPaths.CategoryForm,
    component: CategoryForm,
    exact: true,
    action: [userActionsKeys.addNewFriendCategoryLegal,userActionsKeys.categoryDetailLegal,userActionsKeys.updateFriendCategoryLegal]
  }, {
    path: appPaths.CategoryList,
    component: CategoryList,
    exact: true,
    action: userActionsKeys.categoryListLegal
  }, {
    path: appPaths.NewFriend,
    component: NewFriend,
    exact: true,
    action: userActionsKeys.addNewFriendLegal
  }, {
    path: appPaths.FriendDetail,
    component: FriendDetail,
    exact: true,
    action: userActionsKeys.friendDetailLegal
  }, {
    path: appPaths.FriendList,
    component: FriendList,
    exact: true,
    action: userActionsKeys.friendListLegal
  }, {
    path: appPaths.BonusList,
    component: BonusList,
    exact: true,
    action: userActionsKeys.bonusListLegal
  }, {
    path: appPaths.SeniorityList,
    component: SeniorityList,
    exact: true,
    action: userActionsKeys.seniorityListLegal
  },
  {
    path: appPaths.UserList,
    component: UserList,
    exact: true,
    roles: ['Admin']
  },
  {
    path: appPaths.NewAdvocate,
    component: NewAdvocate,
    exact: true,
    roles: ['Admin']
  },
  //otherwise
  {
    path: ':otherwise',
    component: MemberList,
    exact: false
  }
];

export default routes;