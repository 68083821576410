import React, { useRef }                                                                  from 'react'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { fetchingFn, setAlertNotification }                                               from "../../actions/util";

const ImportFile = ({actionImport, actionDelete, onChange, deleteAll}) => {

  const inputFileRef = useRef();

  const validate = (newFIle) => {
    let allowedExtensions = /(\.xlsx|\.XLSX)$/i;
    if ( !allowedExtensions.exec(newFIle.name)) {
      setAlertNotification("File type is not allowed", 'danger');
      return false
    }
    return true
  };

  const handleOnChange = (event) => {
    if (event.target.files[0] && validate(event.target.files[0])) {
      fetchingFn(true);
      var fileTemp = event.target.files[0];
      var fd = new FormData();
      fd.append('file', fileTemp);
      onChange(fd)
      cleanInput()

    }
  };

  const cleanInput = () => {
    inputFileRef.current.value = null;
  }

  return (
    <div className="float-right ml-3 mb-1">
      {actionImport && <Button color="success fileContainer">
        <input type="file" accept=".xlsx" onChange={handleOnChange} ref={inputFileRef}/>
        Upload File</Button>}
      {" "}
      {deleteAll && actionDelete &&
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="danger">
          Delete
        </DropdownToggle>
        <DropdownMenu style={{minWidth: "8rem"}}>
          <DropdownItem className="text-danger" onClick={() => {
            deleteAll()
          }}>Confirm</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>}
    </div>
  )
};

export default ImportFile