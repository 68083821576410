import { useEffect, useState }                                    from 'react'
import { grievanceAdvocateService, investigationAdvocateService } from "../services";
import { useLocation }                                            from "react-router";
import { naturalCompare, setAlertNotification }                   from "../actions/util";

export const useUsersAdvocateList = () => {

  const [users, setUsers] = useState([]);

  const getUsers = (filterName = "", columnSort = "Name", descending = true) => {
    investigationAdvocateService()
      .getAdvocatesUsers(filterName, columnSort, descending)
      .then(res => {
        let list = res.data || []
        list.sort((a, b) => naturalCompare(a?.user?.name, b?.user?.name))
        setUsers(list);
      })
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  return {users}
};

export const useGrievanceAdvocateList = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const grievanceParamId = param.get("grievance");

  const [grievanceAdvocates, setGrievanceAdvocates] = useState([]);

  const getGrievanceAdvocates = (grievanceId) => {
    if (grievanceId || id || grievanceParamId) {
      grievanceAdvocateService()
        .getAllByGrievance(grievanceId || id || grievanceParamId)
        .then(res => {
          if (res && res.data) {
            setGrievanceAdvocates(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setGrievanceAdvocates({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getGrievanceAdvocates()
    // eslint-disable-next-line
  }, [grievanceParamId, id]);

  const deleteGrievanceAdvocate = (id) => {
    grievanceAdvocateService()
      .deleteAdvocate({id})
      .then(res => {
        getGrievanceAdvocates()
      })
      .catch(err => {
        setAlertNotification("Error deleting an Advocate", "danger")
      });
  }

  return {
    grievanceAdvocates,
    getGrievanceAdvocates,
    grievanceId: grievanceParamId,
    deleteGrievanceAdvocate
  }
};
