import { useEffect, useState }                  from 'react'
import { friendService }                        from "../services";
import { useHistory, useLocation }              from "react-router";
import { naturalCompare, setAlertNotification } from "../actions/util";
import { appPaths }                             from "../routes";
import { useDefaultLimit }                      from "./WindowUtil";

export const useFriendList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [friends, setFriends] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "", descending: true, showInactive: 0, member: 1, nonMember: 1, categoryId: 0, addressType: 0});

  const getFriends = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, showInactive = pagination.showInactive, member = pagination.member, nonMember = pagination.nonMember, categoryId = pagination.categoryId, addressType = pagination.addressType) => {

    friendService()
      .getFriends(limit, skip, filterName, columnSort, descending, showInactive, member, nonMember, categoryId, addressType)
      .then(res => {
        if (res && res.data) {
          setFriends(res.data);
          const friendPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            filterName,
            showInactive,
            member,
            nonMember,
            categoryId,
            addressType
          }

          setPagination(friendPaginateList)
          sessionStorage.setItem("friendPaginateList", JSON.stringify(friendPaginateList))
        }
      })
  };

  useEffect(() => {
    let friendPaginateList = sessionStorage.getItem("friendPaginateList")
    if (friendPaginateList) {
      friendPaginateList = JSON.parse(friendPaginateList)
      getFriends(defaultLimit || friendPaginateList.limit, friendPaginateList.skip, friendPaginateList.filterName, friendPaginateList.columnSort, friendPaginateList.descending, friendPaginateList.showInactive, friendPaginateList.member, friendPaginateList.nonMember, friendPaginateList.categoryId, friendPaginateList.addressType)

    } else {
      getFriends()
    }
    // eslint-disable-next-line
  }, []);

  return {friends, pagination, getFriends}
};

export const useFriend = (props) => {

  const {id, onChange} = props || {}

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const friendParamId = param.get("friend");

  const [friend, setFriend] = useState({});
  const [friendCategories, setFriendCategories] = useState([]);

  const getFriend = (friendId) => {
    if (friendId || id || friendParamId) {
      friendService()
        .getFriend(friendId || id || friendParamId)
        .then(res => {
          if (res && res.data) {
            setFriend(res.data);

            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setFriend({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  const getFriendCategories = (friendId) => {
    if (friendId || id || friendParamId) {
      friendService()
        .getFriendCategories(friendId || id || friendParamId)
        .then(res => {
          if (res && res.data) {
            setFriendCategories(res.data.sort((a, b) => naturalCompare(a.category.description, b.category.description)));
          }
        })
        .catch(err => {
          setFriendCategories([]);
        })
    }
  };

  const deleteFriendCategory = (id) => {
    if (id) {
      friendService()
        .deleteFriendCategory(id)
        .then(() => {
          getFriendCategories()
          setAlertNotification("Action")
        })
        .catch(err => {
          setAlertNotification("Action", "danger")
        });
    }
  };

  useEffect(() => {
    getFriend()
    getFriendCategories()
    // eslint-disable-next-line
  }, [friendParamId, id]);

  const deleteFriend = () => {
    friendService()
      .deleteFriend(friendParamId)
      .then(() => {
        history.push(appPaths.FriendList);
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  const changeFriendState = (isActive = false) => {
    friendService()
      .changeState({id: friendParamId, isActive})
      .then(() => {
        getFriend()
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  return {
    friend,
    getFriend,
    friendId: friendParamId,
    deleteFriend,
    changeFriendState,
    friendCategories,
    getFriendCategories,
    deleteFriendCategory
  }
};
