import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "grievanceActivity/";
export default () => ({
    getGrievanceActivity: (id) => client.request({
      method: 'GET',
      url: base + 'getGrievanceActivity/' + id
    }),
    getGrievanceActivities: (grievanceId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetGrievanceActivities/' + grievanceId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addGrievanceActivity: (params) => client.request({
      method: 'POST',
      url: base + 'addGrievanceActivity',
      data: params
    }),
    updateGrievanceActivity: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateGrievanceActivity/' + params.id,
      data: params
    }),
    deleteGrievanceActivity: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteGrievanceActivity/' + params.id
    })
  }
);
