import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "Bonus/";
export default () => ({
    
    getBonus: (limit = 10, skip = 0, columnSort = "", descending = true, fileNo = "", lastName = "") => loading(client.request({
      method: 'GET',
      url: base + 'GetBonus?limit=' + limit + '&skip=' + skip + '&columnSort=' + columnSort + '&descending=' + descending + '&fileNo=' + fileNo + '&lastName=' + lastName
    })),
    importBonus: (params) => client.request({
      method: 'POST',
      url: base + 'ImportBonus/',
      data: params
    }),
    deleteBonus: () => loading(client.request({
      method: 'DELETE',
      url: base + 'DeleteBonus/'
    }))
  }
);
