import React, { useState }                       from "react";
import { Button, Container, CustomInput, Row }   from "reactstrap";
import { appPaths }                              from "../../routes";
import { useHistory }                            from "react-router";
import { useMember, useSite }                    from "../../hooks";
import { FriendTab, ReadOnly, SelectMemberList } from "../index";

const NewFriend = () => {

  const {siteId} = useSite();
  const {member, setMember, memberId} = useMember();

  const history = useHistory();
  const [isNonMember, setIsNonMember] = useState(false);

  const buttons = <>
    <Button disabled={!(memberId || isNonMember)} onClick={() => {
      history.push(appPaths.FriendDetail + (isNonMember ? "?is-non-member=" + true : "?site=" + siteId + "&member=" + member.id))
    }}>Next</Button>
    {" "} or {" "}
    <Button outline onClick={() => {
      history.push(appPaths.FriendList)
    }}>Cancel</Button>
  </>;

  const memberCmp = <div className="col-12">
    <SelectMemberList isUnionInitiatedAllow={false} selectMember={(member) => {
      setMember(member)
    }}/>
  </div>

  return (
    <Container fluid>
      <FriendTab from="Friends of CSA"/>
      <Row>
        <span className="float-right ml-auto" style={{marginRight: "15px", marginBottom: "-24px", zIndex: 9}}>
          {buttons}
        </span>
        <h3 className="col-12 mb-2">New Friend</h3>
        <div className="col-12 text-gray mb-3">
        You can add members and non-members to Friends of CSA. <br/>
        If it is a non-member, please select the checkbox next to non-member and click next.
        </div>
        <div className="col-12 mt-3 mb-3">
          <CustomInput className="text-center big-checkbox primary-color" type="checkbox" name="unionInitiated" id="unionInitiated" checked={!!isNonMember} onChange={(e) => {
            setIsNonMember(!isNonMember)
          }} label="Non-Member"/>
        </div>
        {isNonMember ?
          <ReadOnly>
            {memberCmp}
          </ReadOnly>
          : memberCmp}
        <div className="col-12 text-center mt-4">{buttons}</div>
      </Row>
    </Container>
  )
}

export default NewFriend