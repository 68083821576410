import { useEffect, useState } from 'react'
import { statusService }       from "../services";

let tmpStatus, isStatusFetching = false, subscribedStatusFunc = [];

const useStatus = () => {

  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (tmpStatus) {
      setStatus(tmpStatus)
    } else if (isStatusFetching) {
      subscribedStatusFunc.push(setStatus)
    } else {
      isStatusFetching = true
      statusService()
        .getStatusAll()
        .then(res => {
          tmpStatus = res.data || [];
          setStatus(tmpStatus)
          subscribedStatusFunc.forEach(func => {
            func(tmpStatus)
          })
          subscribedStatusFunc = []
          isStatusFetching = false
        })
        .catch((err) => {
          setStatus([])
        });
    }
  }, []);

  const getCategory = (name) => {
    if (name) {
      name = name.trim();
      for (let i in status) {
        if (status.hasOwnProperty(i) && status[i].member && status[i].member.trim() === name) {
          return status[i].category ? status[i].category.trim() : "";
        }
      }
    }
    return "";
  };

  return {status, getCategory}
};
export default useStatus;