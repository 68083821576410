import { useEffect, useState }     from 'react'
import { ratingService }           from "../services";
import { useHistory, useLocation } from "react-router";
import { setAlertNotification }    from "../actions/util";
import { appPaths }                from "../routes";
import { useDefaultLimit }         from "./WindowUtil";

export const useRatingList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [ratings, setRatings] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "CreateDate", descending: true, open: 1});

  const getRatings = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    ratingService()
      .getRatings(limit, skip, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          setRatings(res.data);
          const ratingPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            open,
            filterName
          }
          setPagination(ratingPaginateList)
          sessionStorage.setItem("ratingPaginateList", JSON.stringify(ratingPaginateList))
        }
      })
  };

  useEffect(() => {
    let ratingPaginateList = sessionStorage.getItem("ratingPaginateList")
    if (ratingPaginateList) {
      ratingPaginateList = JSON.parse(ratingPaginateList)
      getRatings(defaultLimit || ratingPaginateList.limit, ratingPaginateList.skip, ratingPaginateList.filterName, ratingPaginateList.columnSort, ratingPaginateList.descending, ratingPaginateList.open)
    } else {
      getRatings()
    }
    // eslint-disable-next-line
  }, []);

  return {ratings, pagination, getRatings}
};

export const useRating = ({id, onChange, closeModal}) => {

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const ratingParamId = param.get("rating");

  const [rating, setRating] = useState({});

  const getRating = (ratingId) => {
    if (ratingId || id || ratingParamId) {
      ratingService()
        .getRating(ratingId || id || ratingParamId)
        .then(res => {
          if (res && res.data) {
            setRating({...res.data, superintendentLabel: res.data.superintendentLabel || "Superintendent"});

            if (res.data.siteModelId && res.data.memberModelId) {
              history.push(appPaths.RatingDetail + "?rating=" + res.data.id + "&site=" + res.data.siteModelId + "&member=" + res.data.memberModelId)
            }

            if (onChange) {
              onChange({...res.data, superintendentLabel: res.data.superintendentLabel || "Superintendent"});
            }
          }
        })
        .catch(err => {
          setRating({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getRating()
    // eslint-disable-next-line
  }, [ratingParamId, id]);

  const changeRatingState = (values) => {
    let params = {id: rating.id, isOpen: !rating.isOpen};
    if (values) {
      params = {...params, outcome: values.outcome, closedOn: values.closedOn}
    }
    ratingService()
      .changeState(params)
      .then(() => {
        getRating();
        closeModal();
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };
  const deleteRating = () => {
    ratingService()
      .deleteRating(ratingParamId)
      .then(() => {
        history.push(appPaths.RatingList);
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  return {
    rating,
    getRating,
    ratingId: ratingParamId,
    changeRatingState,
    isRatingOpen: rating.isOpen || typeof rating.isOpen === "undefined",
    deleteRating
  }
};
