import React, { useEffect, useState }                 from 'react'
import { useForm }                                    from "react-hook-form";
import { Button, CustomInput, FormGroup, Label, Row } from "reactstrap";
import { MyDatePicker, UsersList }                    from "../index";
import { memberContactService }                       from '../../services'
import { cleanName, dateFromISOToLocal }              from "../../actions/util";
import { useUserList }                                from "../../hooks";

const MemberContactForm = ({member, memberContact, setMemberContact, toggleModal, getContactVisitMemberList}) => {

  useEffect(() => () => {
    setMemberContact({});
  }, [memberContact, setMemberContact]);

  const {register, handleSubmit} = useForm();

  const [date, setDate] = useState(memberContact.date ? dateFromISOToLocal(memberContact.date) : new Date());
  const [isSendLegal, setIsSendLegal] = useState(memberContact.isSendLegal || false);
  const [isSendMembership, setIsSendMembership] = useState(memberContact.isSendMembership || false);
  const [isSendToUsers, setIsSendToUsers] = useState(memberContact.isSendToUsers || false);
  const [selectedUsers, setSelectedUsers] = useState(memberContact.users || []);

  let isValid = !!date;

  if (isSendToUsers && selectedUsers.length === 0) {
    isValid = false;
  }
  const {users, getUsers, pagination: paginationUser} = useUserList();

  useEffect(() => {
    if (memberContact.isSendToUsers) {
      getUsers();
      memberContactService().getAllUsers(member.id, memberContact.id)
        .then(res => {
          if (res && res.data && res.data) {
            setSelectedUsers(res.data);
          }
        });
    }
    // eslint-disable-next-line
  }, [memberContact, member.id]);

  return (
    <form onSubmit={handleSubmit(async (values) => {
      let tmp = {...memberContact, ...values, date, memberModelId: member.id, isSendLegal, isSendMembership, isSendToUsers, users: selectedUsers};
      memberContact.id
        ? await memberContactService().updateMemberContact(tmp)
        : await memberContactService().addMemberContact(tmp)
      getContactVisitMemberList();
      toggleModal();
    })}>
      <Row>
        <FormGroup className="col-12">
          <Row>
            <Label className="col-3">Member</Label>
            <Label className="col-9 no-padding">{cleanName(member.lastname)}, {cleanName(member.frstname)}</Label>
          </Row>
        </FormGroup>

        <FormGroup className="col-12">
          <Row>
            <Label className="col-3">Date</Label>
            <div className="col-8 no-padding">
              <MyDatePicker
                dateFormat="MM/dd/yyyy"
                selected={date}
                onChange={(value) => {
                  setDate(value)
                }}/>
            </div>
            <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
          </Row>
        </FormGroup>

        <FormGroup className="col-12">
          <Row>
            <Label className="col-3">Contact Type</Label>
            <div className="col-8 no-padding">
              <select className="form-control" name="type" defaultValue={memberContact.type} required ref={register}>
                <option>-</option>
                {["Regular Visit", "Phone", "Email", "Information Purpose Only", "Text Message"]
                  .sort()
                  .map(value => (
                    <option key={value} value={value}>{value}</option>
                  ))}
              </select>
            </div>
            <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
          </Row>
        </FormGroup>

        <FormGroup className="col-12">
          <Row>
            <Label className="col-3">Comment</Label>
            <div className="col-9 no-padding-left">
              <textarea className="form-control" name="comment" defaultValue={memberContact.comment} ref={register}/>
            </div>
          </Row>
        </FormGroup>

        <FormGroup className="col-12">
          <Row>
            <CustomInput className="col-4 text-center" type="checkbox" name="isSendLegal" id="isSendLegal" checked={isSendLegal} onChange={(e) => {
              setIsSendLegal(e.target.checked)
            }} label="Send to Legal"/>
            <CustomInput className="col-4 text-center" type="checkbox" name="isSendMembership" id="isSendMembership" checked={isSendMembership} onChange={(e) => {
              setIsSendMembership(e.target.checked)
            }} label="Send to Membership"/>
            <CustomInput className="col-4 text-center" type="checkbox" name="isSendToUsers" id="isSendToUsers" checked={isSendToUsers} onChange={(e) => {
              const value = e.target.checked;
              if (value && users.length === 0) {
                getUsers();
              }
              setIsSendToUsers(value)
            }} label="Send to User"/>
          </Row>
        </FormGroup>

        {isSendToUsers && <div className="col-12 no-padding">

          <div>
            <h5>Users Selected</h5>
            <p style={{height: "80px", overflow: "auto", border: "1px solid #797979", borderRadius: "4px", padding: "0", backgroundColor: "#f5f5f5"}}>
              {selectedUsers && selectedUsers.map((value, index) => (
                <span key={index} style={{border: "1px solid", borderRadius: "4px", padding: "1px 0 3px 4px", margin: "4px", backgroundColor: "white", display: "inline-block"}}>
                  {value.name}
                  <sup style={{fontWeight: "bold", textShadow: "0 1px 0 #fff", opacity: ".5", padding: "0 4px", cursor: "pointer"}} onClick={() => {
                    let tmp = [];
                    for (let i in selectedUsers) {
                      if (selectedUsers.hasOwnProperty(i) && selectedUsers[i].id !== value.id) {
                        tmp.push(selectedUsers[i])
                      }
                    }
                    setSelectedUsers(tmp)
                  }}>x</sup>
                </span>
              ))}
            </p>
          </div>

          <UsersList users={users} getUsers={getUsers} paginationUser={paginationUser} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}/>
        </div>}

        <div className="col-12" style={{marginTop: "30px", borderTop: "1px solid #797979", paddingTop: "15px"}}>
          <input type="submit" disabled={!isValid} className="btn btn-outline-secondary" value="Save"/>
          {" "}
          or
          {" "}
          <Button outline color="danger" onClick={() => {
            toggleModal()
          }}>Cancel</Button>
        </div>
      </Row>
    </form>
  )
};

export default MemberContactForm;