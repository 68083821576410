import React, { useState } from 'react'
import { MyDatePicker }    from "../index";
import { Button }          from "reactstrap";
import { useForm }         from "react-hook-form";

const CloseGrievance = ({changeGrievanceState}) => {

  const {register, handleSubmit} = useForm();

  const [closedDate, setClosedDate] = useState(new Date());
  return (
    <form className="row" onSubmit={handleSubmit(values => {
      changeGrievanceState({...values, closedDate})
    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Closed Date</div>
      <div className="col-7 mt-2 mb-2">
        <MyDatePicker
          dateFormat="MM/dd/yyyy"
          selected={closedDate}
          onChange={(value) => {
            setClosedDate(value);
          }}/>
      </div>
      <div className="col-5 border-bottom-gray toast-title-item">Outcome</div>
      <div className="col-7 mt-2 mb-2">
        <textarea className="form-control" name="outcome" ref={register}/>
      </div>
      <div className="col-12 mt-4 mb-2"><Button>Close Grievance</Button></div>
    </form>
  )
};

export default CloseGrievance