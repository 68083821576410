import { useEffect, useState }      from 'react'
import { friendAddressService } from "../services";
import { useLocation }              from "react-router";
import { setAlertNotification }     from "../actions/util";

export const useFriendAddressList = (props) => {

  const {friendId} = props || {};
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const friendParamId = param.get("friend");

  const [friendAddresses, setFriendAddresses] = useState([]);
  const [pagination, setPagination] = useState({limit: 15, skip: 0, filterName: "", columnSort: "", descending: true});

  const getFriendAddresses = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    if (friendId || friendParamId) {
      friendAddressService()
        .getFriendAddresses(friendId || friendParamId, limit, skip, filterName, columnSort, descending)
        .then(res => {
          if (res && res.data) {
            setFriendAddresses(res.data);
            setPagination({
              ...pagination,
              count: parseInt(res.headers['x-total-count'], 10),
              limit: parseInt(res.headers['x-total-limit'], 10),
              skip: parseInt(res.headers['x-total-skip'], 10),
              columnSort,
              descending
            })
          }
        })
    }
 
  };

  useEffect(() => {
    getFriendAddresses()
    // eslint-disable-next-line
  }, []);

  const addFriendAddress = (values, cb) => {
    friendAddressService()
      .addFriendAddress({friendModelId: friendId || friendParamId, ...values})
      .then(() => {
        getFriendAddresses();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const updateFriendAddress = (values, cb) => {
    friendAddressService()
      .updateFriendAddress({friendModelId: friendId || friendParamId, ...values})
      .then(() => {
        getFriendAddresses();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const deleteFriendAddress = (id, cb) => {
    friendAddressService()
      .deleteFriendAddress(id)
      .then(() => {
        getFriendAddresses();
        setAlertNotification("Action");
        if (cb) {
          cb()
        }
      })
      .catch(err => {
        setAlertNotification("Action", "danger");
      });
  };

  const onSubmit = (values, cb) => {
    if (values.id) {
      updateFriendAddress(values, cb)
    } else {
      addFriendAddress(values, cb)
    }
  };

  return {
    friendAddresses,
    paginationFriendAddress: pagination,
    friendId: friendParamId,
    getFriendAddresses,
    addFriendAddress,
    onSubmit,
    deleteFriendAddress
  }
};
