//Member CRUD
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAILURE = 'GET_MEMBER_FAILURE';

export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const RESET_MEMBERS = 'RESET_MEMBERS';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';

export const GET_MEMBERS_FROM_SITE_SUCCESS = 'GET_MEMBERS_FROM_SITE_SUCCESS';
export const GET_MEMBERS_FROM_SITE_FAILURE = 'GET_MEMBERS_FROM_SITE_FAILURE';
export const RESET_MEMBERS_FROM_SITE_FAILURE = 'RESET_MEMBERS_FROM_SITE_FAILURE';

export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';

export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';

export const SET_MEMBER_ACTIVE_TAB = 'SET_MEMBER_ACTIVE_TAB';
export const RESET_MEMBER_ACTIVE_TAB = 'RESET_MEMBER_ACTIVE_TAB';

export const GO_TO_MEMBER_VIEW = 'GO_TO_MEMBER_VIEW';
export const RESET_GO_TO_MEMBER_VIEW = 'RESET_GO_TO_MEMBER_VIEW';

//Member Pagination
export const ADD_MEMBER_PAGINATION = 'ADD_MEMBER_PAGINATION';
export const RESET_MEMBER_PAGINATION = 'RESET_MEMBER_PAGINATION';

//Dues Pagination
export const ADD_DUES_PAGINATION = 'ADD_DUES_PAGINATION';
export const RESET_DUES_PAGINATION = 'RESET_DUES_PAGINATION';

export const ADD_MEMBER_FROM_SITE_PAGINATION = 'ADD_MEMBER_FROM_SITE_PAGINATION';
export const RESET_MEMBER_FROM_SITE_PAGINATION = 'RESET_MEMBER_FROM_SITE_PAGINATION';

//User Pagination
export const ADD_USER_PAGINATION = 'ADD_USER_PAGINATION';
export const RESET_USER_PAGINATION = 'RESET_USER_PAGINATION';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const ADD_USER = 'ADD_USER';

//COPE
export const GET_COPE = 'GET_COPE';
export const GET_COPE_SUCCESS = 'GET_COPE_SUCCESS';
export const GET_COPE_FAILURE = 'GET_COPE_FAILURE';

export const GET_COPES = 'GET_COPES';
export const GET_COPES_SUCCESS = 'GET_COPES_SUCCESS';
export const GET_COPES_FAILURE = 'GET_COPES_FAILURE';

export const SET_COPE_FILES = 'SET_COPE_FILES';
export const RESET_COPE_FILES = 'RESET_COPE_FILES';

export const SET_ASSOCIATION_FILES = 'SET_ASSOCIATION_FILES';
export const RESET_ASSOCIATION_FILES = 'RESET_ASSOCIATION_FILES';

export const SET_DUES_FILES = 'SET_DUES_FILES';
export const RESET_DUES_FILES = 'RESET_DUES_FILES';

export const SET_DUES_FILES_ALL = 'SET_DUES_FILES_ALL';
export const RESET_DUES_FILES_ALL = 'RESET_DUES_FILES_ALL';

export const ADD_COPE = 'ADD_COPE';
export const ADD_COPE_SUCCESS = 'ADD_COPE_SUCCESS';
export const ADD_COPE_FAILURE = 'ADD_COPE_FAILURE';

export const UPDATE_COPE_SUCCESS = 'UPDATE_COPE_SUCCESS';
export const UPDATE_COPE_FAILURE = 'UPDATE_COPE_FAILURE';

export const DELETE_COPE_SUCCESS = 'DELETE_COPE_SUCCESS';
export const DELETE_COPE_FAILURE = 'DELETE_COPE_FAILURE';
export const SET_DATE_PREPARE_COPE_FILES = 'SET_DATE_PREPARE_COPE_FILES';
export const RESET_DATE_PREPARE_COPE_FILES = 'RESET_DATE_PREPARE_COPE_FILES';
export const PREPARE_COPE_FILES_SUCCESS = 'PREPARE_COPE_FILES_SUCCESS';
export const PREPARE_COPE_FILES_FAILURE = 'PREPARE_COPE_FILES_FAILURE';
export const PREPARE_ASSOCIATION_FILES_SUCCESS = 'PREPARE_ASSOCIATION_FILES_SUCCESS';
export const PREPARE_ASSOCIATION_FILES_FAILURE = 'PREPARE_ASSOCIATION_FILES_FAILURE';
export const PREPARE_DUES_FILES_SUCCESS = 'PREPARE_DUES_FILES_SUCCESS';
export const PREPARE_DUES_FILES_FAILURE = 'PREPARE_DUES_FILES_FAILURE';
export const PREPARE_DUES_FILES_ALL_SUCCESS = 'PREPARE_DUES_ALL_FILES_SUCCESS';
export const PREPARE_DUES_FILES_ALL_FAILURE = 'PREPARE_DUES_ALL_FILES_FAILURE';
export const PREPARE_FILES_NO_FILES = 'PREPARE_FILES_NO_FILES';
export const POPULATE_DATABASE_SUCCESS = 'POPULATE_DATABASE_SUCCESS';
export const POPULATE_DATABASE_FAILURE = 'POPULATE_DATABASE_FAILURE';
export const UPDATE_USERS_FROM_ACTIVE_DIRECTORY_SUCCESS = 'UPDATE_USERS_FROM_ACTIVE_DIRECTORY_SUCCESS';
export const UPDATE_USERS_FROM_ACTIVE_DIRECTORY_FAILURE = 'UPDATE_USERS_FROM_ACTIVE_DIRECTORY_FAILURE';
export const SET_FISA_DATES = 'SET_FISA_DATES';
export const RESET_FISA_DATES = 'RESET_FISA_DATES';

export const ADD_COPES = 'ADD_COPES';
export const REMOVE_ITEM_COPES = 'REMOVE_ITEM_COPES';
export const ADD_COPE_PAGINATION = 'ADD_COPE_PAGINATION';
export const RESET_COPE_PAGINATION = 'RESET_COPE_PAGINATION';

export const CREATE_COPE = 'CREATE_COPE';
export const UPDATE_COPE = 'UPDATE_COPE';
export const DELETE_COPE = 'DELETE_COPE';

//Assignment
export const GET_ASSIGNMENT_SUCCESS = 'GET_ASSIGNMENT_SUCCESS';
export const GET_ASSIGNMENT_FAILURE = 'GET_ASSIGNMENT_FAILURE';

export const GET_ASSIGNMENTS_SUCCESS = 'GET_ASSIGNMENTS_SUCCESS';
export const GET_ASSIGNMENTS_FAILURE = 'GET_ASSIGNMENTS_FAILURE';

export const SET_ASSIGNMENT_FILES = 'SET_ASSIGNMENT_FILES';
export const RESET_ASSIGNMENT_FILES = 'RESET_ASSIGNMENT_FILES';

export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
export const ADD_ASSIGNMENT_SUCCESS = 'ADD_ASSIGNMENT_SUCCESS';
export const ADD_ASSIGNMENT_FAILURE = 'ADD_ASSIGNMENT_FAILURE';

export const UPDATE_ASSIGNMENT_SUCCESS = 'UPDATE_ASSIGNMENT_SUCCESS';
export const UPDATE_ASSIGNMENT_FAILURE = 'UPDATE_ASSIGNMENT_FAILURE';

export const DELETE_ASSIGNMENT_SUCCESS = 'DELETE_ASSIGNMENT_SUCCESS';
export const DELETE_ASSIGNMENT_FAILURE = 'DELETE_ASSIGNMENT_FAILURE';

export const ADD_ASSIGNMENTS = 'ADD_ASSIGNMENTS';
export const REMOVE_ITEM_ASSIGNMENTS = 'REMOVE_ITEM_ASSIGNMENTS';
export const ADD_ASSIGNMENT_PAGINATION = 'ADD_ASSIGNMENT_PAGINATION';
export const RESET_ASSIGNMENT_PAGINATION = 'RESET_ASSIGNMENT_PAGINATION';

export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';

//Assignment
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'GET_STATUS_FAILURE';

export const GET_STATUSES_SUCCESS = 'GET_STATUSES_SUCCESS';
export const GET_STATUSES_FAILURE = 'GET_STATUSES_FAILURE';

export const SET_STATUS_FILES = 'SET_STATUS_FILES';
export const RESET_STATUS_FILES = 'RESET_STATUS_FILES';

export const ADD_STATUS = 'ADD_STATUS';
export const ADD_STATUS_SUCCESS = 'ADD_STATUS_SUCCESS';
export const ADD_STATUS_FAILURE = 'ADD_STATUS_FAILURE';

export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS';
export const DELETE_STATUS_FAILURE = 'DELETE_STATUS_FAILURE';

export const ADD_STATUSES = 'ADD_STATUSES';
export const REMOVE_ITEM_STATUSES = 'REMOVE_ITEM_STATUSES';
export const ADD_STATUS_PAGINATION = 'ADD_STATUS_PAGINATION';
export const RESET_STATUS_PAGINATION = 'RESET_STATUS_PAGINATION';

export const CREATE_STATUS = 'CREATE_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';

//Title
export const GET_TITLE_SUCCESS = 'GET_TITLE_SUCCESS';
export const GET_TITLE_FAILURE = 'GET_TITLE_FAILURE';
export const RESET_GET_TITLE = 'RESET_GET_TITLE';

export const GET_TITLES_SUCCESS = 'GET_TITLES_SUCCESS';
export const GET_TITLES_FAILURE = 'GET_TITLES_FAILURE';

export const SET_TITLE_FILES = 'SET_TITLE_FILES';
export const RESET_TITLE_FILES = 'RESET_TITLE_FILES';

export const ADD_TITLE = 'ADD_TITLE';
export const ADD_TITLE_SUCCESS = 'ADD_TITLE_SUCCESS';
export const ADD_TITLE_FAILURE = 'ADD_TITLE_FAILURE';

export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS';
export const UPDATE_TITLE_FAILURE = 'UPDATE_TITLE_FAILURE';

export const DELETE_TITLE_SUCCESS = 'DELETE_TITLE_SUCCESS';
export const DELETE_TITLE_FAILURE = 'DELETE_TITLE_FAILURE';

export const ADD_TITLES = 'ADD_TITLES';
export const REMOVE_ITEM_TITLES = 'REMOVE_ITEM_TITLES';
export const ADD_TITLE_PAGINATION = 'ADD_TITLE_PAGINATION';
export const RESET_TITLE_PAGINATION = 'RESET_TITLE_PAGINATION';

export const CREATE_TITLE = 'CREATE_TITLE';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const DELETE_TITLE = 'DELETE_TITLE';

//Site Visit
export const GET_SITE_VISIT_SUCCESS = 'GET_SITE_VISIT_SUCCESS';
export const GET_SITE_VISIT_FAILURE = 'GET_SITE_VISIT_FAILURE';
export const RESET_GET_SITE_VISIT = 'RESET_GET_SITE_VISIT';

export const GET_SITE_VISITS_SUCCESS = 'GET_SITE_VISITS_SUCCESS';
export const GET_SITE_VISITS_FAILURE = 'GET_SITE_VISITS_FAILURE';

export const SET_SITE_VISIT_FILES = 'SET_SITE_VISIT_FILES';
export const RESET_SITE_VISIT_FILES = 'RESET_SITE_VISIT_FILES';

export const ADD_SITE_VISIT = 'ADD_SITE_VISIT';
export const ADD_SITE_VISIT_SUCCESS = 'ADD_SITE_VISIT_SUCCESS';
export const ADD_SITE_VISIT_FAILURE = 'ADD_SITE_VISIT_FAILURE';

export const UPDATE_SITE_VISIT_SUCCESS = 'UPDATE_SITE_VISIT_SUCCESS';
export const UPDATE_SITE_VISIT_FAILURE = 'UPDATE_SITE_VISIT_FAILURE';

export const DELETE_SITE_VISIT_SUCCESS = 'DELETE_SITE_VISIT_SUCCESS';
export const DELETE_SITE_VISIT_FAILURE = 'DELETE_SITE_VISIT_FAILURE';

export const ADD_SITE_VISITS = 'ADD_SITE_VISITS';
export const REMOVE_ITEM_SITE_VISITS = 'REMOVE_ITEM_SITE_VISITS';
export const ADD_SITE_VISIT_PAGINATION = 'ADD_SITE_VISIT_PAGINATION';
export const RESET_SITE_VISIT_PAGINATION = 'RESET_SITE_VISIT_PAGINATION';

export const CREATE_SITE_VISIT = 'CREATE_SITE_VISIT';
export const UPDATE_SITE_VISIT = 'UPDATE_SITE_VISIT';
export const DELETE_SITE_VISIT = 'DELETE_SITE_VISIT';

//Site Update
export const GET_SITE_UPDATE_SUCCESS = 'GET_SITE_UPDATE_SUCCESS';
export const GET_SITE_UPDATE_FAILURE = 'GET_SITE_UPDATE_FAILURE';
export const RESET_GET_SITE_UPDATE = 'RESET_GET_SITE_UPDATE';

export const GET_SITE_UPDATES_SUCCESS = 'GET_SITE_UPDATES_SUCCESS';
export const GET_SITE_UPDATES_FAILURE = 'GET_SITE_UPDATES_FAILURE';

export const SET_SITE_UPDATE_FILES = 'SET_SITE_UPDATE_FILES';
export const RESET_SITE_UPDATE_FILES = 'RESET_SITE_UPDATE_FILES';

export const ADD_SITE_UPDATE = 'ADD_SITE_UPDATE';
export const ADD_SITE_UPDATE_SUCCESS = 'ADD_SITE_UPDATE_SUCCESS';
export const ADD_SITE_UPDATE_FAILURE = 'ADD_SITE_UPDATE_FAILURE';

export const UPDATE_SITE_UPDATE_SUCCESS = 'UPDATE_SITE_UPDATE_SUCCESS';
export const UPDATE_SITE_UPDATE_FAILURE = 'UPDATE_SITE_UPDATE_FAILURE';

export const DELETE_SITE_UPDATE_SUCCESS = 'DELETE_SITE_UPDATE_SUCCESS';
export const DELETE_SITE_UPDATE_FAILURE = 'DELETE_SITE_UPDATE_FAILURE';

export const ADD_SITE_UPDATES = 'ADD_SITE_UPDATES';
export const REMOVE_ITEM_SITE_UPDATES = 'REMOVE_ITEM_SITE_UPDATES';
export const ADD_SITE_UPDATE_PAGINATION = 'ADD_SITE_UPDATE_PAGINATION';
export const RESET_SITE_UPDATE_PAGINATION = 'RESET_SITE_UPDATE_PAGINATION';

export const CREATE_SITE_UPDATE = 'CREATE_SITE_UPDATE';
export const UPDATE_SITE_UPDATE = 'UPDATE_SITE_UPDATE';
export const DELETE_SITE_UPDATE = 'DELETE_SITE_UPDATE';

//Member Visit
export const GET_MEMBER_VISIT_SUCCESS = 'GET_MEMBER_VISIT_SUCCESS';
export const GET_MEMBER_VISIT_FAILURE = 'GET_MEMBER_VISIT_FAILURE';
export const RESET_GET_MEMBER_VISIT = 'RESET_GET_MEMBER_VISIT';

export const GET_MEMBER_VISITS_SUCCESS = 'GET_MEMBER_VISITS_SUCCESS';
export const GET_MEMBER_VISITS_FAILURE = 'GET_MEMBER_VISITS_FAILURE';

export const SET_MEMBER_VISIT_FILES = 'SET_MEMBER_VISIT_FILES';
export const RESET_MEMBER_VISIT_FILES = 'RESET_MEMBER_VISIT_FILES';

export const ADD_MEMBER_VISIT = 'ADD_MEMBER_VISIT';
export const ADD_MEMBER_VISIT_SUCCESS = 'ADD_MEMBER_VISIT_SUCCESS';
export const ADD_MEMBER_VISIT_FAILURE = 'ADD_MEMBER_VISIT_FAILURE';

export const UPDATE_MEMBER_VISIT_SUCCESS = 'UPDATE_MEMBER_VISIT_SUCCESS';
export const UPDATE_MEMBER_VISIT_FAILURE = 'UPDATE_MEMBER_VISIT_FAILURE';

export const DELETE_MEMBER_VISIT_SUCCESS = 'DELETE_MEMBER_VISIT_SUCCESS';
export const DELETE_MEMBER_VISIT_FAILURE = 'DELETE_MEMBER_VISIT_FAILURE';

export const ADD_MEMBER_VISITS = 'ADD_MEMBER_VISITS';
export const REMOVE_ITEM_MEMBER_VISITS = 'REMOVE_ITEM_MEMBER_VISITS';
export const ADD_MEMBER_VISIT_PAGINATION = 'ADD_MEMBER_VISIT_PAGINATION';
export const RESET_MEMBER_VISIT_PAGINATION = 'RESET_MEMBER_VISIT_PAGINATION';

export const CREATE_MEMBER_VISIT = 'CREATE_MEMBER_VISIT';
export const UPDATE_MEMBER_VISIT = 'UPDATE_MEMBER_VISIT';
export const DELETE_MEMBER_VISIT = 'DELETE_MEMBER_VISIT';


//CRUD general state
export const GET = 'GET';
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_FAILURE = 'GET_FAILURE';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const SET_TEMPORAL_CRUD_OBJECT = 'SET_TEMPORAL_CRUD_OBJECT';
export const RESET_TEMPORAL_CRUD_OBJECT = 'RESET_TEMPORAL_CRUD_OBJECT';

// Modal
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_BIG_MODAL = 'TOGGLE_BIG_MODAL';
export const MODAL_COPE_FORM = 'MODAL_COPE_FORM';
export const MODAL_MEMBER_VISIT_FORM = 'MODAL_MEMBER_VISIT_FORM';
export const MODAL_SITE_UPDATE_FORM = 'MODAL_SITE_UPDATE_FORM';
export const MODAL_SITE_VISIT_FORM = 'MODAL_SITE_VISIT_FORM';
export const MODAL_TITLE_FORM = 'MODAL_TITLE_FORM';
export const MODAL_ASSIGNMENT_FORM = 'MODAL_ASSIGNMENT_FORM';
export const MODAL_STATUS_FORM = 'MODAL_STATUS_FORM';
export const MODAL_COMMENT_FORM = 'MODAL_COMMENT_FORM';
export const MODAL_BOE_FORM = 'MODAL_BOE_FORM';
export const MODAL_DUES_COLLECTED_FORM = 'MODAL_DUES_COLLECTED_FORM';
export const MODAL_CUSTOM_REPORT_FORM = 'MODAL_CUSTOM_REPORT_FORM';
export const MODAL_COPA_FORM = 'MODAL_COPA_FORM';
export const MODAL_INTERIM_ACTING_FORM = 'MODAL_INTERIM_ACTING_FORM';
export const MODAL_INTERIM_ACTING_VIEW = 'MODAL_INTERIM_ACTING_VIEW';
export const MODAL_BENEFIT_FORM = 'MODAL_BENEFIT_FORM';
export const MODAL_BENEFIT_VIEW = 'MODAL_BENEFIT_VIEW';
export const MODAL_ATTENDANCE_FORM = 'MODAL_ATTENDANCE_FORM';
export const MODAL_ATTENDANCE_VIEW = 'MODAL_ATTENDANCE_VIEW';
export const MODAL_HISTORY_FORM = 'MODAL_HISTORY_FORM';
export const MODAL_USER_ACTIONS = 'MODAL_USER_ACTIONS';
export const MODAL_CONFIRM_ACTION = 'MODAL_CONFIRM_ACTION';
export const MODAL_MEMBER_FROM_SITE_ACTION = 'MODAL_MEMBER_FROM_SITE_ACTION';

export const TOGGLE_FETCHING = 'TOGGLE_FETCHING';
export const FETCHING = 'FETCHING';
export const FETCHING_END = 'FETCHING_END';
export const FETCHING_COPE_FILES = 'FETCHING_COPE_FILES';
export const FETCHING_COPE_FILES_END = 'FETCHING_COPE_FILES_END';
export const FETCHING_ASSOCIATION_FILES = 'FETCHING_ASSOCIATION_FILES';
export const FETCHING_ASSOCIATION_FILES_END = 'FETCHING_ASSOCIATION_FILES_END';
export const FETCHING_DUES_FILES = 'FETCHING_DUES_FILES';
export const FETCHING_DUES_FILES_END = 'FETCHING_DUES_FILES_END';
export const FETCHING_DUES_ALL_FILES = 'FETCHING_ALL_DUES_FILES';
export const FETCHING_DUES_ALL_FILES_END = 'FETCHING_DUES_ALL_FILES_END';

//Alert Notification
export const ADD_ALERT_NOTIFICATION = 'ADD_ALERT_NOTIFICATION';
export const DISMISS_ALERT_NOTIFICATION = 'DISMISS_ALERT_NOTIFICATION';
export const RESET_ALERT_NOTIFICATION = 'RESET_ALERT_NOTIFICATION';

//Login
export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const HIDE_AUTH_MODAL = 'HIDE_AUTH_MODAL';
export const TOKEN_IS_IN_LOCAL_STORAGE = 'TOKEN_IS_IN_LOCAL_STORAGE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTOMATIC_LOGIN = 'AUTOMATIC_LOGIN';

export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const SET_SUBLIST_ACTIVE_ITEM = 'SET_SUBLIST_ACTIVE_ITEM';
export const RESET_SUBLIST_ACTIVE_ITEM = 'RESET_SUBLIST_ACTIVE_ITEM';
export const TOGGLE_COLLAPSE_MEMBER_OPTIONS = 'TOGGLE_COLLAPSE_MEMBER_OPTIONS';
export const SET_COLLAPSE_MEMBER_OPTIONS = 'SET_COLLAPSE_MEMBER_OPTIONS';
export const RESET_COLLAPSE_MEMBER_OPTIONS = 'RESET_COLLAPSE_MEMBER_OPTIONS';


//Comment
export const GET_COMMENT_SUCCESS = 'GET_COMMENT_SUCCESS';
export const GET_COMMENT_FAILURE = 'GET_COMMENT_FAILURE';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const REMOVE_ITEM_COMMENTS = 'REMOVE_ITEM_COMMENTS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';
export const ADD_COMMENT_PAGINATION = 'ADD_COMMENT_PAGINATION';
export const RESET_COMMENT_PAGINATION = 'RESET_COMMENT_PAGINATION';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE';

export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

//Attendance
export const GET_ATTENDANCE_SUCCESS = 'GET_ATTENDANCE_SUCCESS';
export const GET_ATTENDANCE_FAILURE = 'GET_ATTENDANCE_FAILURE';
export const GET_ATTENDANCES_SUCCESS = 'GET_ATTENDANCES_SUCCESS';
export const ADD_ATTENDANCES = 'ADD_ATTENDANCES';
export const REMOVE_ITEM_ATTENDANCES = 'REMOVE_ITEM_ATTENDANCES';
export const ADD_ATTENDANCE_NOTES = 'ADD_ATTENDANCE_NOTES';
export const RESET_ATTENDANCE_NOTES = 'RESET_ATTENDANCE_NOTES';
export const REMOVE_ITEM_ATTENDANCE_NOTE = 'REMOVE_ITEM_ATTENDANCE_NOTE';
export const GET_ATTENDANCES_FAILURE = 'GET_ATTENDANCES_FAILURE';
export const ADD_ATTENDANCE_PAGINATION = 'ADD_ATTENDANCE_PAGINATION';
export const RESET_ATTENDANCE_PAGINATION = 'RESET_ATTENDANCE_PAGINATION';
export const ADD_USER_ATTENDANCE = 'ADD_USER_ATTENDANCE';
export const RESET_USER_ATTENDANCE = 'RESET_USER_ATTENDANCE';

export const ADD_ATTENDANCE = 'ADD_ATTENDANCE';
export const ADD_ATTENDANCE_SUCCESS = 'ADD_ATTENDANCE_SUCCESS';
export const ADD_ATTENDANCE_FAILURE = 'ADD_ATTENDANCE_FAILURE';

export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS';
export const UPDATE_ATTENDANCE_FAILURE = 'UPDATE_ATTENDANCE_FAILURE';

export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS';
export const DELETE_ATTENDANCE_FAILURE = 'DELETE_ATTENDANCE_FAILURE';

export const CREATE_ATTENDANCE = 'CREATE_ATTENDANCE';
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const DELETE_ATTENDANCE = 'DELETE_ATTENDANCE';

//BOE
export const GET_BOE_SUCCESS = 'GET_BOE_SUCCESS';
export const GET_BOE_FAILURE = 'GET_BOE_FAILURE';
export const GET_BOES_SUCCESS = 'GET_BOES_SUCCESS';
export const ADD_BOES = 'ADD_BOES';
export const REMOVE_ITEM_BOES = 'REMOVE_ITEM_BOES';
export const GET_BOES_FAILURE = 'GET_BOES_FAILURE';
export const ADD_BOE_PAGINATION = 'ADD_BOE_PAGINATION';
export const RESET_BOE_PAGINATION = 'RESET_BOE_PAGINATION';

export const ADD_BOE = 'ADD_BOE';
export const ADD_BOE_SUCCESS = 'ADD_BOE_SUCCESS';
export const ADD_BOE_FAILURE = 'ADD_BOE_FAILURE';

export const UPDATE_BOE_SUCCESS = 'UPDATE_BOE_SUCCESS';
export const UPDATE_BOE_FAILURE = 'UPDATE_BOE_FAILURE';

export const DELETE_BOE_SUCCESS = 'DELETE_BOE_SUCCESS';
export const DELETE_BOE_FAILURE = 'DELETE_BOE_FAILURE';

export const CREATE_BOE = 'CREATE_BOE';
export const UPDATE_BOE = 'UPDATE_BOE';
export const DELETE_BOE = 'DELETE_BOE';

//DuesCollected
export const GET_DUES_COLLECTED_SUCCESS = 'GET_DUES_COLLECTED_SUCCESS';
export const GET_DUES_COLLECTED_FAILURE = 'GET_DUES_COLLECTED_FAILURE';
export const GET_DUE_SUCCESS = 'GET_DUE_SUCCESS';
export const ADD_DUE = 'ADD_DUE';
export const GET_DUE_FAILURE = 'GET_DUE_FAILURE';
export const ADD_DUES_COLLECTED_PAGINATION = 'ADD_DUES_COLLECTED_PAGINATION';
export const RESET_DUES_COLLECTED_PAGINATION = 'RESET_DUES_COLLECTED_PAGINATION';
export const ADD_PREVIOUS_BALANCE = 'ADD_PREVIOUS_BALANCE';
export const RESET_PREVIOUS_BALANCE = 'RESET_PREVIOUS_BALANCE';

export const ADD_DUES_COLLECTED = 'ADD_DUES_COLLECTED';
export const REMOVE_ITEM_DUES_COLLECTED = 'REMOVE_ITEM_DUES_COLLECTED';
export const ADD_DUES_COLLECTED_SUCCESS = 'ADD_DUES_COLLECTED_SUCCESS';
export const ADD_DUES_COLLECTED_FAILURE = 'ADD_DUES_COLLECTED_FAILURE';

export const UPDATE_DUES_COLLECTED_SUCCESS = 'UPDATE_DUES_COLLECTED_SUCCESS';
export const UPDATE_DUES_COLLECTED_FAILURE = 'UPDATE_DUES_COLLECTED_FAILURE';

export const DELETE_DUES_COLLECTED_SUCCESS = 'DELETE_DUES_COLLECTED_SUCCESS';
export const DELETE_DUES_COLLECTED_FAILURE = 'DELETE_DUES_COLLECTED_FAILURE';

export const CREATE_DUES_COLLECTED = 'CREATE_DUES_COLLECTED';
export const UPDATE_DUES_COLLECTED = 'UPDATE_DUES_COLLECTED';
export const DELETE_DUES_COLLECTED = 'DELETE_DUES_COLLECTED';

//History
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';
export const GET_HISTORIES_SUCCESS = 'GET_HISTORIES_SUCCESS';
export const ADD_HISTORIES = 'ADD_HISTORIES';
export const REMOVE_ITEM_HISTORIES = 'REMOVE_ITEM_HISTORIES';
export const GET_HISTORIES_FAILURE = 'GET_HISTORIES_FAILURE';
export const ADD_HISTORY_PAGINATION = 'ADD_HISTORY_PAGINATION';
export const RESET_HISTORY_PAGINATION = 'RESET_HISTORY_PAGINATION';

export const ADD_HISTORY = 'ADD_HISTORY';
export const ADD_HISTORY_SUCCESS = 'ADD_HISTORY_SUCCESS';
export const ADD_HISTORY_FAILURE = 'ADD_HISTORY_FAILURE';

export const UPDATE_HISTORY_SUCCESS = 'UPDATE_HISTORY_SUCCESS';
export const UPDATE_HISTORY_FAILURE = 'UPDATE_HISTORY_FAILURE';

export const DELETE_HISTORY_SUCCESS = 'DELETE_HISTORY_SUCCESS';
export const DELETE_HISTORY_FAILURE = 'DELETE_HISTORY_FAILURE';

export const CREATE_HISTORY = 'CREATE_HISTORY';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const DELETE_HISTORY = 'DELETE_HISTORY';

//COPA
export const GET_COPA_SUCCESS = 'GET_COPA_SUCCESS';
export const GET_COPA_FAILURE = 'GET_COPA_FAILURE';
export const GET_COPAS_SUCCESS = 'GET_COPAS_SUCCESS';
export const ADD_COPAS = 'ADD_COPAS';
export const REMOVE_ITEM_COPAS = 'REMOVE_ITEM_COPAS';
export const GET_COPAS_FAILURE = 'GET_COPAS_FAILURE';
export const ADD_COPA_PAGINATION = 'ADD_COPA_PAGINATION';
export const RESET_COPA_PAGINATION = 'RESET_COPA_PAGINATION';

export const ADD_COPA = 'ADD_COPA';
export const ADD_COPA_SUCCESS = 'ADD_COPA_SUCCESS';
export const ADD_COPA_FAILURE = 'ADD_COPA_FAILURE';

export const UPDATE_COPA_SUCCESS = 'UPDATE_COPA_SUCCESS';
export const UPDATE_COPA_FAILURE = 'UPDATE_COPA_FAILURE';

export const DELETE_COPA_SUCCESS = 'DELETE_COPA_SUCCESS';
export const DELETE_COPA_FAILURE = 'DELETE_COPA_FAILURE';

export const CREATE_COPA = 'CREATE_COPA';
export const UPDATE_COPA = 'UPDATE_COPA';
export const DELETE_COPA = 'DELETE_COPA';

//interimActing
export const GET_INTERIM_ACTING_SUCCESS = 'GET_INTERIM_ACTING_SUCCESS';
export const GET_INTERIM_ACTING_FAILURE = 'GET_INTERIM_ACTING_FAILURE';

export const ADD_INTERIM_ACTING = 'ADD_INTERIM_ACTING';
export const ADD_INTERIM_ACTING_SUCCESS = 'ADD_INTERIM_ACTING_SUCCESS';
export const ADD_INTERIM_ACTING_FAILURE = 'ADD_INTERIM_ACTING_FAILURE';

export const UPDATE_INTERIM_ACTING_SUCCESS = 'UPDATE_INTERIM_ACTING_SUCCESS';
export const UPDATE_INTERIM_ACTING_FAILURE = 'UPDATE_INTERIM_ACTING_FAILURE';

export const DELETE_INTERIM_ACTING_SUCCESS = 'DELETE_INTERIM_ACTING_SUCCESS';
export const DELETE_INTERIM_ACTING_FAILURE = 'DELETE_INTERIM_ACTING_FAILURE';

export const CREATE_INTERIM_ACTING = 'CREATE_INTERIM_ACTING';
export const UPDATE_INTERIM_ACTING = 'UPDATE_INTERIM_ACTING';
export const DELETE_INTERIM_ACTING = 'DELETE_INTERIM_ACTING';

//interimActing
export const GET_BENEFIT_SUCCESS = 'GET_BENEFIT_SUCCESS';
export const GET_BENEFIT_FAILURE = 'GET_BENEFIT_FAILURE';

export const ADD_BENEFIT = 'ADD_BENEFIT';
export const ADD_BENEFIT_SUCCESS = 'ADD_BENEFIT_SUCCESS';
export const ADD_BENEFIT_FAILURE = 'ADD_BENEFIT_FAILURE';

export const UPDATE_BENEFIT_SUCCESS = 'UPDATE_BENEFIT_SUCCESS';
export const UPDATE_BENEFIT_FAILURE = 'UPDATE_BENEFIT_FAILURE';

export const DELETE_BENEFIT_SUCCESS = 'DELETE_BENEFIT_SUCCESS';
export const DELETE_BENEFIT_FAILURE = 'DELETE_BENEFIT_FAILURE';

export const CREATE_BENEFIT = 'CREATE_BENEFIT';
export const UPDATE_BENEFIT = 'UPDATE_BENEFIT';
export const DELETE_BENEFIT = 'DELETE_BENEFIT';

export const GET_CUSTOM_REPORT_SUCCESS = 'GET_CUSTOM_REPORT_SUCCESS';
export const GET_CUSTOM_REPORT_FAILURE = 'GET_CUSTOM_REPORT_FAILURE';

export const ADD_CUSTOM_REPORT_PAGINATION = 'ADD_CUSTOM_REPORT_PAGINATION';
export const RESET_CUSTOM_REPORT_PAGINATION = 'RESET_CUSTOM_REPORT_PAGINATION';

export const GET_CUSTOM_REPORTS_SUCCESS = 'GET_CUSTOM_REPORTS_SUCCESS';
export const GET_CUSTOM_REPORTS_FAILURE = 'GET_CUSTOM_REPORTS_FAILURE';
export const ADD_CUSTOM_REPORTS = 'ADD_CUSTOM_REPORTS';
export const GET_ENTITIES_FAILURE = 'GET_ENTITIES_FAILURE';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const ADD_ENTITIES = 'ADD_ENTITIES';

export const ADD_CUSTOM_REPORT = 'ADD_CUSTOM_REPORT';
export const ADD_CUSTOM_REPORT_SUCCESS = 'ADD_CUSTOM_REPORT_SUCCESS';
export const ADD_CUSTOM_REPORT_FAILURE = 'ADD_CUSTOM_REPORT_FAILURE';

export const UPDATE_CUSTOM_REPORT_SUCCESS = 'UPDATE_CUSTOM_REPORT_SUCCESS';
export const UPDATE_CUSTOM_REPORT_FAILURE = 'UPDATE_CUSTOM_REPORT_FAILURE';

export const DELETE_CUSTOM_REPORT_SUCCESS = 'DELETE_CUSTOM_REPORT_SUCCESS';
export const DELETE_CUSTOM_REPORT_FAILURE = 'DELETE_CUSTOM_REPORT_FAILURE';

export const CREATE_CUSTOM_REPORT = 'CREATE_CUSTOM_REPORT';
export const UPDATE_CUSTOM_REPORT = 'UPDATE_CUSTOM_REPORT';
export const DELETE_CUSTOM_REPORT = 'DELETE_CUSTOM_REPORT';

//Site CRUD
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';

export const GET_SITE = 'GET_SITE';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
export const RESET_GET_SITE = 'RESET_GET_SITE';
export const SITE_VERIFICATION = 'SITE_VERIFICATION';
export const WORK_SITE_VERIFICATION = 'WORK_SITE_VERIFICATION';

export const GET_SITES = 'GET_SITES';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';

export const ADD_SITE = 'ADD_SITE';
export const ADD_SITE_COMMENT = 'ADD_SITE_COMMENT';
export const ADD_PARENT_SITE = 'ADD_PARENT_SITE';
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SITE_FAILURE = 'ADD_SITE_FAILURE';
export const RESET_SITE = 'RESET_SITE';
export const RESET_SITE_COMMENT = 'RESET_SITE_COMMENT';
export const RESET_PARENT_SITE = 'RESET_PARENT_SITE';

export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAILURE = 'UPDATE_SITE_FAILURE';

export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';

export const ADD_SITE_PAGINATION = 'ADD_SITE_PAGINATION';
export const RESET_SITE_PAGINATION = 'RESET_SITE_PAGINATION';

//Visit CRUD
export const CREATE_VISIT = 'CREATE_VISIT';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const DELETE_VISIT = 'DELETE_VISIT';

export const GET_VISIT = 'GET_VISIT';
export const GET_VISIT_SUCCESS = 'GET_VISIT_SUCCESS';
export const GET_VISIT_FAILURE = 'GET_VISIT_FAILURE';
export const RESET_GET_VISIT = 'RESET_GET_VISIT';
export const VISIT_VERIFICATION = 'VISIT_VERIFICATION';
export const WORK_VISIT_VERIFICATION = 'WORK_VISIT_VERIFICATION';

export const GET_VISITS = 'GET_VISITS';
export const GET_VISITS_SUCCESS = 'GET_VISITS_SUCCESS';
export const GET_VISITS_FAILURE = 'GET_VISITS_FAILURE';

export const ADD_VISIT = 'ADD_VISIT';
export const ADD_VISIT_COMMENT = 'ADD_VISIT_COMMENT';
export const ADD_PARENT_VISIT = 'ADD_PARENT_VISIT';
export const ADD_VISIT_SUCCESS = 'ADD_VISIT_SUCCESS';
export const ADD_VISIT_FAILURE = 'ADD_VISIT_FAILURE';
export const RESET_VISIT = 'RESET_VISIT';
export const RESET_VISIT_COMMENT = 'RESET_VISIT_COMMENT';
export const RESET_PARENT_VISIT = 'RESET_PARENT_VISIT';

export const UPDATE_VISIT_SUCCESS = 'UPDATE_VISIT_SUCCESS';
export const UPDATE_VISIT_FAILURE = 'UPDATE_VISIT_FAILURE';

export const DELETE_VISIT_SUCCESS = 'DELETE_VISIT_SUCCESS';
export const DELETE_VISIT_FAILURE = 'DELETE_VISIT_FAILURE';

export const ADD_VISIT_PAGINATION = 'ADD_VISIT_PAGINATION';
export const RESET_VISIT_PAGINATION = 'RESET_VISIT_PAGINATION';

export const PREPARE_DAY_CARE_SUCCESS = 'PREPARE_DAY_CARE_SUCCESS';
export const PROCESSING_PAYROLL_SUCCESS = 'PROCESSING_PAYROLL_SUCCESS';
export const PROCESSING_OPA_SUCCESS = 'PROCESSING_OPA_SUCCESS';
export const PROCESSING_BOE_SUCCESS = 'PROCESSING_BOE_SUCCESS';
export const ASSOCIATION_SUMMARY_REPORT_SUCCESS = 'ASSOCIATION_SUMMARY_REPORT_SUCCESS';
export const NEW_TO_ASSOCIATION_REPORT_SUCCESS = 'NEW_TO_ASSOCIATION_REPORT_SUCCESS';
export const NEW_TO_PAYROLL_REPORT_SUCCESS = 'NEW_TO_PAYROLL_REPORT_SUCCESS';
export const OFF_PAYROLL_REPORT_SUCCESS = 'OFF_PAYROLL_REPORT_SUCCESS';
export const STATUS_CHANGE_REPORT_SUCCESS = 'STATUS_CHANGE_REPORT_SUCCESS';
export const LEFT_ASSOCIATION_REPORT_SUCCESS = 'LEFT_ASSOCIATION_REPORT_SUCCESS';
export const COPE_COLLECTED_BY_DISTRICT_REPORT_SUCCESS = 'COPE_COLLECTED_BY_DISTRICT_REPORT_SUCCESS';
export const ON_PAYROLL_NO_BOE_REPORT_SUCCESS = 'ON_PAYROLL_NO_BOE_REPORT_SUCCESS';
export const COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS = 'COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS';
export const COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS = 'COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_SUCCESS';
export const COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_SUCCESS = 'COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_SUCCESS';
export const COPE_COLLECTED_DETAIL_REPORT_SUCCESS = 'COPE_COLLECTED_DETAIL_REPORT_SUCCESS';
export const NEW_MEMBER_REPORT_SUCCESS = 'NEW_MEMBER_REPORT_SUCCESS';
export const DELETED_MEMBER_REPORT_SUCCESS = 'DELETED_MEMBER_REPORT_SUCCESS';
export const CHANGE_IN_TITLE_REPORT_SUCCESS = 'CHANGE_IN_TITLE_REPORT_SUCCESS';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_SUCCESS = 'CHANGE_IN_SCHOOL_TITLE_REPORT_SUCCESS';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_SUCCESS = 'CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_SUCCESS';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_SUCCESS = 'CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_SUCCESS';
export const CHANGE_IN_SCHOOL_REPORT_SUCCESS = 'CHANGE_IN_SCHOOL_REPORT_SUCCESS';
export const ASSOCIATION_DETAIL_REPORT_SUCCESS = 'ASSOCIATION_DETAIL_REPORT_SUCCESS';
export const PROCESSING_PAYROLL_FAILURE = 'PROCESSING_PAYROLL_FAILURE';
export const PROCESSING_OPA_FAILURE = 'PROCESSING_OPA_FAILURE';
export const PROCESSING_BOE_FAILURE = 'PROCESSING_BOE_FAILURE';
export const ASSOCIATION_SUMMARY_REPORT_FAILURE = 'ASSOCIATION_SUMMARY_REPORT_FAILURE';
export const NEW_TO_ASSOCIATION_REPORT_FAILURE = 'NEW_TO_ASSOCIATION_REPORT_FAILURE';
export const NEW_TO_PAYROLL_REPORT_FAILURE = 'NEW_TO_PAYROLL_REPORT_FAILURE';
export const OFF_PAYROLL_REPORT_FAILURE = 'OFF_PAYROLL_REPORT_FAILURE';
export const STATUS_CHANGE_REPORT_FAILURE = 'STATUS_CHANGE_REPORT_FAILURE';
export const LEFT_ASSOCIATION_REPORT_FAILURE = 'LEFT_ASSOCIATION_REPORT_FAILURE';
export const COPE_COLLECTED_BY_DISTRICT_REPORT_FAILURE = 'COPE_COLLECTED_BY_DISTRICT_REPORT_FAILURE';
export const ON_PAYROLL_NO_BOE_REPORT_FAILURE = 'ON_PAYROLL_NO_BOE_REPORT_FAILURE';
export const COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE = 'COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE';
export const COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE = 'COPE_NO_COPE_COLLECTED_BY_DISTRICT_ZERO_REPORT_FAILURE';
export const COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_FAILURE = 'COPE_COLLECTED_BY_DISTRICT_ZERO_DETAIL_REPORT_FAILURE';
export const COPE_COLLECTED_DETAIL_REPORT_FAILURE = 'COPE_COLLECTED_DETAIL_REPORT_FAILURE';
export const NEW_MEMBER_REPORT_FAILURE = 'NEW_MEMBER_REPORT_FAILURE';
export const DELETED_MEMBER_REPORT_FAILURE = 'DELETED_MEMBER_REPORT_FAILURE';
export const CHANGE_IN_TITLE_REPORT_FAILURE = 'CHANGE_IN_TITLE_REPORT_FAILURE';
export const CHANGE_IN_SCHOOL_REPORT_FAILURE = 'CHANGE_IN_SCHOOL_REPORT_FAILURE';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_FAILURE = 'CHANGE_IN_SCHOOL_TITLE_REPORT_FAILURE';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_FAILURE = 'CHANGE_IN_SCHOOL_TITLE_REPORT_NO_ZONE_SCHOOL_FAILURE';
export const CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_FAILURE = 'CHANGE_IN_SCHOOL_TITLE_REPORT_ZONE_SCHOOL_ONLY_FAILURE';
export const ASSOCIATION_DETAIL_REPORT_FAILURE = 'ASSOCIATION_DETAIL_REPORT_FAILURE';
export const PREPARE_DAY_CARE_FAILURE = 'PREPARE_DAY_CARE_FAILURE';
export const POST_DAY_CARE_PAYMENT_FAILURE = 'POST_DAY_CARE_PAYMENT_FAILURE';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAILURE = 'REPORT_FAILURE';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const DISTRICT_ROSTER_SUCCESS = 'DISTRICT_ROSTER_SUCCESS';
export const DISTRICT_ROSTER_FAILURE = 'DISTRICT_ROSTER_FAILURE';
export const REGION_ROSTER_SUCCESS = 'REGION_ROSTER_SUCCESS';
export const REGION_ROSTER_FAILURE = 'REGION_ROSTER_FAILURE';
export const PER_CAPITA_SUCCESS = 'PER_CAPITA_SUCCESS';
export const PER_CAPITA_FAILURE = 'PER_CAPITA_FAILURE';
export const EXTRACT_ADDRESSES_SUCCESS = 'EXTRACT_ADDRESSES_SUCCESS';
export const EXTRACT_ADDRESSES_FAILURE = 'EXTRACT_ADDRESSES_FAILURE';
export const EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_SUCCESS = 'EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_SUCCESS';
export const EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_FAILURE = 'EXTRACT_ADDRESSES_FOR_SPECIFIC_TITLES_FAILURE';
export const CHARTER_SCHOOL_SUCCESS = 'CHARTER_SCHOOL_SUCCESS';
export const CHARTER_SCHOOL_FAILURE = 'CHARTER_SCHOOL_FAILURE';

export const BONUS_TYPE = 0
export const SENIORITY_TYPE = 1
