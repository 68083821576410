import { useEffect, useState }         from 'react'
import { memberService } from "../services";
import { useLocation }                 from "react-router";
import { useDefaultLimit }             from "./WindowUtil";

export const useMemberList = (onlyWithSite) => {

  const {defaultLimit} = useDefaultLimit()
  const [members, setMembers] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "Name", descending: false});

  const getMembers = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending) => {
    memberService()
      .getMembers(limit, skip, filterName, columnSort, descending, onlyWithSite)
      .then(res => {
        if (res && res.data) {
          setMembers(res.data);
          const memberPaginationList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            filterName
          }
          setPagination(memberPaginationList)
          sessionStorage.setItem("memberPaginationList" + (onlyWithSite || ""), JSON.stringify(memberPaginationList))
        }
      })
  };

  useEffect(() => {
    let memberPaginationList = sessionStorage.getItem("memberPaginationList" + (onlyWithSite || ""));
    if (memberPaginationList) {
      memberPaginationList = JSON.parse(memberPaginationList)
      setPagination(memberPaginationList)
      getMembers(defaultLimit || memberPaginationList.limit, memberPaginationList.skip, memberPaginationList.filterName, memberPaginationList.columnSort, memberPaginationList.descending)
    } else {
      getMembers()
    }
    return () => {
      if (onlyWithSite) {
        sessionStorage.removeItem("memberPaginationList" + onlyWithSite)
      }
    }
    // eslint-disable-next-line
  }, []);

  return {members, pagination, getMembers}
};

export const useMember = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const memberParamId = param.get("member");

  const [member, setMember] = useState({});

  const getMember = (memberId) => {
    if (memberId || id || memberParamId) {
      memberService()
        .getMember({id: memberId || id || memberParamId})
        .then(res => {
          if (res && res.data) {
            setMember(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setMember({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    if (member.id !== memberParamId) {
      getMember()
    }
    // eslint-disable-next-line
  }, [memberParamId, id]);

  return {
    member,
    setMember,
    getMember,
    memberId: memberParamId
  }
};
