import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap";

const SearchBonusSeniority = ({onSearch, fileNoDef, lastNameDef}) => {

    const [fileNo, setFileNo] = useState(fileNoDef || "");
    const [lastName, setLastName] = useState(lastNameDef || "");

    useEffect(() => {
        setFileNo(fileNoDef)
        setLastName(lastNameDef)
    }, [fileNoDef, lastNameDef])

    return (
        <div className="d-flex mb-1" style={{maxWidth: "400px", width: "100%"}}>
            <input
                type="search" name="fileNo" className="form-control" value={fileNo || ""} placeholder="File #"
                onKeyUp={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const value = e.target.value
                    if (e.keyCode === 27) {
                        setFileNo("");
                        onSearch("", "")
                    } else if (e.keyCode === 13 && lastName && value) {
                        onSearch(value, lastName)
                    }
                }}
                onChange={(e) => {
                    if (e.target.value === '') {
                        onSearch("", "")
                    }
                    setFileNo(e.target.value)
                }}/>
            <input
                type="search" name="lastName" className="form-control" value={lastName || ""} style={{marginLeft: "3px", minWidth: "200px"}} placeholder="Lastname"
                onKeyUp={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const value = e.target.value
                    if (e.keyCode === 27) {
                        setLastName("");
                        onSearch("", "")
                    } else if (e.keyCode === 13 && fileNo && value) {
                        onSearch(fileNo, value)
                    }
                }}
                onChange={(e) => {
                    if (e.target.value === '') {
                        onSearch("", "")
                    }
                    setLastName(e.target.value)
                }}/>
            <Button style={{marginLeft: "3px"}} onClick={() => {
                if (fileNo && lastName) {
                    onSearch(fileNo, lastName)
                }
            }}>Search</Button>
        </div>
    )
};

export default SearchBonusSeniority