import React                   from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const MyTooltip = (props) => {

  let tmp = {...props, delay: {show: 350, hide: 350}};

  return (
    <UncontrolledTooltip {...tmp}>
      {props.children}
    </UncontrolledTooltip>
  );
};

export default MyTooltip;
