import React from 'react'

const ReadOnly = ({children}) => {
  let isSuperUser = false;

  let rol = localStorage.getItem("rol");

  if (rol && typeof rol === "string" && /SuperUser/.test(rol)) {
    isSuperUser = true
  }

  if (isSuperUser) {
    return children
  }

  return <span style={{pointerEvents: "none", touchAction: "none", cursor: "not-allowed", width: "100%", opacity: .7}}>{children}</span>
};

export default ReadOnly