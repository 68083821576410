import React                                                  from 'react';
import { Table }                                              from 'reactstrap';
import { ColumnSort, MyPagination, Search, SelectMemberItem } from "../index";
import { useMemberList }                                      from "../../hooks";

let search = "";

const SelectMemberList = ({selectMember, isUnionInitiatedAllow}) => {


  const {members, getMembers, pagination: paginationMember} = useMemberList(true);

  let items = [];

  if (members.length) {
    for (let i in members) {
      items.push(<SelectMemberItem key={"member-item" + i} member={members[i]} index={i} selectMember={selectMember} isUnionInitiatedAllow={isUnionInitiatedAllow}/>);
    }
  }

  const sortFn = (object) => {
    getMembers(paginationMember.limit, paginationMember.skip, search, object.columnSort, object.descending)
  };

  return (
    <div className="member-list">
      <Search defaultValue={paginationMember.filterName} onSearch={(value) => {
        search = value;
        getMembers(paginationMember.limit, 0, value, paginationMember.columnSort, paginationMember.descending)
      }}/>
      <h3>Member List</h3>
      <Table hover responsive>
        <thead>
        <tr>
          <th style={{minWidth: "90px"}}>File No. <ColumnSort columnName="Fileno" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Name <ColumnSort columnName="Name" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "104px"}}>District <ColumnSort columnName="District" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {members.length ?
          items :
          <tr>
            <th colSpan="5" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getMembers()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {members.length > 0 && paginationMember.count > 10 &&
      <MyPagination count={paginationMember.count} limit={paginationMember.limit} skip={paginationMember.skip} action={(skip, limit) => {
        getMembers(limit, skip, search, paginationMember.columnSort, paginationMember.descending)
      }}/>}

    </div>
  );
};


export default SelectMemberList;
