import { useEffect, useState }  from 'react'
import { ratingWaiveService }   from "../services";
import { useLocation }          from "react-router";
import { setAlertNotification } from "../actions/util";

export const useRatingWaiveList = (props) => {

  const {id, onChange} = props || {};

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const ratingParamId = param.get("rating");

  const [ratingWaives, setRatingWaives] = useState([]);

  const getRatingWaives = (ratingId) => {
    if (ratingId || id || ratingParamId) {
      ratingWaiveService()
        .getAllByRating(ratingId || id || ratingParamId)
        .then(res => {
          if (res && res.data) {
            setRatingWaives(res.data);
            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setRatingWaives({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getRatingWaives()
    // eslint-disable-next-line
  }, [ratingParamId, id]);

  const deleteRatingWaive = (id) => {
    ratingWaiveService()
      .deleteWaive({id})
      .then(res => {
        getRatingWaives()
      })
      .catch(err => {
        setAlertNotification("Error deleting an Waive", "danger")
      });
  }

  return {
    ratingWaives,
    getRatingWaives,
    ratingId: ratingParamId,
    deleteRatingWaive
  }
};
