import React, { useEffect }                             from 'react';
import { Table }                                        from 'reactstrap';
import { ColumnSort, MemberItem, MyPagination, Search } from "../index";
import { useMemberList, useStatus, useTitle }           from "../../hooks";

let search = "";

const MemberList = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {members, getMembers: getMembersAction, pagination: paginationMember} = useMemberList();
  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();

  let items = [];

  if (members.length) {
    for (let i in members) {
      items.push(<MemberItem key={"member-item" + i} member={members[i]} index={i} getJobDescription={getJobDescription} getCategory={getCategory}/>);
    }
  }

  const sortFn = (object) => {
    getMembersAction(paginationMember.limit, paginationMember.skip, search, object.columnSort, object.descending)
  };

  return (
    <div className="member-list">
      <Search defaultValue={paginationMember.filterName} onSearch={(value) => {
        search = value.replace(",", "");
        getMembersAction(paginationMember.limit, 0, search, paginationMember.columnSort, paginationMember.descending)
      }}>Member List</Search>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "90px"}}>File No. <ColumnSort columnName="Fileno" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Name <ColumnSort columnName="Name" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Site <ColumnSort columnName="Sitecode" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "104px"}}>District <ColumnSort columnName="District" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "145px"}}>Contact <ColumnSort columnName="Contacts" columnSort={paginationMember.columnSort} descending={paginationMember.descending} changeSortAction={sortFn}/></th>
        </tr>
        </thead>
        <tbody>
        {members.length ?
          items :
          <tr>
            <th colSpan="5" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getMembersAction()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {members.length > 0 && paginationMember.count > 10 &&
      <MyPagination count={paginationMember.count} limit={paginationMember.limit} skip={paginationMember.skip} action={(skip, limit) => {
        getMembersAction(limit, skip, search, paginationMember.columnSort, paginationMember.descending)
      }}/>}

    </div>
  );
};

export default MemberList;
