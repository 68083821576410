import { useEffect, useState }                  from 'react'
import { investigationService }   from "../services";
import { useHistory, useLocation }              from "react-router";
import { naturalCompare, setAlertNotification } from "../actions/util";
import { appPaths }                             from "../routes";
import { useAllName }                           from "./User";
import { useDefaultLimit }                      from "./index";

export const useInvestigationList = () => {

  const [investigations, setInvestigations] = useState([]);
  const {defaultLimit} = useDefaultLimit()
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "LastModification", descending: true, open: 1});
  const {getName} = useAllName();

  const getInvestigations = (limit = pagination.limit, skip = pagination.skip, filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    investigationService()
      .getInvestigations(limit, skip, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          let list = res.data;
          if (columnSort === "Advocate") {
            list.sort((a, b) => naturalCompare((a.advocates || [{userName: 'z'}]).map(advocate => getName(advocate.userName)).join(','), (b.advocates || [[{userName: 'z'}]]).map(advocate => getName(advocate.userName)).join(',')))
            if (descending) {
              list.reverse()
            }
          }

          setInvestigations(list);
          const investigationPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            open,
            filterName
          }
          setPagination(investigationPaginateList)
          sessionStorage.setItem("investigationPaginateList", JSON.stringify(investigationPaginateList))
        }
      })
  };

  useEffect(() => {
    let investigationPaginateList = sessionStorage.getItem("investigationPaginateList")
    if (investigationPaginateList) {
      investigationPaginateList = JSON.parse(investigationPaginateList)
      getInvestigations(defaultLimit || investigationPaginateList.limit, investigationPaginateList.skip, investigationPaginateList.filterName, investigationPaginateList.columnSort, investigationPaginateList.descending, investigationPaginateList.open)
    } else {
      getInvestigations()
    }
    // eslint-disable-next-line
  }, []);

  return {investigations, pagination, getInvestigations}
};

export const useInvestigation = ({id, onChange, closeModal}) => {

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const investigationParamId = param.get("investigation");

  const [investigation, setInvestigation] = useState({});

  const getInvestigation = (investigationId) => {
    if (investigationId || id || investigationParamId) {
      investigationService()
        .getInvestigation(investigationId || id || investigationParamId)
        .then(res => {
          if (res && res.data) {
            setInvestigation(res.data);

            if (res.data.siteModelId && res.data.memberModelId) {
              history.push(appPaths.InvestigationDetail + "?investigation=" + res.data.id + "&site=" + res.data.siteModelId + "&member=" + res.data.memberModelId)
            }

            if (onChange) {
              onChange(res.data);
            }
          }
        })
        .catch(err => {
          setInvestigation({});
          if (onChange) {
            onChange({});
          }
        })
    }
  };

  useEffect(() => {
    getInvestigation()
    // eslint-disable-next-line
  }, [investigationParamId, id]);

  const changeInvestigationState = (values) => {console.log(values?.closedOn)
    let params = {id: investigation.id, isOpen: !investigation.isOpen};
    if (values) {
      params = {...params, outcome: values.outcome, closedOn: values.closedOn}
    }
    investigationService()
      .changeState(params)
      .then(() => {
        getInvestigation();
        closeModal();
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };
  const deleteInvestigation = () => {
    investigationService()
      .deleteInvestigation(investigationParamId)
      .then(() => {
        history.push(appPaths.InvestigationList);
        setAlertNotification("Action")
      })
      .catch(err => {
        setAlertNotification("Action", "danger")
      });
  };

  return {
    investigation,
    getInvestigation,
    investigationId: investigationParamId,
    changeInvestigationState,
    isInvestigationOpen: investigation.isOpen || typeof investigation.isOpen === "undefined",
    deleteInvestigation
  }
};
