import React, { useEffect, useState }                              from 'react';
import { Button, CustomInput, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { visitMemberCommentService }                               from "../../services";
import { useForm }                                                 from "react-hook-form";
import { UsersList }                                               from "../index";
import { useUserList }                                             from "../../hooks";

const VisitMemberEditComment = ({editComment, setEditComment, getVisitMemberComments}) => {

  const {register, handleSubmit, errors, reset} = useForm();
  const [isSendLegal, setIsSendLegal] = useState(false);
  const [isSendMembership, setIsSendMembership] = useState(false);
  const [isSendToUsers, setIsSendToUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {users, getUsers, pagination: paginationUser} = useUserList();

  useEffect(() => {
    reset({...editComment})
    setIsSendLegal(editComment.isSendLegal);
    setIsSendMembership(editComment.isSendMembership);
    setIsSendToUsers(editComment.isSendToUsers);
    if (editComment.id) {
      visitMemberCommentService().getAllVisitMemberCommentUsers(editComment.id).then(res => {
        if (res.data) {
          setSelectedUsers(res.data);
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const closeModal = () => {setEditComment({})};

  return (
    <Modal isOpen={true} toggle={closeModal} className="md-modal">
      <ModalHeader toggle={closeModal}>Edit Comment</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit((values) => {
          if (editComment.id) {
            visitMemberCommentService().updateVisitMemberComment({...editComment, ...values, isSendLegal, isSendMembership, isSendToUsers, users: selectedUsers}).then(() => {
              getVisitMemberComments();
              setEditComment({})
            })
          }
        })}>
          <Row className="no-margin">
            <div className="col-11 mb-2 no-padding">
              <textarea className="form-control" name="comment" defaultValue="" ref={register({required: true})}/>
              <small className="text-danger" style={{position: "absolute", bottom: "-17px"}}>{errors.comment && "This field is required"}</small>
            </div>
            <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>

            <CustomInput
              className="mb-2 mt-auto ml-2"
              type="checkbox"
              id={"send-to-legal-" + editComment.visitModelId}
              checked={isSendLegal}
              inline={true}
              label="Send to Legal"
              onChange={() => {
                setIsSendLegal(!isSendLegal)
              }}/>

            <CustomInput
              className="mb-2 mt-auto ml-2"
              type="checkbox"
              id={"send-to-debbie-" + editComment.visitModelId}
              checked={isSendMembership}
              label="Send to Membership"
              inline={true}
              onChange={() => {
                setIsSendMembership(!isSendMembership)
              }}/>

            <CustomInput
              className="mb-2 mt-auto ml-2"
              type="checkbox"
              id={"send-to-user-" + editComment.visitModelId}
              checked={isSendToUsers}
              label="Send to User"
              inline={true}
              onChange={() => {
                setIsSendToUsers(!isSendToUsers)
              }}/>

            {isSendToUsers && <div className="col-12 mt-2 px-1 pt-1 bg-white no-padding">

              <div>
                <h5>Users Selected</h5>
                <p style={{height: "80px", overflow: "auto", border: "1px solid #797979", borderRadius: "4px", padding: "0", backgroundColor: "#f5f5f5"}}>
                  {selectedUsers && selectedUsers.map((value, index) => (
                    <span key={index} style={{border: "1px solid", borderRadius: "4px", padding: "1px 0 3px 4px", margin: "4px", backgroundColor: "white", display: "inline-block"}}>
                      {value.name}
                      <sup style={{fontWeight: "bold", textShadow: "0 1px 0 #fff", opacity: ".5", padding: "0 4px", cursor: "pointer"}} onClick={() => {
                        let tmp = [];
                        for (let i in selectedUsers) {
                          if (selectedUsers.hasOwnProperty(i) && selectedUsers[i].id !== value.id) {
                            tmp.push(selectedUsers[i])
                          }
                        }
                        setSelectedUsers(tmp)
                      }}>x</sup>
                    </span>
                  ))}
                </p>
              </div>

              <UsersList users={users} getUsers={getUsers} paginationUser={paginationUser} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}/>
            </div>}

            <div className="col-12" style={{marginTop: "30px", borderTop: "1px solid #797979", paddingTop: "15px"}}>
              <input type="submit" className="btn btn-outline-secondary" value="Save"/>
              {" "}
              or
              {" "}
              <Button outline color="danger" onClick={closeModal}>Cancel</Button>
            </div>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default VisitMemberEditComment;
