import * as types       from "../types";

export const site = {
  CRUDSite: (state = types.CREATE_SITE, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_SITE:
        return types.CREATE_SITE;
      case types.UPDATE_SITE:
        return types.UPDATE_SITE;
      case types.DELETE_SITE:
        return types.DELETE_SITE;
      default:
        return state
    }
  },
  getSite: (state = {}, action) => {
    switch (action.type) {
      case types.GET_SITE_SUCCESS:
        return action.data;
      case types.GET_SITE_FAILURE:
        return {};
      case types.RESET_GET_SITE:
        return {};
      default:
        return state
    }
  },
  parentSite: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_PARENT_SITE:
        return action.data;
      case types.RESET_PARENT_SITE:
        return {};
      default:
        return state
    }
  },
  getSites: (state = [], action) => {
    switch (action.type) {
      case types.GET_SITES_SUCCESS:
        return action.data;
      case types.GET_SITES_FAILURE:
        return [];
      default:
        return state
    }
  },
  site: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_SITE:
        return action.data;
      case types.RESET_SITE:
        return {};
      default:
        return state
    }
  },
  siteComment: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_SITE_COMMENT:
        return action.data;
      case types.RESET_SITE_COMMENT:
        return {};
      default:
        return state
    }
  },
  sites: (state = [], action) => {
    switch (action.type) {
      case types.GET_SITES_SUCCESS:
        return action.data;
      case types.GET_SITES_FAILURE:
        return [];
      default:
        return state
    }
  },
  paginationSite: (state = {count: 0, limit: 10, skip: 0, filterName: "", columnSort: "", descending: false}, action) => {
    switch (action.type) {
      case types.ADD_SITE_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_SITE_PAGINATION:
        return {};
      default:
        return state
    }
  }
};