import { visitService } from "../services/index";

import * as types   from "../types";
import { dispatch } from "./util";
import messages     from "../util/messages";
import { appPaths } from "../routes";

export const getVisitAction = (id, cb) => {
  dispatch(types.FETCHING);
  visitService().getVisit({id})
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_VISIT, res);
      if (cb && res && res.data) {
        cb(res.data)
      }
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_VISIT, {data: {}});
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: "Visit Not Found", color: 'danger'});
    });
};

export const getVisitsAction = (limit, skip, filterName, columnSort, descending) => {
  dispatch(types.FETCHING);
  visitService().getVisitsMemberContacts(limit, skip, filterName, columnSort, descending)
    .then(res => {
      dispatch(types.GET_VISITS_SUCCESS, res);
      dispatch(types.ADD_VISIT_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filterName,
          columnSort,
          descending
        }
      });
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const addVisitAction = (visit, history) => {
  dispatch(types.FETCHING);
  visitService().addVisit(visit)
    .then(res => {console.log(res)
      history.push(appPaths.VisitDetail + "?visit=" + res.data + "&site=" + visit.siteModelId);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_VISIT_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      console.log(err);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_VISIT_FAILURE], color: 'danger'});
    });
};

export const updateVisitAction = (visit, history) => {
  dispatch(types.FETCHING);
  visitService().updateVisit(visit)
    .then(res => {
      dispatch(types.UPDATE_VISIT_SUCCESS, {});
      history.goBack();
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_VISIT_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_VISIT_FAILURE], color: 'danger'});
    });
};

export const deleteVisitAction = (id) => {
  dispatch(types.FETCHING);
  visitService().deleteVisit({id})
    .then(res => {
      getVisitsAction();
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_VISIT_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_VISIT_FAILURE], color: 'danger'});
    });
};
