import { useEffect, useState }            from 'react'
import { bonusService } from "../services";
import { useDefaultLimit }                from './WindowUtil';


export const useBonusList = () => {

  const {defaultLimit} = useDefaultLimit()
  const [bonus, setBonus] = useState([]);
  const [pagination, setPagination] = useState({limit: defaultLimit, skip: 0, columnSort: "", descending: true, fileNo: "", lastName: ""});

  const getBonus = (limit = pagination.limit, skip = pagination.skip, columnSort = pagination.columnSort, descending = pagination.descending, fileNo = pagination.fileNo, lastName = pagination.lastName) => {
    bonusService()
      .getBonus(limit, skip, columnSort, descending, fileNo, lastName)
      .then(res => {
        if (res && res.data) {
          setBonus(res.data);
          const bonusPaginateList = {
            ...pagination,
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            columnSort,
            descending,
            fileNo,
            lastName
          }
          setPagination(bonusPaginateList)
          sessionStorage.setItem("bonusPaginateList", JSON.stringify(bonusPaginateList))
        }
      })
  };
  const deleteBonus = () => {
    bonusService()
      .deleteBonus()
      .then(res => {
        setBonus([])
      })
  };
  useEffect(() => {
    let bonusPaginateList = sessionStorage.getItem("bonusPaginateList")
    if (bonusPaginateList) {
      bonusPaginateList = JSON.parse(bonusPaginateList)
      getBonus(defaultLimit || bonusPaginateList.limit, bonusPaginateList.skip, bonusPaginateList.columnSort, bonusPaginateList.descending)
    } else {
      getBonus()
    }
    // eslint-disable-next-line
  }, []);


  return {bonus, pagination, getBonus, deleteBonus}
};

