import React                                                 from 'react'
import { dateFormatMonthDayYear }                            from "../../actions/util";
import { CheckIt, ColumnSort, MyPagination, ReplaceOnWidth } from "../index";
import { Badge, Button, Table }                              from "reactstrap";
import { useAllName }                                        from "../../hooks";
import { isAllow, userActionsKeys }                          from "../../util/userActions";
import { FontAwesomeIcon }                                   from "@fortawesome/react-fontawesome";
import { faPlus }                                            from "@fortawesome/free-solid-svg-icons";

const RatingActivityList = ({ratingActivities, pagination, isRatingOpen, onPaginationAction, setRatingActivity, paginationRatingActivities, setActivityToEdit, setIsActivityModalOpen}) => {

  const {getName} = useAllName();

  const sortFn = (object) => {
    onPaginationAction(pagination.limit, pagination.skip, object.columnSort, object.descending)
  };

  return (
    <>
      <div className="col-12 detail-header border-0">
        <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{paginationRatingActivities.count}</Badge> Rating Activities
        <CheckIt action={userActionsKeys.addRatingActivityLegal}>
          <span style={{position: "absolute", right: "15px"}}>
             {isRatingOpen && <Button style={{marginTop: "-5px"}} onClick={() => {
               setActivityToEdit({});
               setIsActivityModalOpen(true)
             }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>}
            </span>
        </CheckIt>
      </div>
      <div className="col-12 detail no-padding">
        <Table hover className="table-dotted table-responsive-vertical">
          <thead>
          <tr>
            <th style={{minWidth: "106px"}}>Date/Type <ColumnSort columnName="ActivityDate" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
            <th style={{minWidth: "104px"}}>Comment <ColumnSort columnName="Comment" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          </tr>
          </thead>
          <tbody>
          {ratingActivities.length > 0 ? ratingActivities.map((value, index) => (
            <tr key={index + pagination.skip}>
              <th>
                <div>
              <span className={isRatingOpen && isAllow(userActionsKeys.updateRatingActivityLegal) ? "simulate-a" : ""} onClick={() => {
                if (isRatingOpen && isAllow(userActionsKeys.updateRatingActivityLegal)) {
                  setRatingActivity(value);
                }
              }}>{value.type || "Detail"}</span>
                </div>
                <div>{dateFormatMonthDayYear(value.activityDate)}</div>
              </th>
              <th>
                <div>{value.comment && <span dangerouslySetInnerHTML={{__html: value.comment.replace(/\n/g, "<br/>")}}/>}</div>
                <div>
                  <small style={{color: "#999"}}>{getName(value.userName)} on {dateFormatMonthDayYear(value.updateDate)}</small>
                </div>
              </th>
            </tr>
          )) : <tr>
            <th colSpan="2" className="text-center">No content data</th>
          </tr>}
          </tbody>
        </Table>

        {ratingActivities.length > 0 && pagination.count > 10 &&
        <div style={{padding: "30px 15px 0", overflow: "auto"}}>
          <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
            onPaginationAction(limit, skip)
          }}/>
        </div>}
      </div>
    </>
  )
};

export default RatingActivityList;