import React                                                     from 'react'
import { ColumnSort, MyLink, MyPagination, VisitMemberListView } from "../index";
import { Table }                                                 from "reactstrap";
import { appPaths }                                              from "../../routes";
import { useStatus, useTitle }                                   from "../../hooks";
import { cleanName }                                             from "../../actions/util";

const SiteMemberVisitCommentList = ({site, membersVisitsComments, membersVisitsCommentsPagination, getMembersVisitsComments}) => {

  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();

  const sortFn = (object) => {
    getMembersVisitsComments(site.id, membersVisitsCommentsPagination.limit, membersVisitsCommentsPagination.skip, membersVisitsCommentsPagination.filterName, object.columnSort, object.descending)
  };

  return (
    <>
      <Table hover className="table-dotted table-responsive-vertical">
        <thead>
        <tr>
          <th style={{width: "250px"}}>Member <ColumnSort columnName="Name" columnSort={membersVisitsCommentsPagination.columnSort} descending={membersVisitsCommentsPagination.descending} changeSortAction={sortFn}/></th>
          <th>Comments</th>

        </tr>
        </thead>
        <tbody>
        {membersVisitsComments.length ? membersVisitsComments.map((memberVisitsComments, index) => {

            const member = memberVisitsComments.member, visitsComments = memberVisitsComments.visitsComments, visitsCommentsCount = memberVisitsComments.visitsCommentsCount;

            return (

              <tr key={index + membersVisitsCommentsPagination.skip}>
                <th scope="row" className="top">
                  <div>
                    <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
                  </div>
                  <div><MyLink to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a">{cleanName(member.lastname)}{cleanName(member.frstname) && <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !cleanName(member.frstname) &&
                  <span>Detail</span>}</MyLink>
                  </div>
                  <div>
                    <small className="text-gray">{getCategory(member.status)}</small>
                  </div>
                </th>
                <th>
                  {visitsComments && visitsComments.length > 0 ? <VisitMemberListView key={member.id} site={site} member={member} visitsMemberComments={visitsComments} visitsMemberCommentsCount={visitsCommentsCount}/>
                    : <span>This member has not yet been visited</span>}
                </th>
              </tr>
            )
          }) :
          <tr>
            <th colSpan="2" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getMembersVisitsComments(site.id)
            }}>refresh</span></th>
          </tr>}
        </tbody>
      </Table>

      {membersVisitsComments.length > 0 && membersVisitsCommentsPagination.limit < membersVisitsCommentsPagination.count &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={membersVisitsCommentsPagination.count} limit={membersVisitsCommentsPagination.limit} skip={membersVisitsCommentsPagination.skip} action={(skip, limit) => {
          getMembersVisitsComments(site.id, limit, skip, membersVisitsCommentsPagination.filterName, membersVisitsCommentsPagination.columnSort, membersVisitsCommentsPagination.descending)
        }}/>
      </div>}
    </>
  )
};

export default SiteMemberVisitCommentList;