import React, { useEffect, useState }               from 'react'
import { useForm }                                  from "react-hook-form";
import { Button, Container, FormGroup, Label, Row } from "reactstrap";
import { MyDatePicker }                             from "../index";
import { siteService, visitService }                from '../../services'
import { addVisitAction, updateVisitAction }        from "../../actions/visit";
import { dateFromISOToLocal }                       from "../../actions/util";

const VisitForm = ({history, location}) => {

  const [site, setSite] = useState({});
  const [visit, setVisit] = useState({});

  const {register, handleSubmit, setValue} = useForm();

  const [visitDate, setVisitDate] = useState(visit.visitDate ? dateFromISOToLocal(visit.visitDate, visit?.visitId) : new Date());

  useEffect(() => {
    let param = location.search;
    if (param) {
      const siteId = new URLSearchParams(param).get("site");
      const getSite = (siteId) => {
        siteService()
          .getSite({id: siteId}).then(res => {
          if (res && res.data) {
            setSite(res.data);
          }
        });
      };
      if (siteId) {
        getSite(siteId);
      }
      const visitId = new URLSearchParams(param).get("visit");
      if (visitId) {
        visitService()
          .getVisit(visitId)
          .then(res => {
            if (res && res.data) {
              setVisit(res.data);
              setVisitDate(res.data.visitDate ? dateFromISOToLocal(res.data.visitDate, res.data?.visitId) : new Date());
              setValue("visitReason", res.data.visitReason);
              if (!siteId && res.data.siteModelId) {
                getSite(res.data.siteModelId);
              }
            }
          })
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row><h3>{visit.id ? "Update" : "New"} Visit</h3></Row>
      <Row>
        <div className="col-12 detail-header border-bottom-gray">
          Details
        </div>
        <form className="col-12 detail" onSubmit={handleSubmit((values) => {
          let tmp = {...visit, ...values, visitDate, siteModelId: site.id};
          if (tmp.id) {
            updateVisitAction(tmp, history)
          } else {
            addVisitAction(tmp, history)
          }
        })}>
          <Row>
            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Field Director User</Label>
                <Label className="col-8 capitalize no-padding">{sessionStorage.getItem('usernameLFS')}</Label>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Site</Label>
                <Label className="col-8 no-padding">
                  {site.sitename && <span>{site.sitename}<br/></span>}
                  <span>{site.sitecode}</span>
                </Label>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Visit Reason</Label>
                <div className="col-8 no-padding">
                  <select className="form-control" name="visitReason" defaultValue={visit.visitReason} required ref={register}>
                    <option value="">-</option>
                    {["Hot Spot", "Mediation", "Office Visit", "Follow up Visit", "Phone",
                      // "Conflict Between Members", "Special Education Issues",
                      "Email",
                      "Information Purpose Only", "Member Request Visit", "Regular Visit", "Disciplinary Meeting", "Text Message"]
                      .sort()
                      .map(value => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                  </select>
                </div>
                <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
              </Row>
            </FormGroup>

            <FormGroup className="col-12">
              <Row>
                <Label className="col-3">Date</Label>
                <div className="col-8 no-padding max-date-with">
                  <MyDatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={visitDate}
                    onChange={(value) => {
                      setVisitDate(value)
                    }}/>
                </div>
                <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>
              </Row>
            </FormGroup>

            <div className="col-12" style={{marginTop: "30px", borderTop: "1px solid #797979", paddingTop: "15px"}}>
              <input type="submit" className="btn btn-outline-secondary" value="Save"/>
              {" "}
              or
              {" "}
              <Button outline color="danger" onClick={() => {
                history.goBack()
              }}>Cancel</Button>
            </div>
          </Row>
        </form>
      </Row>
    </Container>
  )
};

export default VisitForm;