import React, { useState }   from "react";
import { Nav, NavItem, Row } from "reactstrap";
import classnames            from 'classnames';
import { MyLink }            from "../index";
import { appPaths }          from "../../routes";
import CheckIt               from "../utils/CheckIt";
import { userActionsKeys }   from "../../util/userActions";

const BonusSeniorityTab = ({from}) => {
  const [activeTab, setActiveTab] = useState(from);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Row>
      <Nav tabs className="w-100 mb-3">
        <CheckIt action={userActionsKeys.bonusListLegal}>
          <NavItem>
            <MyLink to={"/" + appPaths.BonusList} className={classnames({active: activeTab === 'Bonus'}, "nav-link")} onClick={() => {
              toggle('Bonus');
            }}>
              Bonus
            </MyLink>
          </NavItem>
        </CheckIt>
        <CheckIt action={userActionsKeys.seniorityListLegal}>
          <NavItem>
            <MyLink to={"/" + appPaths.SeniorityList} className={classnames({active: activeTab === 'Seniority'}, "nav-link")} onClick={() => {
              toggle('Seniority');
            }}>
              Seniority
            </MyLink>
          </NavItem>
        </CheckIt>
      </Nav>
    </Row>
  )
}

export default BonusSeniorityTab