import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "visitMember/";
export default () => ({
    getVisitMember: (id) => client.request({
      method: 'GET',
      url: base + 'getVisitMember/' + id
    }),
    getVisitByFieldDirectorReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetVisitByFieldDirectorReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getVisitBySiteReport: (from, to, timezone) => loading(client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetVisitBySiteReport?timezone=' + timezone + (from ? '&from=' + from.toUTCString() : "") + (to ? '&to=' + to.toUTCString() : "")
    })),
    getVisitMembers: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getVisitMembers?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    getVisitMembersByVisit: (visitId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'getVisitMembersByVisit/' + visitId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    GetVisitMembersBySiteAndMember: (siteId, memberId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetVisitMembersBySiteAndMember?siteId=' + siteId + '&memberId=' + memberId + '&limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    GetAllWithMemberContactBySiteAndMember: (siteId, memberId, limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetAllWithMemberContactBySiteAndMember?siteId=' + siteId + '&memberId=' + memberId + '&limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    })),
    addVisitMember: (params) => client.request({
      method: 'POST',
      url: base + 'addVisitMember',
      data: params
    }),
    updateVisitMember: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateVisitMember/' + params.id,
      data: params
    }),
    changeState: (params) => client.request({
      method: 'PUT',
      url: base + 'ChangeState/' + params.id + "?isOpen=" + params.isOpen,
    }),
    deleteVisitMember: (id) => client.request({
      method: 'DELETE',
      url: base + 'deleteVisitMember/' + id
    })
  }
);
