import React, { useEffect, useState }                                 from 'react';
import { Badge, Button, Container, Row }                              from 'reactstrap';
import { memberService, visitService, defaultLimit }                                from "../../services";
import { phoneFormat }                                                from "../../actions/util";
import { appPaths }                                                   from "../../routes";
import { CheckIt, Search, SiteMemberVisitCommentList, SiteVisitList } from "../index";
import { useSite }                                                    from "../../hooks";
import { userActionsKeys }                                            from "../../util/userActions";

const SiteDetail = ({history, location}) => {

  const {site, siteId} = useSite();
  const [visits, setVisits] = useState([]);
  const [visitPagination, setVisitPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "VisitDate", descending: true});
  const [membersVisitsComments, setMembersVisitsComments] = useState([]);
  const [membersVisitsCommentsPagination, setMembersVisitsCommentsPagination] = useState({limit: defaultLimit, skip: 0, filterName: "", columnSort: "Name", descending: false});

  const getVisits = (siteId, limit = visitPagination.limit, skip = visitPagination.skip, filterName = visitPagination.filterName, columnSort = visitPagination.columnSort, descending = visitPagination.descending) => {
    visitService().getVisitsBySite(siteId, limit, skip, filterName, columnSort, descending).then(res => {
      if (res && res.data) {
        setVisits(res.data);
        const siteDetailVisitPaginateList = {
          ...visitPagination,
          filterName,
          columnSort,
          descending,
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10)
        }
        setVisitPagination(siteDetailVisitPaginateList)
        sessionStorage.setItem("siteDetailVisitPaginateList", JSON.stringify(siteDetailVisitPaginateList))
      }
    });
  };

  const getMembersVisitsComments = (siteId, limit = membersVisitsCommentsPagination.limit, skip = membersVisitsCommentsPagination.skip, filterName = membersVisitsCommentsPagination.filterName, columnSort = membersVisitsCommentsPagination.columnSort, descending = membersVisitsCommentsPagination.descending) => {
    memberService().GetMembersFromSiteIncludeVisitAndComment(siteId, limit, skip, filterName, columnSort, descending).then(res => {
      if (res && res.data) {
        setMembersVisitsComments(res.data);
        const membersVisitsCommentsPaginateList = {
          ...membersVisitsCommentsPagination,
          filterName,
          columnSort,
          descending,
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10)
        }
        setMembersVisitsCommentsPagination(membersVisitsCommentsPaginateList)
        sessionStorage.setItem("membersVisitsCommentsPaginateList", JSON.stringify(membersVisitsCommentsPaginateList))
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (siteId) {
      let siteDetailVisitPaginateList = sessionStorage.getItem("siteDetailVisitPaginateList")
      if (siteDetailVisitPaginateList) {
        siteDetailVisitPaginateList = JSON.parse(siteDetailVisitPaginateList)
        getVisits(siteId, defaultLimit, siteDetailVisitPaginateList.skip, siteDetailVisitPaginateList.filterName, siteDetailVisitPaginateList.columnSort, siteDetailVisitPaginateList.descending);
      } else {
        getVisits(siteId);
      }
      let membersVisitsCommentsPaginateList = sessionStorage.getItem("membersVisitsCommentsPaginateList")
      if (membersVisitsCommentsPaginateList) {
        membersVisitsCommentsPaginateList = JSON.parse(membersVisitsCommentsPaginateList)
        getMembersVisitsComments(siteId, defaultLimit, membersVisitsCommentsPaginateList.skip, membersVisitsCommentsPaginateList.filterName, membersVisitsCommentsPaginateList.columnSort, membersVisitsCommentsPaginateList.descending);
      } else {
        getMembersVisitsComments(siteId);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>

      <Row>
        <h3>{site.sitename} <span className="text-gray">Details</span></h3>
        <CheckIt action={userActionsKeys.addNewVisitLegal}>
          <div style={{marginLeft: "auto"}}>
            <Button className="mb-2" onClick={() => {
              history.push({...location, pathname: appPaths.VisitForm, search: "site=" + site.id})
            }}>+New Visit</Button>
          </div>
        </CheckIt>
      </Row>
      <Row>
        <div className="col-12 detail-header border-bottom-gray">
          Details
        </div>
        <div className="col-12 detail">
          <Row>
            <div className="col-12 col-md-6">
              <Row>
                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Id</div>
                <div className="col-12 col-sm-7">{site.siteId}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site code</div>
                <div className="col-12 col-sm-7">{site.sitecode}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Address</div>
                <div className="col-12 col-sm-7">
                  <div>{site.address1}</div>
                  <div>{site.city ? site.city + (site.state || site.zip ? ", " : "") : ""}{site.state ? site.state + (site.zip ? ", " : "") : ""}{site.zip}</div>
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Phone</div>
                <div className="col-12 col-sm-7">{site.telephon ? phoneFormat(site.areacode + site.telephon) : ""}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Fax</div>
                <div className="col-12 col-sm-7">{site.faxNo ? phoneFormat(site.fareacode + site.faxNo) : ""}</div>
              </Row>
            </div>

            <div className="col-12 col-md-6">
              <Row>
                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Borough</div>
                <div className="col-12 col-sm-7">{site.borough}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">District</div>
                <div className="col-12 col-sm-7">{site.district}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">City Council</div>
                <div className="col-12 col-sm-7">{site.council}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Congress</div>
                <div className="col-12 col-sm-7">{site.congress}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">NYS Assembly District</div>
                <div className="col-12 col-sm-7">{site.nyS_Assm}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">NYS Senate</div>
                <div className="col-12 col-sm-7">{site.nyS_Sen}</div>

              </Row>
            </div>

          </Row>
        </div>

        <CheckIt action={userActionsKeys.siteVisitListLegal}>
          <div className="col-12 detail-header border-bottom-gray" style={{border: "none"}}>
            <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{visitPagination.count}</Badge> Visits
          </div>
          <div className="col-12 detail no-padding">
            <SiteVisitList site={site} visits={visits} visitPagination={visitPagination} getVisits={getVisits}/>
          </div>
        </CheckIt>

        <CheckIt action={userActionsKeys.siteMemberListLegal}>
          <div className="col-12 detail-header border-bottom-gray" style={{border: "none"}}>
            <Search defaultValue={membersVisitsCommentsPagination.filterName} onSearch={(value) => {
              getMembersVisitsComments(siteId, undefined, 0, value);
            }}/>
            <Badge color="secondary" style={{fontSize: "18px", fontWeight: "normal"}}>{membersVisitsCommentsPagination.count}</Badge> Members
          </div>
          <div className="col-12 detail no-padding">
            <SiteMemberVisitCommentList site={site} membersVisitsComments={membersVisitsComments} membersVisitsCommentsPagination={membersVisitsCommentsPagination} getMembersVisitsComments={getMembersVisitsComments}/>
          </div>
        </CheckIt>

      </Row>
    </Container>
  );
};

export default SiteDetail;
