import { useEffect, useState }                                   from 'react'
import { grievanceService, investigationService, ratingService } from "../services";
import { useLocation }                                           from "react-router";
import { compareAtr }                                            from "../actions/util";

export const useCaseList = () => {

  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const memberId = param.get("member");

  const [investigations, setInvestigations] = useState([]);
  const [grievances, setGrievances] = useState([]);
  const [ratings, setRatings] = useState([]);
  const pagination = {filterName: "", columnSort: "", descending: true, open: 1};

  const getDate = (value) => {
    let date;
    if (value.caseType === "Investigation") {
      date = value.initialContactDate
    }
    if (value.caseType === "Grievance") {
      date = value.openDate
    }
    if (value.caseType === "Rating") {
      date = value.createDate
    }
    return date
  }

  let cases = [...investigations, ...grievances, ...ratings].sort((a, b) => compareAtr(getDate(a), getDate(b))).reverse()

  const getInvestigationsByMember = (filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    investigationService()
      .getInvestigationsByMember(memberId, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          setInvestigations(res.data.map(m => ({...m, caseType: "Investigation"})));
        }
      })
  };

  const getGrievancesByMember = (filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    grievanceService()
      .getGrievancesByMember(memberId, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          setGrievances(res.data.map(m => ({...m, caseType: "Grievance"})));
        }
      })
  };

  const getRatingsByMember = (filterName = pagination.filterName, columnSort = pagination.columnSort, descending = pagination.descending, open = pagination.open) => {
    ratingService()
      .getRatingsByMember(memberId, filterName, columnSort, descending, open)
      .then(res => {
        if (res && res.data) {
          setRatings(res.data.map(m => ({...m, caseType: "Rating"})));
        }
      })
  };

  useEffect(() => {
    getInvestigationsByMember()
    getGrievancesByMember()
    getRatingsByMember()
    // eslint-disable-next-line
  }, []);

  return {cases, pagination}
};
