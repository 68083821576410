import React                                from 'react'
import { dateFormatMonthDayYear }           from "../../actions/util";
import { ColumnSort, MyLink, MyPagination } from "../index";
import { Table }                            from "reactstrap";
import { appPaths }                         from "../../routes";
import { useAllName }                       from "../../hooks";

const SiteVisitList = ({site, visits, visitPagination, getVisits}) => {

  const {getNames} = useAllName();
  const sortFn = (object) => {
    getVisits(site.id, visitPagination.limit, visitPagination.skip, visitPagination.filterName, object.columnSort, object.descending)
  };

  return (
    <>
      <Table hover className="table-dotted table-responsive-vertical">
        <thead>
        <tr>
          <th style={{width: "250px"}}>Visit Detail <ColumnSort columnName="VisitDate" columnSort={visitPagination.columnSort} descending={visitPagination.descending} changeSortAction={sortFn}/></th>
          <th>Comment</th>

        </tr>
        </thead>
        <tbody>
        {visits.length ? visits.map((visit, index) => (

            <tr key={index + visitPagination.skip}>
              <th scope="row">
                <MyLink to={"/" + appPaths.VisitDetail + "?visit=" + visit.id + "&site=" + visit.siteModelId} className="no-a">{dateFormatMonthDayYear(visit.visitDate, visit.visitId)}</MyLink>
                <div>{getNames(visit.userName, true)}</div>
                <small className="text-gray">{visit.visitReason}</small>
              </th>
              <th>
                {visit.visitCommentMembershipOnly && visit.visitCommentMembershipOnly.id ? visit.visitCommentMembershipOnly.comment : ""}
              </th>
            </tr>
          )) :
          <tr>
            <th colSpan="2" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getVisits(site.id)
            }}>refresh</span></th>
          </tr>}
        </tbody>
      </Table>

      {visits.length > 0 && visitPagination.count > 10 &&
      <div style={{padding: "30px 15px 0", overflow: "auto"}}>
        <MyPagination count={visitPagination.count} limit={visitPagination.limit} skip={visitPagination.skip} action={(skip, limit) => {
          getVisits(site.id, limit, skip, visitPagination.filterName, visitPagination.columnSort, visitPagination.descending)
        }}/>
      </div>}
    </>
  )
};

export default SiteVisitList;