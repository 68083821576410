import React, { useEffect, useState }                    from 'react';
import { connect }                                       from 'react-redux';
import { Button, Col, FormGroup, Modal, ModalBody, Row } from 'reactstrap';
import { loginAction }                                   from "../../actions/general";
import './login.css'
import illustration                                      from '../../resources/SVG/IMG_680x324px_Ilustration.svg'
import { useForm }                                       from "react-hook-form";

const LoginModal = ({showAuthModal, loginAction}) => {

  const {register, handleSubmit} = useForm();

  const [xs, setXs] = useState(window ? window.innerWidth <= 575 : false);

  useEffect(() => {
    const onResize = (e) => {
      const windowWidth = e.target.innerWidth;
      if (windowWidth <= 575) {
        setXs(true)
      } else {
        setXs(false)
      }
    }

    window.addEventListener("resize", onResize)

    return () => {window.removeEventListener("resize", onResize)}
  }, []);

  const form = <form onSubmit={handleSubmit((values, e) => {
    loginAction(values.username, values.password);
  })}>
    <Row className="mr-0">
      <FormGroup className="col-12 login-input-container">
        {/*<Label>Username</Label>*/}
        <input type="text" className="login-input" placeholder="Username" name="username" required ref={register}/>
      </FormGroup>

      <FormGroup className="col-12 login-input-container">
        {/*<Label>Password</Label>*/}
        <input type="password" className="login-input" placeholder="Password" name="password" required ref={register}/>
      </FormGroup>
      <Col xs="12">
        <Button className="login-button" color="primary" size="lg">Login</Button>
      </Col>
    </Row>
  </form>;


  return (
    <Modal isOpen={showAuthModal || !(sessionStorage.getItem('usernameLFS') && sessionStorage.getItem('tokenAuthLFS'))} className="my-login" fade={false}>
      <ModalBody>
        <Row style={{marginLeft: "-16px"}}>

          <div className="hidden-xs col-sm-6 col-md-8">

            <div className="hidden-xs big-background-img card-left">
              <h3 className="welcome">Welcome to</h3>

              <h1 className="title">Legal & Field Staff <br/></h1>

              {xs && form}

              {xs && <div className="mb-5"/>}

              <div className="illustration-container">
                <img src={illustration} alt="login illustration" className="illustration"/>
              </div>


            </div>

          </div>

          {!xs && <div className="col-12 col-sm-6 col-md-4 pl-0">
            <div className="from-container">
              {form}
            </div>
          </div>}

        </Row>
      </ModalBody>
    </Modal>
  );
};


const mapStateToProps = ({showAuthModal}) => ({
  showAuthModal
});

const mapDispatchProps = dispatch => {
  return {
    loginAction
  }
};

export default connect(mapStateToProps, mapDispatchProps)(LoginModal);
