import React, { useEffect, useRef, useState }                                                                                                                                           from 'react';
import { Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown }                                                                               from 'reactstrap';
import { ratingAdvocateService, ratingService, ratingWaiveService }                                                                                                                     from "../../services";
import { appPaths }                                                                                                                                                                     from "../../routes";
import { useHistory }                                                                                                                                                                   from "react-router";
import { useForm }                                                                                                                                                                      from "react-hook-form";
import { BigModal, CheckIt, CloseRating, Comments, MyDatePicker, MyLink, RatingActivityForm, RatingActivityList, ReplaceOnWidth }                                                       from "../index";
import { cleanName, dateFormatMonthDayYear, dateFromISOToLocal, setAlertNotification }                                                                                                  from "../../actions/util";
import { useAllName, useMember, useRating, useRatingActivityList, useRatingAdvocateList, useRatingCommentList, useRatingWaiveList, useSite, useStatus, useTitle, useUsersAdvocateList } from "../../hooks";
import { Select }                                                                                                                                                                       from "react-functional-select";
import { isAllow, userActionsKeys }                                                                                                                                                     from "../../util/userActions";
import { FontAwesomeIcon }                                                                                                                                                              from "@fortawesome/react-fontawesome";
import { faPlus }                                                                                                                                                                       from "@fortawesome/free-solid-svg-icons";

let schoolYear = [];
const currentYear = new Date().getFullYear();

for (let j = currentYear; j >= 2007; j--) {
  schoolYear.push(`${j - 1}-${j}`);
}

const RatingDetail = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {getName} = useAllName();
  const {site, siteId} = useSite();
  const {member, memberId} = useMember();
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);

  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();
  const [createDate, setCreateDate] = useState();
  const [hearingDate, setHearingDate] = useState();

  const {register, handleSubmit, reset} = useForm();

  const {rating, ratingId, changeRatingState, isRatingOpen} = useRating({
    onChange: (rating) => {
      reset(rating);
      setHearingDate(dateFromISOToLocal(rating?.hearingDate, rating?.ratingId));
      setCreateDate(dateFromISOToLocal(rating?.createDate, rating?.ratingId));
    },
    closeModal: () => {setIsCloseModalOpen(false)}
  });
  const [waiveDate, setWaiveDate] = useState(undefined);

  const selectRef = useRef();
  const {users} = useUsersAdvocateList();
  const [userSelected, setUserSelected] = useState({});
  const {ratingAdvocates, getRatingAdvocates, deleteRatingAdvocate} = useRatingAdvocateList();
  const {ratingWaives, getRatingWaives, deleteRatingWaive} = useRatingWaiveList();

  const [activityToEdit, setActivityToEdit] = useState({});
  const {ratingActivities, getRatingActivities, paginationRatingActivities, onSubmit} = useRatingActivityList();

  const {ratingComments, getRatingComments, paginationRatingComments, addRatingComment} = useRatingCommentList();

  const history = useHistory();

  return (
    <Container fluid={true}>
      <Row className="padding-15 mb-2">
        <h3 className="no-margin">Rating for {member.id && "'" + cleanName(member.lastname) + ", " + cleanName(member.frstname) + "'"}</h3>
        {ratingId && isAllow(userActionsKeys.changeRatingStateLegal) && <div style={{marginLeft: "auto"}}>
          <UncontrolledButtonDropdown className="ml-2 mb-2">
            <DropdownToggle caret color="secondary">
              {isRatingOpen ? "Close" : "Reopen"} Rating
            </DropdownToggle>
            <DropdownMenu style={{minWidth: "8rem"}}>
              <DropdownItem onClick={() => {
                if (isRatingOpen) {
                  setIsCloseModalOpen(true);
                } else {
                  changeRatingState();
                }
              }}>Confirm</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>}
        <div className="width-100"/>
        {!isRatingOpen && <div className="m-auto">This Rating was closed on {dateFormatMonthDayYear(rating.closedDate, rating.ratingId)} {rating.outcome && rating.outcome !== "-" && " as " + rating.outcome}</div>}
      </Row>
      <Row>

        <div className={"col-12 col-md-6 transition-all" + (ratingId ? "" : " offset-md-3")}>
          <div className="col-12 detail-header border-bottom-gray">
            Details
          </div>
          <div className="col-12 detail">
            <form onSubmit={handleSubmit((values) => {
              values = {...values, hearingDate, createDate, memberModelId: memberId, siteModelId: siteId};
              if (rating.id) {
                ratingService()
                  .updateRating({...rating, ...values})
                  .then(res => {setAlertNotification("Saved")})
                  .catch(err => {setAlertNotification("Saved", "danger")})
              } else {
                ratingService()
                  .addRating(values)
                  .then(res => {
                    if (res && res.data) {
                      history.push(appPaths.RatingDetail + "?site=" + siteId + "&member=" + memberId + "&rating=" + res.data)
                    }
                    setAlertNotification("Saved")
                  })
                  .catch(err => {setAlertNotification("Saved", "danger")})
              }
            })}>
              <Row>
                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">District</div>
                <div className="col-12 col-sm-7">{site.district}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Site</div>
                <div className="col-12 col-sm-7">{site.sitecode}<br/>{site.sitename}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">File No.</div>
                <div className="col-12 col-sm-7">{member.fileno}</div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Member</div>
                <div className="col-12 col-sm-7">
                  <div style={{lineHeight: "14px"}}>
                    <small className="text-gray">{getJobDescription(member.title)} {member.title && <span>({member.title.trim()})</span>}</small>
                  </div>
                  <div style={{lineHeight: "23px"}}>
                    <MyLink to={"/" + appPaths.MemberDetail + "?member=" + member.id} className="no-a">{cleanName(member.lastname)}{cleanName(member.frstname) &&
                    <span>, {cleanName(member.frstname)}</span>}{!cleanName(member.lastname) && !cleanName(member.frstname) &&
                    <span>Detail</span>}</MyLink>
                  </div>
                  <div style={{lineHeight: "14px"}}>
                    <small className="text-gray">{getCategory(member.status)}</small>
                  </div>
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Rating Type</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isRatingOpen ?
                    <select className="form-control" name="type" defaultValue="PPR" ref={register}>
                      <option value="">-</option>
                      <option value="U-Rating">U-Rating</option>
                      <option value="PPR">PPR</option>
                      <option value="Discontinuance">Discontinuance</option>
                      <option value="DenialofCompletionofProbation">DenialofCompletionofProbation</option>
                      <option value="APPR_Developing">APPR_Developing</option>
                      <option value="APPR_Ineffective">APPR_Ineffective</option>
                    </select>
                    : rating.type}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">School Year</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isRatingOpen ?
                    <select className="form-control" name="schoolYear" defaultValue="" ref={register}>
                      <option value="">-</option>
                      {schoolYear.map((value, index) => (
                        <option key={index} value={value}>{value}</option>
                      ))}
                    </select>
                    : rating.schoolYear}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Create Date</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isRatingOpen ?
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={createDate}
                      onChange={(value) => {
                        setCreateDate(value);
                      }}/>
                    : dateFormatMonthDayYear(createDate, rating.ratingId)}
                </div>

                <div className="col-12 col-sm-5 border-bottom-gray toast-title-item">Hearing Date</div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isRatingOpen ?
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={hearingDate}
                      onChange={(value) => {
                        setHearingDate(value);
                      }}/>
                    : dateFormatMonthDayYear(hearingDate, rating.ratingId)}
                </div>

                <div className={"col-12 col-sm-5 border-bottom-gray toast-title-item" + (isRatingOpen ? " my-2" : "")}>
                  {isRatingOpen ?
                    <select className="form-control" name="superintendentLabel" defaultValue="Superintendent" ref={register}>
                      <option value="Superintendent">Superintendent</option>
                      <option value="Principal">Principal</option>
                    </select>
                    : rating.superintendentLabel}
                </div>
                <div className="col-12 col-sm-7 mt-2 mb-2">
                  {isRatingOpen ?
                    <input className="form-control" name="superintendent" ref={register}/>
                    : rating.superintendent}
                </div>

                {isRatingOpen && isAllow(rating.id ? userActionsKeys.updateRatingLegal : userActionsKeys.addNewRatingLegal) && <div className="col-12 mt-4 mb-2"><Button>Save</Button></div>}

              </Row>
            </form>

          </div>
        </div>

        {ratingId && <>
          <div className="col-12 col-md-6">
            <div className="col-12 detail-header border-bottom-gray">
              Advocate / Waivers
            </div>
            <div className="col-12 detail">
              <Row>
                <h5 className="col-12 primary-color mt-0 mb-2 border-bottom-gray">Advocate</h5>
                {isRatingOpen && isAllow(userActionsKeys.addRatingAdvocateLegal) && <div className="col-12 d-flex justify-content-around">
                  <div className="select-container flex-grow-1 mr-2">
                    <Select
                      options={users.filter(f => !ratingAdvocates || !ratingAdvocates.some(s => f.userName === s.userName)).map(user => ({name: getName(user.userName), value: user}))}
                      placeholder="Select Advocate"
                      name="advocates"
                      inputId="advocate-select-input"
                      ref={selectRef}
                      onOptionChange={(option) => {
                        if (option && option.value) {
                          setUserSelected(option)
                        }
                      }}
                      scrollMenuIntoView
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.name}
                    />
                  </div>
                  <div className="text-right">
                    <Button
                      disabled={!userSelected?.value?.userName}
                      onClick={() => {
                        let params = {ratingModelId: ratingId, userName: userSelected.value.userName};
                        if (userSelected.value && userSelected.value.userModelId) {
                          params["userModelId"] = userSelected.value.userModelId
                        } else if (userSelected.value && userSelected.value.applicationUserId) {
                          params["applicationUserId"] = userSelected.value.applicationUserId
                        }
                        ratingAdvocateService()
                          .addAdvocate(params)
                          .then(res => {
                            selectRef.current && selectRef.current.clearValue();
                            setUserSelected({});
                            getRatingAdvocates();
                          })
                          .catch(err => {setAlertNotification("Error adding an Advocate", "danger")});
                      }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>
                  </div>
                </div>}

                <div className="col-12 mt-3">
                  {ratingAdvocates?.length > 0 && ratingAdvocates.map((advocate, index) => (
                    <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                      {getName(advocate.userName)}
                      {isRatingOpen && isAllow(userActionsKeys.deleteRatingAdvocateLegal) && <div className="inline-right text-danger hover-show" onClick={() => {deleteRatingAdvocate(advocate.id)}}>Delete</div>}
                    </div>
                  ))}
                </div>

                <h5 className="col-12 primary-color mt-3 mb-2 border-bottom-gray">Waivers</h5>
                {isRatingOpen && isAllow(userActionsKeys.addRatingWaiverLegal) && <div className="col-12 d-flex justify-content-around">
                  <div className="flex-grow-1 mr-2">
                    <MyDatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={waiveDate}
                      onChange={(value) => {
                        setWaiveDate(value);
                      }}/>
                  </div>
                  <div className="text-right">
                    <Button
                      disabled={!waiveDate}
                      onClick={() => {
                        ratingWaiveService()
                          .addWaive({date: waiveDate, ratingModelId: ratingId})
                          .then(res => {
                            setWaiveDate(undefined)
                            getRatingWaives();
                          })
                          .catch(err => {setAlertNotification("Error adding an Waive", "danger")});
                      }}><ReplaceOnWidth content={<FontAwesomeIcon icon={faPlus}/>} smContent="Add"/></Button>
                  </div>
                </div>}

                <div className="col-12 mt-3">
                  {ratingWaives?.length > 0 && ratingWaives.map((waive, index) => (
                    <div className="col-12 border-bottom-gray container-hover-show" key={index}>
                      {dateFormatMonthDayYear(waive.date, rating.ratingId)}
                      {isRatingOpen && isAllow(userActionsKeys.deleteRatingWaiverLegal) && <div className="inline-right text-danger hover-show" onClick={() => {deleteRatingWaive(waive.id)}}>Delete</div>}
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          </div>

          <CheckIt action={userActionsKeys.ratingActivityListLegal}>
            <div className="col-12 col-sm-6">
              <div className="col-12 detail no-padding">
                <RatingActivityList
                  ratingActivities={ratingActivities}
                  pagination={paginationRatingActivities}
                  setRatingActivity={(values) => {
                    setActivityToEdit(values);
                    setIsActivityModalOpen(true);
                  }}
                  onPaginationAction={(limit, skip, columnSort, desc) => {
                    getRatingActivities(limit, skip, "", columnSort, desc)
                  }}
                  isRatingOpen={isRatingOpen}
                  paginationRatingActivities={paginationRatingActivities}
                  setActivityToEdit={setActivityToEdit}
                  setIsActivityModalOpen={setIsActivityModalOpen}/>
              </div>
            </div>
          </CheckIt>

          <CheckIt action={userActionsKeys.ratingCommentListLegal}>
            <div className="col-12 col-sm-6">
              <div className="col-12 detail-header border-bottom-gray">
                Comments
              </div>
              <div className="col-12 detail">
                <Comments
                  comments={ratingComments}
                  pagination={paginationRatingComments}
                  showForm={isRatingOpen && isAllow(userActionsKeys.addRatingCommentLegal)}
                  getPaginationAction={(skip, limit) => {
                    getRatingComments(limit, skip)
                  }}
                  addCommentAction={addRatingComment}/>
              </div>
            </div>
          </CheckIt>
        </>}

      </Row>

      <BigModal className="modal-green" title="Close Rating" isBigModalOpen={isCloseModalOpen} toggleModalAction={() => {setIsCloseModalOpen(false)}}>
        <CloseRating changeRatingState={changeRatingState}/>
      </BigModal>
      <BigModal className="modal-green" title="Rating Activity" isBigModalOpen={isActivityModalOpen} toggleModalAction={() => {setIsActivityModalOpen(false)}}>
        <RatingActivityForm defaultValues={activityToEdit} onCancel={() => {setIsActivityModalOpen(false)}} onSubmit={(values) => {
          onSubmit(values, () => {
            setIsActivityModalOpen(false)
          });
        }}/>
      </BigModal>
    </Container>
  );
};

export default RatingDetail;
