import React                        from 'react';
import { Button, CustomInput, Row } from "reactstrap";
import { SelectMemberList }         from "../index";
import { useHistory, useLocation }  from "react-router";
import { appPaths }                 from "../../routes";
import { useMember, useSite }       from "../../hooks";
import { cleanName }                from "../../actions/util";

const SelectMember = () => {

  const {site, setSite, siteId} = useSite();
  const {member, setMember, memberId} = useMember();

  const history = useHistory();
  const location = useLocation();
  let param = new URLSearchParams(location.search);
  const isUnionInitiatedAllow = param.get("allow-union-initiated") === "true";
  const unionInitiated = param.get("union-initiated");

  let selection = "";
  if (site.id) {
    selection = "(" + site.sitename + ")";
  }
  if (member.id) {
    selection = selection.replace(")", " - " + cleanName(member.lastname) + ", " + cleanName(member.frstname) + ")");
  }

  let previousPath = "/";
  let nextPath = "/";
  let name = "";

  if (location.pathname === "/" + appPaths.SiteAndMemberNewGrievance) {
    previousPath += appPaths.GrievanceList
    nextPath += appPaths.GrievanceDetail
    name = "Grievance"
  } else if (location.pathname === "/" + appPaths.SiteAndMemberNewRating) {
    previousPath += appPaths.RatingList
    nextPath += appPaths.RatingDetail
    name = "Rating"
  } else if (location.pathname === "/" + appPaths.SiteAndMemberNewInvestigation) {
    previousPath += appPaths.InvestigationList
    nextPath += appPaths.InvestigationDetail
    name = "Investigation"
  }

  const buttons = <>
    <Button disabled={!((siteId && memberId) || unionInitiated)} onClick={() => {
      history.push(nextPath + (unionInitiated ? "?union-initiated=" + true : "?site=" + siteId + "&member=" + member.id))
    }}>Next</Button>
    {" "} or {" "}
    <Button outline onClick={() => {
      history.push(previousPath)
    }}>Cancel</Button>
  </>;

  return (
    <Row>
      <span className="float-right ml-auto" style={{marginRight: "15px", marginBottom: "-24px", zIndex: 9}}>
        {buttons}
      </span>
      <h3 className="col-12 mb-2">New {name} {selection}</h3>
      <div className="mb-2 col-12" style={{height: "50px", width: "100%"}}>
        <p className="col-12 text-secondary" style={{border: "1px solid rgba(0, 0, 0, .2)", borderRadius: "4px"}}>
          {isUnionInitiatedAllow && <span> If {name.toLocaleLowerCase()} doesn't have a member, please select Union Initiated.</span>}
          {(siteId && memberId) || unionInitiated ? <span className="primary-color"> Click next to enter details.</span> : ""}
        </p>
      </div>
      {isUnionInitiatedAllow && <div className="col-12 mt-3 mb-3">
        <CustomInput className="text-center big-checkbox primary-color" type="checkbox" name="unionInitiated" id="unionInitiated" checked={!!unionInitiated} onChange={(e) => {
          history.push(location.pathname + (isUnionInitiatedAllow ? "?allow-union-initiated=true" : "") + (!unionInitiated ? "&union-initiated=" + true : ""))
        }} label="Union Initiated"/>
      </div>}
      {!unionInitiated &&
      <div className="col-12"><SelectMemberList isUnionInitiatedAllow={isUnionInitiatedAllow} selectMember={(member) => {
        setMember(member)
        setSite(member?.site || {})
      }}/></div>}
      <div className="col-12 text-center mt-4">{buttons}</div>
    </Row>
  )
};

export default SelectMember;