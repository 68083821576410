import React, { useState }                                                                        from 'react';
import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import userActions, { userActionsTree }                                                           from "../../util/userActions";
import classnames                                                                                 from 'classnames';

const UserActionsModal = ({user, toggleModalAction, addActionUserAction}) => {

  const keys = Object.keys(userActionsTree)
  const [activeTab, setActiveTab] = useState(keys[0]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const allowAll = (user && user.userAction && user.userAction.length) && userActionsTree[activeTab].some(s => !user.userAction.some(us => us.name === s && us.allow === true))

  return (
    <Modal className="permissions-modal" isOpen={!!user?.id} toggle={() => {
      toggleModalAction()
    }}>
      <ModalHeader toggle={() => {
        toggleModalAction()
      }}>User Actions ({user.name})</ModalHeader>
      <ModalBody>
        <Nav tabs>
          {keys.map(value => (
            <NavItem key={value}>
              <NavLink className={classnames({active: activeTab === value})} onClick={() => { toggle(value); }}>
                {userActions[value]}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row className="my-4">
              <div className="col-9">
                  <Button onClick={() => {
                    if (userActionsTree[activeTab]) {
                      for (let i in userActionsTree[activeTab]) {
                        if (isAllow(user, userActionsTree[activeTab][i]) !== allowAll) {
                          let object = {name: userActionsTree[activeTab][i], allow: allowAll};
                          const id = getActionId(user.userAction, userActionsTree[activeTab][i]);
                          if (id) {
                            object['id'] = id;
                          }
                          addActionUserAction(object);
                        }
                      }
                    }
                  }} size="sm" color={allowAll ? "success" : "danger"}>{allowAll ? "Allow all under " : "Deny all under"} {userActions[activeTab]}</Button>
              </div>
            </Row>
            {userActionsTree[activeTab].map((action, index) => {
              const allow = isAllow(user, action);
              return (
                <Row key={action + index} className="mb-2 border-bottom">
                  <div className={"col-9 " + (allow ? "text-success" : "text-danger")} style={{left: getLeft(action)}}>-{userActions[action]}</div>
                  <div className="col-3 text-center mb-2">
                    <Button onClick={() => {
                      let object = {name: action, allow: !allow};
                      let id = getActionId(user.userAction, action);
                      if (id) {
                        object['id'] = id;
                      }
                      addActionUserAction(object);
                    }} size="sm" color={allow ? "success" : "danger"}>{allow ? "Allowed" : "Denied"}</Button>
                  </div>
                </Row>
              )
            })}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

const getLeft = (action) => {
  if (userActionsTree[action]) {
    return "0"
  }
  return "10px";
};

const isAllow = (user, value) => {

  if (!(user && user.userAction && user.userAction.length)) {
    return false;
  }
  let actions = user.userAction;
  for (let i in actions) {
    if (actions[i].allow === true && actions[i].name === value) {
      return true;
    }
  }
  return false;
};

const getActionId = (actions, value) => {

  if (!(actions && actions.length)) {
    return 0;
  }
  for (let i in actions) {
    if (actions[i].name === value) {
      return actions[i].id;
    }
  }
  return false;
};

export default UserActionsModal;
