import React, { useEffect } from 'react'
import { Button }           from "reactstrap";
import { useForm }          from "react-hook-form";

const FriendAddressForm = ({defaultValues, onSubmit, onCancel}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (defaultValues) {
      reset({...defaultValues});
    } else {
      reset({});
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleSubmit(values => {
      onSubmit({...defaultValues, ...values})

    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Address Type</div>
      <div className="col-6 mt-2 mb-2">
        <select className="form-control" name="type" defaultValue="Permanent Home" ref={register({required: true})}>
          {["Permanent Home", "Temporary Home", "Local Business", "Business"]
            .map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
        </select>
      </div>
      <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>

      <div className="col-5 border-bottom-gray toast-title-item">Name</div>
      <div className="col-7 mt-2 mb-2">
        <input className="form-control" name="name" ref={register}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Address</div>
      <div className="col-7 mt-2 mb-2">
        <input className="form-control" name="address" ref={register}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">City</div>
      <div className="col-7 mt-2 mb-2">
        <input className="form-control" name="city" ref={register}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">State</div>
      <div className="col-7 mt-2 mb-2">
        <input className="form-control" name="state" ref={register}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Zip</div>
      <div className="col-7 mt-2 mb-2">
        <input className="form-control" name="zip" ref={register}/>
      </div>

      <div className="col-12 mt-4 mb-2">
        <Button>Save</Button>
        {" "} or {" "}
        <Button outline onClick={() => {
          onCancel()
        }}>Cancel</Button>
      </div>
    </form>
  )
};

export default FriendAddressForm