import React, { useEffect }                                         from 'react';
import { Button, CustomInput, Table }                               from 'reactstrap';
import { CheckIt, ColumnSort, GrievanceItem, MyPagination, Search } from "../index";
import { useGrievanceList, useStatus, useTitle }                    from "../../hooks";
import { useHistory }                                               from "react-router";
import { appPaths }                                                 from "../../routes";
import { userActionsKeys }                                          from "../../util/userActions";

let search = "";

const GrievanceList = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const history = useHistory();
  const {getJobDescription} = useTitle();
  const {getCategory} = useStatus();
  const {grievances, pagination, getGrievances} = useGrievanceList();

  let items = grievances && grievances.map((grievance, i) =>
    <GrievanceItem key={"member-item" + i} grievance={grievances[i]} index={i} getJobDescription={getJobDescription} getCategory={getCategory}/>
  )

  const sortFn = (object) => {
    getGrievances(pagination.limit, pagination.skip, search, object.columnSort, object.descending)
  };

  return (
    <div className="member-list">
      <div className="d-flex justify-content-between">
        <h3>Grievance List</h3>
        <div className="flex-grow-1">
          <CheckIt action={userActionsKeys.addNewGrievanceLegal}>
            <div className="float-right ml-3 mb-1">
              <Button onClick={() => {history.push(appPaths.SiteAndMemberNewGrievance + "?allow-union-initiated=true")}}>New Grievance</Button>
            </div>
          </CheckIt>
          <Search defaultValue={pagination.filterName} onSearch={(value) => {
            search = value;
            getGrievances(pagination.limit, 0, value, pagination.columnSort, pagination.descending)
          }}/>
          <div className="float-right mr-3">
            <CustomInput className="mt-3 text-center up-it" type="checkbox" name="isSendLegal" id="isSendLegal" checked={pagination.open === 0} onChange={(e) => {
              getGrievances(pagination.limit, 0, search, pagination.columnSort, pagination.descending, e.target.checked ? 0 : 1)
            }} label="Show Closed"/>
          </div>
        </div>
      </div>
      <Table hover className="table-responsive-vertical">
        <thead>
        <tr>
          <th style={{minWidth: "166px"}}>Open Date <ColumnSort columnName="OpenDate" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "124px"}}>Member/Case <ColumnSort columnName="Member" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "90px"}}>Site <ColumnSort columnName="Site" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th style={{minWidth: "120px"}}>Infraction <ColumnSort columnName="Infraction" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th className="text-center" style={{minWidth: "145px"}}>Outcome <ColumnSort columnName="Outcome" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/></th>
          <th className="text-center" style={{minWidth: "94px"}}>Status {pagination.open === 0 && <ColumnSort columnName="IsOpen" columnSort={pagination.columnSort} descending={pagination.descending} changeSortAction={sortFn}/>}</th>

        </tr>
        </thead>
        <tbody>
        {grievances.length ?
          items :
          <tr>
            <th colSpan="5" className="text-center">No content data, <span className="simulate-a" onClick={() => {
              getGrievances()
            }}>refresh</span></th>
          </tr>
        }
        </tbody>
      </Table>

      {grievances.length > 0 && pagination.count > 10 &&
      <MyPagination count={pagination.count} limit={pagination.limit} skip={pagination.skip} action={(skip, limit) => {
        getGrievances(limit, skip, search, pagination.columnSort, pagination.descending)
      }}/>}

    </div>
  );
};

export default GrievanceList;
