import { client } from '../util/createRestApiClient';

const base = "Status/";
export default () => ({
    getStatusAll: () => client.request({
      method: 'GET',
      url: base + 'GetStatusAll'
    }),
    getStatus: (title) => client.request({
      method: 'GET',
      url: base + 'GetStatus',
      params: {title}
    }),
    getStatusByMemberId: (id) => client.request({
      method: 'GET',
      url: base + 'GetStatusByMemberId/' + id + "?timestamp=" + new Date().getTime()
    })
  }
);
