import React, { useEffect, useRef, useState } from 'react'
import { MyDatePicker }                       from "../index";
import { Button }                             from "reactstrap";
import { useForm }                            from "react-hook-form";
import { dateFromISOToLocal }               from "../../actions/util";
import { useAllName, useUsersAdvocateList } from "../../hooks";
import { Select }                           from "react-functional-select";

const GrievanceActivityForm = ({grievanceActivities, defaultValues, onSubmit, onCancel}) => {

  const {register, handleSubmit, reset} = useForm();
  const selectRef = useRef();
  const {getName} = useAllName();

  const {users} = useUsersAdvocateList();
  const [userSelected, setUserSelected] = useState({});

  const [activityDate, setActivityDate] = useState(new Date());

  let currentAdvocate = defaultValues && getName(defaultValues.advocateUserName)
  
  useEffect(() => {
    if (defaultValues) {
      reset({...defaultValues});
      setActivityDate(dateFromISOToLocal(defaultValues.activityDate, defaultValues?.grievance?.grievanceId))
      selectRef.current && selectRef.current.setValue({name: getName(defaultValues.advocateUserName), value: defaultValues.advocateUserName})
    } else {
      reset({});
      setActivityDate(new Date())
      selectRef.current && selectRef.current.clearValue();
    }
    // eslint-disable-next-line
  }, [defaultValues, currentAdvocate]);

  return (
    <form className="row" onSubmit={handleSubmit(values => {
      if (activityDate) {
        onSubmit({...defaultValues, ...values, activityDate, advocateUserName: userSelected.value.userName})
      }
    })}>
      <div className="col-5 border-bottom-gray toast-title-item">Stage</div>
      <div className="col-6 mt-2 mb-2">
        <select className="form-control" name="stage" ref={register({required: true})}>
          {["Step 1 Filed", "Step 1 Hearing", "Step 1 Decision", "Step 2 Filed", "Step 2 Hearing", "Step 2 Decision", "Step 3 Filed", "Step 3 Hearing", "Step 3 Decision",
            "Special Complaint Filed", "Special Complaint Hearing", "Special Complaint Decision", "Mediation Filed", "Mediation Decision", "Mediation Hearing"]
            .filter(f => !grievanceActivities.some(s => s.stage === f) || defaultValues.stage === f)
            .map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
        </select>
      </div>
      <div className="col-1 text-danger no-padding" style={{paddingLeft: "5px"}}>*</div>

      <div className="col-5 border-bottom-gray toast-title-item">Date</div>
      <div className="col-6 mt-2 mb-2">
        <MyDatePicker
          dateFormat="MM/dd/yyyy"
          selected={activityDate}
          onChange={(value) => {
            setActivityDate(value);
          }}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Advocate</div>
      <div className="col-6 mt-2 mb-2 select-container">
        <Select
          options={[{name: "", value: {}}, ...users.map(user => ({name: getName(user.userName), value: user}))]}
          placeholder="Select Advocate"
          name="advocates"
          inputId="advocate-select-input"
          ref={selectRef}
          onOptionChange={(option) => {
            if (option) {
              setUserSelected(option)
            }
          }}
          scrollMenuIntoView
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.name}
        />
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Comment</div>

      <div className="col-7 mt-2 mb-2">
        <textarea className="form-control" name="comment" ref={register}/>
      </div>

      <div className="col-5 border-bottom-gray toast-title-item">Outcome</div>
      <div className="col-7 mt-2 mb-2">
        <select className="form-control" name="outcome" ref={register}>
          <option value="">-</option>
          {["Withdrawn", "Denied", "Letter Removed", "Stipulation"]
            .map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
        </select>
      </div>

      <div className="col-12 mt-4 mb-2">
        <Button disabled={!activityDate}>Save</Button>
        {" "} or {" "}
        <Button outline onClick={() => {
          onCancel()
        }}>Cancel</Button>
      </div>
    </form>
  )
};

export default GrievanceActivityForm