import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "category/";
export default () => ({
    getCategory: (id) => loading(client.request({
      method: 'GET',
      url: base + 'GetCategory/' + id
    })),
    getCategories: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => loading(client.request({
      method: 'GET',
      url: base + 'GetCategories?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + "&timestamp=" + new Date().getTime()
    })),
    getAllCategories: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetAllCategories?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    addCategory: (params) => client.request({
      method: 'POST',
      url: base + 'AddCategory',
      data: params
    }),
    updateCategory: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateCategory/' + params.id,
      data: params
    }),
    deleteCategory: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteCategory/' + id
    }),
    exportCategories: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      responseType: 'blob',
      url: base + 'ExportCategories?&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
  }
);
