import { client }  from '../util/createRestApiClient';
import { loading } from "./index";

const base = "site/";
export default () => ({
    getSite: (params) => loading(client.request({
      method: 'GET',
      url: base + 'getSite/' + params.id
    })),
    getSiteFromSiteCode: (siteCode) => client.request({
      method: 'GET',
      url: base + 'getSiteFromSiteCode/' + siteCode
    }),
    getSites: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true, siteIdOnTop = 0, membersCount = true) => loading(client.request({
      method: 'GET',
      url: base + 'getSites?limit=' + limit + '&skip=' + skip + '&filterName=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending + '&siteIdOnTop=' + siteIdOnTop + '&membersCount=' + membersCount
    })),
    addSite: (params) => client.request({
      method: 'POST',
      url: base + 'addSite',
      data: params
    }),
    addSiteComment: (siteId, siteComment) => client.request({
      method: 'POST',
      url: base + 'addSiteComment/' + siteId + "?siteComment=" + siteComment
    }),
    updateSite: (params) => client.request({
      method: 'PUT',
      url: base + 'updateSite',
      data: params
    }),
    updateSiteComment: (siteCommentId, siteComment) => client.request({
      method: 'PUT',
      url: base + 'updateSiteComment/' + siteCommentId + "?siteComment=" + siteComment
    }),
    deleteSite: (params) => client.request({
      method: 'DELETE',
      url: base + 'deleteSite/' + params.id
    })
  }
);
