import { useEffect, useState } from 'react';

export const useWidth = () => {

  const [isOnMobile, setIsOnMobile] = useState(false);

  useEffect(() => {
    // this is saving the current state because this ran only one time at the start
    let isOnMobileLastState = isOnMobile;
    const handleResize = () => {
      // if you are using server-side render, make sure that window is defined
      if (window.innerWidth < 576) {
        // don't fire the same state again
        if (!isOnMobileLastState) {
          setIsOnMobile(true);
          isOnMobileLastState = true;
        }
        // don't fire the same state again
      } else if (isOnMobileLastState) {
        setIsOnMobile(false);
        isOnMobileLastState = false;
      }
    }

    // subcribing
    window.addEventListener("resize", handleResize);

    // firing for the first time
    handleResize();

    // unsubcribing
    return () => {window.removeEventListener("resize", handleResize);}
    // eslint-disable-next-line
  }, []);

  return isOnMobile;
}

export const useIsOnWidth = (width) => {

  const [isOnWidth, setIsOnWidth] = useState(false);

  useEffect(() => {
    if (width) {
      // this is saving the current state because this ran only one time at the start
      let isOnWidthLastState = isOnWidth;
      const handleResize = () => {
        // if you are using server-side render, make sure that window is defined
        if (window.innerWidth <= width) {
          // don't fire the same state again
          if (!isOnWidthLastState) {
            setIsOnWidth(true);
            isOnWidthLastState = true;
          }
          // don't fire the same state again
        } else if (isOnWidthLastState) {
          setIsOnWidth(false);
          isOnWidthLastState = false;
        }
      }

      // subcribing
      window.addEventListener("resize", handleResize);

      // firing for the first time
      handleResize();

      // unsubcribing
      return () => {window.removeEventListener("resize", handleResize);}
    }
    // eslint-disable-next-line
  }, [width]);

  return isOnWidth;
}

export const useDefaultLimit = () => {

  const [defaultLimit, setDefaultLimit] = useState(parseInt(localStorage.getItem("defaultLimitLegal") || "10", 10) ?? 10);

  return {defaultLimit, setDefaultLimit};
}
